import React, { useCallback, useEffect, useRef, useState } from 'react'
import { t } from 'i18next'
import { ScrollView, useWindowDimensions, View, StyleSheet } from 'react-native'
import { ShimmerPlaceholder, Text } from '@libs/components'
import { useTheme } from '@libs/theme'
import LinearGradient from 'react-native-linear-gradient'
import { findPaddingHorizontal } from '../../utils/findPaddingHorizontal'
import {
  DynamicContentPanel,
  FileUploadModal,
  MyApplication,
  SummaryCard,
  VoidBox,
  WelcomeCard,
  YouTubeVideoPlayer,
} from '../../components'
import { sort } from '../../utils/sorting'
import { ActionRequired } from '../../components/actionRequired'
import { categorizeActionItems } from '../../utils/categorizeActionItems'
import PaginatedContent from '../../components/dynamicContentPanel/latestUpdate'
import UpcomingEvents from '../../components/dynamicContentPanel/events'

const DesktopView = ({
  opportunities,
  userName,
  summaryData,
  modalVisible,
  setModalVisible,
  handleSelectedOpportunity,
  selectedOpportunity,
  toggleDropdown,
  handleFileUpload,
  dropDownRef,
  dropdownTop,
  dropdownLeft,
  dropdownWidth,
  handleSubmitAllOpportunityTaskFile,
  fetchDocumentTypes,
  isSubmitLoading,
  documentType,
  isLoading,
  cmsContent,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  notifications,
  handleNextPage,
  handlePrevPage,
  handleUpdateNotification,
  handleEventNavigation,
  currentPage,
  hasSpotLightNextPage,
  hasPrevPage,
  totalPages,
  totalActionRequiredCount,
  events,
  handleEventNextPage,
  handleEventPrevPage,
  currentEventPage,
  hasEventNextPage,
  hasEventPrevPage,
  totalEventPages,
  fetchingCMSContent,
}) => {
  const { width } = useWindowDimensions()
  const { colors } = useTheme()
  const [isVideoPlayerVisible, setIsVideoPlayerVisible] = useState(false)
  const [maxHeight, setMaxHeight] = useState(0)

  const mainContentRef = useRef(null)

  // To store the heights of individual views
  const viewHeights = useRef([])

  const measureHeight = useCallback(() => {
    const totalHeight = viewHeights.current.reduce(
      (sum, height) => sum + height,
      168,
    )

    setMaxHeight(totalHeight)
  }, [])

  const handleLayout = useCallback(
    (event, index) => {
      const { height } = event.nativeEvent.layout
      viewHeights.current[index] = height
      measureHeight()
    },
    [measureHeight],
  )

  // Re-measure on window resize or relevant changes
  useEffect(() => {
    measureHeight()
  }, [width, opportunities, selectedOpportunity, measureHeight])

  const findOpportunityFromOpportunities = opportunities?.find(
    (item) => item?.Id === selectedOpportunity?.WhatId,
  )

  if (isLoading) {
    return <DesktopViewSkeletonLoader />
  }

  const handleCloseVideo = () => {
    setIsVideoPlayerVisible(false)
  }

  const handleOpenVideo = () => {
    setIsVideoPlayerVisible(true)
  }

  return (
    <>
      <ScrollView
        contentContainerStyle={styles.contentContainer(width)}
        style={{
          backgroundColor: colors.backgroundSecondary,
        }}
      >
        <View style={styles.columnContainer} ref={mainContentRef}>
          <View onLayout={(e) => handleLayout(e, 0)}>
            <WelcomeCard name={userName} handleOpenVideo={handleOpenVideo} />
          </View>

          <View onLayout={(e) => handleLayout(e, 1)}>
            {summaryData ? (
              <View style={styles.section}>
                <Text variant="heading3" color="black">
                  {t('DASHBOARD.SUMMARY')}
                </Text>
                <View style={styles.rowContainer}>
                  {summaryData?.map(({ iconName, count, label }, index) => (
                    <SummaryCard
                      key={index}
                      iconName={iconName}
                      count={count}
                      label={label}
                      colors={colors}
                    />
                  ))}
                </View>
              </View>
            ) : null}
          </View>

          <View onLayout={(e) => handleLayout(e, 2)} style={[styles.section]}>
            <Text variant="heading3" color="black">
              {t('DASHBOARD.ACTION_REQUIRED')}
            </Text>
            {totalActionRequiredCount > 0 ? (
              sort(opportunities, 'CreatedDate')?.map((opportunity, index) => {
                let restructuredResponse
                if (opportunity) {
                  restructuredResponse = categorizeActionItems(opportunity)
                }
                return (
                  <ActionRequired
                    key={index}
                    opportunityDetails={restructuredResponse}
                    handleSelectedOpportunity={handleSelectedOpportunity}
                    setModalVisible={setModalVisible}
                    handleSubmitAllOpportunityTaskFile={
                      handleSubmitAllOpportunityTaskFile
                    }
                    fetchDocumentTypes={fetchDocumentTypes}
                    isSubmitLoading={isSubmitLoading}
                  />
                )
              })
            ) : (
              <VoidBox
                heading1={t('EMPTY_CONTAINER.DASHBOARD_NEW_ACTION_REQUIRED')}
                heading2={t('EMPTY_CONTAINER.NO_ACTION_REQUIRED')}
              />
            )}
          </View>

          <View
            onLayout={(e) => handleLayout(e, 3)}
            style={[styles.section, styles.applicationSection]}
          >
            <Text variant="heading3" color="black">
              {t('DASHBOARD.MY_APPLICATION')}
            </Text>
            {sort(opportunities, 'CreatedDate')?.map((item, index) => (
              <MyApplication key={index} data={item} />
            ))}
          </View>
        </View>

        <ScrollView
          contentContainerStyle={[
            styles.sidePanel,
            { rowGap: 24, borderRadius: 10 },
          ]}
          style={{ flex: 0.43, maxHeight, borderRadius: 10 }} // Apply the calculated maxHeight
          showsVerticalScrollIndicator
        >
          <View style={{ gap: 16 }}>
            <Text
              variant="heading4"
              color="black"
              style={{ letterSpacing: 0.2 }}
            >
              {t('DASHBOARD.SPOTLIGHT')}
            </Text>
            {totalPages ? (
              <View>
                <PaginatedContent
                  notifications={notifications}
                  handleNextPage={handleNextPage}
                  handlePrevPage={handlePrevPage}
                  handleUpdateNotification={handleUpdateNotification}
                  handleEventNavigation={handleEventNavigation}
                  currentPage={currentPage}
                  hasNextPage={hasSpotLightNextPage}
                  hasPrevPage={hasPrevPage}
                  totalPages={totalPages}
                />
              </View>
            ) : null}

            {totalEventPages ? (
              <View
                style={{
                  borderWidth: 0.4,
                  borderColor: 'rgba(0,0,0,0.2)',
                  borderStyle: 'solid',
                }}
              />
            ) : null}

            {totalEventPages ? (
              <>
                <Text
                  variant="heading4"
                  color="black"
                  style={{ letterSpacing: 0.2 }}
                >
                  {t('DASHBOARD.UPCOMING_EVENTS')}
                </Text>
                <UpcomingEvents
                  events={events}
                  handleEventNextPage={handleEventNextPage}
                  handleEventPrevPage={handleEventPrevPage}
                  currentEventPage={currentEventPage}
                  hasEventNextPage={hasEventNextPage}
                  hasEventPrevPage={hasEventPrevPage}
                  totalEventPages={totalEventPages}
                />
              </>
            ) : null}

            {cmsContent?.length ? (
              <View
                style={{
                  borderWidth: 0.4,
                  borderColor: 'rgba(0,0,0,0.2)',
                  borderStyle: 'solid',
                  // marginTop: 24,
                }}
              />
            ) : null}
            {fetchingCMSContent ? (
              <ShimmerPlaceholder
                style={{
                  borderRadius: 12,
                  width: '100%',
                  height: 500,
                }}
              />
            ) : cmsContent?.length ? (
              <DynamicContentPanel
                cmsContent={cmsContent}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
              />
            ) : null}
          </View>
        </ScrollView>

        <FileUploadModal
          visible={modalVisible}
          title={t('APPLICATION_DETAIL.ATTACH_DOCUMENT')}
          toggleDropdown={toggleDropdown}
          handleSave={(documentDetails) =>
            handleFileUpload(documentDetails, findOpportunityFromOpportunities)
          }
          handleClose={() => setModalVisible(false)}
          dropDownPosition={{ left: dropdownLeft + 10, top: dropdownTop + 10 }}
          dropdownWidth={dropdownWidth}
          documentType={documentType}
          dropDownRef={dropDownRef}
          selectedDropDownValue={selectedOpportunity?.documentType}
          taskId={selectedOpportunity?.Id}
        />
      </ScrollView>
      <View style={{ position: 'absolute', top: '66vh', right: 170 }}>
        <YouTubeVideoPlayer
          videoId={process.env.REACT_APP_DASHBOARD_VIDEO_ID}
          onClose={handleCloseVideo}
          isVideoPlayerVisible={isVideoPlayerVisible}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  contentContainer: (width) => ({
    paddingHorizontal: findPaddingHorizontal(width),
    paddingVertical: 56,
    flexDirection: 'row',
    gap: 22,
  }),
  columnContainer: {
    flex: 1,
    gap: 56,
  },
  section: {
    flexDirection: 'column',
    gap: 24,
  },
  rowContainer: {
    flexDirection: 'row',
    gap: 20,
    width: '100%',
  },

  sidePanel: {
    backgroundColor: 'rgba(221, 224, 238, 1)',
    paddingHorizontal: 24,
    paddingVertical: 32,
  },
})

export default DesktopView

const DesktopViewSkeletonLoader = () => {
  const { colors } = useTheme()
  const { width } = useWindowDimensions()
  return (
    <ScrollView
      contentContainerStyle={{
        flexDirection: 'row',
        paddingHorizontal: findPaddingHorizontal(width),
        gap: 32,
        marginVertical: 56,
      }}
      style={{ backgroundColor: colors.backgroundSecondary }}
    >
      <View
        style={{
          flex: 1,
          gap: 26,
        }}
      >
        <ShimmerPlaceholder
          style={{
            borderRadius: 12,
            width: '100%',
            height: 301,
          }}
        />

        {/* Summary Section Skeleton */}
        <View style={{ gap: 24 }}>
          <Text variant="heading3" color="black">
            {t('DASHBOARD.SUMMARY')}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              gap: 20,
            }}
          >
            {[...Array(2)].map((_, index) => (
              <ShimmerPlaceholder
                key={index}
                LinearGradient={LinearGradient}
                style={{
                  width: '49%',
                  height: 140,
                  borderRadius: 10,
                }}
              />
            ))}
          </View>
        </View>

        {/* Action Required Section Skeleton */}
        <View style={{ gap: 24 }}>
          <Text variant="heading3" color="black">
            {t('DASHBOARD.ACTION_REQUIRED')}
          </Text>
          <ShimmerPlaceholder
            LinearGradient={LinearGradient}
            style={{
              height: 200,
              borderRadius: 10,
              width: '100%',
            }}
          />
        </View>

        {/* My Application Section Skeleton */}
        <View style={{ gap: 24 }}>
          <Text variant="heading3" color="black">
            {t('DASHBOARD.MY_APPLICATION')}
          </Text>
          {[...Array(2)].map((_, index) => (
            <ShimmerPlaceholder
              key={index}
              LinearGradient={LinearGradient}
              style={{
                height: 150,
                width: '100%',
                borderRadius: 10,
                marginBottom: 20,
              }}
            />
          ))}
        </View>
      </View>

      {/* Side Panel Skeleton */}
      <ShimmerPlaceholder
        style={{
          flex: 0.43,
          borderRadius: 5,
          width: 200,
          height: '80%',
        }}
      />
    </ScrollView>
  )
}
