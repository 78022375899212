import React, { useEffect, useState } from 'react'
import {
  Modal,
  Animated,
  TouchableOpacity,
  View,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native'
import { BlurView } from 'expo-blur'

const BottomSheet = ({
  children,
  triggerComponent,
  drawerHeight = 220,
  isVisible,
  setIsVisible = () => {},
  style,
}) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(isVisible)
    if (isVisible) {
      openDrawer()
    } else {
      closeDrawer()
    }
  }, [isVisible])

  useEffect(() => {
    setIsVisible(visible)
  }, [visible])

  const animation = useState(new Animated.Value(0))[0]

  const openDrawer = () => {
    setVisible(true)
    Animated.timing(animation, {
      toValue: 1,
      duration: 400,
      useNativeDriver: true,
    }).start()
  }

  const closeDrawer = () => {
    Animated.timing(animation, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => setVisible(false))
  }

  const animatedHeight = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, drawerHeight],
  })

  return (
    <>
      <TouchableOpacity onPress={openDrawer}>
        {triggerComponent}
      </TouchableOpacity>

      <Modal visible={visible} transparent animationType="none">
        <BlurView intensity={60} style={styles.overlay}>
          <TouchableOpacity
            style={styles.overlayTouchable}
            onPress={closeDrawer}
            activeOpacity={1}
          >
            <TouchableWithoutFeedback>
              <Animated.View
                style={[
                  styles.drawer,
                  {
                    height: animatedHeight,
                    maxHeight: '80%',
                    minHeight: animatedHeight,

                    overflow: 'scroll',
                  },
                ]}
              >
                <View style={[styles.drawerContent, style]}>{children}</View>
              </Animated.View>
            </TouchableWithoutFeedback>
          </TouchableOpacity>
        </BlurView>
      </Modal>
    </>
  )
}

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(2, 21, 71, 0.37)',
  },
  overlayTouchable: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(2, 21, 71, 0.37)',
  },
  drawer: {
    backgroundColor: '#fff',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
  },
  drawerContent: {
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
})

export default BottomSheet
