import { Icon } from '@app-hero/native-icons'
import { useTheme } from '@libs/theme'
import { BlurView } from 'expo-blur'
import React, { useState, useRef, useEffect } from 'react'
import { View, TouchableOpacity, Modal } from 'react-native'
import YouTubeVideoPlayerMobile from './index.mobile'

const YouTubeVideoPlayer = ({ videoId, onClose, isVideoPlayerVisible }) => {
  const [isMinimized, setIsMinimized] = useState(false)
  const handleMinimizeToggle = () => {
    setIsMinimized(!isMinimized)
  }

  if (window.innerWidth <= 1023) {
    return (
      <YouTubeVideoPlayerMobile
        videoId={videoId}
        onClose={onClose}
        isVideoPlayerVisible={isVideoPlayerVisible}
      />
    )
  }

  return (
    <View style={{ position: 'absolute', bottom: 0 }}>
      {!isMinimized && (
        <Modal visible={isVideoPlayerVisible} transparent animationType="none">
          <BlurView
            intensity={60}
            style={{
              flex: 1,
              backgroundColor: 'rgba(11, 92, 215, 0.2)',
            }}
          >
            <View style={{ flex: 1 }}>
              <DraggableIframe
                videoId={videoId}
                onClose={onClose}
                isMinimized={isMinimized}
                onMinimize={handleMinimizeToggle}
                setIsMinimized={setIsMinimized}
              />
            </View>
          </BlurView>
        </Modal>
      )}

      {isMinimized && isVideoPlayerVisible && (
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '25%',
            height: '25%',
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderRadius: 10,
          }}
        >
          <DraggableIframe
            videoId={videoId}
            onClose={onClose}
            isMinimized={isMinimized}
            onMinimize={handleMinimizeToggle}
            setIsMinimized={setIsMinimized}
          />
        </View>
      )}
    </View>
  )
}

const DraggableIframe = ({
  videoId,
  onClose,
  isMinimized,
  onMinimize,
  setIsMinimized,
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
  const [isHovered, setIsHovered] = useState(false) // State to track hover
  const iframeRef = useRef(null)
  const { colors } = useTheme()

  const handleMouseDown = (e) => {
    setIsDragging(true)
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    })
  }

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y,
      })
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, dragStart])

  const iframeWidth = isMinimized ? 300 : window.innerWidth / 1.5
  const iframeHeight = isMinimized ? 160 : window.innerHeight / 1.5

  return (
    <View
      ref={iframeRef}
      style={{
        width: iframeWidth,
        height: iframeHeight,
        justifyContent: 'center',
        flex: 1,
        alignSelf: 'center',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            backgroundColor: colors.neutral,
            paddingHorizontal: 16,
            paddingVertical: 8,
            width: !isMinimized ? iframeWidth + 38 : iframeWidth,
            borderTopLeftRadius: isMinimized ? 10 : 0,
            borderTopRightRadius: isMinimized ? 10 : 0,
          }}
        >
          <TouchableOpacity onPress={onMinimize} style={{ marginRight: 16 }}>
            <Icon
              name={isMinimized ? 'MaximizeIcon' : 'MinimizeIcon'}
              size={20}
              color={colors.white}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              onClose()
              setIsMinimized(false)
            }}
          >
            <Icon name="Close" size={20} color={colors.white} />
          </TouchableOpacity>
        </View>
      )}
      <div onMouseDown={handleMouseDown} style={{ cursor: 'move' }}>
        <iframe
          width={iframeWidth}
          height={iframeHeight}
          src={`https://www.youtube.com/embed/${videoId}?autoplay=0&controls=1&playsinline=1`}
          title="YouTube video player"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          style={{
            border: !isMinimized ? '20px solid white' : 'none',
            borderBottomLeftRadius: isMinimized ? 10 : 0,
            borderBottomRightRadius: isMinimized ? 10 : 0,
            borderTopLeftRadius: isMinimized && !isHovered ? 10 : 0,
            borderTopRightRadius: isMinimized && !isHovered ? 10 : 0,
          }}
        />
      </div>
    </View>
  )
}

export default YouTubeVideoPlayer
