import { Text } from '@libs/components'
import { useTheme } from '@libs/theme'
import { isWeb } from '@libs/utils/src/screenLayout'
import React from 'react'
import { StyleSheet, useWindowDimensions, View } from 'react-native'

export const TitleHeader = ({ title, children, style }) => {
  const { colors } = useTheme()
  const { width } = useWindowDimensions()
  const isDesktop = isWeb(width)
  if (children) {
    return (
      <View style={[styles.childrenContainer(colors, isDesktop), style]}>
        {children}
      </View>
    )
  } else {
    return (
      <View style={styles.container(colors, isDesktop)}>
        <Text
          variant="heading3"
          color={colors.white}
          style={{ fontWeight: 600 }}
        >
          {title}
        </Text>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: (colors, isDesktop) => ({
    height: isDesktop ? 97 : 'auto',
    paddingHorizontal: isDesktop ? 39 : 24,
    paddingVertical: isDesktop ? 33 : 21,
    width: '100%',
    backgroundColor: colors.teritiary,
    borderRadius: 10,
  }),
  childrenContainer: (colors) => ({
    paddingHorizontal: 39,
    paddingVertical: 33,
    width: '100%',
    backgroundColor: colors.teritiary,
    borderRadius: 10,
  }),
})
