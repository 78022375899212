import { Badge, Text } from '@libs/components'
import { useTheme } from '@libs/theme'
import { SecureStore } from '@libs/utils'
import { formatIntakeDate } from '@libs/utils/src/dateformat'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import {
  Image,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native'

export const MyApplicationMobile = ({ data, style, disabled }) => {
  const { width } = useWindowDimensions()
  const [brandLogoUrl, setBrandLogoUrl] = useState('')
  const { colors } = useTheme()
  const navigation = useNavigation()
  const isActionRequired = window.location.pathname === '/action-required'

  const handleNavigation = () => {
    navigation.navigate('view-application-details', {
      id: data.Id,
      isActive:
        data?.ActionRequiredCount > 0 ? 'ACTION_REQUIRED' : 'INFORMATION',
      isDashboard: true,
    })
  }

  const isFocused = useIsFocused()

  const badgeColor = {
    Offer: {
      color: '#009951',
      backgroundColor: '#CFF7D3',
    },
    Accepted: {
      color: '#009951',
      backgroundColor: '#CFF7D3',
    },
    Enrolment: {
      color: '#009951',
      backgroundColor: '#CFF7D3',
    },
    Admission: {
      color: '#D4691B',
      backgroundColor: '#FFF4E0',
    },
    Draft: {
      backgroundColor: '#FFF4E0',
      color: '#D4691B',
    },
    Visa: {
      backgroundColor: '#FFF4E0',
      color: '#D4691B',
    },
    Withdrawn: {
      backgroundColor: '#FDD3D0',
      color: '#C00F0C',
    },
  }

  const duration = data?.OpportunityLineItems?.records[0]?.Product2?.Duration__c
  const opportunitiesDetails = [
    {
      label: t('APPLICATION_DETAIL.INTAKE'),
      value: data.IntakeDate ? formatIntakeDate(data.IntakeDate) : '-',
      separate: false,
    },
    {
      value: duration || '-',
      label: t('APPLICATION_DETAIL.DURATION'),
      separate: false,
    },
    {
      label: t('APPLICATION_DETAIL.LOCATION'),
      value: data?.OpportunityLineItems?.records?.[0]?.Location__c || '-',
      separate: false,
    },
  ]

  const formattedStatus = (status) => {
    if (status?.split(' ').length === 2) {
      return status?.split(' ')[1]
    } else if (
      status?.split(' ').length === 3 &&
      status?.split(' ')[0] === 'Visa'
    ) {
      return 'Visa'
    } else {
      return status
    }
  }

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  const programName = data?.ProgrammeName__c
    ? `${data?.ProgrammeName__c}${
        data?.Delivery_Mode__c ? `, ${data?.Delivery_Mode__c}` : ''
      }`
    : ''
  return (
    <TouchableOpacity
      style={[styles.mainContainer, style]}
      onPress={() => handleNavigation()}
      disabled={disabled}
    >
      <View>
        <Badge
          style={[
            badgeColor[data?.ApplicationStatus?.status.split(' ')[0]] ||
              badgeColor[data?.ApplicationStatus?.status.split('/')[0]],
            { justifyContent: 'flex-start', alignSelf: 'baseline' },
          ]}
          textVariant="display5"
        >
          Status : {formattedStatus(data?.ApplicationStatus?.status)}
        </Badge>
      </View>

      <Image
        source={`${brandLogoUrl}/${data.BusinessUnitFilter__c?.replaceAll(
          ' ',
          '_',
        )}.png`}
        style={{
          height: 55,
          width: 126,
        }}
        alt={data?.brand}
        resizeMode="contain"
      />

      <View>
        <Text
          style={{
            fontWeight: 700,
            fontSize: 16,
            color: colors.cardTitleColor,
            paddingRight: 20,
          }}
        >
          {programName ? programName : data?.Name?.split('_')[0]}
        </Text>

        <View
          style={{
            marginTop: 24,
            gap: 8,
            flex: 1,
          }}
        >
          {opportunitiesDetails
            ?.slice(0, isActionRequired ? 2 : 3)
            .map((item, index) => (
              <View
                key={index}
                style={{
                  flexDirection: width >= 300 ? 'row' : 'column',
                  justifyContent: width >= 300 ? 'space-between' : '',
                  flexWrap: width <= 300 ? 'wrap' : 'nowrap',
                  gap: width <= 300 ? 4 : 0,
                }}
              >
                <Text variant="display4" color="#545B61">
                  {item.label}:{' '}
                </Text>
                <Text variant="heading6" color={colors.textTeritiary}>
                  {item.value}
                </Text>
              </View>
            ))}
        </View>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    borderRadius: 10,
    backgroundColor: '#fff',
    padding: 24,
  },
  divider: (colors) => ({
    backgroundColor: colors.onNeutral,
    width: 1,
    height: '100%',
    marginHorizontal: 15,
  }),
  buttonStyleOutlined: (colors, isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 40 : 20,
    paddingVertical: 10,
    borderColor: colors.primaryContainer,
  }),
  buttonStyleFilled: (isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 40 : 20,
    paddingVertical: 10,
  }),
})
