import { View, StyleSheet, ImageBackground, ScrollView } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  CheckBox,
  FilePicker,
  Loader,
  MobileInput,
  Text,
  TextInput,
} from '@libs/components'

import SearchDropDown from '@libs/components/src/DropDown/SearchDropdown'
import { useTheme } from '@libs/theme'
import ModalComponent from '../../components/modal'
import { TitleHeader } from '../../components/headerTitle'
import { findPaddingHorizontal } from '../../utils/findPaddingHorizontal'
import LoadingSpinner from '../../components/dynamicContentPanel/customLoader'

const DesktopView = ({
  ctaEnable,
  handleChange,
  countryList,
  DropdownButton,
  dropdownRight,
  dropdownTop,
  dropdownWidth,
  successPopup,
  setSuccessPopup,
  isProfileUpdating,
  isLastNameAvailable,
  profileDetails,
  handleSave,
  getImageSource,
  setProfileDetails,
  toggleDropdown,
  setIsLastNameAvailable,
  errorMessage,
  setCTAEnable,
  mobileNumberError,
  firstNameError,
  lastNameError,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()

  return (
    <ImageBackground
      style={{ flex: 1 }}
      imageStyle={{
        width: '60%',
        position: 'absolute',
        top: 0,
        left: '40%',
      }}
    >
      <ScrollView style={{ flex: 1, backgroundColor: '#F4F5FB' }}>
        <View
          style={{
            marginTop: 32,
            paddingHorizontal: findPaddingHorizontal(window.innerWidth),
          }}
        >
          <TitleHeader title={t('PROFILE.MY_PROFILE')} />
        </View>
        <View style={[styles.mainContainer]}>
          <View style={styles.profileContainer}>
            <View style={styles.contentContainer}>
              <View
                style={{
                  flexDirection: 'row',
                  columnGap: 20,
                  flexWrap: 'wrap',
                }}
              >
                <View
                  style={[
                    styles.imageContainer,
                    { backgroundColor: colors.backgroundSecondary },
                  ]}
                >
                  <View
                    style={{
                      height: '100%',
                      width: '100%',
                      overflow: 'hidden',
                      paddingHorizontal: 25,
                      paddingVertical: 20,
                    }}
                  >
                    <FilePicker
                      isProfile
                      handleFileSelect={(image) =>
                        handleChange('profilePictureLocation', image)
                      }
                      source={getImageSource()}
                      profileStyle={{
                        borderRadius: '80%',
                        width: 130,
                        height: 130,
                      }}
                    />
                  </View>
                </View>
                <View style={styles.formContainer}>
                  <View style={styles.username}>
                    <View>
                      <TextInput
                        placeholder={t('TEXT_INPUT.FIRST_NAME')}
                        onChangeText={(text) => handleChange('firstName', text)}
                        value={profileDetails.firstName}
                        dropDownData=""
                        style={{
                          backgroundColor: 'white',
                          paddingVertical: 10,
                          height: 56,
                          borderRadius: 4,
                          borderColor: '#E0E0E0',
                        }}
                        editable
                        limit={35}
                      />
                      {firstNameError && (
                        <Text
                          variant="display3"
                          color={colors.onAlert}
                          style={{ marginBottom: 10 }}
                        >
                          {firstNameError}
                        </Text>
                      )}
                    </View>
                    <TextInput
                      placeholder={t('TEXT_INPUT.LAST_NAME')}
                      onChangeText={(text) => handleChange('lastName', text)}
                      value={profileDetails.lastName}
                      style={{
                        backgroundColor: 'white',
                        paddingVertical: 10,
                        height: 56,
                        borderRadius: 4,
                        borderColor: '#E0E0E0',
                      }}
                      dropDownData=""
                      editable={!isLastNameAvailable}
                      limit={35}
                    />
                    {lastNameError && (
                      <Text
                        variant="display3"
                        color={colors.onAlert}
                        style={{ marginBottom: 10 }}
                      >
                        {lastNameError}
                      </Text>
                    )}
                    <View
                      style={styles.checkboxContainer}
                      className="checkboxContainer"
                    >
                      <CheckBox
                        value={isLastNameAvailable}
                        handleCheck={() => {
                          setIsLastNameAvailable(!isLastNameAvailable)
                          setCTAEnable(true)
                        }}
                      />
                      <Text variant="display4" color="#0A0A0A">
                        I don’t have last name
                      </Text>
                    </View>
                    <View>
                      <MobileInput
                        countryBasedOn={profileDetails.mobile.mobileCountry}
                        value={profileDetails.mobile.mobileNumber}
                        onChangeText={(text) =>
                          handleChange('mobile', {
                            dialCode: profileDetails.mobile.dialCode,
                            mobileNumber: text,
                            mobileCountryCode:
                              profileDetails.mobile.mobileCountryCode,
                            mobileCountry: profileDetails.mobile.mobileCountry,
                          })
                        }
                        placeholder={t('TEXT_INPUT.PHONE')}
                        dialCode={profileDetails.mobile.dialCode}
                        placeholderColor="#E0E0E0"
                        style={{
                          borderColor: '#E0E0E0',
                          height: 56,
                        }}
                        searchDropDownBackgroundColor={colors.primaryBackground}
                        handleCountrySelection={(selectedCountry) => {
                          setProfileDetails((previousData) => ({
                            ...previousData,
                            mobile: {
                              dialCode: selectedCountry.dial_code,
                              mobileNumber: previousData.mobile.mobileNumber,
                              mobileCountryCode: selectedCountry.code,
                              mobileCountry: selectedCountry.name.en,
                            },
                          }))
                          handleChange('mobile', {
                            dialCode: selectedCountry.dial_code,
                            mobileNumber: profileDetails.mobile.mobileNumber,
                            mobileCountryCode: selectedCountry.code,
                            mobileCountry: selectedCountry.name.en,
                          })
                        }}
                        code={profileDetails.mobile.mobileCountryCode}
                      />
                      {mobileNumberError && (
                        <Text
                          variant="display3"
                          color={colors.onAlert}
                          style={{ gap: 0 }}
                        >
                          {mobileNumberError}
                        </Text>
                      )}
                    </View>
                  </View>
                  <View
                    style={{ flex: 1, marginBottom: 20, marginTop: 20 }}
                    ref={DropdownButton}
                  >
                    <SearchDropDown
                      style={{
                        margin: 0,
                        width: '100%',
                        backgroundColor: 'white',
                        borderRadius: 4,
                        borderColor: '#E0E0E0',
                        height: 56,
                      }}
                      label={t('TEXT_INPUT.COUNTRY')}
                      labelColor={colors.onNeutral}
                      placeHolderColor="#E0E0E0"
                      toggleDropdown={toggleDropdown}
                      onPress={(selectedCountry) =>
                        handleChange('country', selectedCountry)
                      }
                      dropdownWidth={dropdownWidth}
                      isCountryCode
                      items={countryList || []}
                      value={
                        profileDetails.country
                          ? {
                              Label: profileDetails.country,
                              Value: profileDetails.countryCode,
                            }
                          : {}
                      }
                      position={{
                        top: dropdownTop,
                        right: dropdownRight - 205,
                      }}
                      dropdownHeight={150}
                    />
                  </View>
                  <TextInput
                    label="Email ID"
                    onChangeText={() => {}}
                    value={profileDetails?.email || ''}
                    editable={false}
                    dropDownData=""
                    style={{
                      marginBottom: 20,
                      backgroundColor: 'white',
                      paddingVertical: 10,
                      height: 56,
                      borderRadius: 4,
                      borderColor: '#E0E0E0',
                    }}
                    inputFieldStyle={{ color: colors.primaryPlaceHolder }}
                  />
                  {errorMessage ? (
                    <Text
                      variant="display4"
                      color={colors.onAlert}
                      style={{ marginBottom: 20 }}
                    >
                      {errorMessage}
                    </Text>
                  ) : null}
                </View>
              </View>

              {isProfileUpdating ? (
                <View style={{ marginBottom: 30, alignSelf: 'flex-end' }}>
                  <LoadingSpinner size={32} hasFlexValue={false} />
                </View>
              ) : (
                <Button
                  label={t('BUTTON.SAVE_CHANGES')}
                  labelStyle={{
                    color: colors.white,
                    flexDirection: 'row',
                    fontSize: 14,
                    fontWeight: '700',
                  }}
                  onPress={() => handleSave()}
                  disable={!ctaEnable}
                  appearance="filled"
                  buttonStyle={{
                    alignItems: 'center',
                    borderRadius: 4,
                    paddingHorizontal: 70,
                    paddingVertical: 12,
                    justifyContent: 'center',
                    backgroundColor: '#2563EB',
                    marginLeft: 'auto',
                  }}
                />
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      <ModalComponent
        visible={successPopup.visible}
        title={successPopup.message}
        buttonLabel={t('BUTTON.OK')}
        handleCloseModal={() => {
          setSuccessPopup({
            visible: false,
            message: '',
          })
        }}
        handleButton={() => {
          setSuccessPopup({
            visible: false,
            message: '',
          })
        }}
        buttonStyle={{
          borderRadius: 4,
        }}
        titleVariant="display3"
        labelStyle={{ fontWeight: 700 }}
        buttonColor="#2563EB"
        labelColors="white"
      />
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
    maxWidth: 903,
    marginHorizontal: 'auto',
    paddingVertical: 30,
  },
  profileContainer: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    shadowColor: 'rgba(0, 15, 68, 0.15)',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 5,
    borderRadius: 20,
    padding: 35,
    paddingBottom: 30,
  },
  profileHeader: {
    height: 70,
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'space-between',
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    alignItems: 'center',
  },
  imageContainer: {
    height: 167,
    width: 196,
    paddingHorizontal: 10,
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#FFFFFF',
  },

  formContainer: {
    alignSelf: 'center',
    flex: 1,
    marginTop: 5,
  },
  subContainer: {
    width: 320,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    marginLeft: 2,
    marginTop: -10,
  },
  divider: {
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    marginTop: 20,
  },
  profileLeftHeader: {
    flexDirection: 'row',
    columnGap: 30,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  myProfileText: (colors) => ({
    fontWeight: 700,
    fontSize: 24,
    color: colors.primaryText,
    letterSpacing: 0.2,
  }),

  contentContainer: {
    borderRadius: 16,
    columnGap: 20,
  },
  username: {
    flexDirection: 'column',
    rowGap: 6,
  },
})

export default DesktopView
