import React, { useCallback, Suspense, useState, useEffect } from 'react'
import { ScreenLayout, SecureStore } from '@libs/utils'
import { Text } from 'react-native'
import { useAtom } from 'jotai'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import {
  useUpdateBasket,
  useUpdateCompareProgrammesId,
} from '@app-hero/shared-hooks'
import DesktopView from './DesktopView'
import { selectedProgramIds, userProfile } from '../../utils/atom'
import {
  getAllPrograms,
  getPricingBookDetail,
  getProgramsDetailsById,
} from '../../api'
import { isValidBasket } from '../../utils/function'

const ProgrammeDetails = (props) => {
  const { t } = useTranslation()
  const [showBrandName, setShowBrandName] = useState(false)
  const [userprofile] = useAtom(userProfile)
  const isFocused = useIsFocused()
  const [programIds, setSelectedProgramId] = useAtom(selectedProgramIds)
  const queryClient = useQueryClient()
  const [isCompareProgrammesIdUpdate, setIsCompareProgrammesIdUpdate] =
    useState(false)
  const basketItems = queryClient.getQueryData([
    `getBasketItems-${userprofile?.email}`,
  ])
  const programId = props?.route?.params?.id
  const brand = props?.route?.params?.brand
  const [tabData, setTabData] = useState()
  const [recommendedProgram, setRecommendedProgram] = useState()
  const heroContainerDescriptionList = [
    {
      label: t('PROGRAM_SCREEN.BRAND'),
      fieldName: 'school',
      type: 'image',
    },
    {
      label: t('PROGRAM_SCREEN.DELIVERY'),
      fieldName: 'delivery',
      type: 'badge',
    },
    {
      label: t('PROGRAM_SCREEN.DURATION'),
      fieldName: 'duration',
      type: 'badge',
    },
    {
      label: t('PROGRAM_SCREEN.LOCATION'),
      fieldName: 'location',
      type: 'badge',
    },

    {
      label: t('PROGRAM_SCREEN.LEVEL'),
      fieldName: 'level',
      type: 'text',
    },
  ]
  const [alertDetails, setAlertDetails] = useState({
    isVisible: false,
    type: '',
  })
  const [isBasketLoading, setIsBasketLoading] = useState(false)
  const navigation = useNavigation()
  const [intakeData, setIntakeData] = useState([])
  const [brandLogoUrl, setBrandLogoUrl] = useState('')

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  const fetchProgramById = async () => {
    const programData = await getProgramsDetailsById({
      id: programId,
      brand: brand,
    })
    const allPrograms = await getAllPrograms({
      filterQuery: {
        size: 3,
        from: 0,
        filters: {
          Institution: {
            values: [programData?.brandFullName],
          },
          Subject: {
            values: [programData?.subject],
          },
        },
      },
    })
    setRecommendedProgram(allPrograms?.programList)
    return programData
  }

  const fetchPricingBookDetail = async (program) => {
    const pricingData = await getPricingBookDetail({
      id: programId,
      brand: program?.brand,
    })
    return pricingData?.priceEntries
  }

  const { data: programmes, isFetching: isFetchingProgram } = useQuery({
    queryKey: ['getProgramById'],
    queryFn: fetchProgramById,
    enabled: isFocused && !!programId,
    initialData: [],
  })

  const { data: pricingData } = useQuery({
    queryKey: ['getProgramPricingData'],
    queryFn: () => fetchPricingBookDetail(programmes),
    enabled: isFocused && !!programId && !!programmes?.brand,
    initialData: [],
  })

  useEffect(() => {
    if (
      !isFocused &&
      programmes?.length === 0 &&
      pricingData?.priceEntries?.length === 0
    )
      return
    ;(() => {
      const tabsData = programmes?.location
        ?.map((item) => {
          if (item) {
            return {
              [item]: [],
            }
          }
        })
        .filter((item) => item !== undefined)

      pricingData?.map((item) => {
        const matchingTab = tabsData?.find(
          (tabItem) => Object.keys(tabItem)[0] === item?.location,
        )
        if (matchingTab) {
          matchingTab[Object.keys(matchingTab)[0]].push(item)
        }
        return null
      })
      const groupedData = tabsData?.map((intakeItem) => {
        const cityName = Object?.keys(intakeItem)[0]
        const cityPriceEntries = intakeItem[cityName]
        const groupedCityPriceEntries = cityPriceEntries?.reduce(
          (result, priceEntry) => {
            const { priceBookName } = priceEntry

            if (!result[priceBookName]) {
              result[priceBookName] = []
            }
            result[priceBookName]?.push(priceEntry)

            return result
          },
          {},
        )

        return {
          [cityName]: groupedCityPriceEntries,
        }
      })

      const tabs = groupedData?.reduce((keys, item) => {
        const objKeys = Object.keys(item)
        if (
          objKeys.length === 1 &&
          Object.keys(item[objKeys[0]]).length === 0
        ) {
          return keys
        }
        return [...keys, ...objKeys]
      }, [])

      const filterData = groupedData?.filter((item) =>
        tabs.includes(Object.keys(item)[0]),
      )

      setTabData(tabs)
      setIntakeData(filterData)
    })()
  }, [isFocused, programmes, pricingData])

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, DesktopView, DesktopView),
    [],
  )

  const { mutation } = useUpdateBasket()
  const { mutation: updateProgramId } = useUpdateCompareProgrammesId()

  // updateProgrammesId
  const updateCompareProgrammesIds = async ({ programId, type }) => {
    setIsCompareProgrammesIdUpdate(true)
    const response = await updateProgramId.mutateAsync({ programId, type })
    setSelectedProgramId(response)
    setIsCompareProgrammesIdUpdate(false)
  }

  // updateCart
  const updateCartItems = async ({ programDetails }) => {
    const validBasket = isValidBasket({
      programDetails,
      basketItems,
      programmeId: programId,
    })
    setIsBasketLoading(true)
    if (validBasket?.canAdd) {
      await mutation.mutateAsync({
        email: userprofile?.email,
        bucketId: userprofile?.bucketId,
        programmeId: programId,
        programDetails,
      })
      setIsBasketLoading(false)
    } else {
      setAlertDetails({ isVisible: true, type: validBasket?.type })
      setIsBasketLoading(false)
    }
  }

  const handleAddToComparison = (isChecked) => {
    const mappedId = `${brand}_${programId}`
    if (!isChecked && !programIds.includes(mappedId)) {
      updateCompareProgrammesIds({ programId: mappedId, type: 'update' })
    } else {
      updateCompareProgrammesIds({ programId: mappedId, type: 'delete' })
    }
  }

  const handleNavigation = () =>
    navigation.navigate('apply-programmes', { id: programId })

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      await queryClient.invalidateQueries(['getBasketItems'])
    })()
  }, [basketItems?.length, isFocused])

  const viewProps = {
    brandLogoUrl,
    intakeData,
    alertDetails,
    isLoading: isFetchingProgram,
    isCompareProgrammesIdUpdate,
    programId,
    brand,
    programIds,
    programmes,
    isBasketLoading,
    recommendedProgram,
    heroContainerDescriptionList,
    tabData,
    showBrandName,
    updateCartItems,
    updateCompareProgrammesIds,
    setShowBrandName,
    setSelectedProgramId,
    setAlertDetails,
    handleAddToComparison,
    handleNavigation,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default ProgrammeDetails
