import { fetchCMSContent } from '@app-hero/shared/src/api'
import { useInfiniteQuery } from '@tanstack/react-query'

export const useInfiniteCMSContent = (initialPayload, enabled) =>
  useInfiniteQuery({
    queryKey: ['cmsContent', initialPayload],
    queryFn: async ({ pageParam = 1 }) =>
      fetchCMSContent({ ...initialPayload, page: pageParam }),
    getNextPageParam: (lastPage) =>
      lastPage.hasMore ? lastPage.nextPage : null,
    select: (data) => ({
      pages: data.pages.flatMap((page) => page.data),
      pageParams: data.pageParams,
    }),
    enabled,
  })
