import { useMutation, useQueryClient } from '@tanstack/react-query'
import { submitAllOpportunityTaskDocuments } from '@app-hero/shared/src/api'
import { useAtom } from 'jotai'
import { userProfile } from '@app-hero/shared/src/utils/atom'
import { getProgrammeName } from '@app-hero/shared/src/utils/getProgramName'
import { categorizeActionItems } from '@app-hero/shared/src/utils/categorizeActionItems'

export const useUploadDocument = () => {
  const queryClient = useQueryClient()
  const [userprofile] = useAtom(userProfile)

  const mutation = useMutation(async (data) => {
    const payload = {
      base64: data?.file,
      contentType: data?.contentType,
      documentType: data?.documentType,
      fileName: data?.name,
      applicationFormId: data?.opportunities?.ApplicationFormId__c,
      opportunityId: data?.opportunities?.Id,
      BusinessUnitFilter__c: data?.opportunities?.BusinessUnitFilter__c,
      taskId: data?.taskId,
      email: userprofile?.email,
    }

    const response = await submitAllOpportunityTaskDocuments(payload)

    if (response.key) {
      await queryClient.setQueryData(
        [`getActionRequiredItems-${data?.opportunities?.Id}`],
        (prevData) => {
          if (
            !prevData ||
            !prevData.ActionRequiredItems ||
            !prevData.ActionRequiredItems.Items
          ) {
            return prevData
          }

          // Update the ActionRequiredItems
          const updatedItems = prevData.ActionRequiredItems.Items.map((task) =>
            task.Id === data?.taskId
              ? {
                  ...task,
                  Status: 'Completed',
                  s3ObjectKey: response?.key,
                }
              : task,
          )

          // Update the pendingActionRequiredItems if they exist
          const updatedPendingActionRequiredItems =
            prevData.pendingActionRequiredItems?.map((task) =>
              task.Id === data?.taskId
                ? {
                    ...task,
                    Status: 'Completed',
                    s3ObjectKey: response?.key,
                  }
                : task,
            ) || []

          const updatedOpportunityDetail = {
            ...prevData,
            ActionRequiredItems: {
              ...prevData.ActionRequiredItems,
              Items: updatedItems,
            },
            pendingActionRequiredItems: updatedPendingActionRequiredItems,
          }

          global.showToast(
            `The <${
              data?.taskDetails?.outstandingActionRequiredCount
            }> pending documents for <${getProgrammeName(
              data?.opportunities,
            )}> have been submitted successfully.`,
            {
              type: 'success',
              isCompleted: true,
            },
          )

          return categorizeActionItems(updatedOpportunityDetail)
        },
      )

      await queryClient.setQueryData(
        [`fetchOpportunities-${userprofile?.email}`],
        (prevData) => {
          const findOpportunityFromOpportunitiesIndex = prevData?.findIndex(
            (item) => item?.Id === data?.opportunities?.Id,
          )

          const findOpportunityFromOpportunities = prevData?.find(
            (item) => item?.Id === data?.opportunities?.Id,
          )
          if (
            !findOpportunityFromOpportunities ||
            !findOpportunityFromOpportunities.ActionRequiredItems ||
            !findOpportunityFromOpportunities.ActionRequiredItems.Items
          ) {
            return prevData
          }

          // Update ActionRequiredItems
          const updatedItems =
            findOpportunityFromOpportunities.ActionRequiredItems.Items.map(
              (task) =>
                task.Id === data?.taskId
                  ? {
                      ...task,
                      Status: 'Completed',
                      s3ObjectKey: response?.key,
                    }
                  : task,
            )

          // Update pendingActionRequiredItems
          const updatedPendingActionRequiredItems =
            findOpportunityFromOpportunities.pendingActionRequiredItems?.map(
              (task) =>
                task.Id === data?.taskId
                  ? {
                      ...task,
                      Status: 'Completed',
                      s3ObjectKey: response?.key,
                    }
                  : task,
            )

          // Create a new array with updated opportunity

          const updatedOpportunityDetail = {
            ...findOpportunityFromOpportunities,
            ActionRequiredItems: {
              ...findOpportunityFromOpportunities.ActionRequiredItems,
              Items: updatedItems,
            },
            pendingActionRequiredItems: updatedPendingActionRequiredItems,
          }
          const updatedOpportunities = [...prevData]
          updatedOpportunities[findOpportunityFromOpportunitiesIndex] =
            categorizeActionItems(updatedOpportunityDetail)

          return updatedOpportunities
        },
      )

      queryClient.setQueryData(
        ['all-notifications-spotlight', userprofile?.email],
        (prevSpotlightData) => {
          if (!prevSpotlightData) {
            return prevSpotlightData
          }

          const updatedItems = prevSpotlightData.items.filter(
            (item) => item?.messageDetails?.taskId !== data?.taskId,
          )

          return {
            ...prevSpotlightData,
            items: updatedItems,
          }
        },
      )
      await queryClient.setQueryData(
        ['getTotalActionRequiredCount'],
        (prevData) => prevData - 1,
      )
      global.showToast(
        `Successfully closed a task for <${getProgrammeName(
          data?.opportunities,
        )}>`,
        {
          type: 'success',
        },
      )
    } else {
      global.showToast(
        `Document upload for <${getProgrammeName(
          data?.opportunities,
        )}> has failed. Please try again.`,
        {
          type: 'error',
        },
      )
    }

    return response
  }, {})
  return { mutation }
}
