/* eslint-disable no-nested-ternary */
import { View, StyleSheet, ScrollView, useWindowDimensions } from 'react-native'
import React, { useState } from 'react'
import { ShimmerPlaceholder, Text } from '@libs/components'
import { Icon } from '@app-hero/native-icons'
import { useTranslation } from 'react-i18next'
import { spacing, useTheme } from '@libs/theme'
import { MyApplicationSupport, NeedSupport } from '@apphero/assets'
import { isMobile } from '@libs/utils/src/screenLayout'
import {
  NeedSupportAccordion,
  OpportunitiesCard,
  YouTubeVideoPlayer,
} from '../../components'
import EmptyContainer from '../../components/container/EmptyContainer'
import { sort } from '../../utils/sorting'
import { TitleHeader } from '../../components/headerTitle'
import { FilterStatus } from './DesktopView'

const MobileView = ({
  activeTab,
  data,
  brandLogoUrl,
  filterData,
  isLoading,
  setActiveTab,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  const [isVideoPlayerVisible, setIsVideoPlayerVisible] = useState(false)

  return (
    <ScrollView style={[styles.mainContainer]}>
      <>
        <TitleHeader title={t('HEADER.MY_APPLICATION')} />

        {!isLoading ? (
          <View
            style={{
              marginVertical: 25,
              borderBottomWidth: 1,
              borderBottomColor: 'rgba(0,0,0,0.1)',
              width: '100%',
            }}
          >
            <FilterStatus
              filterData={filterData}
              style={{
                justifyContent: 'flex-start',
              }}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              mobile={mobile}
              isLoading={isLoading}
            />
          </View>
        ) : (
          <ShimmerPlaceholder
            style={{
              width: '100%',
              height: 60,
              marginVertical: 24,
              borderRadius: spacing.spacing5,
            }}
          />
        )}

        <View style={{ flex: 1 }}>
          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{ flexGrow: 1 }}
          >
            {filterData[activeTab]?.fieldName === 'AgentDraft' ? (
              <View
                style={{
                  padding: 15,
                  flexDirection: 'row',
                  backgroundColor: colors.primaryVariant,
                  flex: 'wrap',
                  borderRadius: 15,
                }}
              >
                <Icon name="FeaturedIcon" height={34} width={34} />
                <View style={{ marginLeft: 15, flex: 'wrap', maxWidth: '90%' }}>
                  <Text variant="display4" color={colors.onNeutral}>
                    {t('MY_APPLICATION.AGENT_DRAFT_ALERT')}
                  </Text>
                </View>
              </View>
            ) : null}
            {isLoading ? (
              <SkeletonLoaderGrid />
            ) : (
              <View
                style={{
                  flexDirection: 'column',
                  rowGap: 16,
                }}
              >
                {data[filterData[activeTab]?.fieldName]?.length > 0 ? (
                  sort(
                    data[filterData[activeTab].fieldName],
                    'CreatedDate',
                  )?.map((item) => (
                    <OpportunitiesCard
                      data={item}
                      brandLogo={`${brandLogoUrl}/${item.BusinessUnitFilter__c?.replaceAll(
                        ' ',
                        '_',
                      )}.png`}
                      disable={
                        filterData[activeTab]?.fieldName === 'AgentDraft'
                      }
                      style={{
                        minWidth: '30%',
                        width: '100%',
                        backgroundColor: 'white',
                      }}
                      width={150}
                      height={75}
                    />
                  ))
                ) : (
                  <EmptyContainer />
                )}
              </View>
            )}
          </ScrollView>
        </View>

        <View style={styles.divider} />

        <NeedSupportAccordion
          imageSrc={NeedSupport}
          imageAlt="Support Image"
          onShowVideo={() => setIsVideoPlayerVisible(true)}
          canHaveVideo
        >
          <Text variant="display5" color="black">
            Watch our helpful video tutorial to guide you through the
            application process. Learn how to check your application status,
            complete any outstanding tasks, locate your offer letter, and find
            the documents you've uploaded. We've also included contact
            information for your advisor in case you have any questions. We're
            here to support you every step of the way!.
          </Text>
        </NeedSupportAccordion>
      </>
      <View style={{ position: 'absolute', top: '66vh', right: 170 }}>
        <YouTubeVideoPlayer
          videoId={process.env.REACT_APP_MY_APPLICATION_VIDEO_ID}
          onClose={() => {
            setIsVideoPlayerVisible(false)
          }}
          isVideoPlayerVisible={isVideoPlayerVisible}
        />
      </View>
    </ScrollView>
  )
}

const SkeletonLoaderGrid = () => (
  <View style={styles.loaderGrid}>
    {[1, 2, 3, 4].map(() => (
      <ShimmerPlaceholder
        style={{
          width: '100%',
          height: 250,
          borderRadius: spacing.spacing5,
        }}
      />
    ))}
  </View>
)

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 24,
    flexDirection: 'column',
    backgroundColor: 'rgba(244, 245, 251, 1)',
  },
  loaderGrid: {
    flexDirection: 'column',
    marginTop: 10,
    flexWrap: 'wrap',
    gap: 20,
  },
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    borderTopWidth: 1,
    borderTopColor: '#ccc',
  },
  item: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  divider: {
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    marginVertical: 32,
  },
})

export default MobileView
