/* eslint-disable no-nested-ternary */
import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native'
import React from 'react'
import { NotificationAnimation, Text } from '@libs/components'
import LinearGradient from 'react-native-linear-gradient'
import { Icon } from '@app-hero/native-icons'
import { formatNotificationTimestamp } from '@libs/utils/src/dateformat'
import { useTheme } from '@libs/theme'
import EmptyNotificationContainer from '../../components/container/EmptyNotificationContainer'
import { TitleHeader } from '../../components/headerTitle'
import { findPaddingHorizontal } from '../../utils/findPaddingHorizontal'

const DesktopView = ({ navigation, ...props }) => {
  const { colors } = useTheme()
  const {
    inAppNotifications,
    handleUpdateNotification,
    loadMoreNotifications,
    handleEventNavigation,
    total,
    offset,
    notificationLoading,
  } = props

  return (
    <LinearGradient
      style={{
        justifyContent: 'center',
        paddingHorizontal: 25,
        paddingBottom: 80,
        minHeight: '100vh',
      }}
      start={{ x: 0, y: 0 }}
      end={{ x: -1, y: -1 }}
      colors={[colors.gradientStart, colors.gradientEnd]}
      locations={[0.2, 1.3]}
    >
      <View
        style={{
          paddingHorizontal: findPaddingHorizontal(window.innerWidth),
          marginBottom: 32,
        }}
      >
        <TitleHeader title="NOTIFICATIONS" />
      </View>
      <View style={styles.mainView(colors)}>
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'flex-start',
          }}
        >
          {inAppNotifications.length > 0 ? (
            inAppNotifications?.map((notificationItem, index) =>
              notificationItem !== null ? (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    if (!notificationItem?.readStatus) {
                      handleUpdateNotification(notificationItem)
                    }
                    handleEventNavigation(notificationItem)
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      flex: 1,
                      marginHorizontal: 32,
                      borderRadius: '0.8rem',
                      padding: 24,
                      backgroundColor: notificationItem?.readStatus
                        ? 'transparent'
                        : colors.backgroundSecondary,
                      marginVertical: notificationItem?.readStatus ? 0 : 24,
                      gap: 38,
                    }}
                  >
                    <Icon
                      name="NotificationDocs"
                      color={
                        notificationItem?.readStatus ? 'black' : colors.primary
                      }
                      strokeWidth={1.3}
                    />

                    <View
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                      }}
                    >
                      <Text
                        variant="display3"
                        style={
                          notificationItem?.readStatus
                            ? styles.item(colors)
                            : [
                                styles.item(colors),
                                {
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.textPrimary,
                                },
                              ]
                        }
                      >
                        {notificationItem?.message}
                      </Text>

                      <Text
                        variant="display6"
                        style={{
                          color: colors.textPrimary,
                          opacity: notificationItem?.readStatus ? 0.5 : 1,
                          fontSize: 14,
                          fontWeight: notificationItem?.readStatus ? 400 : 700,
                          textAlign: 'right',
                          position: 'absolute',
                          right: 0,
                          bottom: 0,
                        }}
                      >
                        {formatNotificationTimestamp(
                          notificationItem?.createdAt,
                        )}
                      </Text>
                    </View>
                  </View>
                  {index !== inAppNotifications.length - 1 ? (
                    <View style={styles.divider} />
                  ) : null}
                </TouchableOpacity>
              ) : null,
            )
          ) : (
            <EmptyNotificationContainer />
          )}
          {inAppNotifications.length > 0 &&
          total > offset &&
          !notificationLoading ? (
            <TouchableOpacity
              onPress={() => loadMoreNotifications()}
              style={{ height: '48px', justifyContent: 'center' }}
            >
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '100%' }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignSelf: 'center',
                      alignItems: 'center',
                      flex: 1,
                    }}
                  >
                    <Text
                      variant="display3"
                      style={[
                        {
                          color: colors.textAccent,
                          fontWeight: 700,
                          lineHeight: 22.5,
                          textAlign: 'center',
                          flex: 1,
                          height: '100%',
                          width: '100%',
                        },
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      See more
                    </Text>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          ) : (
            total > offset && (
              <View
                style={{
                  width: '100%',
                  alignItems: 'center',
                  height: '48px',
                }}
              >
                <NotificationAnimation height={48} width={48} margin={0} />
              </View>
            )
          )}
        </ScrollView>
      </View>
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  mainContainer: () => ({}),
  mainView: (colors) => ({
    width: '100%',
    maxWidth: 896,
    height: '80%',
    maxHeight: '65vh',
    marginHorizontal: 'auto',
    paddingVertical: 24,
    backgroundColor: colors.white,
    borderRadius: 16,
    marginTop: '0.2rem',
  }),
  item: (colors) => ({
    color: colors.textPrimary,
    fontWeight: 400,
    width: '80%',
  }),
  divider: {
    borderWidth: 0.4,
    borderColor: 'rgba(0, 0, 0, 0.2)',
    marginHorizontal: 32,
  },
})

export default DesktopView
