/* eslint-disable no-nested-ternary */
import {
  View,
  StyleSheet,
  ScrollView,
  useWindowDimensions,
  TouchableOpacity,
} from 'react-native'
import React, { useState } from 'react'
import { ShimmerPlaceholder, Text } from '@libs/components'
import { NeedSupport as NeedSupportImage } from '@apphero/assets'
import { Icon } from '@app-hero/native-icons'
import { useTranslation } from 'react-i18next'
import { spacing, useTheme } from '@libs/theme'
import { isMobile, isTablet } from '@libs/utils/src/screenLayout'
import {
  NeedSupport,
  OpportunitiesCard,
  VoidBox,
  YouTubeVideoPlayer,
} from '../../components'
import { TitleHeader } from '../../components/headerTitle'
import { sort } from '../../utils/sorting'
import { findPaddingHorizontal } from '../../utils/findPaddingHorizontal'

const DesktopView = ({
  activeTab,
  filterData,
  isLoading,
  brandLogoUrl,
  data,
  setActiveTab,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  const tablet = isTablet(width)
  const [isVideoPlayerVisible, setIsVideoPlayerVisible] = useState(false)

  return (
    <>
      <ScrollView style={{ backgroundColor: '#F4F5FB' }}>
        <View
          style={{
            paddingHorizontal: findPaddingHorizontal(width),
            paddingTop: 56,
            paddingBottom: 32,
          }}
        >
          <TitleHeader title={t('HEADER.MY_APPLICATION')} />
        </View>

        <View
          style={[
            styles.mainContainer(width),
            {
              justifyContent: isLoading ? 'center' : '',
              backgroundColor: '#F4F5FB',
              columnGap: 32,
              paddingHorizontal: findPaddingHorizontal(width),
            },
          ]}
        >
          <View style={{ flexDirection: 'column', flex: 1 }}>
            <View
              style={{
                marginVertical: 25,
                borderBottomWidth: 1,
                borderBottomColor: 'rgba(0,0,0,0.1)',
              }}
            >
              <FilterStatus
                filterData={filterData}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                mobile={mobile}
                isLoading={isLoading}
              />
            </View>

            <View style={styles.cardContainer}>
              <ScrollView showsVerticalScrollIndicator={false}>
                {filterData[activeTab]?.fieldName === 'AgentDraft' ? (
                  <View style={[styles.agentDraft]}>
                    <Icon name="FeaturedIcon" height={34} width={34} />
                    <View style={{ flex: 'wrap', maxWidth: '90%' }}>
                      <Text variant="display4" color={colors.onNeutral}>
                        {t('MY_APPLICATION.AGENT_DRAFT_ALERT')}
                      </Text>
                    </View>
                  </View>
                ) : null}
                {isLoading ? (
                  <SkeletonLoaderGrid />
                ) : data[filterData[activeTab]?.fieldName]?.length > 0 ? (
                  tablet ? (
                    <View style={{ rowGap: 24 }}>
                      {sort(
                        data[filterData[activeTab].fieldName],
                        'CreatedDate',
                      )?.map((item) => (
                        <OpportunitiesCard
                          data={item}
                          brandLogo={`${brandLogoUrl}/${item.BusinessUnitFilter__c?.replaceAll(
                            ' ',
                            '_',
                          )}.png`}
                          disable={
                            filterData[activeTab]?.fieldName === 'AgentDraft'
                          }
                          width={123}
                          height={24}
                        />
                      ))}
                    </View>
                  ) : (
                    <View style={{ rowGap: 24 }}>
                      {sort(
                        data[filterData[activeTab].fieldName],
                        'CreatedDate',
                      )?.map((item) => (
                        <OpportunitiesCard
                          data={item}
                          brandLogo={`${brandLogoUrl}/${item.BusinessUnitFilter__c?.replaceAll(
                            ' ',
                            '_',
                          )}.png`}
                          disable={
                            filterData[activeTab]?.fieldName === 'AgentDraft'
                          }
                          width={123}
                          height={50}
                        />
                      ))}
                    </View>
                  )
                ) : (
                  <VoidBox
                    heading1={[filterData[activeTab].errorMessageTitle]}
                    heading2={[filterData[activeTab].errorMessageDescription]}
                  />
                )}
              </ScrollView>
            </View>
          </View>
          <NeedSupport
            width={width}
            imageSrc={NeedSupportImage}
            imageAlt="Support Image"
            onShowVideo={() => setIsVideoPlayerVisible(true)}
            canHaveVideo
          >
            Watch our helpful video tutorial to guide you through the
            application process. Learn how to check your application status,
            complete any outstanding tasks, locate your offer letter, and find
            the documents you've uploaded. We've also included contact
            information for your advisor in case you have any questions. We're
            here to support you every step of the way!.
          </NeedSupport>
        </View>
      </ScrollView>
      <View style={{ position: 'absolute', top: '66vh', right: 170 }}>
        <YouTubeVideoPlayer
          videoId={process.env.REACT_APP_MY_APPLICATION_VIDEO_ID}
          onClose={() => {
            setIsVideoPlayerVisible(false)
          }}
          isVideoPlayerVisible={isVideoPlayerVisible}
        />
      </View>
    </>
  )
}

const SkeletonLoaderGrid = () => (
  <View style={styles.loaderGrid}>
    {[1, 2, 3, 4].map(() => (
      <ShimmerPlaceholder
        style={{
          width: '100%',
          height: 262,
          borderRadius: spacing.spacing3,
        }}
      />
    ))}
  </View>
)

export default DesktopView

export const FilterStatus = ({
  filterData,
  activeTab,
  setActiveTab,
  mobile,
  isLoading,
  style,
}) => (
  <ScrollView
    contentContainerStyle={[
      {
        flexDirection: 'row',
      },
      style,
    ]}
    horizontal
    showsHorizontalScrollIndicator={false}
  >
    {filterData?.map((item, index) => (
      <FilterItem
        item={item}
        setActiveTab={setActiveTab}
        isActive={index === activeTab}
        index={index}
        mobile={mobile}
        isLoading={isLoading}
      />
    ))}
  </ScrollView>
)

export const FilterItem = ({
  item,
  setActiveTab,
  isActive,
  index,
  mobile,
  isLoading,
}) => {
  const { colors } = useTheme()
  return (
    <TouchableOpacity
      style={[
        styles.listItem(mobile),
        index === 0 ? { color: colors.draftBlue } : '',
      ]}
      key={index}
      onPress={() => setActiveTab(index)}
      disabled={isLoading}
    >
      <Text
        style={[
          isActive
            ? styles.isActiveStyle(mobile)
            : {
                fontSize: mobile ? 14 : 15,
                fontWeight: 400,
                color: 'rgba(0,0,0,0.4)',
              },
        ]}
      >
        {item.label}
      </Text>
      {isActive ? (
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            borderRadius: 5,
            height: 3,
            backgroundColor: colors.borderBlue,
          }}
        />
      ) : null}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  mainContainer: (width) => ({
    paddingHorizontal: findPaddingHorizontal(width),
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    paddingBottom: 32,
  }),
  loaderGrid: {
    flexDirection: 'column',
    marginTop: 10,
    flexWrap: 'wrap',
    gap: 20,
  },
  cardContainer: {
    borderRadius: 16,
    backgroundColor: '#F4F5FB',
  },
  agentDraft: {
    flexDirection: 'row',
    flex: 'wrap',
    borderRadius: 15,
  },
  welcomeCard: (colors, width) => ({
    paddingHorizontal: findPaddingHorizontal(width),
    paddingTop: 30,
    overflow: 'visible',
    zIndex: 1,
    backgroundColor: colors.backgroundPrimary,
  }),
  isActiveStyle: (isMobile) => ({
    color: '#0B5CD7',
    fontWeight: 700,
    fontSize: isMobile ? 14 : 18,
  }),
  listItem: (mobile) => ({
    flexDirection: 'row',
    paddingVertical: spacing.spacing4,
    alignItems: 'center',
    borderRadius: 100,
    maxWidth: mobile ? '100%' : '',
    marginRight: 40,
    color: 'rgba(0,0,0,0.4)',
  }),
  rightContainer: (width) => ({
    borderRadius: 13,
    background: 'transparent',
    borderWidth: 0.2,
    borderColor: 'rgba(0, 0, 0, 0.2)',
    backgroundColor: '#DDE0EE',
    minWidth: width >= 1260 ? 400 : 300,
    alignSelf: 'baseline',
  }),
  divider: {
    borderBottomWidth: 2,
    borderColor: 'rgba(0,0,0,0.2)',
    borderStyle: 'solid',
    opacity: 0.2,
    marginVertical: 20,
  },
})
