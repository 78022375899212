import { Text } from 'react-native'
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ScreenLayout } from '@libs/utils'
import { useIsFocused } from '@react-navigation/native'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useUploadDocument } from '@app-hero/shared-hooks'
import DesktopView from './DesktopView'
import MobileView from './MobileView'
import {
  getDocumentType,
  getOpportunities,
  getTasksByEmail,
  submitAllOpportunityTaskDocuments,
} from '../../api'
import { userProfile } from '../../utils/atom'
import { getTotalActionRequiredItemsCounts } from '../../utils/getTotalActionRequiredCount'
import { categorizeActionItems } from '../../utils/categorizeActionItems'
import { getProgrammeName } from '../../utils/getProgramName'

const ActionRequired = () => {
  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  const [userprofile] = useAtom(userProfile)
  const [modalVisible, setModalVisible] = useState(false)
  const [isFileUpload, setIsFileUpload] = useState()
  const dropDownRef = useRef()
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [dropdownWidth, setDropDownWidth] = useState(0)
  const [selectedOpportunity, setSelectedOpportunity] = useState({})
  const queryClient = useQueryClient()
  const isFocused = useIsFocused()
  const [documentType, setDocumentType] = useState([])
  const [totalActionRequiredCount, setTotalActionRequiredCount] = useState(0)

  const fetchAllOpportunities = async () => {
    let response = await getOpportunities({ email: userprofile?.email })

    if (!response) {
      response = await getOpportunities({ email: userprofile?.email })
    }

    // Filter out objects where ApplicationStatus?.category is 'Drafts'
    const updatedResponse = response?.response?.filter(
      (item) => item.ApplicationStatus?.category !== 'Drafts',
    )

    return updatedResponse
  }

  const { data: opportunities, isFetching: isFetchingOpportunities } = useQuery(
    {
      queryKey: [`fetchOpportunities-${userprofile?.email}`],
      queryFn: fetchAllOpportunities,
      enabled: isFocused && !!userprofile?.email,
    },
  )
  const { data: tasks, isFetching: isFetchingTask } = useQuery({
    queryKey: [`fetchTasks-${userprofile?.email}`],
    queryFn: () => getTasksByEmail({ email: userprofile.email }),
    enabled: isFocused && !!userprofile?.email,
  })

  useEffect(() => {
    if (!isFetchingTask && !isFetchingOpportunities && opportunities) {
      opportunities?.map((opportunity) => {
        opportunity.ActionRequiredItems = tasks?.response?.find(
          (task) => task.Id === opportunity.Id,
        )?.ActionRequiredItems

        opportunity.ActionRequiredCount = tasks?.response?.find(
          (task) => task.Id === opportunity.Id,
        )?.ActionRequiredCount
        return opportunity
      })
    }
  }, [isFetchingTask, isFetchingOpportunities])

  const fetchDocumentTypes = async (opportunity) => {
    const documentType = await getDocumentType({
      brandName: opportunity.BusinessUnitFilter__c,
    })
    const documentList = documentType.records.map((document) => ({
      label: document.DocumentType__c,
    }))
    return documentList
  }

  const { mutation } = useUploadDocument()

  const handleFileUpload = async (properties, opportunities) => {
    setIsFileUpload(true)
    await mutation.mutateAsync({
      opportunities,
      ...properties,
    })
    setIsFileUpload(false)
  }

  const toggleDropdown = () => {
    dropDownRef?.current?.measure((_fx, _fy, _w, _h, _px, py) => {
      setDropdownTop(py + 50)
      setDropdownLeft(_px - 10)
      setDropDownWidth(_w)
    })
  }

  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const handleSubmitAllOpportunityTaskFile = async (opportunityDetail) => {
    setIsSubmitLoading(true)

    const payload = {
      opportunityId: opportunityDetail?.Id,
      brand: opportunityDetail?.Brand__c,
      businessUnitFilter: opportunityDetail?.BusinessUnitFilter__c,
      email: userprofile?.email,
    }

    const response = await submitAllOpportunityTaskDocuments(payload)

    setIsSubmitLoading(false)

    if (response?.ok) {
      global.showToast(
        `The <${
          opportunityDetail?.pendingActionRequiredItems?.length
        }> pending documents for <${getProgrammeName(
          opportunityDetail,
        )}> have been submitted successfully.`,
        {
          type: 'success',
          canHaveButton: true,
          buttonLabel: 'VIEW COMPLETED TASKS >',
          navigation: 'view-application-details',
          isCompleted: true,
          Id: opportunityDetail?.Id,
          title: 'Outstanding documents submitted',
        },
      )
      await queryClient.setQueryData(
        [`fetchOpportunities-${userprofile?.email}`],
        (prevData) => {
          const findOpportunityFromOpportunitiesIndex = prevData?.findIndex(
            (data) => data?.Id === opportunityDetail?.Id,
          )

          if (
            !opportunityDetail ||
            !opportunityDetail.ActionRequiredItems ||
            !opportunityDetail.ActionRequiredItems.Items
          ) {
            return prevData
          }

          // Update ActionRequiredItems
          const updatedItems = opportunityDetail.ActionRequiredItems.Items.map(
            (task) => ({
              ...task,
              Status: 'Completed',
            }),
          )

          // Update pendingActionRequiredItems
          const updatedPendingActionRequiredItems =
            opportunityDetail?.pendingActionRequiredItems?.map((task) => ({
              ...task,
              Status: 'Completed',
            }))

          // Create a new array with updated opportunity

          const updatedOpportunityDetail = {
            ...opportunityDetail,
            ActionRequiredItems: {
              ...opportunityDetail.ActionRequiredItems,
              Items: updatedItems,
            },
            pendingActionRequiredItems: updatedPendingActionRequiredItems,
          }
          const updatedOpportunities = [...prevData]
          updatedOpportunities[findOpportunityFromOpportunitiesIndex] =
            categorizeActionItems(updatedOpportunityDetail)

          return updatedOpportunities
        },
      )

      await queryClient.setQueryData(
        ['getTotalActionRequiredCount'],
        (prevData) =>
          opportunityDetail.ActionRequiredCount
            ? prevData - opportunityDetail.ActionRequiredCount
            : prevData,
      )
    } else {
      global.showToast(
        `Unable to upload outstanding documents for <${getProgrammeName(
          opportunityDetail,
        )}> at this time. Please try again later.`,
        { type: 'error' },
      )
    }
  }

  const handleSelectedOpportunity = async (opportunityDetail) => {
    const findOpportunityFromOpportunities = opportunities?.find(
      (item) => item?.Id === opportunityDetail?.WhatId,
    )
    setSelectedOpportunity(opportunityDetail)

    if (
      findOpportunityFromOpportunities &&
      selectedOpportunity?.WhatId !== opportunityDetail?.WhatId
    ) {
      const result = await fetchDocumentTypes(findOpportunityFromOpportunities)
      setDocumentType(result)
    }
    setModalVisible(true)
  }

  useEffect(() => {
    if (isFetchingOpportunities || isFetchingTask) {
      setTotalActionRequiredCount(0)
    } else {
      setTotalActionRequiredCount(
        getTotalActionRequiredItemsCounts(opportunities),
      )
    }
  }, [isFetchingOpportunities, isFetchingTask, opportunities])

  const viewProps = {
    opportunities,
    userName: userprofile.firstName,
    isLoading: isFetchingOpportunities || isFetchingTask,
    modalVisible,
    setModalVisible,
    handleSelectedOpportunity,
    selectedOpportunity,
    setSelectedOpportunity,
    toggleDropdown,
    handleFileUpload,
    dropDownRef,
    dropdownTop,
    dropdownLeft,
    isFileUpload,
    dropdownWidth,
    handleSubmitAllOpportunityTaskFile,
    fetchDocumentTypes,
    totalActionRequiredCount,
    documentType,
    isSubmitLoading,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default ActionRequired
