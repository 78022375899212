import React, { useEffect, useRef, useState } from 'react'
import {
  Animated,
  Platform,
  Pressable,
  View,
  useWindowDimensions,
} from 'react-native'
import * as Sentry from '@sentry/react-native'
import { NavigationContainer, useNavigation } from '@react-navigation/native'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify'
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context'
import { Text } from '@libs/components'
import 'react-native-gesture-handler'
import { Icon } from '@app-hero/native-icons'
import { Provider as JotaiProvider, useAtom } from 'jotai'
import { ThemeProvider } from '@libs/theme'
import { SecureStore } from '@libs/utils'
import { navigationRef } from './navigation/RootNavigator'
import AppNavigator from './navigation'
import awsConfig from './awsConfig'
import { FW_CHAT_BOT_URL, sentryUrl } from './config'
import './translations/i18n'
import { isChatbotVisible, userProfile } from './utils/atom'
import {
  addScript,
  initializeChatbotUser,
  userChatBotDestroy,
} from './utils/addScript'

Sentry.init({
  dsn: sentryUrl,
  tracesSampleRate: 1.0,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 600000,
    },
  },
})

const App = () => {
  const routeNameRef = useRef()
  Amplify.configure(awsConfig)
  const { height } = useWindowDimensions()

  useEffect(() => {
    ;(async () => {
      const gusApiUrl = window.location.origin
      const response = await fetch(`${gusApiUrl}/config.json`)
      const result = await response.json()
      await SecureStore.setItemAsync('config', JSON.stringify(result))
    })()
  }, [])

  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <QueryClientProvider client={queryClient}>
        <NavigationContainer
          ref={navigationRef}
          onReady={() => {
            routeNameRef.current = navigationRef.current.getCurrentRoute().name
          }}
          onStateChange={async () => {
            const currentRouteName =
              navigationRef.current.getCurrentRoute().name
            routeNameRef.current = currentRouteName
          }}
          linking={{ enabled: true }}
        >
          <ThemeProvider>
            <JotaiProvider>
              <View style={{ minHeight: height }}>
                <AppNavigator />
              </View>
            </JotaiProvider>
          </ThemeProvider>

          <ToastManager />
        </NavigationContainer>
      </QueryClientProvider>
    </SafeAreaProvider>
  )
}

Sentry.setTag('Platform:', Platform.OS)

export default Sentry.withProfiler(App)

const CustomToast = ({ toast, toastMessage, onClose }) => {
  const getStyleWithType = () => {
    switch (toast?.type) {
      case 'error':
        return ['#FF3B30', 'ToastError']
      case 'warning':
        return ['#FFCC00', 'ToastWarning']
      case 'success':
        return ['#34C759', 'ToastSuccess']
      case 'info':
        return ['#007AFF', 'ToastInfo']
      default:
        return ['#34C759', 'ToastSuccess']
    }
  }
  const navigation = useNavigation()

  const formatMessage = (message) => {
    const parts = message?.split(/<([^>]+)>/)
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return (
          <Text
            key={index}
            style={{ fontWeight: '600', color: 'black' }}
            variant="display4"
          >
            {part}
          </Text>
        )
      }
      return (
        <Text key={index} variant="display4" color="#000">
          {part}
        </Text>
      )
    })
  }

  const fadeAnim = useRef(new Animated.Value(0)).current
  const timerRef = useRef(null)

  useEffect(() => {
    // Clear any existing timer
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    // Fade in animation
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start()

    // Set new timer
    timerRef.current = setTimeout(() => {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }).start(() => onClose())
    }, 5000)

    // Cleanup function
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [toast, toastMessage])

  const formattedMessage = toastMessage ? formatMessage(toastMessage) : null

  return (
    <Animated.View
      style={{
        maxWidth: 401,
        backgroundColor: '#fff',
        borderRadius: 8,
        borderLeftColor: getStyleWithType()[0],
        borderLeftWidth: 6,
        position: 'absolute',
        bottom: 100,
        right: window.innerWidth >= 650 ? 20 : 0,
        marginHorizontal: window.innerWidth <= 650 ? 24 : 0,
      }}
      ref={timerRef}
    >
      <View
        style={{
          flexDirection: 'row',
          gap: 16,
          padding: 16,
        }}
      >
        <Icon name={getStyleWithType()[1]} width={24} height={24} />
        <View style={{ flex: 1, gap: toast?.title ? 4 : 0 }}>
          {toast?.title ? (
            <Text color="#000" variant="heading5">
              {toast.title}
            </Text>
          ) : null}

          {formattedMessage ? (
            <Text color="#000" variant="display4">
              {formattedMessage}
            </Text>
          ) : null}

          {toast?.canHaveButton ? (
            <Pressable
              style={{ marginTop: 4 }}
              onPress={() =>
                navigation.navigate('view-application-details', {
                  id: toast.Id,
                  canExpandCompletedAction: toast.isCompleted,
                  isActive: 'ACTION_REQUIRED',
                })
              }
            >
              <Text variant="heading6" color="#007AFF">
                {toast.buttonLabel}
              </Text>
            </Pressable>
          ) : null}
        </View>
      </View>
    </Animated.View>
  )
}

const ToastManager = () => {
  const [toastConfig, setToastConfig] = useState(null)

  useEffect(() => {
    global.showToast = async (message, options = {}) => {
      setToastConfig({ message, options })
    }
    return () => {
      global.showToast = undefined
    }
  }, [])

  if (!toastConfig) return null

  return (
    <CustomToast
      toastMessage={toastConfig.message}
      toast={toastConfig.options}
      onClose={async () => {
        setToastConfig(null)
      }}
    />
  )
}
