import React from 'react'
import { View, StyleSheet, Image, Pressable } from 'react-native'
import { Text } from '@libs/components'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@libs/theme'
import { PlayButtonCMS } from '@apphero/assets'

const NeedSupport = ({
  children,
  imageSrc,
  imageAlt,
  style,
  onShowVideo = () => {},
  canHaveVideo,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()

  return (
    <View style={[styles.rightContainer, style]}>
      <View style={{ paddingHorizontal: 32, paddingVertical: 24 }}>
        <Text
          variant="display1"
          style={{
            color: colors.textPrimary,
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          {t('APPLICATION_DETAIL.ANY_QUESTION')}
        </Text>
        <View style={styles.divider} />

        <Text
          variant="display4"
          color={colors.neutral}
          style={{ width: 350, lineHeight: 21 }}
        >
          {children}
        </Text>

        <Pressable
          style={{
            padding: 14,
            backgroundColor: colors.white,
            marginTop: 28,
            borderRadius: 8,
          }}
          onPress={onShowVideo}
          disabled={!canHaveVideo}
        >
          {imageSrc && (
            <Image
              source={imageSrc}
              style={{
                height: 170,
                width: '100%',
                borderRadius: 4,
              }}
              alt={imageAlt}
            />
          )}
          {canHaveVideo ? (
            <View
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: [{ translateX: -30 }, { translateY: -18 }],
              }}
            >
              <Image
                source={PlayButtonCMS}
                style={{ width: 45, height: 45 }}
                resizeMode="contain"
              />
            </View>
          ) : null}
        </Pressable>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  rightContainer: {
    borderRadius: 13,
    background: 'transparent',
    borderWidth: 0.2,
    borderColor: 'rgba(0, 0, 0, 0.2)',
    backgroundColor: '#DDE0EE',
    minWidth: window.innerWidth >= 1260 ? 400 : 300,
    alignSelf: 'baseline',
  },
  divider: {
    borderBottomWidth: 2,
    borderColor: 'rgba(0,0,0,0.2)',
    borderStyle: 'solid',
    opacity: 0.2,
    marginVertical: 20,
  },
})

export default NeedSupport
