import React from 'react'
import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { isWeb } from '@libs/utils/src/screenLayout'
import { Icon } from '@app-hero/native-icons'
import { useTheme } from '@libs/theme'
import Button from './Button'

const LinkedInButtonVariant = ({ onPress }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const isDesktop = isWeb(window.innerWidth)

  return (
    <Button
      onPress={onPress}
      appearance="filled"
      buttonColor={colors.white}
      imageIcon={
        <Icon
          name="LinkedinLogo"
          fill={colors.primary}
          height={32}
          width={32}
          style={{ borderRadius: 24 }}
        />
      }
      label={t('LINK.LINKED_IN')}
      labelVariant="display3"
      labelStyle={[styles.labelStyle, { color: 'black' }]}
      buttonStyle={[
        styles.buttonStyle,
        {
          borderWidth: !isDesktop ? 1 : 0,
          borderRadius: 12,
          borderColor: '#E2E8F0',
        },
      ]}
    />
  )
}

const styles = StyleSheet.create({
  buttonStyle: {
    borderRadius: 4,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  labelStyle: {
    fontWeight: '600',
    fontSize: 16,
    marginLeft: 22,
  },
})

export default LinkedInButtonVariant
