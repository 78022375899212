import {
  View,
  StyleSheet,
  ImageBackground,
  ScrollView,
  Image,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native'
import React, { useEffect, useState, useRef } from 'react'
import { Text } from '@libs/components'
import { useTranslation } from 'react-i18next'
import {
  AppHeroLogoLite,
  Slide1Common,
  Char1,
  Char2,
  Char3,
  SlideBG1,
  SlideBG3,
  SlideBG2,
  SlideBG4,
  SlideBG5,
  AppheroBeta,
} from '@apphero/assets'
import Swiper from 'react-native-web-swiper'
import LinearGradient from 'react-native-linear-gradient'
import { useTheme } from '@libs/theme'
import { useAtom } from 'jotai'
import { Icon } from '@app-hero/native-icons'
import { getRandomImage } from '@libs/utils'
import { BlurView } from 'expo-blur'
import { brandDetailsAtom } from '../../utils/atom'

const AuthLeftContainer = (props) => {
  const swiperRef = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const slideDurations = [5000, 10000]
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % 2
        if (swiperRef.current) {
          swiperRef.current.goTo(nextIndex)
        }
        return nextIndex
      })
    }, slideDurations[currentIndex])

    return () => clearInterval(interval)
  }, [currentIndex])
  const { colors } = useTheme()
  const { t } = useTranslation()
  const brandLogoUrl = props?.brandLogoUrl

  const [brandDetails] = useAtom(brandDetailsAtom)
  const LogoUrl = `${brandLogoUrl}/${brandDetails.brand?.replaceAll(
    ' ',
    '_',
  )}_LITE.png`

  const slide1Images = [Char1, Char2, Char3]
  const slide2Images = [SlideBG1, SlideBG2, SlideBG3, SlideBG4, SlideBG5]
  const { width } = useWindowDimensions()

  const baseWidth = 375

  const scaleFontSize = (size) => {
    const scale = width / baseWidth
    return Math.round(size * scale)
  }

  const slides = [
    {
      content: (
        <>
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              right: '2%',
              zIndex: 10,
              width: '70%',
              height: '37%',
            }}
          >
            <Icon
              name="AppheroSlide1"
              height="100%"
              width="100%"
              color={colors.brandPrimary}
              style={{ borderBottomEndRadius: 16 }}
            />
          </View>

          <View
            style={[
              styles.marginTopValue(width),
              {
                flex: 1,
                marginLeft: '3.375rem',
                zIndex: 57,
              },
            ]}
          >
            <View
              style={[styles.welcomeCardContainer, { flexDirection: 'row' }]}
            >
              <Text
                variant="heading"
                color={colors.white}
                style={[
                  styles.welcomeHeader,
                  {
                    fontWeight: 900,
                    textAlign: 'left',
                    fontSize: '4vw',
                    lineHeight: '120%',
                  },
                ]}
              >
                {t('AUTH.WELCOME_TITLE')}
              </Text>
            </View>
          </View>

          <View
            style={{
              position: 'absolute',
              width: '60%',
              height: '67%',
              zIndex: 50,
              bottom: 0,
              right: 0,
            }}
          >
            <Image
              source={getRandomImage(slide1Images)}
              style={{ height: '135%', width: '150%' }}
              resizeMode="contain"
            />
          </View>
        </>
      ),
      logo: LogoUrl,
    },
    {
      content: (
        <ImageBackground
          source={SlideBG1}
          style={[styles.welcomeCardImage]}
          imageStyle={{ borderRadius: 16 }}
        >
          <LinearGradient
            style={[
              {
                position: 'absolute',
                color: colors.white,
                borderRadius: 16,
                zIndex: 55,
                width: '100%',
                height: '100%',
              },
            ]}
            useAngle
            angle={45}
            angleCenter={{ x: 0.5, y: 0.5 }}
            colors={[colors.backgroundNeutral, 'transparent']}
            locations={[0.35, 2]}
          >
            <View
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '50%',
                height: '72%',
              }}
            >
              <Icon
                name="AppheroSlide2"
                height="100%"
                width="100%"
                color={colors.brandPrimary}
                style={{ borderBottomLeftRadius: 16 }}
              />
            </View>

            <View
              style={[
                styles.marginTopValue(width),
                {
                  flex: 1,
                  marginLeft: '3.375rem',
                  marginRight: '3.375rem',
                  zIndex: 57,
                },
              ]}
            >
              <View
                style={[styles.welcomeCardContainer, { flexDirection: 'row' }]}
              >
                <Text
                  variant="heading"
                  color={colors.white}
                  style={[
                    styles.welcomeHeader,
                    {
                      fontWeight: 900,
                      textAlign: 'left',
                      fontSize: '3vw',
                      lineHeight: '120%',
                      marginBottom: window.devicePixelRatio === 2 ? '4%' : '2%',
                    },
                  ]}
                >
                  {t('AUTH.WELCOME_TITLE_2')}
                </Text>
              </View>

              <View
                style={{
                  backdropFilter: 'blur(102px)',
                  borderRadius: '16px',
                  background: `rgba(255, 255, 255, 0.24)`,
                  height: '60%',
                  maxHeight: '65%',
                  flexDirection: 'column',
                }}
              >
                {brandDetails?.uspDetails?.map((element, index) => (
                  <>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        color: colors.white,
                        alignItems: 'center',
                        marginHorizontal: '32px',
                        paddingVertical: window.devicePixelRatio === 2 ? 30 : 0,
                        height: '30%',
                      }}
                    >
                      <View style={{ width: '35%' }}>
                        <Text
                          style={{
                            color: colors.white,
                            fontWeight: 600,
                            fontSize:
                              window.innerWidth > 2500
                                ? 72
                                : window.innerWidth < 1470
                                ? 18
                                : 32,
                          }}
                        >
                          {element.title}
                        </Text>
                      </View>
                      <View style={{ width: '65%' }}>
                        <Text
                          style={{
                            color: colors.white,
                            fontWeight: 700,
                            fontSize: width < 1150 ? 14 : scaleFontSize(4.5),
                          }}
                        >
                          {element.description}
                        </Text>
                        {element?.subtext?.map((text) => (
                          <Text
                            style={{
                              color: colors.white,
                              fontWeight: 700,
                              fontSize: scaleFontSize(3.5),
                            }}
                          >
                            ({text})
                          </Text>
                        ))}
                      </View>
                    </View>
                    {index + 1 !== brandDetails.uspDetails.length && (
                      <View
                        style={{
                          height: 2,
                          backgroundColor: colors.white,
                          opacity: '30%',
                          marginHorizontal: '2.5rem',
                        }}
                      />
                    )}
                  </>
                ))}
              </View>
            </View>
          </LinearGradient>
        </ImageBackground>
      ),
      logo: LogoUrl,
    },
  ]

  if (brandDetails?.brand && brandLogoUrl)
    return (
      <View style={styles.container}>
        <Swiper
          from={0}
          ref={swiperRef}
          minDistanceForAction={0.1}
          loop
          onIndexChanged={(index) => setCurrentIndex(index)}
          controlsProps={{
            dotsTouchable: true,
            prevPos: 'left',
            nextPos: 'right',
            nextTitle: '',
            prevTitle: '',
            dotsPos: 'bottom-left',
            DotComponent: ({ index, activeIndex, isActive, onPress }) => (
              <TouchableOpacity
                onPress={onPress}
                style={{
                  paddingVertical: 8,
                }}
              >
                <View
                  style={{
                    width: '4.5rem',
                    height: 2,
                    marginHorizontal: 4,
                    backgroundColor: colors.white,
                    opacity: isActive ? '100%' : '20%',
                  }}
                />
              </TouchableOpacity>
            ),
            dotsWrapperStyle: {
              marginBottom: '3.375rem',
              marginLeft: '2.25rem',
            },
          }}
        >
          {slides.map((item, index) => (
            <View
              style={{
                backgroundColor: colors.backgroundNeutral,
                height: '100%',
                borderRadius: 16,
                overflow: 'hidden',
              }}
            >
              {item.content}
              <View
                style={[
                  {
                    position: 'absolute',
                    top: 0,
                    color: colors.white,
                    width: '100%',
                    height: '20%',
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                    zIndex: 55,
                  },
                ]}
              >
                <LinearGradient
                  style={[
                    {
                      color: colors.white,
                      width: '100%',
                      height: '100%',
                      borderBottomLeftRadius: 16,
                      borderBottomRightRadius: 16,
                      zIndex: 55,
                    },
                  ]}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 0, y: 1 }}
                  colors={[colors.backgroundNeutral, 'transparent']}
                  locations={[0.5, 1]}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      paddingHorizontal: '3.75rem',
                      width: '60%',
                    }}
                  >
                    <Image
                      source={item.logo}
                      style={{ height: '100%', width: '100%' }}
                      resizeMode="contain"
                      alt={brandDetails.brand}
                    />
                  </View>
                </LinearGradient>
              </View>
            </View>
          ))}
        </Swiper>
      </View>
    )

  return (
    <>
      <>
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            right: '2%',
            zIndex: 10,
            width: '70%',
            height: '37%',
            borderBottomEndRadius: 16,
          }}
        >
          <Icon
            name="AppheroSlide1"
            height="100%"
            width="100%"
            color={colors.textAccent}
            style={{ borderBottomEndRadius: 16 }}
          />
        </View>

        <View
          style={[
            styles.marginTopValue(width),
            {
              flex: 1,
              marginLeft: '3.375rem',
              zIndex: 57,
            },
          ]}
        >
          <View style={[styles.welcomeCardContainer, { flexDirection: 'row' }]}>
            <Text
              variant="heading"
              color={colors.white}
              style={[
                styles.welcomeHeader,
                {
                  fontWeight: 900,
                  textAlign: 'left',
                  fontSize: '4vw',
                  lineHeight: '120%',
                },
              ]}
            >
              {t('AUTH.WELCOME_TITLE')}
            </Text>
          </View>
        </View>

        <View
          style={{
            position: 'absolute',
            width: '60%',
            height: '67%',
            zIndex: 50,
            bottom: 0,
            right: 0,
            overflow: 'hidden',
            borderRadius: 16,
          }}
        >
          <Image
            source={Char1}
            style={{
              height: '135%',
              width: '150%',
              borderBottomEndRadius: 16,
            }}
            resizeMode="contain"
          />
        </View>
      </>
      <LinearGradient
        style={[
          {
            position: 'absolute',
            top: 0,
            color: colors.white,
            width: '100%',
            height: '20%',
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
            zIndex: 55,
          },
        ]}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingHorizontal: '3.75rem',
            width: width <= 1250 ? '50%' : '40%',
            height: '15%',
          }}
        >
          <Image
            source={AppHeroLogoLite}
            style={{
              height: '100%',
              width: '80%',
            }}
            resizeMode="contain"
          />
        </View>
      </LinearGradient>
    </>
  )
}
const calculateMarginTop = (width) => {
  if (width >= 3000) {
    return '30rem'
  } else if (width >= 1920) {
    return '12rem'
  } else if (width >= 1400) {
    return '8rem'
  } else if (width >= 1000) {
    return '6rem'
  } else {
    return '4rem'
  }
}

const styles = StyleSheet.create({
  welcomeCardImage: {
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  marginTopValue: (width) => {
    return {
      marginTop: calculateMarginTop(width),
    }
  },
  carousel: {
    width: '100%',
    backgroundColor: 'rgba(256,256,256,0.5)',
    borderRadius: 20,
  },
  welcomeHeader: {
    marginBottom: 30,
  },
  welcomeDescription: {
    width: '90%',
  },
  leftHeader: {
    position: 'absolute',
    zIndex: 20,
    bottom: -62,
  },
  container: { flex: 1, borderRadius: 16 },
  slide: { flex: 1, justifyContent: 'center' },
})

export default AuthLeftContainer
