import { useTheme } from '@libs/theme'
import React, { useEffect, useState } from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import { Icon } from '@app-hero/native-icons'
import { countryCodes } from '@libs/utils'
import { Text } from '@libs/components'
import { DescriptionComponent } from './index'

const UpcomingEvents = ({
  events,
  handleEventNextPage,
  handleEventPrevPage,
  currentEventPage,
  hasEventNextPage,
  hasEventPrevPage,
  totalEventPages,
}) => {
  const { colors } = useTheme()
  const [errorOnLoad, setErrorOnLoad] = useState(false)
  const handleRedirection = (data) => {
    if (data?.Registration_Link__c) {
      window.open(data?.Registration_Link__c)
    } else {
      global.showToast('Unable to load the page. Please try again later', {
        type: 'error',
      })
    }
  }

  useEffect(() => {
    setErrorOnLoad(false)
  }, [events])

  const formattedEventDate = (eventDate) =>
    `${eventDate.getDate()}${getOrdinalSuffix(
      eventDate.getDate(),
    )} ${eventDate.toLocaleString('default', {
      month: 'long',
    })}, ${eventDate.getFullYear()}_${eventDate.getHours()}:${eventDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}`

  function getOrdinalSuffix(date) {
    if (date > 3 && date < 21) return 'th'
    switch (date % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  const getCountryNameWithCode = (code) =>
    countryCodes?.find((country) => country.code === code)?.name?.en

  return (
    <>
      <TouchableOpacity onPress={() => handleRedirection(events)}>
        <View>
          {events?.Thumbnail_Image__c && !errorOnLoad ? (
            <Image
              source={events?.Thumbnail_Image__c}
              style={{
                height: 124,
                width: '100%',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              resizeMode="stretch"
              onError={(error) => {
                if (error.nativeEvent.error) {
                  setErrorOnLoad(true)
                }
              }}
            />
          ) : null}
          <View
            style={{
              backgroundColor: 'white',
              paddingHorizontal: 20,
              paddingVertical: 24,
              borderRadius: !events.Thumbnail_Image__c || errorOnLoad ? 10 : 0,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            <View
              style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}
            >
              <Icon name="Building" width={22} height={22} />
              <Text
                style={{ marginLeft: 2 }}
                color="#262627"
                variant="display5"
              >
                {events?.Applicable_Institution__c}
              </Text>
              <View>
                {events.Applicable_Country__c ? (
                  <View
                    style={{
                      backgroundColor: '#262627',
                      width: 3,
                      height: 3,
                      borderRadius: '50%',
                    }}
                  />
                ) : null}
              </View>
              <Text color="#262627" variant="display5">
                {getCountryNameWithCode(events?.Applicable_Country__c) ||
                  events?.Applicable_Country__c}
              </Text>
            </View>
            <View style={{ marginVertical: 16, gap: 8 }}>
              <Text variant="heading5" color="#262627">
                {events.Title}
              </Text>
              {events.Description && events.Description !== 'null' ? (
                <Text variant="display4" color="#27272A">
                  <DescriptionComponent
                    description={events?.Description}
                    onLinkClick={(url) =>
                      handleRedirection({ Registration_Link__c: url })
                    }
                  />
                </Text>
              ) : null}
            </View>
            <View style={{ gap: 4 }}>
              <Text color="rgba(38, 38, 39, 0.5)" variant="display4">
                Registration free
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Text variant="display4" color="#262627">
                  {
                    formattedEventDate(
                      new Date(events?.Event_Date_Time__c),
                    )?.split('_')[0]
                  }
                </Text>
                {formattedEventDate(
                  new Date(events?.Event_Date_Time__c),
                )?.split('_')[1] ? (
                  <View
                    style={{
                      backgroundColor: '#262627',
                      width: 3,
                      height: 3,
                      borderRadius: '50%',
                      marginHorizontal: 4,
                    }}
                  />
                ) : null}
                <Text variant="display4" color="#262627">
                  {
                    formattedEventDate(
                      new Date(events?.Event_Date_Time__c),
                    )?.split('_')[1]
                  }
                </Text>{' '}
                <Text variant="display4" color="#262627">
                  {' '}
                  ({events?.Time_Zone__c || 'GMT'})
                </Text>
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
      {totalEventPages > 1 ? (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 4,
            paddingHorizontal: 16,
          }}
        >
          <TouchableOpacity
            onPress={() => handleEventPrevPage()}
            disabled={!hasEventPrevPage}
          >
            <Icon
              name="ArrowUp"
              width={24}
              height={24}
              color={hasEventPrevPage ? '#000000' : 'rgba(38, 38, 39, 0.5)'}
              style={{ transform: [{ rotate: '-90deg' }] }}
            />
          </TouchableOpacity>
          <View
            style={{
              flexDirection: 'row',
              rowGap: 6,
              columnGap: 8,
              flex: 1,
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {[...Array(totalEventPages)].map((_, index) => {
              const pageNumber = index + 1
              const isActive = currentEventPage === pageNumber

              return (
                <View
                  key={index}
                  style={{
                    width: isActive ? 40 : 10,
                    height: 10,
                    backgroundColor: isActive ? colors.primary : '#ffff',
                    borderRadius: 5,
                  }}
                />
              )
            })}
          </View>

          <TouchableOpacity
            onPress={() => handleEventNextPage()}
            disabled={!hasEventNextPage}
          >
            <Icon
              name="ArrowDown"
              width={24}
              height={24}
              color={hasEventNextPage ? '#000000' : 'rgba(38, 38, 39, 0.5)'}
              style={{ transform: [{ rotate: '-90deg' }] }}
            />
          </TouchableOpacity>
        </View>
      ) : null}
    </>
  )
}

export default UpcomingEvents
