import React, { useState } from 'react'
import { Pressable, View } from 'react-native'
import { Icon } from '@app-hero/native-icons'
import { t } from 'i18next'
import { useAtom } from 'jotai'
import {
  previewTaskDocument,
  deleteTaskDocument,
  downloadTaskDocument,
} from '@app-hero/shared-services'
import { useQueryClient } from '@tanstack/react-query'
import { Loader } from '@libs/components'
import { handleNavigation, isValidDocumentUrl } from '../../utils/function'
import { userProfile } from '../../utils/atom'
import { DocumentActionListMobile } from './documentActionList.mobile'

export const DocumentActionList = ({
  iconNames,
  taskDetails,
  businessUnitFilter,
  programmeName,
}) => {
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)
  const [userprofile] = useAtom(userProfile)
  const [visible, setVisible] = useState(false)

  const handleAction = async (item) => {
    setIsLoading(true)

    if (item === 'DeleteIcon') {
      const payload = {
        taskId: taskDetails?.Id,
        opportunityId: taskDetails?.WhatId,
        BusinessUnitFilter__c: businessUnitFilter,
        objectKey: taskDetails?.s3ObjectKey,
      }

      const deleteResponse = await deleteTaskDocument(payload)

      if (deleteResponse.ok) {
        await queryClient.setQueryData(
          [`getActionRequiredItems-${taskDetails?.WhatId}`],
          (prevData) => {
            if (
              !prevData ||
              !prevData.ActionRequiredItems ||
              !prevData.ActionRequiredItems.Items
            ) {
              return prevData
            }

            const updatedItems = prevData?.ActionRequiredItems?.Items?.map(
              (task) => {
                if (task.Id === taskDetails?.Id) {
                  return {
                    ...task,
                    isDocumentUploaded: false,
                  }
                }
                return task
              },
            )

            const updatedPendingActionRequiredItems =
              prevData?.pendingActionRequiredItems?.map((task) => {
                if (task.Id === taskDetails?.Id) {
                  return {
                    ...task,
                    isDocumentUploaded: false,
                  }
                }
                return task
              })

            return {
              ...prevData,
              ActionRequiredItems: {
                ...prevData.ActionRequiredItems,
                Items: updatedItems,
              },
              pendingActionRequiredItems: updatedPendingActionRequiredItems,
            }
          },
        )

        await queryClient.setQueryData(
          [`fetchOpportunities-${userprofile?.email}`],
          (prevData) => {
            const findOpportunityFromOpportunities = prevData?.find(
              (data) => data?.Id === taskDetails?.WhatId,
            )
            const findOpportunityFromOpportunitiesIndex = prevData?.findIndex(
              (data) => data?.Id === taskDetails?.WhatId,
            )

            if (
              !findOpportunityFromOpportunities ||
              !findOpportunityFromOpportunities.ActionRequiredItems ||
              !findOpportunityFromOpportunities.ActionRequiredItems.Items
            ) {
              return prevData
            }

            // Update ActionRequiredItems
            const updatedItems =
              findOpportunityFromOpportunities?.ActionRequiredItems?.Items?.map(
                (task) => {
                  if (task.Id === taskDetails?.Id) {
                    return {
                      ...task,
                      isDocumentUploaded: false,
                    }
                  }
                  return task
                },
              )

            // Update pendingActionRequiredItems
            const updatedPendingActionRequiredItems =
              findOpportunityFromOpportunities?.pendingActionRequiredItems?.map(
                (task) => {
                  if (task.Id === taskDetails?.Id) {
                    return {
                      ...task,
                      isDocumentUploaded: false,
                    }
                  }
                  return task
                },
              )

            // Create a new array with updated opportunity
            const updatedOpportunities = [...prevData]
            updatedOpportunities[findOpportunityFromOpportunitiesIndex] = {
              ...findOpportunityFromOpportunities,
              ActionRequiredItems: {
                ...findOpportunityFromOpportunities.ActionRequiredItems,
                Items: updatedItems,
              },
              pendingActionRequiredItems: updatedPendingActionRequiredItems,
            }

            return updatedOpportunities
          },
        )

        global.showToast(
          `The document was successfully deleted for <${programmeName}>`,
          {
            type: 'success',
          },
        )
      } else {
        global.showToast(
          `Unable to delete the document for <${programmeName}>. Please try again later.`,
          { type: 'error' },
        )
      }
    } else if (item === 'Eye') {
      if (!taskDetails?.s3ObjectKey) {
        global.showToast(
          'The document is not available for preview right now. Please check back later.',
          { type: 'error' },
        )
        setIsLoading(false)
        return
      }

      const response = await previewTaskDocument(taskDetails?.s3ObjectKey)

      if (response?.signedUrl && isValidDocumentUrl(response?.signedUrl)) {
        handleNavigation({
          url: response.signedUrl,
          fileName: '',
          translation: t,
        })
      } else {
        global.showToast(
          `We couldn’t load the document. Please try again later.`,
          {
            type: 'error',
          },
        )
      }
    } else if (item === 'Download') {
      if (!taskDetails?.s3ObjectKey) {
        global.showToast(
          'Sorry, the document is currently unavailable for download.',
          {
            type: 'error',
          },
        )
        setIsLoading(false)
        return
      }

      const response = await downloadTaskDocument(taskDetails?.s3ObjectKey)

      if (response?.signedUrl && isValidDocumentUrl(response?.signedUrl)) {
        const link = document.createElement('a')
        link.href = response.signedUrl
        link.download = taskDetails?.s3ObjectKey
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        global.showToast('The document was downloaded successfully.', {
          type: 'success',
        })
      } else {
        global.showToast(
          'We couldn’t download the document. Please try again later.',
          {
            type: 'error',
          },
        )
      }
    }
    setIsLoading(false)
  }

  if (window.innerWidth >= 650) {
    return (
      <View style={{ flexDirection: 'row', gap: 12 }}>
        {isLoading ? (
          <Loader
            size={20}
            style={{
              flexDirection: 'row',
              marginRight: iconNames?.length === 2 ? 20 : 40,
            }}
          />
        ) : (
          iconNames?.map((iconName, index) => (
            <Pressable onPress={() => handleAction(iconName)}>
              <Icon
                name={iconName}
                index={index}
                width={20}
                height={20}
                color="#848484"
              />
            </Pressable>
          ))
        )}
      </View>
    )
  }
  return (
    <DocumentActionListMobile
      iconNames={iconNames}
      taskDetails={taskDetails}
      businessUnitFilter={businessUnitFilter}
      programmeName={programmeName}
      visible={visible}
      setVisible={setVisible}
    />
  )
}
