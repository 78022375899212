import { Text } from '@libs/components'
import { useTheme } from '@libs/theme'
import { t } from 'i18next'
import React, { useState } from 'react'
import { View, Pressable, StyleSheet } from 'react-native'
import { DocumentLogo } from '@apphero/assets'
import { Icon } from '@app-hero/native-icons'
import { DocumentTaskList } from './documentTaskList'
import { VoidBox } from '../container/actionRequiredEmptyContainers/emptyCard'
import { sort } from '../../utils/sorting'
import { CompletedRequiredActionMobile } from './completedRequiredActionCard.mobile'

export const CompletedRequiredAction = ({ data, canExpandCompletedAction }) => {
  const { colors } = useTheme()

  const [isExpanded, setIsExpanded] = useState(
    canExpandCompletedAction || false,
  )

  const iconNames = ['Eye', 'Download']

  if (window.innerWidth >= 650) {
    return (
      <View
        style={{
          paddingHorizontal: 24,
          paddingVertical: 25,
          backgroundColor: colors.backgroundPrimary,
          borderRadius: 8,
          gap: 15,
        }}
      >
        <Pressable
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          onPress={() => setIsExpanded(!isExpanded)}
        >
          <Text variant="display3" style={{ fontWeight: '600' }} color="#000">
            {t('ACTION_REQUIRED.COMPLETED')} (
            {data?.completedActionRequiredItems?.length})
          </Text>
          <Icon
            name={isExpanded ? 'ArrowUp' : 'ArrowDown'}
            width={20}
            height={20}
          />
        </Pressable>
        {isExpanded ? (
          data?.completedActionRequiredItems?.length > 0 ? (
            <View
              style={{
                gap: 8,
                marginTop: 12,
                backgroundColor: colors.white,
                paddingHorizontal: 26,
                paddingVertical: 21,
                borderRadius: 8,
              }}
            >
              {sort(data?.completedActionRequiredItems, 'CreatedDate')?.map(
                (item, index) => (
                  <>
                    <DocumentTaskList
                      item={item}
                      index={index}
                      iconNames={iconNames}
                      status={
                        item?.Status === 'Completed' ||
                        item.Status === 'Cancelled'
                      }
                      activeCheckboxColor="#08C928"
                      isCompletedActionRequiredItems={true}
                    />
                    {data?.completedActionRequiredItems?.length - 1 !==
                    index ? (
                      <View style={styles.divider} />
                    ) : (
                      ''
                    )}
                  </>
                ),
              )}
            </View>
          ) : (
            <VoidBox
              heading1={t('EMPTY_CONTAINER.NO_COMPLETED_HEADING')}
              heading2={t('EMPTY_CONTAINER.COMPLETED_ACTION_MESSAGE')}
              image={DocumentLogo}
            />
          )
        ) : null}
      </View>
    )
  }
  return (
    <CompletedRequiredActionMobile
      data={data}
      canExpandCompletedAction={canExpandCompletedAction}
    />
  )
}

const styles = StyleSheet.create({
  buttonStyleFilled: (colors, isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 54 : 20,
    paddingVertical: 10,
    backgroundColor: colors.primary,
  }),
  buttonLabelStyle: (colors) => ({
    color: colors.white,
    fontWeight: '500',
    textTransform: 'uppercase',
  }),
  disabledButtonStyleFilled: (isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 34 : 20,
    paddingVertical: 10,
    backgroundColor: 'transparent',
    borderColor: 'black',
    borderWidth: 1,
    opacity: 0.4,
  }),
  disabledLabelStyle: (colors) => ({
    color: colors.black,
    fontWeight: '500',
    textTransform: 'uppercase',
  }),
  divider: {
    borderBottomWidth: 1,
    borderColor: '#162447',
    opacity: '10%',
    marginVertical: 12,
  },
  uploadButtonStyleFilled: (colors, isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 20 : 20,
    paddingVertical: 10,
    backgroundColor: colors.primary,
  }),
  uploadButtonLabelStyle: (colors) => ({
    color: colors.white,
    fontWeight: '500',
    textTransform: 'uppercase',
  }),
})
