import { View, StyleSheet, ScrollView } from 'react-native'
import React, { useEffect } from 'react'
import { Button, Loader, Text } from '@libs/components'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { useTheme } from '@libs/theme'
import { Icon } from '@app-hero/native-icons'
import { ProgramCard } from '../../components'
import { BasketAlertBox } from '../../components/basketAlert'

const DesktopView = ({
  alertDetails,
  brandLogoUrl,
  categoryData,
  contentHeight,
  isBasketLoading,
  isClearingProgram,
  isLoading,
  programCardRef,
  programDetails,
  handleRemove,
  setAlertDetails,
  setContentHeight,
  updateCartItems,
}) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const navigation = useNavigation()

  if (isLoading) {
    return (
      <ScrollView
        contentContainerStyle={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader />
      </ScrollView>
    )
  }

  return (
    <ScrollView style={{ flex: 1 }}>
      <View
        // source={ProfileBg}
        style={styles.container}
        // imageStyle={{ width: '70%', left: '30%' }}
      >
        <View style={styles.header}>
          <Text variant="display1" color={colors.onNeutral}>
            {t('COMPARE_SCREEN.COMPARE_PROGRAM')}
          </Text>
          <Button
            label={t('COMPARE_SCREEN.CLEAR_COMPARE_PROGRAM')}
            leftIcon={
              isClearingProgram ? (
                <Loader size={20} hasFlexValue={false} />
              ) : (
                <Icon name="Trash" />
              )
            }
            onPress={() => handleRemove('clear')}
            buttonColor={colors.primaryVariant}
            hoverColor={colors.primaryVariant3}
            buttonStyle={{
              paddingHorizontal: 16,
              paddingVertical: 8,
              marginLeft: 20,
            }}
          />
        </View>
        <View style={styles.programContainer}>
          <View
            style={[
              styles.column,
              { justifyContent: 'flex-end', marginRight: 14 },
            ]}
          >
            {Object.entries(categoryData).map(([key, value], categoryIndex) => {
              return (
                <TextContainer
                  data={value}
                  index={categoryIndex}
                  isLastIndex={categoryData?.length === categoryIndex + 1}
                  contentHeight={contentHeight}
                  setContentHeight={setContentHeight}
                />
              )
            })}
          </View>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <View style={styles.programDetails}>
              {programDetails?.map((item, index) => (
                <View style={styles.subContainer} key={index}>
                  <View ref={programCardRef} style={{ height: 205 }}>
                    <ProgramCard
                      data={item}
                      isBasketLoading={isBasketLoading}
                      isCompareProgram
                      style={{ flex: 1, margin: 0 }}
                      handleSecondaryButton={(id) => {
                        navigation.navigate('apply-programmes', { id })
                      }}
                      brandLogo={`${brandLogoUrl}/${item.brand?.replaceAll(
                        ' ',
                        '_',
                      )}.png`}
                      onClose={(removeData) => handleRemove(removeData)}
                      cardLine={false}
                      updateCartItems={updateCartItems}
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 40,
                      backgroundColor: colors.white,
                      borderRadius: 20,
                    }}
                  >
                    {Object.entries(categoryData).map(
                      ([key, value], categoryIndex) => (
                        <TextContainer
                          data={{
                            title: key,
                            fieldName: value?.fieldName,
                            added: value?.added,
                            programDetails: item,
                          }}
                          isLastIndex={
                            categoryData?.length === categoryIndex + 1
                          }
                          index={categoryIndex}
                          variant="body1"
                          hasApiData
                          contentHeight={contentHeight}
                          setContentHeight={setContentHeight}
                        />
                      ),
                    )}
                  </View>
                </View>
              ))}
            </View>
          </ScrollView>
        </View>
      </View>
      <BasketAlertBox
        isVisible={alertDetails?.isVisible}
        handleCloseModal={() => {
          setAlertDetails({ isVisible: false, type: '' })
        }}
        type={alertDetails?.type}
      />
    </ScrollView>
  )
}

const TextContainer = ({
  data,
  index,
  style,
  variant = 'heading5',
  hasApiData,
  isLastIndex,
  contentHeight,
  setContentHeight,
}) => {
  const { colors } = useTheme()

  function reduceData(data) {
    const reducedData = {}

    data.forEach((item) => {
      const { index, height } = item

      if (!reducedData[index] || height > reducedData[index].height) {
        reducedData[index] = item
      }
    })

    return Object.values(reducedData)
  }

  useEffect(() => {
    let content
    if (hasApiData) {
      content = getData()?.trim()
    } else {
      content = data?.title.trim()
    }
    const contentLength = content?.length > 30 ? 60 + content?.length / 2 : 60
    const cloneContentHeight = contentHeight
    const newData = {
      height: contentLength,
      index: index,
    }
    cloneContentHeight.push(newData)
    const result = reduceData(cloneContentHeight)
    setContentHeight(result)
  }, [])

  const getData = () => {
    const value = data?.programDetails?.[data?.fieldName]
    const field = data?.fieldName
    if (field === 'online') {
      if (value) return 'Yes'
      return 'No'
    }
    if (field === 'location') {
      return value?.join(', ') || '-'
    }
    if (field === 'minFees' || field === 'maxFees') {
      return `${value} ${data?.programDetails?.institutionCurrencyIsoCode}`
    }
    return `${value || '-'}  ${value && data?.added ? data.added : ''}`
  }

  return (
    <View
      style={[
        styles.textContainer,
        style,
        {
          height: contentHeight[index]?.height || 60,
          borderBottomWidth: !isLastIndex ? 1 : 0,
          borderBottomColor: !isLastIndex ? '#ECF2FC' : 'transparent',
        },
      ]}
      key={index}
    >
      <Text variant={variant} color={colors.onNeutral}>
        {hasApiData ? getData()?.trim() || '--' : data?.title?.trim()}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 40,
    paddingVertical: 60,
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    marginBottom: 50,
    alignItems: 'center',
  },
  programContainer: {
    flexDirection: 'row',
  },
  programDetails: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  subContainer: {
    flexDirection: 'column',
    width: 360,
    marginHorizontal: 20,
  },
  column: {
    flexDirection: 'column',
    // backgroundColor: '#FFFFFF',
    shadowColor: '#ECF2FC',
    shadowOffset: { width: 0, height: 2 },
    elevation: 5,
    shadowOpacity: 1,
    shadowRadius: -1,
  },
  textContainer: {
    paddingHorizontal: 16,
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
  },
})

export default DesktopView
