import React, { useState } from 'react'
import { View, StyleSheet, Image, Pressable } from 'react-native'
import { Text } from '@libs/components'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@libs/theme'
import { Icon } from '@app-hero/native-icons'
import { PlayButtonCMS } from '@apphero/assets'

const NeedSupportAccordion = ({
  children,
  imageSrc,
  imageAlt,
  style,
  onShowVideo = () => {},
  canHaveVideo,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <View style={[styles.rightContainer, style]}>
      <View style={{ paddingHorizontal: 32, paddingVertical: 24 }}>
        <Pressable
          onPress={() => setIsOpen(!isOpen)}
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text
            variant="display1"
            style={{
              color: colors.textPrimary,
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            {t('APPLICATION_DETAIL.ANY_QUESTION')}
          </Text>
          <Icon
            name={isOpen ? 'ArrowUp' : 'ArrowDown'}
            width={20}
            height={20}
          />
        </Pressable>

        {isOpen ? (
          <View style={{ flexDirection: 'column' }}>
            <View style={styles.divider} />

            {children}

            {imageSrc ? (
              <Pressable
                style={{
                  paddingHorizontal: 14,
                  backgroundColor: colors.white,
                  marginTop: 28,
                  borderRadius: 8,
                  flex: 1,
                }}
                onPress={onShowVideo}
                disabled={!canHaveVideo}
              >
                <Image
                  source={imageSrc}
                  style={{
                    width: '100%',
                    aspectRatio: 16 / 9,
                    borderRadius: 4,
                    flex: 1,
                  }}
                  alt={imageAlt}
                  resizeMode="contain"
                />
                {canHaveVideo ? (
                  <View
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: [{ translateX: -30 }, { translateY: -18 }],
                    }}
                  >
                    <Image
                      source={PlayButtonCMS}
                      style={{ width: 45, height: 45 }}
                      resizeMode="contain"
                    />
                  </View>
                ) : null}
              </Pressable>
            ) : null}
          </View>
        ) : null}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  rightContainer: {
    borderRadius: 13,
    background: 'transparent',
    borderWidth: 0.2,
    borderColor: 'rgba(0, 0, 0, 0.2)',
    backgroundColor: '#DDE0EE',
    width: '100%',
    flexDirection: 'column',
  },
  divider: {
    borderBottomWidth: 2,
    borderColor: 'rgba(0,0,0,0.2)',
    borderStyle: 'solid',
    opacity: 0.2,
    marginVertical: 20,
  },
})

export default NeedSupportAccordion
