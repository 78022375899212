import { View, StyleSheet, ScrollView } from 'react-native'
import React from 'react'
import { Button, Loader, Text } from '@libs/components'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { useTheme } from '@libs/theme'
import { ApplicationBasketCard } from '../../components'

const DesktopView = ({
  subHeadingCount,
  isLoading,
  basketData = [],
  deleteCartItem,
  deletingCartItemId,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const navigation = useNavigation()

  if (isLoading) {
    return (
      <ScrollView
        contentContainerStyle={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader visible />
      </ScrollView>
    )
  }
  return (
    <ScrollView
      style={styles.mainContainer}
      showsVerticalScrollIndicator={false}
    >
      <View style={{ flex: 1, maxWidth: '80%', alignSelf: 'center' }}>
        {subHeadingCount > 0 ? (
          <>
            <Text variant="heading4" style={styles.textStyle}>
              {t('APPLICATION_BASKET.HEADING1')}
            </Text>
            <Text variant="display3" style={styles.textStyle}>
              {t(`APPLICATION_BASKET.SUB_HEADING${subHeadingCount}`)}
            </Text>
          </>
        ) : null}
        <Text variant="heading4" style={styles.textStyle}>
          {t('APPLICATION_BASKET.HEADING2')}
        </Text>
        <ScrollView
          contentContainerStyle={styles.cardContainer(basketData?.length)}
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          {basketData.map((basketItem, index) => (
            <ApplicationBasketCard
              item={basketItem}
              index={index}
              handleDeleteCardItem={deleteCartItem}
              deletingCartItemId={deletingCartItemId}
            />
          ))}
        </ScrollView>
        <Text variant="display3" style={styles.textStyle}>
          {t('APPLICATION_BASKET.DESCRIPTION')}
        </Text>
        <View style={styles.buttonContainer}>
          <Button
            appearance="outline"
            label={t('BUTTON.GO_TO_MY_APPLICATION')}
            labelColors={colors.primary}
            buttonColor={colors.primary}
            buttonStyle={{
              width: '45%',
            }}
            onPress={() => navigation.goBack()}
          />
          <Button
            label={t('BUTTON.PROCEED')}
            labelColors={colors.white}
            buttonColor={colors.primary}
            buttonStyle={{
              width: '45%',
            }}
            onPress={() =>
              navigation.navigate('student-details', {
                routeName: basketData[0]?.routeName,
              })
            }
          />
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: (basketData) => ({
    flex: 1,
    marginBottom: 30,
    flexDirection: 'row',
    justifyContent: basketData <= 3 ? 'center' : 'flex-start',
  }),
  textStyle: {
    marginBottom: 20,
  },
  mainContainer: {
    flex: 1,
    paddingHorizontal: 40,
    paddingVertical: 60,
  },
})

export default DesktopView
