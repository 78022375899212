import { Text } from '@libs/components'
import React, { useState } from 'react'
import { Image, Pressable, View } from 'react-native'
import { dateFormatForCMS } from '@libs/utils/src/dateformat'
import { useTheme } from '@libs/theme'
import { sort } from '../../utils/sorting'
import LoadingSpinner from './customLoader'

const DynamicContentPanel = ({
  cmsContent,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) => {
  const cmsDynamicContent = cmsContent || []

  const handleRedirection = (data) => {
    if (data?.Hyper_Link__c) {
      window.open(data?.Hyper_Link__c)
    } else {
      global.showToast('Unable to load the page. Please try again later', {
        type: 'error',
      })
    }
  }

  const { colors } = useTheme()
  return (
    <View style={{ flex: 1 }}>
      <View>
        {cmsDynamicContent?.map((data, index) =>
          index === 0 ? (
            <View style={{ flex: 1 }} key={index}>
              <MainVideoCard
                data={data}
                handleRedirection={handleRedirection}
              />
            </View>
          ) : (
            <View key={index}>
              {index === 1 ? (
                <View
                  style={{
                    borderWidth: 0.5,
                    borderColor: colors.divider,
                    borderStyle: 'solid',
                    marginVertical: 24,
                  }}
                />
              ) : null}
              <VideoCard data={data} handleRedirection={handleRedirection} />
              {index !== cmsDynamicContent.length - 1 ? (
                <View
                  style={{
                    borderWidth: 0.5,
                    borderColor: colors.divider,
                    borderStyle: 'solid',
                    marginVertical: 24,
                  }}
                />
              ) : null}
            </View>
          ),
        )}
      </View>

      {hasNextPage ? (
        <View style={{ alignSelf: 'center' }}>
          {!isFetchingNextPage ? (
            <Pressable
              onPress={() => {
                fetchNextPage()
              }}
              style={{ marginTop: 32 }}
            >
              <Text variant="heading6" color={colors.primary}>
                VIEW MORE
              </Text>
            </Pressable>
          ) : (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 24,
                marginBottom: 8,
              }}
            >
              <LoadingSpinner size={20} />
            </View>
          )}
        </View>
      ) : null}
    </View>
  )
}

export default DynamicContentPanel

const MainVideoCard = ({ data, handleRedirection }) => {
  const { colors } = useTheme()
  const [errorOnLoad, setErrorOnLoad] = useState(false)

  return (
    <View style={{ flex: 1, gap: 16 }}>
      {data?.Image_Link__c && !errorOnLoad ? (
        <Pressable
          style={{ position: 'relative' }}
          onPress={() => handleRedirection(data)}
        >
          <Image
            source={data?.Image_Link__c}
            style={{ width: '100%', height: 122, borderRadius: 8 }}
            resizeMode="stretch"
            onError={(err) => {
              if (err.nativeEvent.error) {
                setErrorOnLoad(true)
              }
            }}
          />
        </Pressable>
      ) : null}
      <View style={{ gap: 8 }}>
        <Pressable onPress={() => handleRedirection(data)} style={{ gap: 8 }}>
          <Text variant="display5" color={colors.textSecondary}>
            {dateFormatForCMS(data?.Published_Date__c)}
          </Text>
          <View>
            <Text variant="heading5" color="#27272A" numberOfLines={2}>
              {data?.Title}
            </Text>
            <Text variant="display4" color="#27272A" numberOfLines={3}>
              <DescriptionComponent description={data?.Description} />
            </Text>
          </View>
        </Pressable>
      </View>
    </View>
  )
}

const VideoCard = ({ data, handleRedirection }) => {
  const { colors } = useTheme()
  const [errorOnLoad, setErrorOnLoad] = useState(false)
  return (
    <View
      style={{ flex: 1, gap: 16, flexDirection: 'row', alignItems: 'center' }}
    >
      {data?.Image_Link__c && !errorOnLoad ? (
        <Pressable
          style={{ position: 'relative' }}
          onPress={() => handleRedirection(data)}
        >
          <Image
            source={data?.Image_Link__c}
            style={{ width: 128, height: 155, borderRadius: 8 }}
            resizeMode="strech"
            onError={(err) => {
              if (err.nativeEvent.error) {
                setErrorOnLoad(true)
              }
            }}
          />
        </Pressable>
      ) : null}

      <View style={{ gap: 8, flex: 1 }}>
        <Pressable onPress={() => handleRedirection(data)} style={{ gap: 8 }}>
          <Text variant="display5" color={colors.textSecondary}>
            {dateFormatForCMS(data?.Published_Date__c)}
          </Text>
          <View>
            <Text variant="heading5" color="#27272A" numberOfLines={2}>
              {data?.Title}
            </Text>
            <Text variant="display4" color="#27272A" numberOfLines={3}>
              <DescriptionComponent
                description={data?.Description}
                onLinkClick={(url) => handleRedirection({ Hyper_Link__c: url })}
              />
            </Text>
          </View>
        </Pressable>
      </View>
    </View>
  )
}

function decodeHTMLEntities(text) {
  const textarea = document.createElement('textarea')
  textarea.innerHTML = text
  return textarea.value
}

export const DescriptionComponent = ({ description, onLinkClick }) => {
  const decodedDescription = decodeHTMLEntities(description)

  const handleClick = (e) => {
    if (e.target.tagName === 'A') {
      e.preventDefault()
      e.stopPropagation()

      const url = e.target.href
      if (onLinkClick) {
        onLinkClick(url)
      }
    }
  }

  return (
    <div
      dangerouslySetInnerHTML={{ __html: decodedDescription }}
      onClick={handleClick}
    />
  )
}
