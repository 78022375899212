/* eslint-disable no-nested-ternary */
import { View, StyleSheet, Image, FlatList, ScrollView } from 'react-native'
import { DropDown, Text, Button, ShimmerPlaceholder } from '@libs/components'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { HeroImage } from '@apphero/assets'
import { spacing, useTheme } from '@libs/theme'
import { Filter, ProgramCard } from '../../components'
import ProgramAccordion from '../programAccordion'
import { BasketAlertBox } from '../../components/basketAlert'

const DesktopView = ({
  alertDetails,
  brandLogoUrl,
  dropdownLeft,
  dropdownTop,
  filter,
  filters,
  filterApplied,
  filterKey,
  isBasketLoading,
  isLoading,
  isFilterDataFetching,
  isFilterDataFetchNextPage,
  isCompareProgrammesIdUpdate,
  handleCheck,
  handleClearFilter,
  handleFilterSelection,
  handleLoadMore,
  hasNextPage,
  institution,
  isFetchingNextPage,
  program,
  selectedAccordion,
  setFilterApplied,
  setSelectedAccordion,
  setAlertDetails,
  toggleDropdown,
  updateCartItems,
  updateCompareProgrammesIds,
}) => {
  const { t } = useTranslation()
  const { colors } = useTheme()

  const navigation = useNavigation()

  return (
    <ScrollView style={{ flex: 1 }}>
      <View style={styles.mainContainer}>
        <HeroComponent />
        <View style={styles.programContainer}>
          <View style={styles.row}>
            <Text
              variant="body2"
              style={{ paddingBottom: spacing.spacing2, marginRight: 10 }}
            >
              {t('DASHBOARD.SHOW_BY')}
            </Text>
            <View>
              <DropDown
                items={filter}
                toggleDropdown={toggleDropdown}
                isOutLine
                position={{ top: dropdownTop, left: dropdownLeft }}
                onPress={(selectedFilter) =>
                  handleFilterSelection(selectedFilter)
                }
                dropdownHeight={79}
              />
            </View>
          </View>
          <View style={styles.programBody}>
            <View style={styles.filterSection}>
              {isLoading && !isFetchingNextPage ? (
                <View style={{ gap: spacing.spacing4 }}>
                  {[1, 2, 3, 4, 5, 6].map(() => (
                    <ShimmerPlaceholder
                      style={{
                        width: '100%',
                        height: 60,
                        borderRadius: spacing.spacing5,
                      }}
                    />
                  ))}
                </View>
              ) : (
                <>
                  <Filter
                    filterData={filters}
                    hasSubData
                    handleCheck={handleCheck}
                    filtersApplied={filterApplied}
                    setFiltersApplied={setFilterApplied}
                  />
                  <Button
                    label={t('DASHBOARD.CLEAR_FILTER')}
                    buttonStyle={{ margin: 10 }}
                    onPress={handleClearFilter}
                  />
                </>
              )}
            </View>
            {isLoading && !isFetchingNextPage ? (
              <View
                style={[
                  styles.bodySection,
                  {
                    alignItems: 'center',
                  },
                ]}
              >
                <SkeletonLoaderGrid isProgrammes={filter} />
              </View>
            ) : (
              <View
                style={[
                  styles.bodySection,
                  {
                    alignItems:
                      isFilterDataFetching && !isFilterDataFetchNextPage
                        ? 'center'
                        : '',
                  },
                ]}
              >
                {isFilterDataFetching && !isFilterDataFetchNextPage ? (
                  <SkeletonLoaderGrid filter={filter} />
                ) : filter[0].isSelected ? (
                  <View style={styles.grid}>
                    <FlatList
                      data={program}
                      renderItem={({ item }) => (
                        <ProgramCard
                          data={item}
                          isCompareProgrammesIdUpdate={
                            isCompareProgrammesIdUpdate
                          }
                          style={{ width: '30%', marginBottom: 30 }}
                          handlePrimaryButton={(id, brand) =>
                            navigation.navigate('programme-detail', {
                              id,
                              brand,
                            })
                          }
                          isBasketLoading={isBasketLoading}
                          handleSecondaryButton={(id) =>
                            navigation.navigate('apply-programmes', { id })
                          }
                          brandLogo={`${brandLogoUrl}/${item.brand?.replaceAll(
                            ' ',
                            '_',
                          )}.png`}
                          updateCartItems={updateCartItems}
                          updateCompareProgrammesIds={
                            updateCompareProgrammesIds
                          }
                        />
                      )}
                      ListEmptyComponent={
                        <Text
                          variant="display3"
                          textAlign="center"
                          color={colors.onNeutral}
                        >
                          {t('DASHBOARD.NO_PROGRAM')}
                        </Text>
                      }
                      keyExtractor={(item) => item.id}
                      numColumns={3}
                    />
                  </View>
                ) : (
                  <View style={styles.accordionContainer}>
                    {institution?.length > 0 ? (
                      institution?.map((institutionData) => (
                        <ProgramAccordion
                          activeTab={selectedAccordion}
                          brandLogoUrl={brandLogoUrl}
                          setActiveTab={setSelectedAccordion}
                          institutionData={institutionData}
                          filters={filterApplied}
                          filterKey={filterKey}
                          ListEmptyComponent={
                            <View
                              style={{
                                flex: 1,
                                marginTop: '4%',
                              }}
                            >
                              <Text
                                variant="display3"
                                textAlign="center"
                                color={colors.onNeutral}
                              >
                                {t('DASHBOARD.NO_PROGRAM')}
                              </Text>
                            </View>
                          }
                          renderItem={({ item }) => (
                            <ProgramCard
                              data={item}
                              isCompareProgrammesIdUpdate={
                                isCompareProgrammesIdUpdate
                              }
                              accordion
                              style={{ width: 420 }}
                              isBasketLoading={isBasketLoading}
                              handlePrimaryButton={(id, brand) =>
                                navigation.navigate('programme-detail', {
                                  id,
                                  brand,
                                })
                              }
                              handleSecondaryButton={(id) =>
                                navigation.navigate('apply-programmes', { id })
                              }
                              brandLogo={`${brandLogoUrl}/${item.brand?.replaceAll(
                                ' ',
                                '_',
                              )}.png`}
                              updateCartItems={updateCartItems}
                              updateCompareProgrammesIds={
                                updateCompareProgrammesIds
                              }
                            />
                          )}
                        />
                      ))
                    ) : (
                      <Text
                        variant="display3"
                        textAlign="center"
                        color={colors.onNeutral}
                      >
                        {t('DASHBOARD.NO_PROGRAM')}
                      </Text>
                    )}
                  </View>
                )}
                {hasNextPage ? (
                  isFetchingNextPage || isFilterDataFetchNextPage ? (
                    <SkeletonLoaderGrid filter={filter} />
                  ) : (
                    <Button
                      label={t('DASHBOARD.LOAD_MORE')}
                      appearance="outline"
                      buttonStyle={{
                        width: '25%',
                        marginLeft: 15,
                        alignSelf: 'center',
                      }}
                      onPress={handleLoadMore}
                    />
                  )
                ) : null}
              </View>
            )}
          </View>
        </View>
      </View>

      <BasketAlertBox
        isVisible={alertDetails?.isVisible}
        handleCloseModal={() => {
          setAlertDetails({ isVisible: false, type: '' })
        }}
        type={alertDetails?.type}
      />
    </ScrollView>
  )
}

const SkeletonLoaderGrid = ({ filter }) => (
  <View style={styles.loaderGrid}>
    {[1, 2, 3, 4, 5, 6].map(() => (
      <ShimmerPlaceholder
        style={{
          width: filter?.[1]?.isSelected ? '100%' : 300,
          height: filter?.[1]?.isSelected ? 100 : 300,
          borderRadius: spacing.spacing5,
        }}
      />
    ))}
  </View>
)

const HeroComponent = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  return (
    <View style={styles.heroContainer}>
      <View style={styles.rightSession}>
        <Text variant="heading1" color={colors.onNeutral}>
          {t('DASHBOARD.HERO_HEADER')}
        </Text>
        <Text
          variant="heading5"
          color={colors.onNeutral}
          style={{ paddingTop: 25, paddingBottom: 10, lineHeight: 24 }}
        >
          {t('DASHBOARD.HERO_SUB_HEADER')}
        </Text>
        <Text variant="display3" color={colors.text} style={{ lineHeight: 23 }}>
          {t('DASHBOARD.HERO_DESCRIPTION')}
        </Text>
      </View>
      <Image
        source={HeroImage}
        style={{ height: 439, width: '60%' }}
        resizeMode="contain"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    paddingHorizontal: 40,
    paddingVertical: 60,
  },

  heroContainer: {
    flexDirection: 'row',
    paddingHorizontal: 40,
    justifyContent: 'space-between',
    marginBottom: 50,
    flex: 1,
  },
  rightSession: {
    flexDirection: 'column',
    flex: 1,
    paddingRight: 43,
  },
  programContainer: {
    flexDirection: 'column',
  },
  programBody: {
    flexDirection: 'row',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  loaderGrid: {
    flexDirection: 'row',
    marginTop: 10,
    flexWrap: 'wrap',
    gap: 20,
  },
  grid: {
    flexDirection: 'row',
    marginTop: 10,
  },
  accordionContainer: {
    flexDirection: 'column',
    marginTop: 30,
  },
  filterSection: {
    width: '25%',
    paddingVertical: 20,
    backgroundColor: 'transparent',
    marginRight: 30,
  },
  cardHeader: {
    flexDirection: 'column',
  },
  filterContainer: {
    borderRadius: 50,
  },
  bodySection: {
    flexDirection: 'column',
    flex: 1,
  },
  loadingContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    width: '6%',
    padding: 6,
  },
  loadingDots: {
    padding: 4,
  },
})

export default DesktopView
