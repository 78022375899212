import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { Loader, Text } from '@libs/components'
import { Icon } from '@app-hero/native-icons'
import { formatNotificationTimestamp } from '@libs/utils/src/dateformat'
import EmptyNotificationContainer from '../container/EmptyNotificationContainer'

const Notification = ({
  colors,
  navigation,
  setNotification,
  inAppNotifications,
  loading,
  handleUpdateNotification,
  handleEventNavigation,
  paddingHorizontal,
}) => {
  if (loading) {
    return <Loader size={24} />
  }

  const handleNavigation = () => {
    navigation.navigate('notification')
    setNotification(false)
  }

  return (
    <View style={[styles.profileMenu(colors, paddingHorizontal), {}]}>
      <View style={styles.arrowUp} />
      {inAppNotifications.length > 0 ? (
        inAppNotifications?.slice(0, 5).map((notificationItem, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => {
              if (!notificationItem?.readStatus) {
                handleUpdateNotification(notificationItem)
                handleEventNavigation(notificationItem)
              }
              handleNavigation()
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                flex: 1,
                alignItems: 'flex-start',
              }}
            >
              <View
                style={{
                  borderRadius: 32,
                  marginRight: '1rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icon
                  name="NotificationDocs"
                  color={!notificationItem?.readStatus ? colors.primary : null}
                />
              </View>

              <View style={{ width: '100%' }}>
                <Text
                  variant="display6"
                  style={{
                    color: colors.textPrimary,
                    opacity: 0.5,
                    paddingBottom: '0.25rem',
                    fontWeight: notificationItem?.readStatus ? 400 : 700,
                    fontSize: 14,
                  }}
                >
                  {formatNotificationTimestamp(notificationItem.createdAt)}
                </Text>
                <View style={{ flexDirection: 'row', width: '85%' }}>
                  <Text
                    variant="display3"
                    style={[
                      styles.menuItem(colors),
                      {
                        flexWrap: 'wrap',
                        flexShrink: 2,
                        fontWeight: notificationItem?.readStatus ? 400 : 700,
                        fontSize: 16,
                      },
                    ]}
                    numberOfLines={2}
                    ellipsizeMode="tail"
                  >
                    {notificationItem.message}
                  </Text>
                </View>
              </View>
            </View>
            {index < 4 ? <View style={styles.divider} /> : null}
          </TouchableOpacity>
        ))
      ) : (
        <EmptyNotificationContainer />
      )}
      {inAppNotifications.length > 0 ? (
        <View style={{}}>
          <TouchableOpacity
            onPress={() => {
              handleNavigation()
            }}
          >
            <Text
              style={{
                color: colors.textAccent,
                fontWeight: 700,
                lineHeight: 22.5,
                textAlign: 'center',
                marginTop: inAppNotifications.length === 0 ? 20 : 16,
              }}
              lineHeight={22.5}
            >
              See more
            </Text>
          </TouchableOpacity>
        </View>
      ) : null}
    </View>
  )
}
const styles = StyleSheet.create({
  buttons: (mobile, name) => ({
    height: mobile ? 40 : 40,
    width: mobile && !name === 'my-application' ? 28 : 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  }),
  profileMenu: (colors, paddingHorizontal) => ({
    position: 'absolute',
    top: 30,
    right: paddingHorizontal + 50,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: 10,
    padding: 24,
    flexDirection: 'column',
    width: '22.25rem',
    shadowColor: 'black', // Black shadow
    shadowOffset: { width: 0, height: 6 }, // Adjust shadow offset
    shadowOpacity: 0.25, // Adjust opacity (0-1 range)
    shadowRadius: 8, // Blur radius of the shadow
    elevation: 5,
  }),

  menuItem: (colors) => ({
    // textTransform: 'uppercase',
    // fontSize: 14,

    width: '90%',
  }),
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(22, 36, 71, 0.24)',
    position: 'absolute',
    marginTop: 75,
  },
  divider: {
    borderWidth: 0.6,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    marginVertical: 17,
  },
  arrowUp: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 20,
    borderRightWidth: 20,
    borderBottomWidth: 30,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'white',
    position: 'absolute',
    top: -15,
    right: 15,
    zIndex: 0,
  },
  blurContainer: {
    flex: 1,
    textAlign: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: 20,
    marginTop: 40,
    backgroundColor: ' rgba(22, 36, 71, 0.24)',
  },
})

export default Notification
