export const getTotalActionRequiredCount = (opportunities) => {
  const totalCount = opportunities?.reduce((sum, obj) => {
    if (
      obj.ApplicationStatus?.category !== 'Drafts' &&
      obj.ActionRequiredCount
    ) {
      return sum + obj.ActionRequiredCount
    }
    return sum
  }, 0)

  return totalCount
}

export const getTotalActionRequiredItemsCounts = (opportunities) => {
  const totalCount = opportunities?.reduce((sum, obj) => {
    if (
      obj.ApplicationStatus?.category !== 'Drafts' &&
      obj?.ActionRequiredItems?.Count
    ) {
      return sum + obj.ActionRequiredItems.Count
    }
    return sum
  }, 0)

  console.log('Total Count', totalCount)

  return totalCount
}
