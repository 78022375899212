import { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useNavigation } from '@react-navigation/native'
import { useWindowDimensions } from 'react-native'
import { isWeb } from '@libs/utils/src/screenLayout'
import { userProfile } from '@app-hero/shared/src/utils/atom'
import {
  getNotificationByEmail,
  updateNotificationStatus,
} from '@app-hero/shared-services'

export const useNotifications = () => {
  const [userprofile] = useAtom(userProfile)
  const [notifications, setNotifications] = useState([])
  const [offset, setOffset] = useState(0)
  const [totalNotifications, setTotalNotifications] = useState(0)
  const [notificationLoading, setNotificationLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const navigation = useNavigation()
  const { width } = useWindowDimensions()
  const isDesktop = isWeb(width)

  const fetchNotifications = async ({ email, limit, offset }) => {
    const data = await getNotificationByEmail({
      email,
      limit,
      offset,
    })
    setNotificationLoading(false)
    return data
  }

  const {
    data,
    isError,
    refetch: refetchNotification,
  } = useQuery(
    ['notifications', userprofile?.email],
    () =>
      fetchNotifications({
        email: userprofile?.email,
        limit: isDesktop ? 4 : 5,
        offset: 0,
      }),
    {
      enabled: !!userprofile?.email,
      onSuccess: (data) => {
        setNotifications(data.items)
        setTotalNotifications(data.total)
        setOffset(6)
        setIsLoading(false) // Update isLoading state
      },
      onError: () => {
        setIsLoading(false) // Update isLoading state in case of error
      },
    },
  )

  const { mutate: updateNotification } = useMutation(updateNotificationStatus, {
    onSuccess: () => {
      refetchNotification()
    },
  })

  const loadMoreNotifications = async () => {
    setNotificationLoading(true)

    const data = await fetchNotifications({
      email: userprofile?.email,
      limit: 6,
      offset: offset,
    })

    setNotifications((prev) => [...prev, ...data.items])
    setOffset((prevOffset) => prevOffset + 6)
  }

  const handleUpdateNotification = (notificationItem) => {
    if (notificationItem) {
      updateNotification(notificationItem)
    }
  }

  useEffect(() => {
    if (data) {
      setNotifications(data?.items)
      setTotalNotifications(data?.total)
      setIsLoading(false)
    }
  }, [data, isError])

  const handleEventNavigation = (item) => {
    let isActive = null
    switch (item.event) {
      case 'ADMISSION_STATUS_UPDATE':
        isActive = 'INFORMATION'
        break
      case 'REVIEW_CENTER_COMMENT':
        isActive = 'ACTION_REQUIRED'
        break
      case 'ADMISSION_CONDITION':
      case 'ISSUED_LETTERS':
        isActive = 'OFFERS'
        break
      case 'AGENT_UPLOADED_DOCUMENT':
        isActive = 'DOCUMENTS_LETTER'
        break
      case 'AGENT_TASK_CLOSURE':
        isActive = 'ACTION_REQUIRED'
        break
      default:
        break
    }

    if (isActive == null) return
    const navigationParams = {
      id: item?.messageDetails?.opportunityId,
      isActive,
    }
    if (item?.messageDetails?.taskId) {
      navigationParams.commentId = item?.messageDetails?.taskId
    }
    if (item.event === 'AGENT_TASK_CLOSURE') {
      navigationParams.canExpandCompletedAction = true
    }
    navigation.navigate('view-application-details', { ...navigationParams })
  }

  return {
    notifications,
    isLoading,
    isError,
    loadMoreNotifications,
    handleUpdateNotification,
    notificationLoading,
    totalNotifications,
    offset,
    notificationCount: data?.unreadCount,
    handleEventNavigation,
  }
}
