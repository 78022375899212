import { useState, useMemo } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useNavigation } from '@react-navigation/native'
import { userProfile } from '@app-hero/shared/src/utils/atom'
import { updateNotificationStatus } from '@app-hero/shared-services'
import { GET_SPOTLIGHT_NOTIFICATIONS_QUERY } from '@app-hero/shared-services/src/graphql/query'
import { apiCall } from '@app-hero/shared-services/src/graphql/apicall'

export const getNotificationByEmail = async (payload) => {
  const { data } = await apiCall('graphql', GET_SPOTLIGHT_NOTIFICATIONS_QUERY, {
    ...payload,
  })

  return data.getInAppNotificationByEmail
}

export const useSpotLightNotifications = () => {
  const [userprofile] = useAtom(userProfile)
  const [currentPage, setCurrentPage] = useState(1)
  const navigation = useNavigation()

  const fetchAllNotifications = async () => {
    const data = await getNotificationByEmail({
      email: userprofile.email,
      filters: {
        event: ['REVIEW_CENTER_COMMENT', 'ISSUED_LETTERS'],
        isClosed: false,
      },
    })

    return data
  }

  const {
    data,
    isLoading,
    isError,
    refetch: refetchNotification,
    isFetching,
  } = useQuery({
    queryKey: ['all-notifications-spotlight', userprofile?.email],
    queryFn: fetchAllNotifications,
    enabled: !!userprofile?.email,
  })

  const { mutate: updateNotification } = useMutation(updateNotificationStatus, {
    onSuccess: () => {
      refetchNotification()
    },
  })

  const itemsPerPage = 3

  const paginatedNotifications = useMemo(() => {
    if (isLoading && !data?.items && isFetching) return []
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return data?.items?.slice(startIndex, endIndex)
  }, [data, currentPage, itemsPerPage, isLoading, isFetching])

  const totalPages = useMemo(() => {
    if (!data || !data.total) return 0
    return Math.ceil(data.items.length / itemsPerPage)
  }, [data, itemsPerPage])

  // Next page function
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  // Previous page function
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }

  const handleUpdateNotification = (notificationItem) => {
    if (notificationItem) {
      updateNotification(notificationItem)
    }
  }

  const handleEventNavigation = (item) => {
    let isActive = null
    switch (item.event) {
      case 'ADMISSION_STATUS_UPDATE':
        isActive = 'INFORMATION'
        break
      case 'REVIEW_CENTER_COMMENT':
        isActive = 'ACTION_REQUIRED'
        break
      case 'ADMISSION_CONDITION':
      case 'ISSUED_LETTERS':
        isActive = 'OFFERS'
        break
      case 'AGENT_UPLOADED_DOCUMENT':
        isActive = 'DOCUMENTS_LETTER'
        break
      default:
        break
    }

    if (isActive == null) return
    const navigationParams = {
      id: item?.messageDetails?.opportunityId,
      isActive,
    }
    if (item?.messageDetails?.taskId) {
      navigationParams.commentId = item?.messageDetails?.taskId
    }
    navigation.navigate('view-application-details', { ...navigationParams })
  }

  const notificationCount = useMemo(() => {
    if (!data || !data.items) return 0
    return data.unreadCount
  }, [data])

  return {
    notifications: paginatedNotifications,
    isLoading,
    isError,
    handleUpdateNotification,
    isFetchingNextPage: false, // Always false as we're not fetching from backend
    totalNotifications: data?.total || 0,
    notificationCount,
    handleEventNavigation,
    hasNextPage: currentPage < totalPages,
    hasPrevPage: currentPage > 1, // Check if there's a previous page
    handleNextPage: nextPage,
    handlePrevPage: prevPage, // Return the prevPage function
    currentPage,
    totalPages,
  }
}
