import { API, Auth, graphqlOperation } from 'aws-amplify'
import { SUBSCRIPTION_QUERY } from './query'

export const notificationSubscriber = async (email, callback) => {
  const user = await Auth.currentAuthenticatedUser()
  const token = user.signInUserSession.accessToken.jwtToken
  const endPoint = API._options.API.endpoints.find(
    (e) => e.name === 'graphql',
  ).endpoint

  API._graphqlApi._options.aws_appsync_graphqlEndpoint = `${endPoint}`
  API._graphqlApi._options.aws_appsync_authenticationType =
    'AMAZON_COGNITO_USER_POOLS'
  API._graphqlApi._options.aws_appsync_authToken = token

  const newNotificationListener = API.graphql(
    graphqlOperation(SUBSCRIPTION_QUERY, {
      email,
      type: 'inApp',
    }),
  ).subscribe({
    next: ({ value }) => {
      if (typeof callback === 'function') {
        callback(value.data)
      }
    },
    error: (error) => {
      console.log('Subscription error:', error.errors)
    },
  })

  return newNotificationListener
}
