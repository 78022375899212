import React from 'react'
import { StyleSheet, View } from 'react-native'
import LottieView from 'react-native-web-lottie'

const LoadingSpinner = () => {
  return (
    <View style={styles.container}>
      <LottieView source={require('./loader.json')} autoPlay loop speed={0.1} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 100,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default LoadingSpinner
