const sentryUrl =
  'https://277b4676e9f448ab9508ec958cbb970c@o4504434079301632.ingest.sentry.io/4504434081660928'

const videoUrl =
  'https://storage.pardot.com/873531/1651055274Iu5NgSl3/video_5__2_.mp4'

const gusUrl = 'https://www.globaluniversitysystems.com/'

const privacyPolicyUrl =
  'https://www.globaluniversitysystems.com/privacy-policy'

const termsAndConditionsUrl =
  'https://www.globaluniversitysystems.com/terms-and-conditions'

const ourNetwork = 'https://www.globaluniversitysystems.com/our-network'

const generateAuthToken = (userProfile) => {
  const { firstName, lastName, email, externalId } = userProfile
  const userString = JSON.stringify({ firstName, lastName, email, externalId })
  return btoa(userString)
}

const generateSAuthToken = (userProfile) => {
  const { firstName, lastName, email, externalId } = userProfile
  const sauthString = JSON.stringify({ firstName, lastName, email, externalId })
  return btoa(sauthString)
}

const getFWChatBotUrl = (userProfile) => {
  try {
    const base64Token = generateAuthToken(userProfile)
    const sauthToken = generateSAuthToken(userProfile)

    return `${process.env.REACT_APP_IVY_AI_URL}?auth=${base64Token}&sauth=${sauthToken}`
  } catch (error) {
    console.error('Error generating FW_CHAT_BOT_URL:', error)
    return null
  }
}

export const FW_CHAT_BOT_URL = (userProfile) => {
  const chatBotUrl = getFWChatBotUrl(userProfile)
  return chatBotUrl
}

export {
  sentryUrl,
  videoUrl,
  privacyPolicyUrl,
  gusUrl,
  termsAndConditionsUrl,
  ourNetwork,
}
