import React, { useCallback, Suspense, useState, useEffect } from 'react'
import { ScreenLayout, SecureStore } from '@libs/utils'
import { Text } from 'react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import {
  useUpdateCompareProgrammesId,
  useUpdateProfile,
  useUpdateBasket,
  useProgramsInfiniteData,
  useInstitutionInfiniteData,
  useFilter,
} from '@app-hero/shared-hooks'
import { Auth } from 'aws-amplify'
import { useTranslation } from 'react-i18next'
import { getBasketItems, getFilterData, getUserDetails } from '../../api'
import DesktopView from './DesktopView'
import {
  cartItemsCount,
  selectedProgramIds,
  userProfile,
} from '../../utils/atom'

import { isValidBasket } from '../../utils/function'

const PathwayFinder = () => {
  const isFocused = useIsFocused()
  const [filterApplied, setFilterApplied] = useState({})
  const [userprofile, setUserDetails] = useAtom(userProfile)
  const [, setCartItemCount] = useAtom(cartItemsCount)
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [programIds, setSelectedProgramID] = useAtom(selectedProgramIds)
  const [alertDetails, setAlertDetails] = useState({
    isVisible: false,
    type: '',
  })
  const [isBasketLoading, setIsBasketLoading] = useState(false)
  const [selectedAccordion, setSelectedAccordion] = useState({})
  const [dropdownWidth, setDropDownWidth] = useState(0)
  const [isCompareProgrammesIdUpdate, setIsCompareProgrammesIdUpdate] =
    useState(false)
  const [filterKey, setFilterKey] = useState({
    currentKey: '',
    previousKey: [],
  })
  const { t } = useTranslation()
  const [brandLogoUrl, setBrandLogoUrl] = useState('')

  const [filter, setFilter] = useState([
    {
      label: t('DROP_DOWN.PROGRAMMES'),
      isSelected: true,
    },
    {
      label: t('DROP_DOWN.INSTITUTION'),
      isSelected: false,
    },
  ])
  const navigation = useNavigation()

  const getBasketDetail = async () => {
    let basketResponse = []
    if (userprofile?.email) {
      basketResponse = await getBasketItems({ email: userprofile?.email })
      let userDetailsCopy = { ...userprofile }
      userDetailsCopy['bucketId'] = basketResponse?.[0]?.bucketId || ''
      setUserDetails(userDetailsCopy)
    }
    return basketResponse
  }

  // API Call

  // fetch basket Items
  const { data: basketData } = useQuery({
    queryKey: [`getBasketItems-${userprofile?.email}`],
    queryFn: getBasketDetail,
    initialData: [],
  })

  // fetch filter data
  const { data: filters, isFetching: isFilterFetching } = useQuery({
    queryKey: ['getFilters'],
    queryFn: getFilterData,
    enabled: isFocused,
  })

  // fetch program details
  const {
    allData: program,
    isFetching: isProgramFetching,
    hasNextPage: programHasNextPage,
    isFetchingNextPage: isProgramFetchNextPage,
    fetchNextPage: programFetchNextPage,
  } = useProgramsInfiniteData({
    queryKey: 'getPrograms',
    limit: 9,
    enabled: isFocused && !filterKey.currentKey,
  })

  // filter the data
  const {
    allData: filterData,
    isFetching: isFilterDataFetching,
    hasNextPage: filterHasNextPage,
    isFetchingNextPage: isFilterDataFetchNextPage,
    fetchNextPage: filterDataFetchNextPage,
    refetch: filterRefetch,
  } = useFilter({
    queryKey: `getFilterData-${filterKey.currentKey}`,
    enabled: !!filterKey.currentKey && isFocused,
    limit: 9,
    filters: filterApplied,
    isInstitution: filter[1]?.isSelected,
  })

  // fetch All institution
  const {
    allData: institution,
    hasNextPage: institutionHasNextPage,
    isFetchingNextPage: isInstitutionFetchNextPage,
    fetchNextPage: institutionFetchNextPage,
  } = useInstitutionInfiniteData({
    queryKey: 'getAllInstitution',
    enabled: isFocused && !filterKey.currentKey,
    limit: 5,
  })

  // fetch userProfile
  const { data: userDetails } = useQuery({
    queryKey: ['getUserProfile'],
    queryFn: () => getUserDetails({ email: userprofile.email }),
    initialData: [],
    enabled: !!userprofile.email && isFocused,
  })

  const { mutation } = useUpdateBasket()

  const { mutation: updateProgramId } = useUpdateCompareProgrammesId()

  // Functions

  // updateProgrammesId

  const updateCompareProgrammesIds = async ({ programId, type }) => {
    setIsCompareProgrammesIdUpdate(true)
    if (programIds.includes(programId)) {
      setAlertDetails({ isVisible: true, type: 'compareProgrammes' })
      setIsCompareProgrammesIdUpdate(false)
    } else {
      const response = await updateProgramId.mutateAsync({ programId, type })
      setSelectedProgramID(response)
      setIsCompareProgrammesIdUpdate(false)
    }
  }

  // updateCart
  const updateCartItems = async ({ programDetails }) => {
    const validBasket = isValidBasket({
      programDetails,
      basketItems: basketData,
    })
    setIsBasketLoading(true)
    if (validBasket?.canAdd) {
      await mutation.mutateAsync({
        email: userprofile?.email,
        bucketId: userprofile?.bucketId,
        programDetails,
      })
      setIsBasketLoading(false)
    } else {
      setAlertDetails({ isVisible: true, type: validBasket?.type })
      setIsBasketLoading(false)
    }
  }

  // setting up the dropDown alignment
  const toggleDropdown = (visible, ref) => {
    if (visible) {
      return
    }
    if (!visible) {
      ref?.current?.measure((_fx, _fy, _w, _h, _px, py) => {
        setDropdownTop(py + 25)
        setDropdownLeft(_px - 5)
        setDropDownWidth(_w)
      })
    }
  }

  // fetch next data
  const handleLoadMore = () => {
    if (filterKey.currentKey) {
      return filterDataFetchNextPage()
    }
    if (filter[0].isSelected) {
      programFetchNextPage()
    } else {
      institutionFetchNextPage()
    }
  }

  // handle filterSelection
  const handleFilterSelection = (selectedFilter) => {
    const newFilter = filter.map((item) => {
      if (item?.label === selectedFilter?.label) {
        item.isSelected = true
      } else {
        item.isSelected = false
      }
      return item
    })
    setFilter(newFilter)
  }

  const handleClearFilter = () => {
    const initialFilerKey = {
      currentKey: '',
      previousKey: [],
    }
    setFilterKey(initialFilerKey)
    setFilterApplied({})
  }

  const fetchData = (filterValue) => {
    const keys = Object.keys(filterApplied)
    if (keys?.length > 0) {
      if (filter[0].isSelected && !filterValue.isInstitution) {
        return filterData
      } else if (filter[1].isSelected && filterValue.isInstitution) {
        return filterData
      } else if (!filterValue.isInstitution) {
        return program
      } else {
        return institution
      }
    }
    if (!filterValue.isInstitution) {
      return program
    } else {
      return institution
    }
  }

  const getHasNextPage = () => {
    const keys = Object.keys(filterApplied)
    if (keys?.length > 0) {
      return filterHasNextPage
    }
    if (filter[0].isSelected) {
      return programHasNextPage
    } else {
      return institutionHasNextPage
    }
  }

  const handleCheck = (isChecked, field, label, value) => {
    field = field?.charAt(0).toUpperCase() + field.slice(1)
    label = value.replaceAll(',', '')
    setSelectedAccordion({})

    const updatedFiltersApplied = { ...filterApplied }

    if (!isChecked) {
      updatedFiltersApplied[field] = {
        values: [...(updatedFiltersApplied[field]?.values || []), label],
      }
      const { previousKey } = filterKey
      const currentKey =
        filterKey.currentKey?.length > 0
          ? filterKey.currentKey.concat(`-${label}`).replaceAll(' ', '_')
          : label
      previousKey.push(label)
      const newKey = {
        currentKey: currentKey,
        previousKey: previousKey,
      }
      setFilterKey(newKey)
    } else if (updatedFiltersApplied[field]?.values) {
      updatedFiltersApplied[field].values = updatedFiltersApplied[
        field
      ].values.filter((ele) => ele !== label)

      if (!updatedFiltersApplied[field].values.length > 0) {
        delete updatedFiltersApplied[field]
      }
      const previousKey = filterKey.previousKey.filter((item) => item !== label)
      const currentKey =
        previousKey
          ?.map((item) => item)
          ?.join('-')
          ?.replaceAll(' ', '_') || ''

      const newKey = {
        currentKey: currentKey,
        previousKey: previousKey,
      }
      setFilterKey(newKey)
    }

    setFilterApplied(updatedFiltersApplied)
  }

  const { mutation: updateProfile } = useUpdateProfile()

  useEffect(() => {
    if (!isFocused) return

    const keys = Object.keys(filterApplied)
    if (keys?.length > 0) filterRefetch()
  }, [filter, isFocused])

  useEffect(() => {
    if (!isFocused) return

    if (basketData?.length > 0) {
      setCartItemCount(basketData?.length)
    }
  }, [isFocused, basketData])

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      if (!userprofile?.accessToken || !userprofile?.email) {
        const currentUser = await Auth.currentAuthenticatedUser().catch(
          (error) => console.log({ error }),
        )
        const userData = currentUser?.attributes

        const userDetails = {
          email: userData?.email || '',
          firstName: userData['custom:first_name'] || '',
          lastName: userData['custom:last_name'] || '',
          userID: userData?.sub || '',
          accessToken: currentUser?.signInUserSession?.accessToken?.jwtToken,
        }
        setUserDetails(userDetails)
      }
    })()
  }, [isFocused, userprofile])

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      if (Object.keys(userDetails).length > 0) {
        setUserDetails((prev) => ({ ...prev, ...userDetails }))
      }
      const currentUser = await Auth.currentAuthenticatedUser().catch((error) =>
        console.log({ error }),
      )
      if (
        userprofile?.email &&
        !userDetails?.email &&
        currentUser?.username.includes('linkedin')
      ) {
        const userDetails = {
          email: userprofile?.email || '',
          firstName: userprofile?.firstName || '',
          lastName: userprofile?.lastName || '',
        }
        await updateProfile.mutateAsync({
          updatedProfile: userDetails,
          isInitial: true,
        })
      }
    })()
  }, [userDetails, isFocused])

  useEffect(() => {
    if (!isFocused) return

    if (!userprofile.email) {
      navigation.navigate('login')
    }
  }, [isFocused, userprofile])

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const filterData = JSON.parse(await SecureStore.getItemAsync('filters'))
      let newkey = ''

      Object.entries(filterData || {})?.map(([key, value]) => {
        newkey =
          newkey?.length > 0
            ? newkey +
              `-${value.values
                .join(',')
                .replaceAll(' ', '_')
                .replaceAll(',', '-')}`
            : value.values.join(',').replaceAll(' ', '_').replaceAll(',', '-')
      })
      if (newkey.length > 0) {
        setFilterKey({
          currentKey: newkey,
          previousKey: newkey.replaceAll('_', ' ').split('-'),
        })
        setFilterApplied(filterData)
      }
    })()
  }, [isFocused])

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, DesktopView, DesktopView),
    [],
  )

  const viewProps = {
    alertDetails,
    brandLogoUrl,
    program: isFocused && fetchData({ isInstitution: false }),
    filters,
    institution: isFocused && fetchData({ isInstitution: true }),
    isCompareProgrammesIdUpdate,
    dropdownLeft,
    dropdownTop,
    dropdownWidth,
    isBasketLoading,
    isLoading: isProgramFetching || isFilterFetching,
    hasNextPage: isFocused && getHasNextPage(),
    isFetchingNextPage: filter[0].isSelected
      ? isProgramFetchNextPage
      : isInstitutionFetchNextPage,
    filter,
    filterApplied,
    selectedAccordion,
    isFilterDataFetching,
    isFilterDataFetchNextPage,
    filterHasNextPage,
    filterKey,
    filterDataFetchNextPage,
    setFilter,
    setSelectedAccordion,
    setAlertDetails,
    setFilterApplied,
    toggleDropdown,
    handleCheck,
    handleClearFilter,
    handleLoadMore,
    handleFilterSelection,
    updateCartItems,
    updateCompareProgrammesIds,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default PathwayFinder
