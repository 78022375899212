import React, {
  useCallback,
  Suspense,
  useRef,
  useState,
  useEffect,
} from 'react'
import {
  CustomAuth,
  ScreenLayout,
  SecureStore,
  countryCodes,
} from '@libs/utils'
import { useQuery } from '@tanstack/react-query'
import { Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import { useUpdateProfile } from '@app-hero/shared-hooks'
import { ProfilePlaceHolder } from '@apphero/assets'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import DesktopView from './DesktopView'
import { userProfile, isChatbotVisible } from '../../utils/atom'
import MobileView from './MobileView'
import { userChatBotDestroy } from '../../utils/addScript'
import { getUserDetails, getCountryList } from '../../api'
import { isValidPhoneNumber } from 'libphonenumber-js'

const Profile = () => {
  const [userprofile, setUserDetails] = useAtom(userProfile)
  const [profileDetails, setProfileDetails] = useState({
    fullName: '',
    firstName: '',
    lastName: '',
    mobile: {
      dialCode: '',
      mobileNumber: '',
      mobileCountryCode: '',
      mobileCountry: '',
    },
    country: '',
    countryCode: '',
  })
  const [canShowChatbot] = useAtom(isChatbotVisible)

  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownRight, setDropdownRight] = useState(0)
  const [dropdownWidth, setDropDownWidth] = useState(0)
  const [isLastNameAvailable, setIsLastNameAvailable] = useState()
  const [ctaEnable, setCTAEnable] = useState(false)
  const [countryDropdownData, setCountryDropDownData] = useState({
    countryData: [],
    selectedCountry: {},
  })
  const [isLoading, setIsLoading] = useState()
  const [isProfileUpdating, setIsProfileUpdating] = useState()
  const [successPopup, setSuccessPopup] = useState({
    visible: false,
    message: '',
  })
  const { t } = useTranslation()
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const [mobileNumberError, setMobileNumberError] = useState('')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')

  const DropdownButton = useRef()

  const toggleDropdown = (visible, ref) => {
    if (visible) {
      return
    }
    if (!visible) {
      ref?.current?.measure((_fx, _fy, _w, h, _px, py) => {
        setDropdownTop(py + 50)
        setDropdownRight(_px - 10)
        setDropDownWidth(_w)
      })
    }
  }

  const handleLogout = async () => {
    if (canShowChatbot) {
      await userChatBotDestroy()
    }
    setIsLoading(true)
    await CustomAuth.logOut()
    await SecureStore.deleteItemAsync('userProfile')
    await SecureStore.deleteItemAsync('config')
    await SecureStore.deleteItemAsync('selectedProgramId')
    await SecureStore.deleteItemAsync('userLanguage')
    await SecureStore.deleteItemAsync('filters')

    navigation.navigate('login')
    setIsLoading(false)
  }

  function isValidName(name) {
    const specialCharPattern =
      /[!@#$%`^&*~()_+\-=\[\]{};':"\\|,.<>\/?1234567890]+/

    return !specialCharPattern.test(name)
  }

  const handleChange = (field, selectedValue) => {
    if (field === 'firstName') {
      if (!isValidName(selectedValue)) {
        setFirstNameError('Please enter a valid First Name')
        setCTAEnable(false)
        return setProfileDetails((previousData) => ({
          ...previousData,
          [field]: selectedValue,
        }))
      } else {
        setFirstNameError('')
        setCTAEnable(true)
        return setProfileDetails((previousData) => ({
          ...previousData,
          [field]: selectedValue,
        }))
      }
    }
    if (field === 'lastName') {
      if (!isValidName(selectedValue)) {
        setLastNameError('Please enter a valid Last Name')
        setCTAEnable(false)
        return setProfileDetails((previousData) => ({
          ...previousData,
          [field]: selectedValue,
        }))
      } else {
        setLastNameError('')
        setCTAEnable(true)
        return setProfileDetails((previousData) => ({
          ...previousData,
          [field]: selectedValue,
        }))
      }
    }
    if (field === 'profilePictureLocation') {
      setCTAEnable(true)
      setUserDetails((prev) => ({
        ...prev,
        selectedImage: selectedValue.selectedFile,
      }))
      return setProfileDetails((previousData) => ({
        ...previousData,
        base64Picture: selectedValue.file,
        fileName: selectedValue.fileName,
        selectedImage: selectedValue.selectedFile,
      }))
    }
    if (field === 'mobile') {
      const { dialCode, mobileNumber } = selectedValue
      const fullNumber = `${dialCode}${mobileNumber}`

      const isValidMobileNumber = isValidPhoneNumber(fullNumber)

      if (!isValidMobileNumber && selectedValue) {
        setMobileNumberError('Please enter a valid Phone Number')
        setCTAEnable(false)
      } else {
        setMobileNumberError('')
        setCTAEnable(true)
      }

      return setProfileDetails((previousData) => ({
        ...previousData,
        [field]: selectedValue,
      }))
    }
    if (field === 'country') {
      setCTAEnable(true)

      return setProfileDetails((previousData) => ({
        ...previousData,
        [field]: selectedValue.Label,
        countryCode: selectedValue.Value,
      }))
    } else {
      setCTAEnable(true)
      return setProfileDetails((previousData) => ({
        ...previousData,
        [field]: selectedValue,
      }))
    }
  }

  const fetchUserDetails = async () => {
    let response = await getUserDetails({ email: userprofile.email })
    return response || {}
  }

  // fetch userProfile
  const { data: userDetails } = useQuery({
    queryKey: ['getUserProfile'],
    queryFn: fetchUserDetails,
    initialData: [],
    enabled: !!userprofile.email,
  })

  const { mutation } = useUpdateProfile()

  const handleSave = async () => {
    setIsProfileUpdating(true)
    setCTAEnable(false)
    await mutation.mutateAsync({
      updatedProfile: profileDetails,
      isLastNameAvailable,
    })

    setIsProfileUpdating(false)
  }

  useEffect(() => {
    if (!isFocused) return

    if (Object.keys(userDetails).length > 0) {
      const newUserDetails = {
        email: userprofile?.email,
        userID: userprofile.userID,
        accessToken: userprofile?.accessToken,
        bucketId: userprofile?.bucketId,
        ...userDetails,
      }
      setUserDetails(newUserDetails)
    }
  }, [userDetails, isFocused])

  useEffect(() => {
    if (!isFocused) return
    if (Object.keys(userprofile).length > 0) {
      let selectedCountry = {}
      let newUserDetails = { ...userprofile }

      const countryData = Object.entries(countryCodes)?.map(([key, item]) => {
        if (item?.dial_code === userprofile?.phoneNumber?.split('-')[0]) {
          selectedCountry = {
            countryNames: item.name,
            dial_code: item?.dial_code,
          }
        }
        return { countryNames: item.name, dial_code: item?.dial_code }
      })
      const firstName =
        userprofile?.firstName?.charAt(0).toUpperCase() +
        userprofile?.firstName?.slice(1)
      const lastName = !!userprofile?.lastName
        ? userprofile?.lastName?.charAt(0).toUpperCase() +
          userprofile?.lastName?.slice(1)
        : ''
      newUserDetails = {
        ...newUserDetails,
        fullName: `${firstName} ${lastName}`,
        mobile: {
          dialCode: userprofile?.phoneNumber?.split('-')[0],
          mobileNumber: userprofile?.phoneNumber?.split('-')[1],
        },
      }
      delete newUserDetails['phoneNumber']
      setCountryDropDownData({ countryData, selectedCountry })
      setProfileDetails(newUserDetails)
    }
  }, [isFocused, userprofile])

  const getImageSource = () => {
    if (profileDetails?.selectedImage) {
      return profileDetails.selectedImage
    } else if (profileDetails?.profilePictureLocation) {
      return { uri: profileDetails?.profilePictureLocation }
    } else {
      return ProfilePlaceHolder
    }
  }

  const { data: countryList } = useQuery({
    queryKey: ['getCountryList'],
    queryFn: getCountryList,
    enabled: isFocused,
  })

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  const hasError = () =>
    Boolean(lastNameError || firstNameError || mobileNumberError) || !ctaEnable

  useEffect(() => {
    setCTAEnable(!hasError())
  }, [
    lastNameError,
    firstNameError,
    mobileNumberError,
    profileDetails,
    ctaEnable,
  ])

  const viewProps = {
    countryDropdownData,
    ctaEnable,
    countryList,
    DropdownButton,
    dropdownRight,
    dropdownTop,
    dropdownWidth,

    isLoading,
    isProfileUpdating,
    isLastNameAvailable,
    profileDetails,
    successPopup,
    getImageSource,
    handleChange,
    handleLogout,
    handleSave,
    setProfileDetails,
    setIsLastNameAvailable,
    setUserDetails,
    setSuccessPopup,
    setCTAEnable,
    toggleDropdown,
    ProfilePlaceHolder,
    navigation,
    mobileNumberError,
    firstNameError,
    lastNameError,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default Profile
