import { View, Text, Image, useWindowDimensions } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyFile } from '@apphero/assets'
import { isMobile } from '@libs/utils/src/screenLayout'
import { useTheme } from '@libs/theme'

const EmptyContainer = ({ hasFixedHight = false, imageSource, noData }) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  return (
    <View
      style={[
        {
          backgroundColor: mobile ? 'white' : 'white',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 20,
          paddingVertical: 12,
        },
        hasFixedHight
          ? {
              height: 250,
            }
          : { flex: !mobile && 1, height: mobile ? 400 : 0 },
      ]}
    >
      <Image
        source={imageSource || EmptyFile}
        style={{ height: 148, width: 219, marginBottom: 16 }}
      />
      <Text
        variant="display1"
        color={colors.primary}
        style={{ textAlign: 'center' }}
      >
        {noData || t('MY_APPLICATION.NO_DATA')}
      </Text>
    </View>
  )
}

export default EmptyContainer
