import { atomWithStorage } from 'jotai/utils'
import { atom } from 'jotai'
import { languageDropDown } from '../constants'

export const userLanguage = atomWithStorage('userLanguage', languageDropDown[0])
export const selectedProgramIds = atomWithStorage('selectedProgramId', [])
export const cartItemsCount = atomWithStorage('cartItemsCount', 0)
export const isChatbotVisible = atomWithStorage('isChatbotVisible', false)

export const userProfile = atomWithStorage('userProfile', {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  userID: '',
})

export const signUpDetailsAtom = atomWithStorage('signUpDetails', {
  country: '',
  countryCode: '',
  firstName: '',
  lastName: '',
  email: '',
  mobile: {
    dialCode: '',
    mobileNumber: '',
  },
  password: '',
  repeatPassword: '',
})

export const ResetPasswordAtom = atom({
  email: '',
  code: '',
  newPassword: '',
  confirmPassword: '',
})

export const errorMessageAtom = atom('')
export const OTPVerification = atom('')

export const alertBoxAtom = atomWithStorage('alertBox', {
  visible: false,
  message: '',
})

export const brandDetailsAtom = atomWithStorage('uspBrandThemeDetails', {
  colors: {},
  uspDetails: [],
  brand: '',
  brandName: 'AppHero',
  emailStatus: null,
})
