import { Button, CheckBox, Loader, Text } from '@libs/components'

import { View, StyleSheet, useWindowDimensions } from 'react-native'
import React, { useState } from 'react'
import { DateforComments as dateFormat } from '@libs/utils'
import { t } from 'i18next'
import { useTheme } from '@libs/theme'
import { isWeb } from '@libs/utils/src/screenLayout'
import { DocumentActionList } from './documentActionList'
import { DocumentTaskListMobile } from './documentTaskList.mobile'

export const DocumentTaskList = ({
  item,
  iconNames,
  status,
  handleSelectedOpportunity,
  activeCheckboxColor,
  businessUnitFilter,
  isCompletedActionRequiredItems,
  programmeName,
}) => {
  const { colors } = useTheme()
  const { width } = useWindowDimensions()
  const isDesktop = isWeb(width)
  const [isLoading, setIsLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  if (width >= 650) {
    return (
      <View
        key={item?.Id}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <CheckBox
          style={{ alignSelf: 'baseline', padding: 0 }}
          disable
          value={status}
          activeCheckboxColor={activeCheckboxColor}
        />
        <View style={{ gap: 5, justifyContent: 'flex-start', flex: 1 }}>
          <Text variant="display5" color="#545B61">
            {dateFormat(item?.CreatedDate)}
          </Text>
          <Text variant="display5" color="#131E1D" style={{ paddingRight: 20 }}>
            {item.IsAgentClosed ? (
              <Text variant="display5" style={{ fontWeight: 700 }}>
                Completed by the Agent -{' '}
              </Text>
            ) : null}
            {item.IsAgentCancelled ? (
              <Text variant="display5" style={{ fontWeight: 700 }}>
                Cancelled by the Agent -{' '}
              </Text>
            ) : null}
            {item?.IsDirectSalesClosed ? (
              <Text variant="display5" style={{ fontWeight: 700 }}>
                Closed by Advisor -{' '}
              </Text>
            ) : null}
            {item?.comment}
          </Text>
        </View>
        <View style={{ alignSelf: 'center' }}>
          {!item?.IsAgentClosed &&
          !item?.IsAgentCancelled &&
          !item.IsDirectSalesClosed ? (
            isCompletedActionRequiredItems ? (
              <DocumentActionList
                iconNames={iconNames}
                taskDetails={item}
                businessUnitFilter={businessUnitFilter}
                programmeName={programmeName}
              />
            ) : isLoading ? (
              <Loader size={20} style={{ marginRight: 20 }} />
            ) : (
              <Button
                onPress={async () => {
                  setIsLoading(true)
                  await handleSelectedOpportunity(item)
                  setIsLoading(false)
                }}
                appearance="filled"
                label={t('BUTTON.UPLOAD_FILE')}
                labelVariant="display5"
                labelStyle={[styles.uploadButtonLabelStyle(colors)]}
                buttonStyle={styles.uploadButtonStyleFilled(colors, isDesktop)}
              />
            )
          ) : null}
        </View>
      </View>
    )
  }
  return (
    <DocumentTaskListMobile
      item={item}
      iconNames={iconNames}
      status={status}
      handleSelectedOpportunity={handleSelectedOpportunity}
      activeCheckboxColor={activeCheckboxColor}
      businessUnitFilter={businessUnitFilter}
      isCompletedActionRequiredItems={isCompletedActionRequiredItems}
      programmeName={programmeName}
      visible={visible}
      setVisible={setVisible}
    />
  )
}

const styles = StyleSheet.create({
  uploadButtonStyleFilled: (colors, isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 21 : 20,
    paddingVertical: 10,
    backgroundColor: colors.primary,
  }),
  uploadButtonLabelStyle: (colors) => ({
    color: colors.white,
    fontWeight: '700',
    textTransform: 'uppercase',
    fontSize: 12,
  }),
})
