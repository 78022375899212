import { useTheme } from '@libs/theme'
import React, { useEffect, useState, useCallback } from 'react'
import { View, Text, FlatList, Pressable, StyleSheet } from 'react-native'

const HorizontalTimeline = ({ data, activeTitle }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [itemWidths, setItemWidths] = useState({})
  const { colors } = useTheme()

  useEffect(() => {
    if (activeTitle === 'Withdrawn') {
      data?.pop()
      data?.push({
        id: 5,
        title: 'WITHDRAWN',
        status: 'Withdrawn',
      })
    } else {
      data?.pop()
      data?.push({
        id: 5,
        title: 'ENROLMENT',
        status: 'Enrolment',
      })
    }
    const index = data.findIndex((item) => item?.status === activeTitle)
    if (index !== -1) {
      setActiveIndex(index)
    }
  }, [activeTitle, data])

  const onItemLayout = useCallback((event, id) => {
    const { width } = event.nativeEvent.layout
    setItemWidths((prev) => ({ ...prev, [id]: width }))
  }, [])

  const renderItem = ({ item, index }) => {
    const isActive = index === activeIndex
    const isBeforeActive = index < activeIndex

    const findMargin = () => {
      const width = window.innerWidth
      if (width >= 1200 && width < 1400) {
        return 55
      }
      if (width >= 1100 && width < 1200) {
        return 50
      }
      if (width >= 1024 && width < 1100) {
        return 40
      }
    }

    return (
      <Pressable
        key={item.id.toString()}
        style={({ pressed }) => [
          styles.itemContainer,
          pressed && styles.pressedItemContainer,
        ]}
        disabled
        onLayout={(event) => onItemLayout(event, item.id)}
      >
        <View
          style={{
            flexDirection: 'row',
            position: 'relative',
            marginHorizontal: window.innerWidth >= 1400 ? 70 : findMargin(),
          }}
        >
          <View
            style={
              isActive || isBeforeActive
                ? [
                    styles.activeDot(isActive),
                    { backgroundColor: colors.buttonPrimary, zIndex: 1 },
                  ]
                : [styles.dot, { backgroundColor: 'black', zIndex: 1 }]
            }
          />
          {item?.id < data.length && (
            <View
              style={{
                width: window.innerWidth >= 1400 ? 145 : itemWidths[item.id],
                borderBottomWidth: 1,
                alignSelf: 'center',
                position: 'absolute',
                left: 10,
                top: isActive ? 5 : 4,
                borderStyle: isBeforeActive ? 'solid' : 'dashed',
                borderColor: isBeforeActive ? colors.buttonPrimary : 'gray',
              }}
            />
          )}
        </View>
        <Text
          style={
            isActive || isBeforeActive
              ? [
                  styles.activeTitle,
                  {
                    color: colors.buttonPrimary,
                    fontWeight: isActive ? '700' : '400',
                  },
                ]
              : [styles.title, { color: 'black' }]
          }
        >
          {item.title?.split(' ')[0]}
        </Text>
        <Text
          style={
            isActive || isBeforeActive
              ? [
                  styles.activeTitle,
                  {
                    color: colors.buttonPrimary,
                    fontWeight: isActive ? '700' : '400',
                    height: 16,
                  },
                ]
              : [styles.title, { color: 'black' }]
          }
        >
          {item.title?.split(' ')[1]} {item.title?.split(' ')[2]}
        </Text>
      </Pressable>
    )
  }

  return (
    <View style={styles.container}>
      <FlatList
        data={data}
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.timeline}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    paddingHorizontal: 25,
    paddingTop: 48,
    paddingBottom: 32,
    borderRadius: 10,
  },
  timeline: {
    alignItems: 'center',
  },
  itemWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemContainer: {
    alignItems: 'center',
  },
  pressedItemContainer: {
    opacity: 0.5,
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginBottom: 5,
  },
  activeDot: (isActive) => ({
    width: isActive ? 12 : 10,
    height: isActive ? 12 : 10,
    borderRadius: isActive ? 6 : 5,
    marginBottom: 5,
  }),
  title: {
    color: 'gray',
    marginTop: 5,
  },
  activeTitle: {
    color: 'blue',
    fontWeight: 'bold',
    marginTop: 5,
    marginLeft: -5,
  },
})

export default HorizontalTimeline
