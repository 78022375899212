import { colors } from './tokens'

export const LightTheme = {
  colors: {
    primary: colors.primary.blue400,
    primaryVariant: colors.primary.blue100,
    primaryContainer: colors.primary.blue200,
    primaryVariant3: colors.primary.blue300,
    buttonPrimary: colors.primary.blue400,
    onHoverColor: colors.primary.blue500,
    primaryVariant6: colors.primary.blue600,
    white: colors.neutral.white,
    neutral: colors.neutral.neutral900,
    onPrimary: colors.neutral.white,
    onNeutral: colors.neutral.neutral800,
    fieldBorder: colors.neutral.neutral300,
    primaryPlaceHolder: colors.neutral.neutral500,
    secondaryPlaceHolder: colors.neutral.white700,
    text: colors.neutral.neutral700,
    textSecondary: colors.neutral.neutral600,
    buttonVariant1: colors.secondary.yellow100,
    onAlert: colors.functionalError.error500,
    background: colors.secondary.blue100,
    primaryBackground: colors.neutral.neutral100,
    backgroundOnNeutral: colors.neutral.white500,
    backgroundNeutral: colors.neutral.neutralVariant10,
    gradientStart: '#EBF0FF',
    gradientEnd: '#E4E4E7',
    notificationUnreadBackground: '#F4F5FB',
    primaryIconColor: colors.neutral.neutral400,
    success: colors.success.green700,
    successVariant1: colors.success.green500,
    warning: colors.warning.warning,
    warningVariant: colors.warning.warning100,
    warningVariant1: colors.warning.warning200,
    textPrimary: '#162447',
    textAccent: '#2563EB',
    linkedinBackground: '#0A66C2',
    linkedinOnHover: '#004377',
    backgroundPrimary: colors.background.primary,
    cardTitleColor: colors.card.titleColor,
    draftBlue: colors.card.draftBlue,
    borderPrimary: colors.border.primary,
    authPrimary: colors.auth.primary,
    borderBlue: colors.tab.activeColor,
    teritiary: '#0D1B2A',
    divider: colors.border.divider,
    backgroundSecondary: '#F4F5FB',
    alertPrimary: '#D4691B',
    textTeritiary: '#021547',
  },
}
