import React from 'react'
import { View, TouchableOpacity, StyleSheet, Dimensions } from 'react-native'
import { Text } from '@libs/components'
import { Icon } from '@app-hero/native-icons'
import { useTheme } from '@libs/theme'
import { formatNotificationTimestamp } from '@libs/utils/src/dateformat'

const { width: SCREEN_WIDTH } = Dimensions.get('window')

const PaginatedContent = ({
  notifications,
  handleNextPage,
  handlePrevPage,
  handleUpdateNotification,
  handleEventNavigation,
  currentPage,
  hasNextPage,
  hasPrevPage,
  totalPages,
}) => {
  const { colors } = useTheme()

  return (
    <View style={styles.container}>
      <View>
        {notifications?.map((notificationItem, index) =>
          notificationItem !== null ? (
            <React.Fragment key={index}>
              <TouchableOpacity
                onPress={() => {
                  if (!notificationItem?.readStatus) {
                    handleUpdateNotification(notificationItem)
                  }
                  handleEventNavigation(notificationItem)
                }}
              >
                <View
                  style={{
                    flex: 1,
                    gap: 8,
                  }}
                >
                  <Text variant="display5" color={colors.textSecondary}>
                    {formatNotificationTimestamp(notificationItem?.createdAt)}
                  </Text>
                  <Text variant="display4" color="#27272A">
                    {notificationItem?.message}
                  </Text>
                </View>
              </TouchableOpacity>
              {notifications.length - 1 !== index && (
                <View style={styles.divider} />
              )}
            </React.Fragment>
          ) : null,
        )}
      </View>
      {totalPages > 1 ? (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            justifyContent: 'space-between',
            marginTop: 16,
            gap: 4,
          }}
        >
          <TouchableOpacity
            onPress={() => handlePrevPage()}
            disabled={!hasPrevPage}
          >
            <Icon
              name="ArrowUp"
              width={20}
              height={20}
              color={hasPrevPage ? '#000000' : '#DDDFEE'}
              style={{ transform: [{ rotate: '-90deg' }] }}
            />
          </TouchableOpacity>
          <View
            style={{
              flexDirection: 'row',
              rowGap: 6,
              columnGap: 8,
              flex: 1,
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {[...Array(totalPages)].map((_, index) => {
              const pageNumber = index + 1
              const isActive = currentPage === pageNumber

              return (
                <View
                  key={index}
                  style={{
                    width: isActive ? 40 : 10,
                    height: 10,
                    backgroundColor: isActive ? colors.primary : '#DDDFEE',
                    borderRadius: 5,
                  }}
                />
              )
            })}
          </View>

          <TouchableOpacity
            onPress={() => handleNextPage()}
            disabled={!hasNextPage}
          >
            <Icon
              name="ArrowDown"
              width={20}
              height={20}
              color={hasNextPage ? '#000000' : '#DDDFEE'}
              style={{ transform: [{ rotate: '-90deg' }] }}
            />
          </TouchableOpacity>
        </View>
      ) : null}
    </View>
  )
}

export default PaginatedContent

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    padding: 24,
    borderRadius: 8,
  },
  page: {
    width: SCREEN_WIDTH,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pagination: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 20,
    alignSelf: 'center',
  },
  paginationDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: 'rgba(255, 255, 255, 0.92)',
    marginHorizontal: 4,
  },
  paginationDotActive: {
    backgroundColor: '#007AFF',
  },
  divider: {
    borderWidth: 0.3,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    borderStyle: 'solid',
    marginVertical: 24,
  },
})
