import { Button, Loader, Text } from '@libs/components'
import { useTheme } from '@libs/theme'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useWindowDimensions, View, Pressable, StyleSheet } from 'react-native'
import { Icon } from '@app-hero/native-icons'
import { isWeb } from '@libs/utils/src/screenLayout'
import { AlertBox } from './alertConatiner'
import { DocumentTaskList } from './documentTaskList'
import { VoidBox } from '../container/actionRequiredEmptyContainers/emptyCard'
import { sort } from '../../utils/sorting'
import { OutstandingRequiredActionMobile } from './outstandingRequiredActionCard.mobile'
import { getProgrammeName } from '../../utils/getProgramName'

export const OutstandingRequiredAction = ({
  data,
  setModalVisible,
  handleSelectedOpportunity,
  handleSubmitAllOpportunityTaskFile,
  businessUnitFilter,
  isSubmitLoading,
  programName,
}) => {
  const { colors } = useTheme()
  const { width } = useWindowDimensions()
  const isDesktop = isWeb(width)
  const [isExpanded, setIsExpanded] = useState(
    data?.pendingActionRequiredItems?.length,
  )

  const iconNames = ['Eye', 'Download', 'DeleteIcon']

  if (width >= 650) {
    return (
      <View
        style={{
          paddingHorizontal: 24,
          paddingVertical: 25,
          backgroundColor: colors.backgroundPrimary,
          borderRadius: 8,
          gap: 15,
        }}
      >
        <Pressable
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          onPress={() => setIsExpanded(!isExpanded)}
        >
          <Text variant="display3" style={{ fontWeight: '600' }} color="#000">
            {t('ACTION_REQUIRED.OUTSTANDING')} (
            {data?.pendingActionRequiredItems?.length})
          </Text>
          <Icon
            name={isExpanded ? 'ArrowUp' : 'ArrowDown'}
            width={20}
            height={20}
          />
        </Pressable>
        {isExpanded ? (
          data?.pendingActionRequiredItems?.length > 0 ? (
            <>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                  flex: 1,
                  gap: 12,
                }}
              >
                <View style={{ flex: 1 }}>
                  <AlertBox
                    iconColor={colors.alertPrimary}
                    iconSize={20}
                    iconName="AlertCircle"
                    style={{
                      flexDirection: 'row',
                      gap: 8,
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <View style={{ flex: 1, flexWrap: 'wrap', width: '80%' }}>
                      <Text variant="display4" color={colors.alertPrimary}>
                        <b>{t('ACTION_REQUIRED.NOTE')}</b>
                        {t('ACTION_REQUIRED.ALERT')}
                      </Text>
                    </View>
                  </AlertBox>
                </View>
              </View>
              {data?.pendingActionRequiredItems?.length ? (
                <View
                  style={{
                    gap: 8,
                    marginTop: 12,
                    backgroundColor: colors.white,
                    paddingHorizontal: 26,
                    paddingVertical: 21,
                    borderRadius: 8,
                  }}
                >
                  {sort(data?.pendingActionRequiredItems, 'CreatedDate')?.map(
                    (item, index) => {
                      return (
                        <>
                          <DocumentTaskList
                            item={item}
                            index={index}
                            programmeName={
                              programName || getProgrammeName(data)
                            }
                            iconNames={iconNames}
                            setModalVisible={setModalVisible}
                            handleSelectedOpportunity={
                              handleSelectedOpportunity
                            }
                            status={false}
                            activeCheckboxColor={colors.primary}
                            businessUnitFilter={businessUnitFilter}
                          />
                          {data?.pendingActionRequiredItems?.length - 1 !==
                          index ? (
                            <View style={styles.divider} />
                          ) : (
                            ''
                          )}
                        </>
                      )
                    },
                  )}
                </View>
              ) : null}
            </>
          ) : (
            <VoidBox
              heading1={t('EMPTY_CONTAINER.HEADING_1')}
              heading2={t('EMPTY_CONTAINER.PENDING_ACTION_MESSAGE')}
            />
          )
        ) : null}
      </View>
    )
  }
  return (
    <OutstandingRequiredActionMobile
      data={data}
      setModalVisible={setModalVisible}
      handleSelectedOpportunity={handleSelectedOpportunity}
      handleSubmitAllOpportunityTaskFile={handleSubmitAllOpportunityTaskFile}
      businessUnitFilter={businessUnitFilter}
      isSubmitLoading={isSubmitLoading}
      programName={programName}
    />
  )
}

const styles = StyleSheet.create({
  buttonStyleFilled: (colors, isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 53 : 20,
    paddingVertical: 10,
    backgroundColor: colors.primary,
    alignSelf: 'baseline',
  }),
  buttonLabelStyle: (colors) => ({
    color: colors.white,
    fontWeight: '700',
    textTransform: 'uppercase',
    fontSize: 12,
  }),
  disabledButtonStyleFilled: (isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 53 : 20,
    paddingVertical: 10,
    backgroundColor: 'transparent',
    borderColor: 'black',
    borderWidth: 1,
    opacity: 0.4,
    alignSelf: 'baseline',
  }),
  disabledLabelStyle: (colors) => ({
    color: colors.black,
    fontWeight: '500',
    textTransform: 'uppercase',
    fontSize: 12,
  }),
  divider: {
    borderBottomWidth: 1,
    borderColor: '#162447',
    opacity: '10%',
    marginVertical: 12,
  },
  uploadButtonStyleFilled: (colors, isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 20 : 20,
    paddingVertical: 10,
    backgroundColor: colors.primary,
  }),
  uploadButtonLabelStyle: (colors) => ({
    color: colors.white,
    fontWeight: '500',
    textTransform: 'uppercase',
  }),
})
