import React, { useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  ScrollView,
  Platform,
  Linking,
  useWindowDimensions,
} from 'react-native'
import { Text, ShimmerPlaceholder } from '@libs/components'
import { Icon } from '@app-hero/native-icons'

import { useTranslation } from 'react-i18next'
import { spacing, useTheme } from '@libs/theme'
import { isTablet, isWeb } from '@libs/utils/src/screenLayout'
import { handleNavigation } from '../../utils/function'
import { sort } from '../../utils/sorting'
import {
  FileUploadModal,
  NeedSupportAccordion,
  VerticalTimeline,
} from '../../components'
import EmptyContainer from '../../components/container/EmptyContainer'
import { DocumentItem } from '../../components/viewApplication/documentItem'
import Drawer from '../../components/Drawer/drawer'
import { signedurl } from '../../api'
import { TitleHeader } from '../../components/headerTitle'
import { timeLineData } from '../../constants/dropDownData'
import { OutstandingRequiredAction } from '../../components/actionRequired/outstandingRequiredActionCard'
import { CompletedRequiredAction } from '../../components/actionRequired/completedRequiredActionCard'

const MobileView = ({
  setTabData,
  canShowMenu,
  brandLogoUrl,
  admissionsNotesTabData,
  applicationDocuments,
  applicationLetterDocuments,
  documentType,
  dropdownTop,
  dropdownLeft,
  dropdownWidth,
  dropDownRef,
  handleFileUpload,
  informationList,
  isDocumentFetching,
  moreIconDropDownDetails,
  handleMoreIcon,
  isLoading,
  modalVisible,
  opportunities,
  setModalVisible,
  tabData,
  toggleDropdown,
  handleScrollComment,
  scrollViewRef,
  childRef,
  handleUpdateComment,
  isActiveComment,
  taskDetails,
  handleSubmitAllOpportunityTaskFile,
  selectedOpportunity,
  handleSelectedOpportunity,
  setSelectedOpportunity,
  isSubmitLoading,
  navigationText,
  canExpandCompletedAction,
}) => {
  const { t } = useTranslation()

  const { colors } = useTheme()
  const { width } = useWindowDimensions()
  const web = isWeb(width)
  const tablet = isTablet(width)

  if (isLoading) {
    return (
      <ScrollView
        contentContainerStyle={{
          flexDirection: 'column',
          flex: 1,
          marginTop: 32,
          paddingHorizontal: 24,
        }}
        style={{ flex: 1, height: '100%' }}
      >
        <TouchableOpacity
          onPress={() => {
            if (Platform.OS === 'web') {
              window.history.go(-1)
            } else {
              // navigation.goBack()
            }
          }}
          style={{
            marginBottom: !tablet && spacing.spacing2,
            flexDirection: 'row',
            columnGap: 14,
          }}
        >
          <Icon name="ArrowNarrowLeft" height={20} width={20} />
          <Text
            style={{
              textTransform: 'uppercase',
              colors: colors.onNeutral,
              letterSpacing: '1.5%',
            }}
          >
            {navigationText}
          </Text>
        </TouchableOpacity>

        <View style={{ flexDirection: 'column' }}>
          <ShimmerPlaceholder
            style={{
              height: 150,
              width: '100%',
              marginTop: spacing.spacing5,
              borderRadius: spacing.spacing5,
              marginBottom: 32,
            }}
          />
          <Text variant="heading4" style={{ fontSize: 20 }}>
            {t('APPLICATION_DETAIL.STATUS_OF_APPLICATION')}
          </Text>
          <ShimmerPlaceholder
            style={{
              height: 250,
              borderRadius: spacing.spacing5,
              width: '100%',
              marginTop: 20,
            }}
          />
          <ShimmerPlaceholder
            style={{
              height: 60,
              borderRadius: spacing.spacing5,
              width: '100%',
              marginTop: 20,
            }}
          />
          <ShimmerPlaceholder
            style={{
              height: 350,
              borderRadius: spacing.spacing5,
              width: '100%',
              marginVertical: 20,
            }}
          />
        </View>
      </ScrollView>
    )
  }

  const programName = opportunities?.ProgrammeName__c
    ? `${opportunities?.ProgrammeName__c} ${
        opportunities?.Delivery_Mode__c
          ? `, ${opportunities?.Delivery_Mode__c}`
          : ''
      }`
    : ''

  return (
    <ScrollView
      style={{
        backgroundColor: colors.backgroundPrimary,
        flex: 1,
        paddingHorizontal: 24,
      }}
      contentContainerStyle={{
        flexGrow: 1,
        marginVertical: 34,
      }}
      ref={scrollViewRef}
    >
      <TouchableOpacity
        onPress={() => {
          if (Platform.OS === 'web') {
            window.history.go(-1)
          } else {
            // navigation.goBack()
          }
        }}
        style={{
          marginBottom: 15,
          flexDirection: 'row',
          columnGap: 10,
          alignItems: 'center',
        }}
      >
        <Icon name="ArrowNarrowLeft" height={24} width={24} />
        <Text
          style={{
            textTransform: 'uppercase',
            colors: colors.onNeutral,
            letterSpacing: '1.5%',
          }}
          variant="display4"
        >
          {navigationText}
        </Text>
      </TouchableOpacity>

      <TitleHeader
        style={{
          paddingHorizontal: 31,
        }}
      >
        <View
          style={{
            flexDirection: 'column',
            gap: 12,
          }}
        >
          <Image
            source={`${brandLogoUrl}/${opportunities.BusinessUnitFilter__c?.replaceAll(
              ' ',
              '_',
            )}_LIGHT.png`}
            style={{
              height: 47,
              width: 170,
            }}
            alt={opportunities?.brand}
            resizeMode="contain"
          />
          <Text
            variant="heading3"
            color={colors.white}
            style={{ fontSize: 20 }}
          >
            {programName || opportunities?.Name?.split('_')[0]}
          </Text>
        </View>
      </TitleHeader>
      <View style={styles.mainContainer}>
        <LeftContainer
          informationList={informationList}
          brandLogoUrl={brandLogoUrl}
          data={opportunities}
          applicationDocuments={applicationDocuments}
          applicationLetterDocuments={applicationLetterDocuments}
          isDocumentFetching={isDocumentFetching}
          tabData={tabData}
          setModalVisible={setModalVisible}
          moreIconDropDownDetails={moreIconDropDownDetails}
          canShowMenu={canShowMenu}
          handleMoreIcon={handleMoreIcon}
          isLoading={isLoading}
          setTabData={setTabData}
          web={web}
          tablet={tablet}
          childRef={childRef}
          handleUpdateComment={handleUpdateComment}
          handleScrollComment={handleScrollComment}
          admissionsNotesTabData={admissionsNotesTabData}
          isActiveComment={isActiveComment}
          taskDetails={taskDetails}
          selectedOpportunity={selectedOpportunity}
          setSelectedOpportunity={setSelectedOpportunity}
          handleSelectedOpportunity={handleSelectedOpportunity}
          handleSubmitAllOpportunityTaskFile={
            handleSubmitAllOpportunityTaskFile
          }
          isSubmitLoading={isSubmitLoading}
          canExpandCompletedAction={canExpandCompletedAction}
          programName={programName}
        />
      </View>

      <View style={styles.dividerWithPadding} />

      <NeedSupportAccordion>
        <Text
          variant="display3"
          color={colors.neutral}
          style={[styles.rightSideTextContainer]}
        >
          {!opportunities?.isDirectSales
            ? 'Contact your in-country representative '
            : 'Contact your Advisor '}
          <b>
            {!opportunities?.isDirectSales
              ? opportunities?.Agent_Contact__r?.Name
                ? opportunities?.Agent_Contact__r?.Name + ', '
                : ''
              : opportunities?.OwnerName__c
              ? opportunities?.OwnerName__c + ', '
              : ''}
          </b>
        </Text>

        <View
          style={{
            flexDirection: 'row',
            paddingBottom: 5,
            paddingTop: 8,
          }}
        >
          <Icon name="Mail" width={18} height={18} />
          <Text
            variant="display3"
            color={colors.neutral}
            numberOfLines={2}
            style={{
              marginLeft: 10,
              fontSize: 15,
              fontWeight: 700,
              borderBottomWidth: 1.5,
              color: colors.textPrimary,
            }}
          >
            {!opportunities?.isDirectSales
              ? opportunities?.Agent_Contact__r?.Email
              : opportunities?.OwnerEmail__c}
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 10,
          }}
        >
          <Icon name="Phone" width={18} height={18} />
          <Text
            variant="display3"
            color={colors.neutral}
            style={{
              marginLeft: 10,
              fontSize: 15,
              fontWeight: 700,
              borderBottomWidth: 1.5,
              color: colors.textPrimary,
            }}
          >
            {!opportunities?.isDirectSales
              ? opportunities?.Agent_Contact__r?.Phone
              : opportunities?.Owner?.Phone}
          </Text>
        </View>

        {opportunities?.isDirectSales &&
          opportunities?.Owner?.Appointment_Booking_Link__c && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: 10,
              }}
            >
              <Icon name="CalenderIcon" width={18} height={18} />
              <TouchableOpacity
                onPress={() => {
                  const url = opportunities?.Owner?.Appointment_Booking_Link__c
                  if (url) {
                    Linking.openURL(url).catch((err) =>
                      console.error('Failed to open URL:', err),
                    )
                  }
                }}
                style={{
                  marginLeft: 10,
                }}
              >
                <Text
                  variant="display3"
                  color={colors.neutral}
                  style={{
                    marginLeft: 10,
                    fontSize: 15,
                    fontWeight: 700,
                    borderBottomWidth: 1.5,
                    color: colors.textPrimary,
                  }}
                >
                  {t('APPLICATION_DETAIL.BOOK_A_MEETING')}
                </Text>
              </TouchableOpacity>
            </View>
          )}
        <Text
          variant="display3"
          color={colors.neutral}
          style={[styles.rightSideTextContainer, { marginTop: 20 }]}
        >
          Or speak to our support team by opening the chat window below.
        </Text>
      </NeedSupportAccordion>

      <FileUploadModal
        visible={modalVisible}
        title={t('APPLICATION_DETAIL.ATTACH_DOCUMENT')}
        toggleDropdown={toggleDropdown}
        handleSave={(documentDetails) => handleFileUpload(documentDetails)}
        handleClose={() => setModalVisible(false)}
        dropDownPosition={{ left: dropdownLeft + 10, top: dropdownTop + 10 }}
        dropdownWidth={dropdownWidth}
        documentType={documentType}
        dropDownRef={dropDownRef}
        setModalVisible={setModalVisible}
        selectedDropDownValue={selectedOpportunity?.documentType}
        taskId={selectedOpportunity?.Id}
      />
    </ScrollView>
  )
}

const LeftContainer = ({
  admissionsNotesTabData,
  applicationDocuments,
  applicationLetterDocuments,
  childRef,
  data,
  handleScrollComment,
  informationList,
  isDocumentFetching,
  setModalVisible,
  setTabData,
  tabData,
  handleUpdateComment,
  isActiveComment,
  taskDetails,
  selectedOpportunity,
  setSelectedOpportunity,
  handleSelectedOpportunity,
  handleSubmitAllOpportunityTaskFile,
  isSubmitLoading,
  programName,
  canExpandCompletedAction,
}) => {
  const { t } = useTranslation()

  return (
    <View style={[styles.leftSession]}>
      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 24,
        }}
      >
        <Text variant="heading4" style={{ fontSize: 20 }}>
          {t('APPLICATION_DETAIL.STATUS_OF_APPLICATION')}
        </Text>
        <VerticalTimeline
          data={timeLineData}
          activeTitle={data?.ApplicationStatus?.status}
        />
      </View>

      <DrawerContainer
        informationList={informationList}
        applicationDocuments={applicationDocuments}
        applicationLetterDocuments={applicationLetterDocuments?.documents || []}
        setTabData={setTabData}
        isDocumentFetching={isDocumentFetching}
        tabData={tabData}
        setModalVisible={setModalVisible}
        data={data}
        admissionsNotesTabData={admissionsNotesTabData}
        childRef={childRef}
        handleScrollComment={handleScrollComment}
        handleUpdateComment={handleUpdateComment}
        isActiveComment={isActiveComment}
        taskDetails={taskDetails}
        selectedOpportunity={selectedOpportunity}
        setSelectedOpportunity={setSelectedOpportunity}
        handleSelectedOpportunity={handleSelectedOpportunity}
        handleSubmitAllOpportunityTaskFile={handleSubmitAllOpportunityTaskFile}
        isSubmitLoading={isSubmitLoading}
        programName={programName}
        canExpandCompletedAction={canExpandCompletedAction}
      />
    </View>
  )
}

const DrawerContainer = ({
  setTabData,
  applicationDocuments,
  applicationLetterDocuments,
  informationList,
  tabData,
  data,
  admissionsNotesTabData,
  isDocumentFetching,
  taskDetails,
  selectedOpportunity,
  setSelectedOpportunity,
  handleSelectedOpportunity,
  handleSubmitAllOpportunityTaskFile,
  setModalVisible,
  isSubmitLoading,
  programName,
  canExpandCompletedAction,
}) => {
  const [activeTab, setActiveTab] = useState(
    tabData.findIndex((item) => item.isActive === true),
  )
  const { colors } = useTheme()
  const { t } = useTranslation()

  const [expandedIndex, setExpandedIndex] = useState(0)

  useEffect(() => {
    setExpandedIndex(0)
  }, [activeTab])

  const messageCounts = [0, 0, 0, 0]

  messageCounts[0] = taskDetails?.pendingActionRequiredItems?.length

  return (
    <View style={{ gap: 27, marginTop: 24 }}>
      <DrawerSection
        activeTab={activeTab}
        tabData={tabData}
        messageCounts={messageCounts}
        admissionsNotesTabData={admissionsNotesTabData}
        conditionsCount={
          !!data?.Admissions_Condition__c &&
          !Object.keys(applicationLetterDocuments)?.length > 0
        }
        setActiveTab={(selectedTab) => {
          const tabDataCopy = [...tabData]
          tabDataCopy?.map((tabItem, index) => {
            if (index === selectedTab) {
              tabDataCopy[index] = { ...tabDataCopy[index], isActive: true }
            } else {
              tabDataCopy[index] = { ...tabDataCopy[index], isActive: false }
            }
          })
          setActiveTab(selectedTab)
          setTabData(tabDataCopy)
        }}
        programName={programName}
      />
      {activeTab === 1
        ? activeTab === 1 && (
            <View
              style={{
                padding: 24,
                backgroundColor: colors.white,
                borderRadius: 16,
              }}
            >
              {informationList?.length > 0 ? (
                informationList?.map((item, index) => (
                  <View key={index}>
                    <View
                      style={{
                        flexDirection:
                          window.innerWidth <= 415 ? 'column' : 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        gap: 8,
                      }}
                    >
                      <Text
                        variant="display5"
                        style={{ color: '#545B61', fontSize: 16 }}
                      >
                        {item?.displayName ? `${item?.displayName}:` : ''}
                      </Text>
                      <View>
                        {item?.field === 'MeetLink' ? (
                          <TouchableOpacity
                            onPress={() => {
                              const url = item?.fieldName
                              if (url && url !== '-') {
                                Linking.openURL(url).catch((err) =>
                                  console.error('Failed to open URL:', err),
                                )
                              }
                            }}
                          >
                            <Text
                              style={{
                                color: colors.textTeritiary,
                                fontSize: 16,
                                fontWeight: '700',
                                textAlign: 'right',
                                textDecorationLine: 'underline', // Optional: underline the text
                              }}
                              variant="display5"
                            >
                              {t('APPLICATION_DETAIL.BOOK_A_MEETING')}
                            </Text>
                          </TouchableOpacity>
                        ) : (
                          <Text
                            style={{
                              color: colors.textTeritiary,
                              fontSize: 16,
                              fontWeight: 700,
                            }}
                            variant="display5"
                          >
                            {item?.fieldName !== '-' ? item?.fieldName : 'NIL'}
                          </Text>
                        )}
                      </View>
                    </View>
                    {index !== informationList.length - 1 && (
                      <View style={[styles.divider, { marginVertical: 20 }]} />
                    )}
                  </View>
                ))
              ) : (
                <EmptyContainer hasFixedHight />
              )}
            </View>
          )
        : null}

      {activeTab === 2 ? (
        <View
          style={{
            paddingHorizontal: 14,
            backgroundColor: colors.white,
            borderRadius: 16,
          }}
        >
          {activeTab === 2 ? (
            <View
              style={{
                flexDirection: 'column',
                paddingVertical: 24,
              }}
            >
              {applicationDocuments === undefined ? (
                isDocumentFetching ? (
                  [...Array(3)].map(() => (
                    <>
                      <View style={[styles.divider, { marginTop: 10 }]} />
                      <ShimmerPlaceholder
                        style={{
                          height: 250,
                          width: '100%',
                          marginTop: spacing.spacing5,
                          borderRadius: spacing.spacing5,
                        }}
                      />
                    </>
                  ))
                ) : (
                  <EmptyContainer hasFixedHight />
                )
              ) : (
                Object?.entries(applicationDocuments || {})?.map(
                  ([keys, value], index) => (
                    <View style={{ paddingHorizontal: 8 }}>
                      <DocumentAccordion
                        label={keys}
                        data={value}
                        index={index}
                        activeTab={activeTab}
                        showDate={false}
                        style={{
                          flexWrap: 'wrap',
                          width: '100%',
                          marginBottom: 2,
                          color: '#162447',
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                        sectionCount={
                          Object?.entries(applicationDocuments).length
                        }
                        setExpandedIndex={setExpandedIndex}
                        expandedIndex={expandedIndex}
                      />
                    </View>
                  ),
                )
              )}
            </View>
          ) : null}
        </View>
      ) : null}

      {activeTab === 3 ? (
        <View
          style={{
            paddingHorizontal: 14,
            backgroundColor: colors.white,
            borderRadius: 16,
          }}
        >
          {activeTab === 3 ? (
            <View
              style={{
                flexDirection: 'column',
                paddingVertical: 24,
              }}
            >
              {isDocumentFetching ? (
                [...Array(1)].map(() => (
                  <>
                    <ShimmerPlaceholder
                      style={{
                        height: 250,
                        width: '100%',
                        marginTop: spacing.spacing5,
                        borderRadius: spacing.spacing5,
                      }}
                    />
                  </>
                ))
              ) : Object.keys(applicationLetterDocuments)?.length > 0 ||
                data?.Admissions_Condition__c ? (
                <View
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }}
                >
                  {Object.keys(applicationLetterDocuments)?.length > 0 ? (
                    Object?.entries(applicationLetterDocuments || {})?.map(
                      ([keys, value], index) => (
                        <DocumentAccordion
                          label={keys}
                          data={value}
                          index={index}
                          style={{
                            width: '100%',
                            marginBottom: 2,
                            color: '#162447',
                            fontSize: 14,
                            fontWeight: 400,
                          }}
                          sectionCount={
                            Object?.entries(applicationLetterDocuments).length
                          }
                          setExpandedIndex={setExpandedIndex}
                          expandedIndex={expandedIndex}
                        />
                      ),
                    )
                  ) : (
                    <>
                      <Text
                        variant="display2"
                        color={colors.neutral}
                        style={{ marginBottom: spacing.spacing5 }}
                      >
                        {t('APPLICATION_DETAIL.OFFER_LETTER')}
                      </Text>
                      <EmptyContainer hasFixedHight />
                    </>
                  )}
                </View>
              ) : (
                <EmptyContainer hasFixedHight />
              )}
            </View>
          ) : null}
        </View>
      ) : null}
      {activeTab === 0 ? (
        <View
          style={{
            padding: 24,
            backgroundColor: colors.white,
            borderRadius: 16,
          }}
        >
          {activeTab === 0 ? (
            <View style={{ gap: 24 }}>
              <OutstandingRequiredAction
                data={taskDetails}
                setModalVisible={setModalVisible}
                setSelectedOpportunity={setSelectedOpportunity}
                handleSelectedOpportunity={handleSelectedOpportunity}
                selectedOpportunity={selectedOpportunity}
                handleSubmitAllOpportunityTaskFile={
                  handleSubmitAllOpportunityTaskFile
                }
                businessUnitFilter={data?.BusinessUnitFilter__c}
                isSubmitLoading={isSubmitLoading}
                programName={programName}
              />
              {window.innerWidth <= 650 ? (
                <View
                  style={{
                    borderBottomWidth: 1,
                    borderColor: 'rgba(0,0,0,0.1)',
                    borderStyle: 'solid',
                  }}
                />
              ) : null}
              <CompletedRequiredAction
                data={taskDetails}
                programName={programName}
                canExpandCompletedAction={canExpandCompletedAction}
              />
            </View>
          ) : null}
        </View>
      ) : null}
    </View>
  )
}

const DrawerSection = ({
  tabData,
  activeTab,
  setActiveTab,
  messageCounts,
  style,
  labelStyle,
}) => {
  const { colors } = useTheme()

  return (
    <ScrollView
      style={{
        paddingBottom: 15,
        borderBottomColor: 'rgba(0, 0, 0, 0.2)',
        borderBottomWidth: 1,
        flex: 1,
        width: '100%',
        paddingTop: 24,
      }}
      horizontal
      showsHorizontalScrollIndicator={false}
    >
      <View style={{ flexDirection: 'row', gap: 23 }}>
        {tabData?.map((item, index) => (
          <TouchableOpacity
            style={[
              {
                paddingBottom: 10,
                alignItems: 'center',
                flexDirection: 'row',
                overflow: 'visible',
              },
              style,
            ]}
            onPress={() => setActiveTab(index)}
            key={index}
          >
            <Text
              variant="display3"
              color={
                activeTab === index ? colors.primary : 'rgba(0, 0, 0, 0.4)'
              }
              style={[
                {
                  fontWeight: activeTab === index ? 700 : 400,
                },
                labelStyle,
              ]}
            >
              {item.displayText}
            </Text>
            {activeTab === index ? (
              <View
                style={{
                  position: 'absolute',
                  bottom: -15,
                  left: 0,
                  borderRadius: 5,
                  height: 3,
                  width: '100%',
                  backgroundColor: colors.borderBlue,
                }}
              />
            ) : null}
            {item.displayText === 'Offers' && (
              <View
                style={{
                  backgroundColor: 'red',
                  marginLeft: 6,
                  width: 15,
                  height: 15,
                  borderRadius: 15 / 2,
                  alignItems: 'center',
                }}
              >
                <Text
                  variant="display6"
                  style={{ color: 'white', fontSize: 12 }}
                >
                  {' • '}
                </Text>
              </View>
            )}
            {messageCounts[index] > 0 && (
              <Text
                variant={activeTab === index ? 'heading5' : 'display6'}
                style={{
                  color:
                    activeTab === index ? colors.primary : 'rgba(0, 0, 0, 0.4)',
                  paddingLeft: 3,
                }}
              >
                ({messageCounts[index]})
              </Text>
            )}
          </TouchableOpacity>
        ))}
      </View>
    </ScrollView>
  )
}

const DocumentAccordion = ({
  label,
  data,
  index,
  activeTab,
  style,
  height,
  sectionCount,
  expandedIndex,
  setExpandedIndex,
}) => {
  const { colors } = useTheme()

  const { t } = useTranslation()

  const toggleAccordion = () => {
    if (expandedIndex === index) {
      setExpandedIndex(null)
    } else {
      setExpandedIndex(index)
    }
  }

  return (
    <View style={{ width: '100%' }}>
      {activeTab === 2 ? (
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            backgroundColor: colors.accent,
            borderRadius: 8,
            justifyContent: 'space-between',
          }}
          onPress={toggleAccordion}
        >
          <View style={{ flexDirection: 'column', gap: 4 }}>
            <Text
              variant="display3"
              color={colors.textPrimary}
              style={{ marginRight: 10, fontWeight: '500' }}
            >
              {label}
            </Text>
            <Text
              style={{
                color: 'rgba(22, 36, 71, 0.5)',
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              {data.length !== 1
                ? `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENTS')})`
                : `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENT')})`}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              columnGap: 20,
            }}
          >
            <Icon
              name={expandedIndex === index ? 'ArrowUp' : 'ArrowDown'}
              color="black"
              width={24}
              height={24}
            />
          </View>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            backgroundColor: colors.accent,
            borderRadius: 8,
            justifyContent: 'space-between',
          }}
          onPress={toggleAccordion}
        >
          <View style={{ flexDirection: 'column', gap: 4 }}>
            <Text
              color={colors.textPrimary}
              style={{ marginRight: 10, fontWeight: 500 }}
              variant="display3"
            >
              {label.replaceAll('-', ' ')}
            </Text>
            <Text
              style={{
                color: 'rgba(22, 36, 71, 0.5)',
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              {data.length !== 1
                ? `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENTS')})`
                : `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENT')})`}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              columnGap: 20,
            }}
          >
            <Icon
              name={expandedIndex === index ? 'ArrowUp' : 'ArrowDown'}
              color="black"
              width={24}
              height={24}
            />
          </View>
        </TouchableOpacity>
      )}
      {expandedIndex === index ? (
        <View
          style={{
            paddingTop: 24,
            gap: 28,
            width: '100%',
          }}
        >
          {expandedIndex === index &&
            sort(data, 'CreatedDate')?.map((item) => {
              const documentData = item?.CreatedDate?.split('T')[0]
              const finalDocumentDate = documentData
                ?.split('-')
                .reverse()
                .join('/')
              return (
                <DocumentItem
                  index={index}
                  item={item}
                  finalDocumentDate={finalDocumentDate}
                  onPress={async () => {
                    const resp = await signedurl(item)
                    if (resp.FilePath) {
                      await handleNavigation({
                        url: resp.FilePath,
                        fileName: item?.Name,
                        translation: t,
                      })
                    }
                  }}
                  style={style}
                  height={height}
                  documentCount={data?.length}
                />
              )
            })}
        </View>
      ) : null}
      {sectionCount - 1 !== index && <View style={styles.dividerWithPadding} />}
    </View>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    paddingTop: 20,
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dividerWithPadding: {
    borderWidth: 0.2,
    borderColor: '#000000',
    borderStyle: 'solid',
    opacity: 0.1,
    marginVertical: 24,
  },

  leftSession: {
    flex: 1,
    width: '100%',
  },
  activeTab: {
    flexDirection: 'column',
  },
  unActiveTab: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    marginTop: 4,
  },
  notes: {
    marginBottom: 10,
    marginTop: 23,
    backgroundColor: '#FFF4E0',
    borderColor: 'rgba(255, 233, 192, 1)',
    borderRadius: 5,
    borderWidth: 0.2,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 5,
    paddingVertical: 10,
  },
  divider: {
    borderWidth: 0.2,
    borderColor: '#000000',
    borderStyle: 'solid',
    opacity: 0.1,
  },
  attachDocument: {
    flexDirection: 'column',
    paddingLeft: 24.5,
    paddingVertical: 32,
    columnGap: 3,
    borderRadius: 16,
  },
  rightContainer: {
    paddingHorizontal: 24,
    paddingVertical: 32,
    borderRadius: 13,
    background: 'transparent',
    borderWidth: 0.2,
    borderColor: '#000000',
    width: '100%',
  },

  rightSideTextContainer: {
    fontSize: 15,
    lineHeight: 21,
  },
})

export default MobileView
