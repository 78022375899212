import React, { useEffect, useState } from 'react'
import {
  Modal,
  Animated,
  TouchableOpacity,
  View,
  FlatList,
  StyleSheet,
  Image,
} from 'react-native'
import { useRoute } from '@react-navigation/native'
import { BlurView } from 'expo-blur'
import { Icon } from '@app-hero/native-icons'
import { BlueDot, ProfilePlaceHolder } from '@apphero/assets'
import { Text } from '@libs/components'
import { useAtom } from 'jotai'
import { useTheme } from '@libs/theme'
import { userProfile } from '../../utils/atom'
import LoadingSpinner from '../dynamicContentPanel/customLoader'

const Drawer = ({
  items = [],
  onItemSelect,
  selectedItem,
  tabs,
  labelColor,
  iconColor,
  iconSize = 16,
  labelStyle,
  hasNoBorder,
  hasNoBorderStyle,
  isProfile = '',
  source,
  isLoading,
  isViewApplication,
  messageCounts,
  notificationCount,
  actionRequiredCount,
}) => {
  const [visible, setVisible] = useState(false)
  const { colors } = useTheme()
  const animation = useState(new Animated.Value(0))[0]
  const [userprofile] = useAtom(userProfile)
  const [activeScreen, setActiveScreen] = useState('')
  const route = useRoute()

  const isLandingScreen =
    activeScreen === 'my-application' ||
    activeScreen === 'action-required' ||
    activeScreen === 'dashboard'

  useEffect(() => {
    setActiveScreen(route?.name)
  }, [route])

  function getAnimatedHeight() {
    let outputRange
    if (!isLandingScreen && isProfile)
      return tabs?.length === 3 ? (outputRange = 570) : (outputRange = 540)
    else if (isViewApplication) return (outputRange = 300)
    else if (isProfile)
      return tabs?.length === 3 ? (outputRange = 570) : (outputRange = 540)
    else return (outputRange = 220)
  }

  const openDrawer = () => {
    setVisible(true)
    Animated.timing(animation, {
      toValue: 1,
      duration: 400,
      useNativeDriver: true,
    }).start()
  }

  const closeDrawer = (item) => {
    if (item?.action === 'logout') {
      return
    }
    Animated.timing(animation, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => setVisible(false))
  }

  const renderItem = ({ item, index }) => (
    <TouchableOpacity
      style={[styles.items, !isLandingScreen ? {} : {}]}
      onPress={() => {
        onItemSelect(item, index)
        closeDrawer(item)
      }}
    >
      {isProfile ? (
        <>
          <View
            style={[
              styles.item,
              { paddingVertical: !isLandingScreen && isProfile ? 20 : 12 },
            ]}
          >
            {isLoading && item?.icon === 'Logout' ? (
              ''
            ) : (
              <Icon
                name={item?.icon}
                color={activeScreen === item.action ? iconColor : 'black'}
                width={20}
                height={20}
              />
            )}

            <Text
              style={{
                color: activeScreen === item.action ? labelColor : '#545B61',
                fontWeight: activeScreen === item.action ? 700 : 400,
                fontSize:
                  activeScreen === item.action ? 14 : item?.icon ? 12 : 14,
                marginHorizontal:
                  isLoading && item?.icon === 'Logout' ? 75 : '',
              }}
            >
              {isLoading && item?.action === 'logout' ? (
                <LoadingSpinner size={24} />
              ) : (
                <>
                  {item?.label}
                  {item?.label === 'Notifications' && notificationCount > 0 ? (
                    <View
                      style={[
                        styles.notificationCount,
                        { padding: notificationCount > 9 ? 1 : 2 },
                      ]}
                    >
                      <Text
                        variant="display6"
                        style={{
                          color: '#E5E5E5',
                          fontSize: 12,
                          textAlign: 'center',
                          fontWeight: 500,
                        }}
                      >
                        {notificationCount}
                      </Text>
                    </View>
                  ) : null}
                  {item?.label === 'Action Required' &&
                  actionRequiredCount > 0 ? (
                    <View
                      style={[
                        styles.notificationCount,
                        {
                          marginLeft: 14,
                          padding: actionRequiredCount > 9 ? 1 : 2,
                        },
                      ]}
                    >
                      <Text
                        variant="display6"
                        style={{
                          color: '#E5E5E5',
                          fontSize: 12,
                          textAlign: 'center',
                          fontWeight: 500,
                        }}
                      >
                        {actionRequiredCount}
                      </Text>
                    </View>
                  ) : null}
                </>
              )}
            </Text>
          </View>

          {activeScreen === item.action ? (
            <Image source={BlueDot} style={{ width: 8, height: 8 }} />
          ) : (
            ''
          )}
        </>
      ) : (
        <>
          <View style={styles.item}>
            <Icon
              name={item.icon || item.iconName}
              color={
                isViewApplication
                  ? selectedItem?.displayText === item.displayText
                    ? iconColor
                    : '#545B61'
                  : selectedItem.label === item.label
                  ? iconColor
                  : '#545B61'
              }
              width={20}
              height={20}
            />
            <Text
              style={{
                color: isViewApplication
                  ? selectedItem?.displayText === item.displayText
                    ? iconColor
                    : '#545B61'
                  : selectedItem.label === item.label
                  ? iconColor
                  : '#545B61',
                fontWeight: selectedItem.label === item.label ? 600 : 400,
                fontSize: 15,
              }}
            >
              {item.label || item.displayText}
              {isViewApplication &&
              item?.displayText === 'Action Required' &&
              index === 0 ? (
                messageCounts[0] > 0 ? (
                  <Text
                    variant="display6"
                    style={{
                      color: isViewApplication
                        ? selectedItem?.displayText === item.displayText
                          ? iconColor
                          : '#545B61'
                        : selectedItem.label === item.label
                        ? iconColor
                        : '#545B61',
                      fontWeight: selectedItem.label === item.label ? 600 : 400,
                      fontSize: 15,
                    }}
                  >
                    {' '}
                    ({messageCounts[index]})
                  </Text>
                ) : (
                  ''
                )
              ) : null}
              {item?.count ? ` (${item.count})` : ''}
            </Text>
          </View>
          {isViewApplication &&
          selectedItem?.displayText === item.displayText ? (
            <Image source={BlueDot} style={{ width: 8, height: 8 }} />
          ) : null}
          {!isViewApplication && selectedItem.label === item.label ? (
            <Image source={BlueDot} style={{ width: 8, height: 8 }} />
          ) : null}
        </>
      )}
    </TouchableOpacity>
  )

  const animatedHeight = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, getAnimatedHeight()],
  })

  return (
    <>
      <TouchableOpacity
        style={[
          styles.container,
          hasNoBorder ? hasNoBorderStyle : '',
          { marginBottom: !isProfile && !isViewApplication ? 20 : '' },
        ]}
        onPress={openDrawer}
      >
        {!isProfile ? (
          (selectedItem?.icon ||
            selectedItem?.iconName ||
            selectedItem.label ||
            selectedItem.displayText) && (
            <>
              <View style={[styles.iconContainer]}>
                {selectedItem.icon || selectedItem.iconName ? (
                  <Icon
                    name={selectedItem.icon || selectedItem.iconName}
                    height={20}
                    width={20}
                    color={iconColor}
                  />
                ) : null}

                <Text
                  color={labelColor}
                  style={[
                    isViewApplication ? styles.selectedTab(colors) : '',
                    labelStyle,
                  ]}
                >
                  {selectedItem.label || selectedItem.displayText}
                  {selectedItem?.label && selectedItem?.count
                    ? ` (${selectedItem.count})`
                    : ''}
                  {isViewApplication &&
                  selectedItem?.displayText === 'Action Required' ? (
                    messageCounts[0] > 0 ? (
                      <Text
                        variant="display6"
                        style={({ color: colors.primary }, labelStyle)}
                      >
                        {' '}
                        ({messageCounts[0]})
                      </Text>
                    ) : (
                      ''
                    )
                  ) : null}
                </Text>
              </View>
              <Icon
                name="ArrowDown"
                height={iconSize}
                width={iconSize}
                color={labelColor}
              />
            </>
          )
        ) : (
          <Icon name="Menu" color={colors.black} />
        )}
      </TouchableOpacity>

      <Modal visible={visible} transparent animationType="none">
        <BlurView intensity={60} style={styles.overlay}>
          <TouchableOpacity
            style={styles.overlayTouchable}
            onPress={closeDrawer}
            activeOpacity={1}
          >
            <Animated.View
              style={[
                styles.drawer,
                {
                  height: animatedHeight,
                  maxHeight: '80%',
                  overflow: 'scroll',
                },
              ]}
            >
              {!isProfile ? (
                <TouchableOpacity
                  style={styles.drawerContent}
                  activeOpacity={1}
                >
                  <View style={styles.topdivider} />
                  <FlatList
                    data={items}
                    renderItem={(data) => {
                      const { item, index } = data
                      return (
                        <>
                          <View
                            style={{
                              marginLeft: 0,
                            }}
                          >
                            {renderItem({ item, index })}
                          </View>
                          {index !== items?.length - 1 ? (
                            <View style={styles.divider} />
                          ) : (
                            ''
                          )}
                        </>
                      )
                    }}
                    keyExtractor={(item, index) => index.toString()}
                  />
                </TouchableOpacity>
              ) : (
                <View style={styles.drawerContent} activeOpacity={1}>
                  <View style={styles.topdivider} />
                  <View
                    style={{
                      paddingHorizontal: 10,
                      paddingVertical: 16,
                      flexDirection: 'row',
                      alignItems: 'center',
                      columnGap: 21,
                    }}
                  >
                    <View style={styles.profileImage}>
                      <Image
                        source={source || ProfilePlaceHolder}
                        style={{
                          width: 55.93,
                          height: 55.93,
                          borderRadius: 30,
                        }}
                      />
                    </View>
                    <Text
                      style={{
                        fontSize: 24,
                        fontWeight: 700,
                        color: '#545B61',
                      }}
                    >
                      {userprofile?.firstName}
                      <br />
                      {userprofile.lastName}
                    </Text>
                  </View>
                  <View>
                    <FlatList
                      data={tabs}
                      renderItem={(data, index) => {
                        return (
                          <>
                            <View>{renderItem(data)}</View>
                            {tabs?.length > 1 &&
                            data?.index !== tabs?.length - 1 ? (
                              <View style={styles.divider} />
                            ) : (
                              ''
                            )}
                          </>
                        )
                      }}
                      keyExtractor={(item, index) => index.toString()}
                      contentContainerStyle={{
                        backgroundColor: '#F4F5FB',
                        paddingHorizontal: 22,
                        paddingVertical: 14,
                        borderRadius: 16,
                      }}
                    />

                    <FlatList
                      data={items}
                      renderItem={(data, index) => {
                        return (
                          <>
                            <View>{renderItem(data)}</View>
                            {data?.index !== items?.length - 1 ? (
                              <View
                                style={[styles.divider, { marginVertical: 4 }]}
                              />
                            ) : (
                              ''
                            )}
                          </>
                        )
                      }}
                      keyExtractor={(item, index) => index.toString()}
                      contentContainerStyle={{
                        paddingTop: 29.6,
                      }}
                    />
                  </View>
                </View>
              )}
            </Animated.View>
          </TouchableOpacity>
        </BlurView>
      </Modal>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(2, 21, 71, 0.37)',
    justifyContent: 'flex-end',
  },
  overlayTouchable: {
    flex: 1,
    backgroundColor: 'rgba(2, 21, 71, 0.37)',
    justifyContent: 'flex-end',
  },
  drawer: {
    backgroundColor: 'white',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
  },
  drawerContent: {
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  items: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
    paddingVertical: 12,
  },

  divider: {
    borderWidth: 0.5,
    borderColor: 'rgba(0, 0, 0, 0.08)',
    borderStyle: 'solid',
    marginVertical: 8,
  },
  topdivider: {
    borderWidth: 2,
    borderColor: 'rgba(212, 212, 216, 1)',
    borderStyle: 'solid',
    marginHorizontal: 120,
    borderRadius: 3.5,
    marginBottom: 15,
  },
  profileImage: {
    width: 65.4,
    height: 65.4,
    borderRadius: 117.01,
    borderWidth: 0.5,
    borderColor: 'rgba(46, 56, 81, 0.7)',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedTab: (colors) => ({
    borderColor: colors.draftBlue,
  }),
  notificationCount: {
    backgroundColor: '#D72C2C',
    marginLeft: 6,
    width: 21,
    height: 21,
    borderRadius: 21 / 2,
    borderWidth: 1.5,
    textAlign: 'center',
    borderColor: '#E5E5E5',
  },
})

export default Drawer
