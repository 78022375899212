import { Text } from '@libs/components'
import { useTheme } from '@libs/theme'
import { t } from 'i18next'
import React, { useState } from 'react'
import { View, Pressable, StyleSheet } from 'react-native'
import { Icon } from '@app-hero/native-icons'
import { AlertBox } from './alertConatiner'
import { DocumentTaskList } from './documentTaskList'
import { VoidBox } from '../container/actionRequiredEmptyContainers/emptyCard'
import { sort } from '../../utils/sorting'
import { getProgrammeName } from '../../utils/getProgramName'

export const OutstandingRequiredActionMobile = ({
  data,
  setModalVisible,
  handleSelectedOpportunity,
  businessUnitFilter,
  programName,
}) => {
  const { colors } = useTheme()
  const [isExpanded, setIsExpanded] = useState(
    data?.pendingActionRequiredItems?.length,
  )

  const iconNames = ['Eye', 'Download', 'DeleteIcon']

  return (
    <View
      style={{
        backgroundColor: colors.white,
        borderRadius: 8,
        gap: 15,
      }}
    >
      <Pressable
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onPress={() => setIsExpanded(!isExpanded)}
      >
        <Text variant="display3" style={{ fontWeight: '600' }} color="#000">
          {t('ACTION_REQUIRED.OUTSTANDING')} (
          {data?.pendingActionRequiredItems?.length})
        </Text>
        <Icon
          name={isExpanded ? 'ArrowUp' : 'ArrowDown'}
          width={20}
          height={20}
        />
      </Pressable>
      {data?.pendingActionRequiredItems?.length > 0 ? (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            flex: 1,
            gap: 12,
          }}
        >
          <View style={{ flex: 1 }}>
            <AlertBox
              iconColor={colors.alertPrimary}
              iconSize={20}
              iconName="AlertCircle"
              style={{
                flexDirection: 'row',
                gap: 8,
                alignItems: 'center',
                width: '100%',
              }}
            >
              <View style={{ flex: 1, flexWrap: 'wrap', width: '80%' }}>
                <Text variant="display4" color={colors.alertPrimary}>
                  <b>{t('ACTION_REQUIRED.NOTE')}</b>
                  {t('ACTION_REQUIRED.ALERT')}
                </Text>
              </View>
            </AlertBox>
          </View>
        </View>
      ) : null}
      {isExpanded ? (
        data?.pendingActionRequiredItems?.length > 0 ? (
          <>
            {data?.pendingActionRequiredItems?.length ? (
              <View
                style={{
                  gap: 8,
                  marginTop: 12,
                  backgroundColor: colors.backgroundPrimary,
                  paddingHorizontal: 13,
                  paddingVertical: 18,
                  borderRadius: 8,
                }}
              >
                {sort(data?.pendingActionRequiredItems, 'CreatedDate')?.map(
                  (item, index) => {
                    return (
                      <>
                        <DocumentTaskList
                          item={item}
                          index={index}
                          iconNames={iconNames}
                          setModalVisible={setModalVisible}
                          handleSelectedOpportunity={handleSelectedOpportunity}
                          status={false}
                          activeCheckboxColor={colors.primary}
                          businessUnitFilter={businessUnitFilter}
                          programmeName={programName || getProgrammeName(data)}
                        />
                        {data?.pendingActionRequiredItems?.length - 1 !==
                        index ? (
                          <View style={styles.divider} />
                        ) : (
                          ''
                        )}
                      </>
                    )
                  },
                )}
              </View>
            ) : null}
          </>
        ) : (
          <VoidBox
            heading1={t('EMPTY_CONTAINER.HEADING_1')}
            heading2={t('EMPTY_CONTAINER.PENDING_ACTION_MESSAGE')}
          />
        )
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonStyleFilled: (colors) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingVertical: 14,
    backgroundColor: colors.primary,
  }),
  buttonLabelStyle: (colors) => ({
    color: colors.white,
    fontWeight: '700',
    textTransform: 'uppercase',
    fontSize: 12,
  }),
  disabledButtonStyleFilled: () => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingVertical: 14,
    borderColor: 'black',
    backgroundColor: '#2563EB',
    color: 'white',
  }),
  disabledLabelStyle: (colors) => ({
    color: 'white',
    fontWeight: '500',
    textTransform: 'uppercase',
    fontSize: 12,
  }),
  divider: {
    borderBottomWidth: 1,
    borderColor: '#162447',
    opacity: '10%',
    marginVertical: 12,
  },
  uploadButtonStyleFilled: (colors, isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 20 : 20,
    paddingVertical: 10,
    backgroundColor: colors.primary,
  }),
  uploadButtonLabelStyle: (colors) => ({
    color: colors.white,
    fontWeight: '500',
    textTransform: 'uppercase',
  }),
})
