import React, { useState } from 'react'
import { t } from 'i18next'
import {
  ScrollView,
  useWindowDimensions,
  View,
  StyleSheet,
  TouchableOpacity,
} from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import { ShimmerPlaceholder, Text } from '@libs/components'
import { Icon } from '@app-hero/native-icons'
import { isWeb } from '@libs/utils/src/screenLayout'
import { useTheme } from '@libs/theme'
import {
  DynamicContentPanel,
  FileUploadModal,
  MyApplication,
  SummaryCard,
  VoidBox,
  WelcomeCard,
  YouTubeVideoPlayer,
} from '../../components'
import { sort } from '../../utils/sorting'
import { ActionRequired } from '../../components/actionRequired'
import { categorizeActionItems } from '../../utils/categorizeActionItems'
import PaginatedContent from '../../components/dynamicContentPanel/latestUpdate'
import UpcomingEvents from '../../components/dynamicContentPanel/events'

const MobileView = ({
  opportunities,
  userName,
  summaryData,
  modalVisible,
  setModalVisible,
  handleSelectedOpportunity,
  selectedOpportunity,
  toggleDropdown,
  handleFileUpload,
  dropDownRef,
  dropdownTop,
  dropdownLeft,
  dropdownWidth,
  handleSubmitAllOpportunityTaskFile,
  fetchDocumentTypes,
  isSubmitLoading,
  documentType,
  isLoading,
  cmsContent,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  notifications,
  handleNextPage,
  handlePrevPage,
  handleUpdateNotification,
  handleEventNavigation,
  currentPage,
  hasSpotLightNextPage,
  hasPrevPage,
  totalPages,
  totalActionRequiredCount,
  events,
  handleEventNextPage,
  handleEventPrevPage,
  currentEventPage,
  hasEventNextPage,
  hasEventPrevPage,
  totalEventPages,
  fetchingCMSContent,
}) => {
  const { width } = useWindowDimensions()
  const { colors } = useTheme()
  const isDesktop = isWeb(width)
  const [isVideoPlayerVisible, setIsVideoPlayerVisible] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleCloseVideo = () => {
    setIsVideoPlayerVisible(false)
  }

  const handleOpenVideo = () => {
    setIsVideoPlayerVisible(true)
  }

  const findOpportunityFromOpportunities = opportunities?.find(
    (item) => item?.Id === selectedOpportunity?.WhatId,
  )

  if (isLoading) {
    return <MobileViewSkeletonLoader />
  }

  return (
    <ScrollView
      contentContainerStyle={styles.contentContainer(width, isDesktop)}
      style={{
        backgroundColor: colors.backgroundSecondary,
      }}
    >
      <View style={styles.columnContainer}>
        <View>
          <WelcomeCard name={userName} handleOpenVideo={handleOpenVideo} />
        </View>

        <View>
          {summaryData ? (
            <View style={styles.section}>
              <Text variant="heading3" color="black" style={{ fontSize: 20 }}>
                {t('DASHBOARD.SUMMARY')}
              </Text>
              <View style={styles.rowContainer}>
                {summaryData?.map(({ iconName, count, label }, index) => (
                  <SummaryCard
                    key={index}
                    iconName={iconName}
                    count={count}
                    label={label}
                    colors={colors}
                  />
                ))}
              </View>
            </View>
          ) : null}
        </View>

        <View style={[styles.section]}>
          <Text variant="heading3" color="black" style={{ fontSize: 20 }}>
            {t('DASHBOARD.MY_ACTIONS')}
          </Text>
          {totalActionRequiredCount > 0 ? (
            sort(opportunities, 'CreatedDate')?.map((opportunity) => {
              let restructuredResponse
              if (opportunity) {
                restructuredResponse = categorizeActionItems(opportunity)
              }
              return (
                <ActionRequired
                  opportunityDetails={restructuredResponse}
                  handleSelectedOpportunity={handleSelectedOpportunity}
                  setModalVisible={setModalVisible}
                  handleSubmitAllOpportunityTaskFile={
                    handleSubmitAllOpportunityTaskFile
                  }
                  fetchDocumentTypes={fetchDocumentTypes}
                  isSubmitLoading={isSubmitLoading}
                />
              )
            })
          ) : (
            <VoidBox
              heading1={t('EMPTY_CONTAINER.DASHBOARD_NEW_ACTION_REQUIRED')}
              heading2={t('EMPTY_CONTAINER.NO_ACTION_REQUIRED')}
            />
          )}
        </View>

        <View style={[styles.section]}>
          <Text variant="heading3" color="black">
            {t('DASHBOARD.MY_APPLICATION')}
          </Text>
          {sort(opportunities, 'CreatedDate')?.map((item) => (
            <MyApplication key={item.id} data={item} />
          ))}
        </View>
        <View style={[styles.sidePanel]}>
          <View style={{ gap: 16 }}>
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              onPress={() => setIsExpanded(!isExpanded)}
            >
              <Text variant="heading4" color="black" style={{ fontSize: 18 }}>
                {t('DASHBOARD.SPOTLIGHT')}
              </Text>
              <Icon
                name={isExpanded ? 'ArrowUp' : 'ArrowDown'}
                width={22}
                height={22}
              />
            </TouchableOpacity>
            {isExpanded ? (
              <>
                {totalPages ? (
                  <View
                    style={{
                      borderWidth: 0.4,
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderStyle: 'solid',
                    }}
                  />
                ) : null}

                {totalPages ? (
                  <View>
                    <PaginatedContent
                      notifications={notifications}
                      handleNextPage={handleNextPage}
                      handlePrevPage={handlePrevPage}
                      handleUpdateNotification={handleUpdateNotification}
                      handleEventNavigation={handleEventNavigation}
                      currentPage={currentPage}
                      hasNextPage={hasSpotLightNextPage}
                      hasPrevPage={hasPrevPage}
                      totalPages={totalPages}
                    />
                  </View>
                ) : null}
                {totalEventPages ? (
                  <View
                    style={{
                      borderWidth: 0.4,
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderStyle: 'solid',
                      marginTop: 4,
                    }}
                  />
                ) : null}
                {totalEventPages > 1 ? (
                  <>
                    <Text
                      variant="heading4"
                      color="black"
                      style={{ letterSpacing: 0.2 }}
                    >
                      {t('DASHBOARD.UPCOMING_EVENTS')}
                    </Text>
                    <UpcomingEvents
                      events={events}
                      handleEventNextPage={handleEventNextPage}
                      handleEventPrevPage={handleEventPrevPage}
                      currentEventPage={currentEventPage}
                      hasEventNextPage={hasEventNextPage}
                      hasEventPrevPage={hasEventPrevPage}
                      totalEventPages={totalEventPages}
                    />
                  </>
                ) : null}
                {cmsContent.length ? (
                  <View
                    style={{
                      borderWidth: 0.4,
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderStyle: 'solid',
                    }}
                  />
                ) : null}
                {fetchingCMSContent ? (
                  <ShimmerPlaceholder
                    style={{
                      borderRadius: 12,
                      width: '100%',
                      height: 500,
                    }}
                  />
                ) : cmsContent.length ? (
                  <DynamicContentPanel
                    cmsContent={cmsContent}
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                  />
                ) : null}
              </>
            ) : null}
          </View>
        </View>
      </View>

      <FileUploadModal
        visible={modalVisible}
        title={t('APPLICATION_DETAIL.ATTACH_DOCUMENT')}
        toggleDropdown={toggleDropdown}
        handleSave={(documentDetails) =>
          handleFileUpload(documentDetails, findOpportunityFromOpportunities)
        }
        handleClose={() => setModalVisible(false)}
        setModalVisible={setModalVisible}
        dropDownPosition={{ left: dropdownLeft + 10, top: dropdownTop + 10 }}
        dropdownWidth={dropdownWidth}
        documentType={documentType}
        dropDownRef={dropDownRef}
        selectedDropDownValue={selectedOpportunity?.documentType}
        taskId={selectedOpportunity?.Id}
      />

      <YouTubeVideoPlayer
        videoId={process.env.REACT_APP_DASHBOARD_VIDEO_ID}
        onClose={handleCloseVideo}
        isVideoPlayerVisible={isVideoPlayerVisible}
      />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  contentContainer: () => ({
    flexDirection: 'column',
    flex: 1,
    gap: 22,
    paddingHorizontal: 24,
    paddingVertical: 32,
  }),
  columnContainer: {
    gap: 48,
  },
  section: {
    flexDirection: 'column',
    gap: 24,
  },
  rowContainer: {
    gap: 20,
    width: '100%',
    flexWrap: 'wrap',
    flex: 1,
  },
  sidePanel: {
    backgroundColor: 'rgba(221, 223, 238, 1)',
    borderRadius: 10,
    marginBottom: 32,
    paddingHorizontal: 18,
    paddingVertical: 24,
  },
})

export default MobileView

const MobileViewSkeletonLoader = () => {
  const { colors } = useTheme()
  return (
    <ScrollView
      contentContainerStyle={{
        paddingHorizontal: 24,
        gap: 16,
        paddingVertical: 24,
      }}
      style={{ backgroundColor: colors.backgroundSecondary }}
    >
      <View
        style={{
          flex: 1,
          gap: 26,
        }}
      >
        <ShimmerPlaceholder
          style={{
            borderRadius: 12,
            width: '100%',
            height: 301,
          }}
        />

        {/* Summary Section Skeleton */}
        <View style={{ gap: 24 }}>
          <Text variant="heading3" color="black">
            {t('DASHBOARD.SUMMARY')}
          </Text>
          <View
            style={{
              gap: 20,
            }}
          >
            {[...Array(2)].map((_, index) => (
              <ShimmerPlaceholder
                key={index}
                LinearGradient={LinearGradient}
                style={{
                  width: '100%',
                  height: 140,
                  borderRadius: 10,
                }}
              />
            ))}
          </View>
        </View>

        {/* Action Required Section Skeleton */}
        <View style={{ gap: 24 }}>
          <Text variant="heading3" color="black">
            {t('DASHBOARD.ACTION_REQUIRED')}
          </Text>
          <ShimmerPlaceholder
            LinearGradient={LinearGradient}
            style={{
              height: 200,
              borderRadius: 10,
              width: '100%',
            }}
          />
        </View>

        {/* My Application Section Skeleton */}
        <View style={{ gap: 24 }}>
          <Text variant="heading3" color="black">
            {t('DASHBOARD.MY_APPLICATION')}
          </Text>
          {[...Array(2)].map((_, index) => (
            <ShimmerPlaceholder
              key={index}
              LinearGradient={LinearGradient}
              style={{
                height: 150,
                width: '100%',
                borderRadius: 10,
                marginBottom: 20,
              }}
            />
          ))}
        </View>
      </View>
    </ScrollView>
  )
}
