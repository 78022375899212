/* eslint-disable no-nested-ternary */
import {
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Platform,
} from 'react-native'
import React from 'react'
import { NotificationAnimation, Text } from '@libs/components'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@libs/theme'
import { Icon } from '@app-hero/native-icons'
import { formatNotificationTimestamp } from '@libs/utils/src/dateformat'
import { useNavigation } from '@react-navigation/native'
import EmptyNotificationContainer from '../../components/container/EmptyNotificationContainer'

const MobileView = ({ ...props }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const navigation = useNavigation()
  const {
    inAppNotifications,
    handleUpdateNotification,
    loadMoreNotifications,
    handleEventNavigation,
    notificationLoading,
    total,
    offset,
  } = props

  return (
    <View style={styles.mainContainer}>
      <View style={styles.NotificationContainer}>
        <View style={styles.NotificationLeftHeader}>
          <TouchableOpacity
            onPress={() => {
              if (Platform.OS === 'web') {
                window.history.go(-1)
              } else {
                navigation.goBack()
              }
            }}
          >
            <Icon
              name="ArrowBack"
              height={24}
              width={24}
              color={colors.black}
            />
          </TouchableOpacity>
          <Text
            style={[
              styles.NotificationText,
              { textAlign: 'center', marginRight: 10 },
            ]}
          >
            {t('PROFILE.NOTIFICATIONS')}
          </Text>
        </View>
      </View>
      <ScrollView
        style={{
          paddingHorizontal: 24,
          paddingVertical: 40,
        }}
      >
        {inAppNotifications.length > 0 ? (
          inAppNotifications?.map((notificationItem, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => {
                if (!notificationItem?.readStatus) {
                  handleUpdateNotification(notificationItem)
                }
                handleEventNavigation(notificationItem)
              }}
            >
              <View style={{ flexDirection: 'row', flex: 1, gap: 12 }}>
                <View>
                  <Icon
                    name="NotificationDocs"
                    color={
                      notificationItem?.readStatus ? 'black' : colors.primary
                    }
                    strokeWidth={1.3}
                  />
                </View>

                <View style={{ width: '100%' }}>
                  <Text
                    variant="display6"
                    style={{
                      color: colors.textPrimary,
                      opacity: 0.5,
                      paddingBottom: '0.25rem',
                      fontWeight: notificationItem?.readStatus ? 400 : 700,
                      fontSize: 14,
                    }}
                  >
                    {formatNotificationTimestamp(notificationItem?.createdAt)}
                  </Text>
                  <View style={{ flexDirection: 'row', width: '85%' }}>
                    <Text
                      variant="display3"
                      style={[
                        styles.menuItem(colors),
                        {
                          flexWrap: 'wrap',
                          flexShrink: 2,
                          fontWeight: notificationItem?.readStatus ? 400 : 700,
                          fontSize: 16,
                        },
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      {notificationItem?.message}
                    </Text>
                  </View>
                </View>
              </View>
              {inAppNotifications.length - 1 !== index ? (
                <View style={styles.divider} />
              ) : null}
            </TouchableOpacity>
          ))
        ) : (
          <EmptyNotificationContainer />
        )}
        {inAppNotifications.length > 0 &&
        total > offset &&
        !notificationLoading ? (
          <>
            <View style={[styles.divider, { marginBottom: 0 }]} />
            <TouchableOpacity
              onPress={() => loadMoreNotifications()}
              style={{ height: '64px', justifyContent: 'center' }}
            >
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '100%' }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignSelf: 'center',
                      alignItems: 'center',
                      flex: 1,
                    }}
                  >
                    <Text
                      variant="display3"
                      style={[
                        styles.menuItem(colors),
                        {
                          color: colors.textAccent,
                          fontWeight: 700,
                          lineHeight: 22.5,
                          textAlign: 'center',
                          flex: 1,
                          height: '100%',
                          width: '100%',
                        },
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      See more
                    </Text>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          </>
        ) : (
          total > offset && (
            <>
              <View style={styles.divider} />
              <View
                style={{
                  width: '100%',
                  alignItems: 'center',
                  height: '64px',
                }}
              >
                <NotificationAnimation height={48} width={48} margin={0} />
              </View>
            </>
          )
        )}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: '#F4F5FB',
    flex: 1,
  },
  NotificationContainer: {
    width: '100%',
  },
  NotificationLeftHeader: {
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingHorizontal: 24,
    paddingVertical: 29,
  },
  NotificationText: {
    fontWeight: 700,
    fontSize: 18,
    color: '#1E1E2D',
    textTransform: 'uppercase',
    flex: 1,
    textAlign: 'center',
  },
  menuItem: () => ({
    width: '90%',
  }),
  divider: {
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderStyle: 'solid',
    marginVertical: 16,
  },
})

export default MobileView
