import { FlatList, View, StyleSheet, ImageBackground } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Text } from '@libs/components'
import { useIsFocused } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import OpportunitiesCard from './OpportunitiesCard'
import { OfferCardBackgroundImage } from '@apphero/assets'
import { useTheme } from '@libs/theme'
import { SecureStore } from '@libs/utils'

const OfferCard = ({ data }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const [brandLogoUrl, setBrandLogoUrl] = useState('')
  const isFocused = useIsFocused()

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  return (
    <ImageBackground
      style={styles.container}
      source={OfferCardBackgroundImage}
      imageStyle={{ flex: 1, borderRadius: 20 }}
    >
      <View style={{ marginBottom: 30 }}>
        <Text variant="heading2" color={colors.white}>
          {t('APPLICATION.CONGRATULATION')}
        </Text>
        <Text variant="display2" color={colors.white}>
          {t('APPLICATION.OFFER_DESCRIPTION')}
        </Text>
      </View>
      <FlatList
        data={data}
        renderItem={({ item }) => {
          return (
            <OpportunitiesCard
              data={item}
              brandLogo={`${brandLogoUrl}/${item.BusinessUnitFilter__c?.replaceAll(
                ' ',
                '_',
              )}.png`}
              style={{ maxWidth: 450 }}
            />
          )
        }}
        keyExtractor={(item) => item.Id}
        numColumns={data?.length > 1 ? 2 : 1}
      />
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 40,
    paddingHorizontal: 40,
    flexDirection: 'column',
    backgroundColor: '#0B5CD7',
    borderRadius: 20,
    marginBottom: 40,
  },
})

export default OfferCard
