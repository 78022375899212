import React, { useCallback, Suspense } from 'react'
import { ScreenLayout } from '@libs/utils'
import { Text } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import NotificationLoader from '@libs/components/src/Loader/NotificationLoader'
import { useNotifications } from '@app-hero/shared-hooks'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

const Notification = () => {
  const navigation = useNavigation()
  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  const {
    notifications,
    isLoading,
    isError,
    loadMoreNotifications,
    handleUpdateNotification,
    notificationLoading,
    offset,
    totalNotifications,
    handleEventNavigation,
  } = useNotifications()

  if (isLoading) {
    return <NotificationLoader />
  }

  if (isError) {
    return navigation.navigate('dashboard')
  }

  const viewProps = {
    navigation,
    inAppNotifications: notifications,
    handleUpdateNotification,
    loadMoreNotifications,
    isLoading,
    handleEventNavigation,
    notificationLoading,
    offset,
    total: totalNotifications,
  }

  return (
    <Suspense fallback={<Text>Loading...</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default Notification
