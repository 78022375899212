import moment from 'moment'

export const DateFormat = (originalDate) => {
  const parts = originalDate?.split('-')
  const year = parts?.[0]
  const month = parts?.[1]
  const day = parts?.[2]

  const convertedDate = `${day?.padStart(2, '0')}/${month?.padStart(
    2,
    '0',
  )}/${year}`
  return convertedDate
}

export const DateforComments = (dateString) => {
  const today = new Date()
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
  const date = new Date(dateString)

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th' // For 4th to 20th
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  const options = { month: 'short', year: 'numeric' }
  const day = date.getDate()
  const suffix = getOrdinalSuffix(day)
  const formattedDate = `${day}${suffix} ${date.toLocaleDateString(
    'en-UK',
    options,
  )}`

  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return 'Today'
  } else if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    return `Yesterday`
  } else {
    return formattedDate
  }
}

export const findDateFormat = (dateString) => {
  const formatsToCheck = ['YYYY/MM/DD', 'DD/MM/YYYY', 'YYYY/DD/MM']

  for (const format of formatsToCheck) {
    const parsedDate = moment(dateString, format, true)
    if (parsedDate.isValid()) {
      return format
    }
  }

  return 'In valid Date'
}

export function formatNotificationTimestamp(timestamp) {
  const date = moment(timestamp)
  const now = moment()

  if (date.isSame(now, 'day')) {
    return `Today at ${date.format('h:mm A')}`
  } else if (date.isSame(now.subtract(1, 'days'), 'day')) {
    return `Yesterday at ${date.format('h:mm A')}`
  } else if (date.isSame(now, 'year')) {
    return date.format('MMM D [at] h:mm A')
  } else {
    return date.format('DD/MM/YYYY [at] h:mm A')
  }
}

export const dateFormatForCMS = (dateString) => {
  const date = new Date(dateString)

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th' // For 4th to 20th
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  const options = { month: 'short', year: 'numeric' }
  const day = date.getDate()
  const suffix = getOrdinalSuffix(day)
  const formattedDate = `${day}${suffix} ${date.toLocaleDateString(
    'en-UK',
    options,
  )}`

  return formattedDate
}

export function formatIntakeDate(dateString) {
  const date = new Date(dateString)
  return new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' })
    .format(date)
    .replace(' ', '-')
}
