import { useInfiniteQuery } from '@tanstack/react-query'
import { getAllInstitution } from '@app-hero/shared/src/api'

export const useInstitutionInfiniteData = ({
  queryKey,
  enabled,
  limit,
  institutionName,
}) => {
  async function fetchAllInstitution(pageParam) {
    // payload
    let payload = {
      filterQuery: {
        size: limit,
        from: pageParam * limit,
      },
    }
    if (institutionName?.length > 0) {
      payload = {
        filterQuery: {
          size: limit,
          from: pageParam * limit,
          filters: {
            Institution: {
              values: institutionName,
            },
          },
        },
      }
    }

    const allInstitution = await getAllInstitution(payload)
    const AllInstitutionName = []
    allInstitution?.institutions?.map((item) => {
      if (item?.Name) {
        AllInstitutionName.push(item?.Name)
      }
      return null
    })
    return allInstitution
  }

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [queryKey],
    initialData: [],
    queryFn: async ({ pageParam = 0 }) => fetchAllInstitution(pageParam),
    getNextPageParam: (_lastPage, pages) => {
      const paginatedData = []
      pages?.forEach((page) =>
        page?.institutions?.map((item) => paginatedData?.push(item)),
      )
      if (paginatedData?.length < _lastPage?.total) return pages?.length
    },

    enabled: enabled && !!queryKey,

    retry: false,
    onError: (error) => {
      console.log(error)
    },
  })
  const allData = (data?.pages || [])
    .flatMap((page) => page?.institutions || [])
    .filter((item) => item !== undefined)
  return {
    allData,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  }
}
