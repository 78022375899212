import { Text } from '@libs/components'
import React from 'react'
import { Image, View } from 'react-native'
import EmptyGlass from './emptyGlass.png'

export const VoidBox = ({ heading1, heading2, image }) => (
  <View
    style={{
      borderWidth: 1,
      borderStyle: 'dashed',
      borderColor: 'rgba(0, 0, 0, 0.5)',
      flexDirection: window.innerWidth <= 650 ? 'column' : 'row',
      justifyContent: 'center',
      alignContent: 'center',
      borderRadius: 10,
      flexWrap: 'wrap',
      paddingVertical: 24,
      paddingHorizontal: 32,
      maxHeight: window.innerWidth >= 650 ? 280 : 'auto',
      flex: 1,
    }}
  >
    <View
      style={{
        justifyContent: 'center',
        alignSelf: window.innerWidth <= 650 ? 'center' : 'auto',
      }}
    >
      <Image
        source={image || EmptyGlass}
        style={{ width: 150, height: 150 }}
        resizeMode="contain"
      />
    </View>

    <View
      style={{
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <Text variant="heading4" color="#4A4A4A">
        {heading1}
      </Text>
      <Text variant="display3" color="#4A4A4A">
        {heading2}
      </Text>
    </View>
  </View>
)
