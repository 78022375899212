import {
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native'
import React, { useEffect, useState } from 'react'
import { Button, PasswordInput, Text } from '@libs/components'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import { Icon } from '@app-hero/native-icons'
import { useTheme } from '@libs/theme'
import { isWeb } from '@libs/utils/src/screenLayout'
import { errorMessageAtom } from '../../utils/atom'

const SetPassword = ({
  countryList,
  invalidUser,
  signUpDetails,
  handleSignUp,
  mobile,
  tablet,
  setSignupDetails,
  userSelectedLanguage,
  setIsLoading,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const isDesktop = isWeb(width)
  const { colors } = useTheme()
  const isFocused = useIsFocused()
  const navigation = useNavigation()
  const routeName = props.route.name
  const [errorMessage, setErrorMessage] = useAtom(errorMessageAtom)

  const [isCTAEnable, setIsCTAEnable] = useState(false)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState({
    error: '',
    errorKey: '',
  })

  useEffect(() => {
    if (!isFocused) return
    ;(() => {
      setPasswordErrorMessage((prev) => ({ ...prev, error: t(prev.errorKey) }))
    })()
  }, [userSelectedLanguage, isFocused])

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const emailPattern = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const isValidFirstName = signUpDetails.firstName?.length > 0
      const isValidEmail = emailPattern.test(signUpDetails?.email)
      const isValidMobile = signUpDetails.mobile.mobileNumber?.length > 0
      const isValidPassword =
        (typeof passwordErrorMessage === 'boolean'
          ? passwordErrorMessage
          : !passwordErrorMessage.error) &&
        signUpDetails.password?.length > 0 &&
        signUpDetails.repeatPassword?.length > 0

      if (isValidFirstName && isValidEmail && isValidMobile && isValidPassword)
        setIsCTAEnable(true)
      else setIsCTAEnable(false)

      if (
        signUpDetails.password !== signUpDetails.repeatPassword &&
        signUpDetails.repeatPassword?.length > 0
      ) {
        setPasswordErrorMessage({
          error: t('ERROR_MESSAGE.PASSWORD_NOT_MATCH'),
          errorKey: 'ERROR_MESSAGE.PASSWORD_NOT_MATCH',
        })
        setIsCTAEnable(false)
      }
      if (
        signUpDetails.password === signUpDetails.repeatPassword &&
        signUpDetails.password !== ''
      ) {
        setPasswordErrorMessage({
          error: '',
          errorKey: '',
        })
        setIsCTAEnable(true)
      }
    })()
  }, [isFocused, signUpDetails])

  const handleChange = (field, selectedValue) => {
    if (field === 'repeatPassword' || field === 'confirmPassword') {
      const isValidPassword = selectedValue === signUpDetails.password
      if (!isValidPassword) {
        setPasswordErrorMessage({
          error: t('ERROR_MESSAGE.PASSWORD_NOT_MATCH'),
          errorKey: 'ERROR_MESSAGE.PASSWORD_NOT_MATCH',
        })
      } else {
        setPasswordErrorMessage({
          error: '',
          errorKey: '',
        })
      }

      return setSignupDetails((previousData) => ({
        ...previousData,
        [field]: selectedValue,
      }))
    }

    return setSignupDetails((previousData) => ({
      ...previousData,
      [field]: selectedValue,
    }))
  }

  return (
    <ScrollView
      style={{ flex: 1, minHeight: '90vh' }}
      contentContainerStyle={styles.signUpContainer(mobile, tablet, isDesktop)}
    >
      <View style={[styles.SignUpFields, { minHeight: '50vh' }]}>
        <View
          style={[
            {
              minHeight: '40vh',
              marginTop: mobile || tablet ? 0 : 30,
              width: '100%',
            },
          ]}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'baseline',
              gap: 8,
            }}
          >
            {(mobile || tablet) && (
              <View style={{ flex: 0.1 }}>
                {routeName !== 'privacy-policy' && routeName !== 'login' && (
                  <TouchableOpacity
                    onPress={() => {
                      navigation.goBack()
                      setSignupDetails((prevDetails) => ({
                        ...prevDetails,
                        password: '',
                        repeatPassword: '',
                      }))
                    }}
                  >
                    <View style={{}}>
                      <Icon name="ArrowNarrowLeft" color={colors.neutral} />
                    </View>
                  </TouchableOpacity>
                )}
              </View>
            )}
            <View style={{ flex: mobile || tablet ? 0.9 : 1 }}>
              <Text
                color={colors.neutral}
                variant={isDesktop ? 'heading2' : 'heading3'}
                style={{
                  textAlign: mobile || tablet ? 'start' : 'center',
                  fontWeight: 700,
                  marginTop: mobile || tablet ? 0 : 30,
                  marginBottom: 40,
                  borderRadius: 4,
                }}
              >
                {t('AUTH.SET_PASSWORD')}
              </Text>
            </View>
          </View>

          <PasswordInput
            value={signUpDetails.password}
            onChangeText={(text) => handleChange('password', text)}
            placeholder={t('TEXT_INPUT.PASSWORD')}
            onPassAllValid={(val) => setPasswordErrorMessage(val)}
            style={{
              height: 56,
              marginBottom: 16,
              borderRadius: isDesktop ? 4 : 8,
            }}
            iconName="Lock"
            inputFieldStyle={{ marginLeft: 12 }}
            iconSize={24}
          />
          <PasswordInput
            value={signUpDetails.repeatPassword}
            onChangeText={(text) => handleChange('repeatPassword', text)}
            placeholder={t('TEXT_INPUT.RE_ENTER_PASSWORD')}
            style={{
              height: 56,
              marginBottom: 16,
              borderRadius: isDesktop ? 4 : 8,
            }}
            iconName="Lock"
            inputFieldStyle={{ marginLeft: 12 }}
            iconSize={24}
          />
          {Boolean(passwordErrorMessage.error) ? (
            <Text
              variant="display3"
              color={colors.onAlert}
              style={{ marginBottom: 20 }}
            >
              {passwordErrorMessage.error}
            </Text>
          ) : null}
          <PasswordInput
            value={signUpDetails.password}
            onChangeText={(text) => {
              handleChange('password', text)
            }}
            placeholder={t('TEXT_INPUT.PASSWORD')}
            onPassAllValid={(val) => setPasswordErrorMessage(val)}
            style={{ height: 56, marginBottom: 10, borderRadius: 4 }}
            iconName="Lock"
            hasValidateOnly
            hasValidate
          />
          <Button
            label={t('BUTTON.SET_PASSWORD')}
            buttonColor={colors.primary}
            labelColors={colors.white}
            onPress={() => {
              handleSignUp(true)
              // navigation.navigate('Otp')
            }}
            disable={!isCTAEnable}
            buttonStyle={{ width: '100%', borderRadius: 4, height: 56 }}
            labelStyle={{ fontWeight: 700 }}
          />
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  authLinks: {
    alignSelf: 'center',
  },
  buttonsContainer: {
    width: '100%',
    marginTop: 20,
    marginBottom: 30,
  },
  signUpContainer: (mobile, tablet, isDesktop) => {
    return {
      flexDirection: 'column',
      alignItems: isDesktop && 'center',
      justifyContent: 'center',
      marginTop: !isDesktop ? '6vh' : '0vh',
      paddingLeft: isDesktop ? '12%' : '6%',
      paddingRight: isDesktop
        ? window.innerWidth <= 1140
          ? '3%'
          : '12%'
        : '6%',
    }
  },
  SignUpFields: {
    marginTop: 30,
    width: '100%',
    // justifyContent: 'space-evenly',
    flex: 1,
  },
  leftHeader: {
    position: 'absolute',
    zIndex: 20,
    top: 0,
    left: -35,
  },
  footer: {
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default SetPassword
