import { View, StyleSheet, Modal, TouchableOpacity, Image } from 'react-native'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DropDown, FilePicker, Loader, Text } from '@libs/components'

import { Icon } from '@app-hero/native-icons'
import {
  PdfImage,
  PngImage,
  JpegImage,
  JpgImage,
  MiscImage,
} from '@apphero/assets'
import { useTheme } from '@libs/theme'
import { BlurView } from 'expo-blur'
import BottomSheet from '../Drawer'
import { AlertBox } from '../actionRequired/alertConatiner'

const FileUploadModalMobile = ({
  title = '',
  visible = false,
  toggleDropdown = () => {},
  handleSave = async () => {},
  handleClose = () => {},
  dropDownRef,
  dropDownPosition = {},
  dropdownWidth = '',
  documentType = [],
  selectedDropDownValue,
  taskId,
  setModalVisible,
}) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const [file, setFile] = useState({})
  const [error, setError] = useState()
  const [selectedDocumentType, setSelectedDocumentType] = useState({})
  const [isFileSaving, setIsFileSaving] = useState()

  const selectedDropDownItem = documentType?.find(
    (item) => item.label === selectedDropDownValue,
  )

  useEffect(() => {
    if (selectedDropDownItem) {
      setSelectedDocumentType(selectedDropDownItem)
    }
  }, [selectedDropDownItem, file])

  const handleSaveDocument = async () => {
    setIsFileSaving(true)
    await handleSave({
      ...file,
      documentType: selectedDocumentType?.label,
      taskId,
    })
    setIsFileSaving(false)

    handleClose()
    setSelectedDocumentType({})
    setFile({})
  }

  const canCTADisable = () =>
    Object.keys(selectedDocumentType).length === 0 ||
    Object.keys(file).length === 0 ||
    !!error
  const getFileTypeImage = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase()
    switch (extension) {
      case 'pdf':
        return PdfImage
      case 'png':
        return PngImage
      case 'jpg':
        return JpgImage
      case 'jpeg':
        return JpegImage
      default:
        return MiscImage
    }
  }

  return (
    <BottomSheet
      drawerHeight={465}
      isVisible={visible}
      setIsVisible={setModalVisible}
      style={{
        backgroundColor: '#fff',
        borderTopLeftRadius: 32,
        borderTopRightRadius: 32,
      }}
    >
      <View style={styles.topdivider} />
      <View style={[styles.container, styles.modalOverlay]}>
        <View style={styles.card}>
          <View style={[styles.header]}>
            <Text
              style={{
                paddingVertical: 20,
                fontSize: 24,
                fontWeight: '700',
                color: colors.neutral,
              }}
              placeHolder="Text"
            >
              {title}
            </Text>
          </View>
          <View>
            <Text variant="display4" color={colors.neutral}>
              {t('FILE_UPLOAD.DOCUMENT_TYPE')}{' '}
              <Text variant="display4" color={colors.onAlert}>
                *
              </Text>
            </Text>

            <View ref={dropDownRef}>
              <DropDown
                items={documentType}
                toggleDropdown={toggleDropdown}
                position={dropDownPosition}
                dropdownWidth={dropdownWidth}
                onPress={(selectedOption) =>
                  setSelectedDocumentType(selectedOption)
                }
                label={t('DROP_DOWN.LABEL_SELECT')}
                dropdownHeight={190}
                style={{
                  margin: 0,
                  marginTop: 8,
                  marginBottom: 20,
                }}
                placeHolderColor={colors.fieldBorder}
                value={selectedDropDownItem}
                disable={selectedDropDownItem}
              />
            </View>
          </View>
          <View>
            <Text
              variant="display4"
              style={{
                flexWrap: 'wrap',
                flex: 1,
              }}
              color={colors.neutral}
            >
              {t('FILE_UPLOAD.ATTACH_DOCUMENT')}{' '}
              <Text variant="display4" color={colors.onAlert}>
                *
              </Text>
            </Text>
            {Object.keys(file).length > 0 ? (
              <View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderColor: colors.primaryBackground,
                    borderWidth: 2,
                    padding: 20,
                    marginVertical: 15,
                    borderRadius: 15,
                    flexWrap: 'wrap',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      flex: 1,
                    }}
                  >
                    <Image
                      source={getFileTypeImage(file.name)}
                      style={{ height: 15, width: 15 }}
                    />
                    <Text
                      variant="body2"
                      style={{ marginHorizontal: 10, maxWidth: '70%' }}
                    >
                      {file.name}
                    </Text>
                  </View>
                  <TouchableOpacity
                    onPress={() => {
                      setFile({})
                      setError('')
                    }}
                  >
                    <Icon name="Close" height={15} width={15} />
                  </TouchableOpacity>
                </View>
              </View>
            ) : (
              <FilePicker
                style={{
                  marginBottom: 20,
                }}
                handleFileSelect={({ fileJson, error, file }) => {
                  if (error) {
                    setError(error)
                  }
                  setFile({ ...fileJson, file })
                }}
              />
            )}
            <View style={{ flex: 1, marginBottom: 32 }}>
              <AlertBox
                iconColor={colors.alertPrimary}
                iconSize={20}
                iconName="AlertCircle"
                style={{
                  flexDirection: 'row',
                  gap: 8,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <View style={{ flex: 1, flexWrap: 'wrap', width: '80%' }}>
                  <Text variant="display4" color={colors.alertPrimary}>
                    <b>{t('FILE_UPLOAD.NOTE')}</b>
                    {t('FILE_UPLOAD.ALERT')}
                  </Text>
                </View>
              </AlertBox>
            </View>
          </View>
          {error ? (
            <Text
              variant="display4"
              color={colors.onAlert}
              style={{ marginBottom: 20 }}
            >
              {error}
            </Text>
          ) : null}
          <View style={styles.buttonContainer}>
            {isFileSaving ? (
              <Loader size={20} />
            ) : (
              <View
                style={{
                  flex: 1,
                  flexDirection: 'column',
                  gap: 16,
                }}
              >
                <Button
                  label={t('BUTTON.SAVE')}
                  buttonColor={colors.primary}
                  onPress={() => handleSaveDocument()}
                  disable={canCTADisable()}
                  labelColors="#FFFF"
                  labelvariant="heading5"
                  buttonStyle={{
                    flex: 1,
                    borderRadius: 4,
                    paddingHorizontal: 83.5,
                    paddingVertical: 10,
                  }}
                  labelStyle={{
                    textTransform: 'uppercase',
                    fontWeight: '700',
                  }}
                />
                <Button
                  label={t('BUTTON.CANCEL')}
                  buttonColor="#B6CFF3"
                  onPress={() => {
                    handleClose()
                    setSelectedDocumentType({})
                    setFile({})
                    setError('')
                  }}
                  appearance="outline"
                  labelColors={colors.primary}
                  buttonStyle={{
                    flex: 1,
                    borderRadius: 4,
                    paddingHorizontal: 83.5,
                    paddingVertical: 10,
                  }}
                  labelStyle={{ textTransform: 'uppercase' }}
                />
              </View>
            )}
          </View>
        </View>
      </View>
    </BottomSheet>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    paddingHorizontal: 8,
  },
  card: {
    width: '100%',
    borderRadius: 15,
  },
  header: {
    position: 'relative',
  },
  buttonContainer: {
    flexDirection: 'column',
  },
  overlay: {
    flex: 1,
  },
  topdivider: {
    borderWidth: 2,
    borderColor: 'rgba(212, 212, 216, 1)',
    borderStyle: 'solid',
    marginHorizontal: 80,
    borderRadius: 3.5,
    marginBottom: 15,
  },
})

export default FileUploadModalMobile
