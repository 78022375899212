import React from 'react'
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native'
import { PlayButton } from '@apphero/assets'
import { useTheme } from '@libs/theme'
import { Text } from '@libs/components'
import { t } from 'i18next'

const WelcomeCardMobile = ({ name, handleOpenVideo, coBrandingImage }) => {
  const { colors } = useTheme()

  return (
    <View
      style={[
        {
          backgroundColor: '#252734',
          borderRadius: 10,
          height: 266,
          overflow: 'hidden',
        },
      ]}
    >
      <View style={{ width: '100%', padding: 24 }}>
        <View style={{ gap: 8 }}>
          <Text style={[styles.userContainer, { fontWeight: 400 }]}>
            Welcome,{' '}
            <Text style={[styles.userContainer, { fontWeight: 700 }]}>
              {name ? `${name}!` : ''}
            </Text>
          </Text>
          <Text
            style={{
              color: 'white',
              flexWrap: 'wrap',
            }}
            variant="display3"
          >
            Track Every Step of Your Study Abroad Dream
          </Text>
        </View>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            marginTop: 32,
          }}
          onPress={handleOpenVideo}
        >
          <Image
            source={PlayButton}
            style={{ width: 32, height: 32 }}
            resizeMode="cover"
          />

          <Text variant="heading6" color={colors.white}>
            {t('DASHBOARD.LEARN_MORE')}
          </Text>
        </TouchableOpacity>
      </View>
      <View
        style={{
          width: window.innerWidth <= 450 ? '13rem' : '60%',
          height: '80%',
          borderRadius: 16,
          right: 0,
          position: 'absolute',
          bottom: window.innerWidth <= 450 ? -33 : -15,
        }}
      >
        <Image
          source={coBrandingImage}
          style={{
            height: '100%',
            width: '100%',
            borderBottomEndRadius: 16,
          }}
          resizeMode="contain"
        />
      </View>
    </View>
  )
}
export default WelcomeCardMobile

const styles = StyleSheet.create({
  mainContainer: {
    borderRadius: 16,
    paddingVertical: 20,
    position: 'relative',
  },
  userContainer: {
    color: 'white',
    fontSize: 24,
  },
})
