import { View, Modal, StyleSheet } from 'react-native'
import React from 'react'
import { Button, Text } from '@libs/components'
import { useTheme } from '@libs/theme'

const ModalComponent = ({
  handleCloseModal = () => {},
  handleButton = () => {},
  visible = false,
  title = '',
  titleVariant = 'body1',
  buttonLabel = '',
  buttonColor = '',
  labelColors = '',
  buttonStyle = {},
  labelStyle = {},
}) => {
  const { colors } = useTheme()
  return (
    <Modal
      visible={visible}
      transparent
      onBackdropPress={() => handleCloseModal()}
      onRequestClose={() => {
        handleCloseModal()
      }}
    >
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <Text style={{ padding: 20 }} variant={titleVariant}>
            {title}
          </Text>
          <Button
            buttonColor={buttonColor || colors.buttonVariant1}
            label={buttonLabel}
            onPress={() => handleButton()}
            buttonStyle={{
              height: 10,
              alignSelf: 'flex-end',
              padding: 20,
              minWidth: 100,
              ...buttonStyle,
            }}
            labelStyle={labelStyle}
            labelColors={labelColors || colors.primaryVariant}
          />
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000000CC',
    paddingHorizontal: 20,
  },
  contentContainer: {
    alignSelf: 'center',
    backgroundColor: '#ffffff',
    padding: 20,
    marginTop: 300,
    maxWidth: 500,
    width: '100%',
    borderRadius: 15,
  },
})

export default ModalComponent
