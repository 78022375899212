import React, { useCallback, Suspense, useState, useEffect } from 'react'
import { CustomAuth, ScreenLayout, SecureStore } from '@libs/utils'
import { Text, useWindowDimensions } from 'react-native'
import { useAtom } from 'jotai'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { Auth as Authentication } from 'aws-amplify'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { isMobile, isTablet } from '@libs/utils/src/screenLayout'
import { Loader } from '@libs/components'
import { useTheme } from '@libs/theme'
import DesktopView from './DesktopView'
import {
  ForgotPassword,
  Login,
  Signup,
  SetPassword,
  Otp,
} from '../../components'
import PrivacyPolicy from '../../components/auth/PrivacyPolicy'
import {
  alertBoxAtom,
  brandDetailsAtom,
  errorMessageAtom,
  isChatbotVisible,
  signUpDetailsAtom,
  userLanguage,
  userProfile,
} from '../../utils/atom'
import { getBrandTheme, getCountryList } from '../../api'
import { navigate } from '../../navigation/RootNavigator'
import MobileView from './MobileView'
import { userChatBotDestroy } from '../../utils/addScript'

const Auth = (props) => {
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [dropdownWidth, setDropDownWidth] = useState(0)
  const [canShowChatbot] = useAtom(isChatbotVisible)
  const [errorMessage, setErrorMessage] = useAtom(errorMessageAtom)
  const [userSelectedLanguage, setUserSelectedLanguage] = useAtom(userLanguage)
  const [brandDetails, setBrandDetails] = useAtom(brandDetailsAtom)
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  const [userDetails] = useAtom(userProfile)
  const navigation = useNavigation()
  const [isLoading, setIsLoading] = useState(false)
  const [isThemeLoading, setIsThemeLoading] = useState(false)
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  const tablet = isTablet(width)

  const [alertBoxDetails, setAlertBoxDetails] = useAtom(alertBoxAtom)

  const [signUpDetails, setSignupDetails] = useAtom(signUpDetailsAtom)

  const routeName = props.route.name
  const validUser = !!props.route.params?.invalidUser
  const validUserMessage = props.route.params?.message
  const [brandLogoUrl, setBrandLogoUrl] = useState('')
  const { updateColors } = useTheme()

  useEffect(() => {
    if (!isFocused) return
    ;(() => {
      i18n.changeLanguage(userSelectedLanguage.languageCode)
    })()
  }, [isFocused, userSelectedLanguage])

  useEffect(() => {
    setAlertBoxDetails({
      visible: validUser,
      message: validUser ? validUserMessage || t('ALERT.INVALID_EMAIL') : '',
    })
    return () => {
      setAlertBoxDetails({
        visible: false,
        message: '',
      })
    }
  }, [validUser])

  const handleLanguageChange = (selectedLanguage) => {
    setUserSelectedLanguage(selectedLanguage)
  }

  const { data: countryList } = useQuery({
    queryKey: ['getCountryList'],
    queryFn: getCountryList,
    enabled: isFocused && routeName === 'sign-up',
  })

  const renderModule = (propsFromModule) => {
    if (routeName === 'login') {
      return (
        <Login
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )
    }
    if (routeName === 'sign-up') {
      return (
        <Signup
          countryList={countryList}
          invalidUser={!!props.route.params?.invalidUser}
          {...props}
          {...propsFromModule}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )
    }
    if (routeName === 'set-password') {
      return (
        <SetPassword
          invalidUser={!!props.route.params?.invalidUser}
          {...props}
          {...propsFromModule}
        />
      )
    }
    if (routeName === 'otp') {
      return (
        <Otp
          invalidUser={!!props.route.params?.invalidUser}
          {...props}
          {...propsFromModule}
          signUpDetails={signUpDetails}
          setSignupDetails={setSignupDetails}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleSignUp={handleSignUp}
        />
      )
    }
    if (routeName === 'privacy-policy') {
      return <PrivacyPolicy />
    }
    if (routeName === 'forgot-password') {
      return (
        <ForgotPassword isLoading={isLoading} setIsLoading={setIsLoading} />
      )
    }
    if (routeName === 'set-new-password') {
      return (
        <SetPassword
          invalidUser={!!props.route.params?.invalidUser}
          {...props}
          {...propsFromModule}
        />
      )
    }
    return <ForgotPassword />
  }

  const handleLogout = async () => {
    if (canShowChatbot) {
      await userChatBotDestroy()
    }
    setIsLoading(true)
    await CustomAuth.logOut()

    setIsLoading(false)

    // window.location.reload()
  }

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      if (routeName === 'sign-up') {
        const consentAccepted = await SecureStore.getItemAsync(
          'consentAccepted',
        )
        if (consentAccepted !== 'true') {
          navigation.navigate('privacy-policy')
        }
      }
    })()
  }, [isFocused, routeName])

  useEffect(() => {
    if (
      !isFocused ||
      !props?.cobrandId ||
      brandDetails?.cobrandId === props?.cobrandId ||
      routeName !== 'login'
    )
      return

    const fetchBrandTheme = async () => {
      try {
        setIsThemeLoading(true)
        const res = await getBrandTheme({ id: props?.cobrandId })
        const user = await Authentication.currentAuthenticatedUser().catch(
          () => null,
        )
        await SecureStore.setItemAsync(
          'tempUser',
          JSON.stringify({ email: res?.email }),
        )

        setBrandDetails({ ...res, cobrandId: props?.cobrandId })
        updateColors({ ...res.colors })

        if (user) {
          if (
            userDetails?.email !== props?.email &&
            userDetails?.email !== ''
          ) {
            await setBrandDetails(res)
            await handleLogout()
            await SecureStore.setItemAsync('consentAccepted', false)
          }
        } else {
          if (res.emailStatus === false && routeName === 'login') {
            const tempUser = await localStorage.getItem('tempUser')
            const userEmail = JSON.parse(tempUser)?.email
            if (userEmail !== props?.email) {
              await SecureStore.setItemAsync('consentAccepted', false)
            }
            navigation.navigate('sign-up', res.emailStatus)
          } else if (res.emailStatus === true && routeName === 'login') {
            navigation.navigate('login')
          }
        }
      } catch (error) {
        console.error('Error fetching brand theme:', error)
      } finally {
        setIsThemeLoading(false)
      }
    }

    fetchBrandTheme()
  }, [isFocused, props?.cobrandId])

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const user = await Authentication.currentAuthenticatedUser().catch(
        (error) => {},
      )

      if (Object.keys(user || {}).length > 0) {
        if (userDetails?.canApply) {
          navigate('path-finder')
        } else {
          navigate('dashboard')
        }
      }
    })()
  }, [isFocused])

  const toggleDropdown = (visible, ref) => {
    if (visible) {
      return
    }
    if (!visible) {
      ref?.current?.measure((_fx, _fy, _w, _h, _px, py) => {
        setDropdownTop(py + 50)
        setDropdownLeft(_px)
        setDropDownWidth(_w)
      })
    }
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  const handleSignUp = async (loader) => {
    let userDetailsSignup = {
      email: signUpDetails.email.toLowerCase(),
      'custom:first_name': signUpDetails.firstName,
      phone_number: `${signUpDetails.mobile.dialCode || '+1'}${
        signUpDetails.mobile.mobileNumber
      }`,
    }
    if (signUpDetails.lastName.length > 0) {
      userDetailsSignup = {
        ...userDetailsSignup,
        'custom:last_name': signUpDetails.lastName,
      }
    }
    if (loader) {
      setIsLoading(true)
    }
    const userData = await CustomAuth.emailSignUp(
      signUpDetails.email.toLowerCase(),
      signUpDetails.password,
      userDetailsSignup,
    )
    navigation.navigate('otp')

    if (userData?.message) {
      if (userData?.name === 'UsernameExistsException') {
        console.log('Error occured :', userData?.message)
        setErrorMessage(userData?.message)
        setAlertBoxDetails({
          visible: true,
          message: userData?.message,
        })
        navigation.navigate('sign-up', {
          invalidUser: true,
          message: userData?.message,
        })
      } else {
        navigation.navigate('sign-up', { invalidUser: true })
        setAlertBoxDetails({
          visible: true,
          message: t('ALERT.INVALID_EMAIL'),
        })
      }
      if (loader) {
        setIsLoading(false)
      }
      return
    }
    if (loader) {
      setIsLoading(false)
    }
  }

  if (isThemeLoading) return <Loader />

  const viewProps = {
    dropdownLeft,
    dropdownTop,
    dropdownWidth,
    routeName,
    showVideo: false,
    userSelectedLanguage,
    toggleDropdown,
    renderModule,
    handleLanguageChange,
    handleSignUp,
    alertBoxDetails,
    setAlertBoxDetails,
    signUpDetails,
    setSignupDetails,
    isLoading,
    setIsLoading,
    mobile,
    tablet,
    brandLogoUrl,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default Auth
