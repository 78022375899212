export const colors = {
  primary: {
    blue100: '#ECF2FC',
    blue200: '#B6CFF3',
    blue300: '#3E89DB',
    blue400: '#0B5CD7',
    blue500: '#044DBA',
    blue600: '#043C8F',
  },
  neutral: {
    white: '#FFFFFF',
    white700: 'rgba(255,255,255,0.7)',
    white500: 'rgba(255,255,255,0.5)',
    neutral100: '#F5F5F5',
    neutral200: '#EDEDED',
    neutral300: '#E0E0E0',
    neutral400: '#C2C2C2',
    neutral500: '#9E9E9E',
    neutral600: '#757575',
    neutral700: '#616161',
    neutral800: '#424242',
    neutral900: '#0A0A0A',
    neutralVariant10: '#252734',
  },
  functionalError: {
    error500: '#D72C2C',
  },
  success: {
    green700: '#065F46',
    green500: '#7DC436',
  },
  secondary: {
    yellow100: '#FADB2C',
    blue100: '#F8FAFF',
    neutral200: '#ECEFF1',
  },
  gradients: {
    scienceBlue: ['#0B5CD7', '#043C8F'],
    white: ['#FFFFFF', '#0B5CD7'],
    codGray: ['#0A0A0A', '#0B5CD7'],
  },
  warning: {
    warning: '#F6A411',
    warning100: '#FFE794',
    warning200: '#FFF4CE',
  },
  background: {
    primary: 'rgba(227, 231, 244, 0.4)',
    secondary: '#ffffff',
  },

  card: {
    titleColor: '#0F172A',
    draftBlue: 'rgba(37, 99, 235, 1)',
  },
  tab: {
    activeColor: '#0B5CD7',
  },
  border: {
    primary: 'rgba(0, 0, 0, 0.2)',
    divider: 'rgba(0, 0, 0, 0.1)',
  },
  auth: {
    primary: '#64748B',
  },
}
