import { useInfiniteQuery } from '@tanstack/react-query'
import { getAllInstitution, getAllPrograms } from '@app-hero/shared/src/api'

export const useFilter = ({
  queryKey,
  enabled,
  limit,
  filters,
  isInstitution,
}) => {
  const fetchFilterData = async (pageParam) => {
    const payload = {
      filterQuery: {
        size: limit,
        from: pageParam * limit,
        filters: filters,
      },
    }
    const keys = Object.keys(filters)
    if (keys?.length > 0) {
      if (isInstitution) {
        const institutionData = await getAllInstitution(payload)
        return institutionData
      } else {
        const programData = await getAllPrograms(payload)
        return programData
      }
    } else return []
  }

  const {
    data,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [queryKey, filters],
    initialData: [],
    queryFn: ({ pageParam = 0 }) => fetchFilterData(pageParam),
    enabled,
    getNextPageParam: (_lastPage, pages) => {
      const paginatedData = []
      pages?.forEach((page) => {
        if (page?.programList)
          return page?.programList?.map((item) => paginatedData?.push(item))
        return page?.institutions?.map((item) => paginatedData?.push(item))
      })

      if (paginatedData?.length < _lastPage?.total) return pages?.length
    },

    retry: false,
    onError: (error) => {
      console.log(error)
    },
  })
  const allData = (data?.pages || [])
    .flatMap((page) => page?.programList || page?.institutions || [])
    .filter((item) => item !== undefined)
  return {
    allData,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  }
}
