import React, { Suspense, useEffect } from 'react'
import { Linking, View } from 'react-native'
import { SecureStore } from '@libs/utils'
import { useAtom } from 'jotai'
import { useIsFocused } from '@react-navigation/native'
import { useQueryClient } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'
import { notificationSubscriber } from '@app-hero/shared-services'
import { isChatbotVisible, userProfile as userProfileAtom } from '../utils/atom'
import { HomeStackNavigator } from './StackNavigator'
import { navigate, navigateWithParams } from './RootNavigator'
import { addScript, initializeChatbotUser } from '../utils/addScript'
import { FW_CHAT_BOT_URL } from '../config'
import { categorizeActionItems } from '../utils/categorizeActionItems'

const AppNavigator = () => {
  const [canShowChatbot] = useAtom(isChatbotVisible)
  const isFocused = useIsFocused()
  const [userprofile] = useAtom(userProfileAtom)
  const queryClient = useQueryClient()

  useEffect(() => {
    async function handleInitialUrl() {
      // Get the initial URL from the app's deep linking event
      const initialUrl = await Linking.getInitialURL()

      const initialSplittedUrl = initialUrl?.split('?')[1]

      if (initialSplittedUrl?.split('&')[0].includes('email')) {
        const filterList = parseQueryString(initialSplittedUrl)

        const filterData = {
          Institution: {
            values: filterList.brand,
          },
          Level: {
            values: filterList.level,
          },
          Subject: {
            values: filterList.subject,
          },
          Intake: {
            values: filterList.intake,
          },
        }
        await SecureStore.setItemAsync('filters', JSON.stringify(filterData))
        if (filterList.email) {
          await SecureStore.setItemAsync(
            'tempUser',
            JSON.stringify({
              email: filterList.email,
            }),
          )
          navigate('sign-up')
        }
      } else if (
        initialSplittedUrl?.split('&')[0].split('=')[1].startsWith('PreSignUp')
      ) {
        // handle the error
        navigateWithParams('sign-up', { invalidUser: true })
      }
    }

    // Call the function to handle the initial URL when the app loads
    handleInitialUrl()
  }, [])

  // fetch bot connection

  // functions
  function parseQueryString(inputString) {
    const result = {}

    const keyValuePairs = inputString.split('&')

    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split('=')
      const decodedValue = decodeURIComponent(value)

      const newValue = []
      decodedValue.split(',').map((item) => {
        newValue.push(item)
      })
      result[key] = newValue
    })

    return result
  }

  useEffect(() => {
    if (userprofile?.email !== '') {
      const subscribeToNotifications = async () => {
        const user = await Auth.currentAuthenticatedUser().catch(() => null)
        if (!user) return // Exit the block if the user is not authenticated

        const unsubscribe = await notificationSubscriber(
          userprofile?.email,
          async (newNotification) => {
            await queryClient.setQueryData(
              ['notifications', userprofile?.email],
              (prevData) => {
                if (prevData && prevData !== undefined) {
                  const messageIdExists = prevData.items.some((notifications) =>
                    notifications !== null
                      ? notifications.messageDetails?.messageId ===
                        newNotification.onNewNotification.messageDetails
                          ?.messageId
                      : false,
                  )

                  if (!messageIdExists) {
                    if (
                      newNotification?.onNewNotification?.event ===
                        'TASK_CLOSURE' &&
                      newNotification?.onNewNotification?.messageDetails
                        ?.taskClosedBy === 'Agent'
                    ) {
                      global.showToast(
                        `Your agent, <${newNotification?.onNewNotification?.messageDetails?.agentAccountName}>, completed a task on your application. `,
                        { type: 'success', title: 'Tasks Completed' },
                      )
                      queryClient.setQueryData(
                        [
                          `getActionRequiredItems-${newNotification?.onNewNotification?.messageDetails?.opportunityId}`,
                        ],
                        (prevData) => {
                          if (
                            !prevData ||
                            !prevData.ActionRequiredItems ||
                            !prevData.ActionRequiredItems.Items
                          ) {
                            return prevData
                          }

                          // Update the ActionRequiredItems
                          const updatedItems =
                            prevData.ActionRequiredItems.Items.map((task) =>
                              task.Id ===
                              newNotification?.onNewNotification?.messageDetails
                                ?.taskId
                                ? {
                                    ...task,
                                    Status: 'Completed',
                                    IsAgentClosed: true,
                                  }
                                : task,
                            )

                          // Update the pendingActionRequiredItems if they exist
                          const updatedPendingActionRequiredItems =
                            prevData.pendingActionRequiredItems?.map((task) =>
                              task.Id ===
                              newNotification?.onNewNotification?.messageDetails
                                ?.taskId
                                ? {
                                    ...task,
                                    Status: 'Completed',
                                    IsAgentClosed: true,
                                  }
                                : task,
                            ) || []

                          const updatedOpportunityDetail = {
                            ...prevData,
                            ActionRequiredItems: {
                              ...prevData.ActionRequiredItems,
                              Items: updatedItems,
                            },
                            pendingActionRequiredItems:
                              updatedPendingActionRequiredItems,
                          }

                          return categorizeActionItems(updatedOpportunityDetail)
                        },
                      )

                      queryClient.setQueryData(
                        [`fetchOpportunities-${userprofile?.email}`],
                        (prevData) => {
                          const findOpportunityFromOpportunitiesIndex =
                            prevData?.findIndex(
                              (item) =>
                                item?.Id ===
                                newNotification?.onNewNotification
                                  ?.messageDetails?.opportunityId,
                            )

                          const findOpportunityFromOpportunities =
                            prevData?.find(
                              (item) =>
                                item?.Id ===
                                newNotification?.onNewNotification
                                  ?.messageDetails?.opportunityId,
                            )

                          if (
                            !findOpportunityFromOpportunities ||
                            !findOpportunityFromOpportunities.ActionRequiredItems ||
                            !findOpportunityFromOpportunities
                              .ActionRequiredItems.Items
                          ) {
                            return prevData
                          }

                          // Update ActionRequiredItems
                          const updatedItems =
                            findOpportunityFromOpportunities.ActionRequiredItems.Items.map(
                              (task) =>
                                task.Id ===
                                newNotification?.onNewNotification
                                  ?.messageDetails?.taskId
                                  ? {
                                      ...task,
                                      Status: 'Completed',
                                      IsAgentClosed: true,
                                    }
                                  : task,
                            )

                          // Update pendingActionRequiredItems
                          const updatedPendingActionRequiredItems =
                            findOpportunityFromOpportunities.pendingActionRequiredItems?.map(
                              (task) =>
                                task.Id ===
                                newNotification?.onNewNotification
                                  ?.messageDetails?.taskId
                                  ? {
                                      ...task,
                                      Status: 'Completed',
                                      IsAgentClosed: true,
                                    }
                                  : task,
                            )

                          // Create a new array with updated opportunity

                          const updatedOpportunityDetail = {
                            ...findOpportunityFromOpportunities,
                            ActionRequiredItems: {
                              ...findOpportunityFromOpportunities.ActionRequiredItems,
                              Items: updatedItems,
                            },
                            pendingActionRequiredItems:
                              updatedPendingActionRequiredItems,
                          }
                          const updatedOpportunities = [...prevData]
                          updatedOpportunities[
                            findOpportunityFromOpportunitiesIndex
                          ] = categorizeActionItems(updatedOpportunityDetail)

                          return updatedOpportunities
                        },
                      )

                      queryClient.setQueryData(
                        ['getTotalActionRequiredCount'],
                        (prevData) => prevData - 1,
                      )
                    }

                    if (
                      newNotification.onNewNotification.event ===
                        'REVIEW_CENTER_COMMENT' ||
                      newNotification.onNewNotification.event ===
                        'ISSUED_LETTERS'
                    ) {
                      queryClient.setQueryData(
                        ['all-notifications-spotlight', userprofile?.email],
                        (prevSpotlightData) => {
                          if (!prevSpotlightData) {
                            return prevSpotlightData
                          }

                          return {
                            ...prevSpotlightData,
                            items: [
                              newNotification.onNewNotification,
                              ...prevSpotlightData.items,
                            ],
                          }
                        },
                      )
                    }
                    return {
                      ...prevData,
                      unreadCount: !newNotification.onNewNotification.readStatus
                        ? Number(prevData.unreadCount) + 1
                        : prevData.unreadCount,
                      items: [
                        newNotification.onNewNotification,
                        ...prevData.items,
                      ],
                    }
                  }
                }

                return prevData
              },
            )
          },
        )

        return () => {
          unsubscribe && unsubscribe.unsubscribe()
        }
      }

      subscribeToNotifications()
    }
  }, [userprofile, queryClient])

  useEffect(() => {
    const userProfile = JSON.parse(localStorage.getItem('userProfile'))

    if (userProfile?.email && canShowChatbot && isFocused) {
      const dynamicChatBotUrl = FW_CHAT_BOT_URL(userProfile)
      addScript(dynamicChatBotUrl, () => {
        initializeChatbotUser({
          email: userProfile?.email,
          firstName: userProfile?.firstName,
          lastName: userProfile?.lastName,
          externalId: userProfile?.email,
          phoneNumber: userProfile?.phoneNumber,
          appherotoken: userProfile?.accessToken,
          properties: {
            plan: 'Pro',
            status: 'Active',
          },
        })
      })
    }
  }, [canShowChatbot, isFocused])

  return (
    <Suspense>
      <View style={{ width: '100%', flex: 1, overflow: 'hidden' }}>
        <HomeStackNavigator />
      </View>
    </Suspense>
  )
}

export default AppNavigator
