import { useState, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'

import { userProfile } from '@app-hero/shared/src/utils/atom'
import { fetchWebinarEvents } from '@app-hero/shared/src/api'

export const useUpcomingEvents = () => {
  const [userprofile] = useAtom(userProfile)
  const [currentPage, setCurrentPage] = useState(1)

  const { data, isLoading, isError, isFetching } = useQuery({
    queryKey: ['webinar-events', userprofile?.email],
    queryFn: () =>
      fetchWebinarEvents({ email: userprofile.email, isTaskRequired: false }),
    enabled: !!userprofile?.email,
  })

  const itemsPerPage = 1

  const paginatedEvents = useMemo(() => {
    if (isLoading && !data?.items && isFetching) return []
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return data?.items?.slice(startIndex, endIndex)[0]
  }, [data, currentPage, itemsPerPage, isLoading, isFetching])

  const totalPages = useMemo(() => {
    if (!data || !data.total) return 0
    return Math.ceil(data.items.length / itemsPerPage)
  }, [data, itemsPerPage])

  // Next page function
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  // Previous page function
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }

  return {
    events: paginatedEvents,
    isLoading,
    isError,
    isFetchingNextPage: false, // Always false as we're not fetching from backend
    totalNotifications: data?.total || 0,
    hasEventNextPage: currentPage < totalPages,
    hasEventPrevPage: currentPage > 1, // Check if there's a previous page
    handleEventNextPage: nextPage,
    handleEventPrevPage: prevPage, // Return the prevPage function
    currentEventPage: currentPage,
    totalEventPages: totalPages,
  }
}
