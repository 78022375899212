import { getScreenName, screenUseCaseMappings } from '@app-hero/shared/src/api'
import { API, Auth } from 'aws-amplify'

export const apiCall = async (endPointName, query, variables) => {
  const endPoint = API._options.API.endpoints.find(
    (e) => e.name === endPointName,
  ).endpoint
  const user = await Auth.currentAuthenticatedUser()
  const token = user.signInUserSession.accessToken.jwtToken
  let headers = {
    Authorization: token,
    'x-screen-name': screenUseCaseMappings[getScreenName()],
  }

  if (!token) {
    headers = {}
  }

  API._graphqlApi._options.aws_appsync_authenticationType = token
    ? 'AMAZON_COGNITO_USER_POOLS'
    : ''

  API._graphqlApi._options.aws_appsync_graphqlEndpoint = `${endPoint}`

  try {
    const response = await API.post(endPointName, '', {
      headers,
      body: {
        query,
        variables,
      },
    })
      .then((res) => res)
      .catch((error) => error.response)

    return response
  } catch (error) {}
}
