import React, { useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  ScrollView,
  TouchableOpacity,
  Platform,
} from 'react-native'
import { Icon } from '@app-hero/native-icons'
import { useNavigation } from '@react-navigation/native'
import {
  Medal,
  Downtown,
  Employability,
  ProgrammeDetailsBg,
} from '@apphero/assets'
import {
  Badge,
  Button,
  CheckBox,
  Loader,
  Text,
  WebView,
} from '@libs/components'
import { useTranslation } from 'react-i18next'
import { PricingCard, ProgramCard } from '../../components'
import { BasketAlertBox } from '../../components/basketAlert'
import { useTheme } from '@libs/theme'

const DesktopView = ({
  brandLogoUrl,
  setAlertDetails,
  alertDetails,
  isBasketLoading,
  isCompareProgrammesIdUpdate,
  handleAddToComparison,
  heroContainerDescriptionList,
  handleNavigation,
  showBrandName,
  setShowBrandName,
  intakeData,
  isLoading,
  programId,
  programIds,
  programmes,
  recommendedProgram,
  tabData,
  updateCartItems,
  updateCompareProgrammesIds,
  brand,
}) => {
  const { colors } = useTheme()
  const navigation = useNavigation()
  const { t } = useTranslation()

  return (
    <View style={{ flex: 1 }}>
      {isLoading ? (
        <Loader visible={isLoading} />
      ) : (
        <>
          <ScrollView
            style={{ flex: 1, backgroundColor: 'white', position: 'relative' }}
            contentContainerStyle={{ paddingVertical: 60 }}
          >
            <View style={styles.container}>
              <View style={styles.programmeContainer}>
                <View style={styles.courseContainer}>
                  <View style={{ flexDirection: 'row', flex: 1 }}>
                    <TouchableOpacity
                      style={styles.icon}
                      onPress={() => {
                        if (Platform.OS === 'web') {
                          window.history.go(-1)
                        } else {
                          navigation.goBack()
                        }
                      }}
                    >
                      <Icon name="Reply" width={20} height={25} />
                    </TouchableOpacity>
                    <View style={styles.titleContainer}>
                      <Text variant="heading3" color={colors.oNeutral}>
                        {programmes?.name}
                      </Text>
                      <Text
                        style={styles.courseDesc}
                        variant="display3"
                        color={colors.text}
                      >
                        {programmes?.description}
                      </Text>
                      <View style={{ flexDirection: 'row' }}>
                        <View
                          style={{
                            maxWidth: '40%',
                            flexDirection: 'column',
                            height: 80,
                          }}
                        >
                          {heroContainerDescriptionList.map((content) => {
                            return (
                              <View style={{ height: 34, marginBottom: 20 }}>
                                <Text variant="display4">{content?.label}</Text>
                              </View>
                            )
                          })}
                        </View>
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                          {heroContainerDescriptionList.map((content) => {
                            return (
                              <View style={{ height: 34, marginBottom: 20 }}>
                                <HeroContainerDescriptionContainer
                                  data={content}
                                  brandLogoUrl={brandLogoUrl}
                                  showBrandName={showBrandName}
                                  setShowBrandName={setShowBrandName}
                                  programmes={programmes}
                                />
                              </View>
                            )
                          })}
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {programmes?.uspVideoLink ? (
                        <WebView
                          url={programmes?.uspVideoLink}
                          id="video"
                          type="youtube"
                          style={styles.videoContent}
                        />
                      ) : (
                        <Image
                          source={ProgrammeDetailsBg}
                          style={styles.videoContent}
                        />
                      )}
                    </View>
                  </View>
                </View>
                <View style={styles.courseOverview}>
                  <View style={styles.overviewItem}>
                    <Image source={Medal} style={styles.medal} />
                    <Text variant="heading5">{programmes?.usp1Title}</Text>
                    <Text variant="display3" style={styles.itemText}>
                      {programmes?.usp1Description}
                    </Text>
                  </View>
                  <View style={styles.overviewItem}>
                    <Image source={Downtown} style={styles.downtown} />
                    <Text variant="heading5">{programmes?.usp2Title}</Text>
                    <Text variant="display3" style={styles.itemText}>
                      {programmes?.usp2Description}
                    </Text>
                  </View>
                  <View style={styles.overviewItem}>
                    <Image
                      source={Employability}
                      style={styles.employability}
                    />
                    <Text variant="heading5">{programmes?.usp3Title}</Text>
                    <Text variant="display3" style={styles.itemText}>
                      {programmes?.usp3Description}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.priceContainer}>
                <View styles={styles.priceSide}>
                  <Text variant="heading3" color={colors.oNeutral}>
                    {t('PROGRAM_SCREEN.PROGRAM_PRICE')}
                  </Text>
                </View>

                <View style={styles.subContainer}>
                  <Text color={colors.text}>
                    {t('PROGRAM_SCREEN.PROGRAM_PRICE_DESCRIPTION')}
                  </Text>
                  <TabSection tabData={tabData} intakeData={intakeData} />
                </View>
              </View>

              {recommendedProgram?.length > 0 ? (
                <View style={styles.recommendCont}>
                  <Text
                    variant="display1"
                    color={colors.oNeutral}
                    style={{ fontWeight: 700 }}
                  >
                    {t('PROGRAM_SCREEN.RECOMMENDED_PROGRAM')}
                  </Text>
                  <View style={{ flexDirection: 'row', marginTop: 40 }}>
                    {recommendedProgram?.map((item) => (
                      <ProgramCard
                        data={item}
                        style={{
                          width: '30%',
                          margin: 0,
                          marginRight: 40,
                        }}
                        handlePrimaryButton={(id, brand) => {
                          navigation.push('programme-detail', { id, brand })
                        }}
                        handleSecondaryButton={(id) =>
                          navigation.navigate('apply-programmes', { id })
                        }
                        brandLogo={`${brandLogoUrl}/${item.brand?.replaceAll(
                          ' ',
                          '_',
                        )}.png`}
                        isCompareProgrammesIdUpdate={
                          isCompareProgrammesIdUpdate
                        }
                        updateCompareProgrammesIds={updateCompareProgrammesIds}
                      />
                    ))}
                  </View>
                </View>
              ) : null}
            </View>
          </ScrollView>
          <View style={styles.footer}>
            {isBasketLoading ? (
              <Loader
                size={20}
                hasFlexValue={false}
                style={{ marginRight: 30 }}
              />
            ) : (
              <Button
                label={t('BUTTON.ADD_TO_APPLICATION')}
                buttonStyle={{ width: 200, marginRight: 50 }}
                onPress={() => updateCartItems({ programDetails: programmes })}
              />
            )}
            <CheckBox
              label={t('PROGRAM_SCREEN.ADD_TO_COMPARISON')}
              style={{ width: 200 }}
              handleCheck={handleAddToComparison}
              programId={`${brand}_${programId}`}
              selectedProgrammes={programIds}
            />
          </View>
          <BasketAlertBox
            isVisible={alertDetails?.isVisible}
            handleCloseModal={() => {
              setAlertDetails({ isVisible: false, type: '' })
            }}
            type={alertDetails?.type}
          />
        </>
      )}
    </View>
  )
}

const TabSection = ({ intakeData, tabData }) => {
  const [activeTab, setActiveTab] = useState(0)
  const activeData = intakeData?.[activeTab]
  return (
    <View style={{ marginBottom: 60 }}>
      <Tab
        activeTab={activeTab}
        tabData={tabData}
        setActiveTab={setActiveTab}
      />
      <ScrollView
        style={{
          flexDirection: 'row',
        }}
        contentContainerStyle={{
          justifyContent: 'space-evenly',
          paddingLeft: 10,
          paddingBottom: 30,
        }}
        horizontal
        showsHorizontalScrollIndicator={false}
      >
        {Object.entries(activeData || {})?.map(([_, value]) =>
          Object.entries(value)?.map(([key, intakeItem], index) => (
            <PricingCard
              pricingData={intakeItem}
              key={key}
              index={index}
              style={{ width: '100%', marginRight: 15 }}
            />
          )),
        )}
      </ScrollView>
    </View>
  )
}

const Tab = ({
  tabData,
  activeTab,
  setActiveTab,
  marginTop = 50,
  disabledFieldIndex = -1,
}) => {
  const { colors } = useTheme()
  return (
    <View style={{ marginTop }}>
      <ScrollView
        style={{
          flexDirection: 'row',
        }}
        contentContainerStyle={{ alignItems: 'center' }}
        horizontal
        showsHorizontalScrollIndicator={false}
      >
        {tabData?.map((item, index) => (
          <TouchableOpacity
            style={[
              activeTab === index ? styles.activeTab : styles.unActiveTab,
              {
                marginRight: 30,
                paddingBottom: 10,
              },
            ]}
            onPress={() => index !== disabledFieldIndex && setActiveTab(index)}
            key={item}
          >
            <Text
              style={{
                fontSize: 15,
                fontWeight: activeTab === index ? 700 : 400,
                color:
                  activeTab === index ? colors.oNeutral : colors.textSecondary,
                marginLeft: 8,
              }}
            >
              {item}
            </Text>
          </TouchableOpacity>
        ))}
      </ScrollView>
      <View
        style={{
          width: '100%',
          height: 1,
          backgroundColor: '#757575',
          opacity: 0.2,
          marginBottom: 30,
        }}
      />
    </View>
  )
}

const HeroContainerDescriptionContainer = ({
  brandLogoUrl,
  data,
  showBrandName,
  setShowBrandName,
  programmes,
}) => {
  const { colors } = useTheme()
  if (data.type === 'image') {
    return (
      <>
        {!showBrandName ? (
          <Image
            source={`${brandLogoUrl}/${programmes.brand?.replaceAll(
              ' ',
              '_',
            )}.png`}
            style={{
              height: 34,
              width: 170,
            }}
            alt={programmes?.brand}
            resizeMode="contain"
            onError={(error) => {
              setShowBrandName(true)
            }}
          />
        ) : (
          <Text variant="heading5" color={colors.neutral}>
            {programmes?.brandFullName}
          </Text>
        )}
      </>
    )
  }
  if (data.type === 'badge') {
    return (
      <>
        {programmes?.[data.fieldName]?.length > 0 ? (
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '95%',
            }}
          >
            {programmes?.[data.fieldName]?.map((item, index) =>
              item ? (
                <Badge theme="blue" key={index}>
                  {item}
                </Badge>
              ) : null,
            )}
          </View>
        ) : (
          <Text variant="display4" style={styles.descText}>
            -
          </Text>
        )}
      </>
    )
  }
  if (data.type === 'text') {
    return (
      <Text variant="display3" color={colors.text} style={{ marginLeft: 10 }}>
        {programmes?.[data.fieldName] || '-'}
      </Text>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingRight: 40,
    paddingLeft: 40,
  },
  videoContent: {
    height: 350,
    width: 400,
    borderRadius: 10,
    marginRight: 40,
    border: 'none',
    shadowColor: 'rgba(3, 30, 125, 0.05)',
    shadowOffset: { width: 0, height: 2 },
    elevation: 5,
    shadowOpacity: 1,
    shadowRadius: 2,
  },
  titleContainer: {
    width: 522,
  },
  courseTitle: {
    fontWeight: 600,
    color: '#043C8F',
    fontSize: 24,
  },
  courseContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    marginBottom: 60,
  },
  icon: {
    marginRight: 18,
  },
  courseDesc: {
    marginTop: 15,
    marginBottom: 40,
  },
  courseSubDesc: {
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  courseSubDesc2: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  descText: {
    marginRight: 15,
  },
  courseOverview: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    flex: 1,
    backgroundColor: '#ECF2FC',
    borderRadius: 20,
    paddingLeft: 100,
    paddingRight: 110,
  },
  overviewImage: {
    width: 70,
    height: 70,
  },
  medal: {
    width: 45,
    height: 66,
    marginBottom: 20,
  },
  downtown: {
    width: 68,
    height: 66,
    marginBottom: 20,
  },
  employability: {
    width: 57,
    height: 60,
    marginBottom: 20,
  },
  overviewItem: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingVertical: 80,
    width: 260,
  },
  itemText: {
    marginTop: 15,
    textAlign: 'center',
  },
  priceContainer: {
    flexDirection: 'row',
    marginTop: 78,
    paddingLeft: 38,
  },
  subContainer: {
    flex: 1,
    flexDirection: 'column',
    // height: 498,
    marginLeft: 50,
    paddingLeft: 80,
    paddingRight: 40,
  },
  priceSide: {
    width: 271,
    height: 34,
  },
  recommendCont: {
    backgroundColor: '#ECF2FC',
    borderRadius: 20,
    paddingLeft: 80,
    paddingRight: 80,
    paddingTop: 50,
    paddingBottom: 60,
  },
  activeTab: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderStyle: 'solid',
    borderColor: '#424242',
  },
  unActiveTab: {
    flexDirection: 'row',
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    backgroundColor: 'white',
    shadowOffset: { width: 0, height: -5 },
    shadowRadius: 20,
    shadowColor: 'rgba(3, 30, 125, 0.15)',
    shadowOpacity: 1,
    elevation: 2,
  },
})

export default DesktopView
