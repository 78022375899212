import React from 'react'
import { View } from 'react-native'
import { OutstandingRequiredAction } from './outstandingRequiredActionCard'
import { MyApplication } from '../myApplicationCard'
import { CompletedRequiredAction } from './completedRequiredActionCard'

export const ActionRequired = ({
  opportunityDetails,
  handleSelectedOpportunity,
  handleSubmitAllOpportunityTaskFile,
  isActionRequiredScreen = false,
  isSubmitLoading,
}) => {
  // Assuming `opportunityDetails` is derived from opportunities

  // Check if there are any pending or completed action items based on the condition
  const hasActionItems =
    opportunityDetails?.pendingActionRequiredItems?.length > 0 ||
    (isActionRequiredScreen &&
      opportunityDetails?.completedActionRequiredItems?.length > 0)

  // Conditionally render if there are action items
  if (!hasActionItems) {
    return null
  }

  return (
    <View style={{ gap: 20 }}>
      <View
        style={{
          backgroundColor: 'white',
          gap: window.innerWidth >= 650 ? 20 : 24,
          paddingHorizontal: 24,
          paddingVertical: window.innerWidth <= 1023 ? 24 : 32,
          borderRadius: 10,
        }}
        key={opportunityDetails?.Id}
      >
        <MyApplication
          data={opportunityDetails}
          style={{ padding: 0 }}
          disabled
        />
        {window.innerWidth <= 650 ? (
          <View
            style={{
              borderBottomWidth: 1,
              borderColor: 'rgba(0,0,0,0.1)',
              borderStyle: 'solid',
            }}
          />
        ) : null}

        <View style={{ gap: 8 }}>
          <OutstandingRequiredAction
            data={opportunityDetails}
            businessUnitFilter={opportunityDetails?.BusinessUnitFilter__c}
            handleSelectedOpportunity={handleSelectedOpportunity}
            handleSubmitAllOpportunityTaskFile={
              handleSubmitAllOpportunityTaskFile
            }
            isSubmitLoading={isSubmitLoading}
          />
          {window.innerWidth <= 650 ? (
            <View
              style={{
                borderBottomWidth: 1,
                borderColor: 'rgba(0,0,0,0.1)',
                borderStyle: 'solid',
                marginVertical: 16,
              }}
            />
          ) : null}
          <CompletedRequiredAction data={opportunityDetails} />
        </View>
      </View>
    </View>
  )
}
