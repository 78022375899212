import React, { useState } from 'react'
import { Pressable, View } from 'react-native'
import { Icon } from '@app-hero/native-icons'
import { t } from 'i18next'
import { useAtom } from 'jotai'
import {
  previewTaskDocument,
  deleteTaskDocument,
  downloadTaskDocument,
} from '@app-hero/shared-services'
import { useQueryClient } from '@tanstack/react-query'
import { Loader, Text } from '@libs/components'
import { handleNavigation, isValidDocumentUrl } from '../../utils/function'
import { userProfile } from '../../utils/atom'
import BottomSheet from '../Drawer'

export const DocumentActionListMobile = ({
  iconNames,
  taskDetails,
  businessUnitFilter,
  programmeName,
  visible,
  setVisible,
}) => {
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)
  const [userprofile] = useAtom(userProfile)

  const handleAction = async (item) => {
    setIsLoading((prev) => ({ ...prev, [item]: true }))

    if (item === 'DeleteIcon') {
      const payload = {
        taskId: taskDetails?.Id,
        opportunityId: taskDetails?.WhatId,
        BusinessUnitFilter__c: businessUnitFilter,
        objectKey: taskDetails?.s3ObjectKey,
      }

      const deleteResponse = await deleteTaskDocument(payload)

      if (deleteResponse.ok) {
        await queryClient.setQueryData(
          [`getActionRequiredItems-${taskDetails?.WhatId}`],
          (prevData) => {
            if (
              !prevData ||
              !prevData.ActionRequiredItems ||
              !prevData.ActionRequiredItems.Items
            ) {
              return prevData
            }

            const updatedItems = prevData?.ActionRequiredItems?.Items?.map(
              (task) => {
                if (task.Id === taskDetails?.Id) {
                  return {
                    ...task,
                    isDocumentUploaded: false,
                  }
                }
                return task
              },
            )

            const updatedPendingActionRequiredItems =
              prevData?.pendingActionRequiredItems?.map((task) => {
                if (task.Id === taskDetails?.Id) {
                  return {
                    ...task,
                    isDocumentUploaded: false,
                  }
                }
                return task
              })

            return {
              ...prevData,
              ActionRequiredItems: {
                ...prevData.ActionRequiredItems,
                Items: updatedItems,
              },
              pendingActionRequiredItems: updatedPendingActionRequiredItems,
            }
          },
        )
        await queryClient.setQueryData(
          [`fetchOpportunities-${userprofile?.email}`],
          (prevData) => {
            const findOpportunityFromOpportunities = prevData?.find(
              (data) => data?.Id === taskDetails?.WhatId,
            )
            const findOpportunityFromOpportunitiesIndex = prevData?.findIndex(
              (data) => data?.Id === taskDetails?.WhatId,
            )

            if (
              !findOpportunityFromOpportunities ||
              !findOpportunityFromOpportunities.ActionRequiredItems ||
              !findOpportunityFromOpportunities.ActionRequiredItems.Items
            ) {
              return prevData
            }

            // Update ActionRequiredItems
            const updatedItems =
              findOpportunityFromOpportunities?.ActionRequiredItems?.Items?.map(
                (task) => {
                  if (task.Id === taskDetails?.Id) {
                    return {
                      ...task,
                      isDocumentUploaded: false,
                    }
                  }
                  return task
                },
              )

            // Update pendingActionRequiredItems
            const updatedPendingActionRequiredItems =
              findOpportunityFromOpportunities?.pendingActionRequiredItems?.map(
                (task) => {
                  if (task.Id === taskDetails?.Id) {
                    return {
                      ...task,
                      isDocumentUploaded: false,
                    }
                  }
                  return task
                },
              )

            // Create a new array with updated opportunity
            const updatedOpportunities = [...prevData]
            updatedOpportunities[findOpportunityFromOpportunitiesIndex] = {
              ...findOpportunityFromOpportunities,
              ActionRequiredItems: {
                ...findOpportunityFromOpportunities.ActionRequiredItems,
                Items: updatedItems,
              },
              pendingActionRequiredItems: updatedPendingActionRequiredItems,
            }

            return updatedOpportunities
          },
        )

        global.showToast(
          `The document was successfully deleted for <${programmeName}>`,
          {
            type: 'success',
          },
        )
      } else {
        global.showToast(
          `Unable to delete the document for <${programmeName}>. Please try again later.`,
          { type: 'error' },
        )
      }
    } else if (item === 'Eye') {
      if (!taskDetails?.s3ObjectKey) {
        global.showToast(
          'The document is not available for preview right now. Please check back later.',
          { type: 'error' },
        )
        setIsLoading((prev) => ({ ...prev, [item]: false }))
        setVisible(false)
        return
      }

      const response = await previewTaskDocument(taskDetails?.s3ObjectKey)

      if (response?.signedUrl && isValidDocumentUrl(response?.signedUrl)) {
        handleNavigation({
          url: response.signedUrl,
          fileName: '',
          translation: t,
        })
      } else {
        global.showToast(
          'We couldn’t load the document. Please try again later.',
          {
            type: 'error',
          },
        )
      }
    } else if (item === 'Download') {
      if (!taskDetails?.s3ObjectKey) {
        global.showToast(
          'Sorry, the document is currently unavailable for download.',
          {
            type: 'error',
          },
        )
        setIsLoading(false)
        setVisible(false)
        return
      }
      const response = await downloadTaskDocument(taskDetails?.s3ObjectKey)
      if (response?.signedUrl && isValidDocumentUrl(response?.signedUrl)) {
        const link = document.createElement('a')
        link.href = response.signedUrl
        link.download = taskDetails?.s3ObjectKey
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        global.showToast('The document was downloaded successfully.', {
          type: 'success',
        })
      } else {
        global.showToast(
          'We couldn’t download the document. Please try again later.',
          {
            type: 'error',
          },
        )
      }
    }
    setIsLoading(false)
    setVisible(false)
  }

  return (
    <BottomSheet
      triggerComponent={<Icon name="DotsVertical" width={20} height={20} />}
      drawerHeight={iconNames.length === 3 ? 235 : 185}
      isVisible={visible}
      setIsVisible={setVisible}
    >
      <View
        style={{
          borderWidth: 2,
          borderColor: 'rgba(212, 212, 216, 1)',
          borderStyle: 'solid',
          marginHorizontal: 80,
          borderRadius: 3.5,
          marginBottom: 15,
        }}
      />
      <View style={{ gap: 14, paddingHorizontal: 5, paddingTop: 24 }}>
        {iconNames?.map((item, index) => (
          <>
            {isLoading[item] ? (
              <Loader
                size={20}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  marginLeft: 12,
                }}
              />
            ) : (
              <Pressable
                onPress={async () => {
                  await handleAction(item)
                }}
              >
                <Text variant="display3" color="#545B61">
                  {t(`ACTION_REQUIRED.${item.toUpperCase()}`)}
                </Text>
              </Pressable>
            )}

            {iconNames.length - 1 !== index && (
              <View
                style={{
                  borderWidth: 0.2,
                  borderColor: 'rgba(212, 212, 216, 1)',
                  borderStyle: 'solid',
                  marginVertical: 6,
                }}
              />
            )}
          </>
        ))}
      </View>
    </BottomSheet>
  )
}
