import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from '@libs/components'
import { Icon } from '@app-hero/native-icons'

const SummaryCard = ({ iconName, count, label, colors }) => (
  <View style={styles.summaryCard(colors)}>
    <View style={styles.iconWrapper}>
      <Icon name={iconName} height={26} width={26} />
    </View>
    <View style={{ flexWrap: 'wrap', flex: 1 }}>
      <Text variant="heading3" style={styles.boldText}>
        {count}
      </Text>
      <Text
        variant={window.innerWidth <= 1023 ? 'display4' : 'display5'}
        color={colors.neutral}
        style={{ textTransform: 'uppercase' }}
      >
        {label}
      </Text>
    </View>
  </View>
)

const styles = StyleSheet.create({
  summaryCard: (colors) => ({
    backgroundColor: colors.white,
    padding: 38,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 24,
    flex: 1,
  }),
  iconWrapper: {
    backgroundColor: '#F4F5FB',
    padding: 8,
    borderRadius: 38,
  },
  boldText: {
    fontWeight: '800',
  },
})

export default SummaryCard
