import React from 'react'
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native'
import { DefaultCoBranding, PlayButton } from '@apphero/assets'
import { useTheme } from '@libs/theme'
import { useBrandImage } from '@app-hero/shared-hooks'
import { useAtom } from 'jotai'
import { Text } from '@libs/components'
import { t } from 'i18next'
import { brandDetailsAtom } from '../../utils/atom'
import WelcomeCardMobile from './welcome.mobile'

const WelcomeCard = ({ name, handleOpenVideo }) => {
  const [brandDetails] = useAtom(brandDetailsAtom)

  const coBrandingImage = useBrandImage(brandDetails?.brand)
  const { colors } = useTheme()

  if (window.innerWidth <= 1023) {
    return (
      <WelcomeCardMobile
        name={name}
        handleOpenVideo={handleOpenVideo}
        coBrandingImage={coBrandingImage}
      />
    )
  }

  return (
    <View
      style={[
        styles.contentContainer,
        {
          flexDirection: 'row',
          backgroundColor: colors.teritiary,
          borderRadius: 10,
          overflow: 'hidden',
        },
      ]}
    >
      <View
        style={{
          paddingLeft: window.innerWidth >= 1024 ? 40 : 24,
          paddingVertical: window.innerWidth >= 1024 ? 46 : 24,
          gap: 79,
          flex: 1,
        }}
      >
        <View style={{ gap: 8 }}>
          <Text style={[styles.userContainer, { fontWeight: 400 }]}>
            Welcome,{' '}
            <Text style={[styles.userContainer, { fontWeight: 700 }]}>
              {name ? `${name}!` : ''}
            </Text>
          </Text>
          <Text
            style={{
              color: 'white',
              flexWrap: 'wrap',
              height: 0,
            }}
            variant="display3"
          >
            Track Every Step of Your Study Abroad Dream
          </Text>
        </View>
        <TouchableOpacity
          style={{ flexDirection: 'row', alignItems: 'center', gap: 20 }}
          onPress={() => handleOpenVideo()}
        >
          <Image
            source={PlayButton}
            style={{ width: 50, height: 45 }}
            resizeMode="cover"
          />

          <Text variant="heading5" color={colors.white}>
            {t('DASHBOARD.LEARN_MORE')}
          </Text>
        </TouchableOpacity>
      </View>
      <View
        style={{
          width: '55.7%',
          height: '100%',
          right: 20,
          borderRadius: 16,
          bottom: 0,
        }}
      >
        <Image
          source={coBrandingImage}
          style={{
            height: '100%',
            width: '100%',
            borderBottomEndRadius: 16,
          }}
          resizeMode="contain"
        />
      </View>
    </View>
  )
}
export default WelcomeCard

const styles = StyleSheet.create({
  mainContainer: {
    borderRadius: 16,
    position: 'relative',
    paddingVertical: 20,
  },
  userContainer: {
    color: 'white',
    fontSize: 32,
  },
})
