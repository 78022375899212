import {
  UCW_CO_BRANDING,
  BERLIN_SCHOOL_CO_BRANDING,
  CCTB_CO_BRANDING,
  GIH_CO_BRANDING,
  HZU_CO_BRANDING,
  IBAT_CO_BRANDING,
  IBS_CO_BRANDING,
  LIM_COLLEGE_CO_BRANDING,
  TIC_CO_BRANDING,
  TLGCA_CO_BRANDING,
  TSOM_CO_BRANDING,
  UEG_CO_BRANDING,
  UNFC_CO_BRANDING,
  WEBSTER_CO_BRANDING,
  AVI_CO_BRANDING,
  DefaultCoBranding,
  CCU_CO_BRANDING,
  CUC_CO_BRANDING,
  LSBFSG_CO_BRANDING,
  UCA_CO_BRANDING,
  UOG_CO_BRANDING,
  ULAW_CO_BRANDING,
} from '@apphero/assets'

export const useBrandImage = (brand) => {
  const brandImages = {
    AVI: AVI_CO_BRANDING,
    'Berlin School of Business and Innovation': BERLIN_SCHOOL_CO_BRANDING,
    CCTB: CCTB_CO_BRANDING,
    CCU: CCU_CO_BRANDING,
    CUC: CUC_CO_BRANDING,
    GIH: GIH_CO_BRANDING,
    HZU: HZU_CO_BRANDING,
    IBAT: IBAT_CO_BRANDING,
    IBS: IBS_CO_BRANDING,
    'LIM College': LIM_COLLEGE_CO_BRANDING,
    'London School of Business and Finance': LSBFSG_CO_BRANDING,
    LSBFSG: LSBFSG_CO_BRANDING,
    TIC: TIC_CO_BRANDING,
    TLGCA: TLGCA_CO_BRANDING,
    TSOM: TSOM_CO_BRANDING,
    UCA: UCA_CO_BRANDING,
    UCW: UCW_CO_BRANDING,
    UEG: UEG_CO_BRANDING,
    'The University of Law': ULAW_CO_BRANDING,
    UNFC: UNFC_CO_BRANDING,
    UOG: UOG_CO_BRANDING,
    Webster: WEBSTER_CO_BRANDING,
  }

  return brandImages[brand] || DefaultCoBranding
}
