import React from 'react'
import { View } from 'react-native'
import { Icon } from '@app-hero/native-icons'

export const AlertBox = ({
  iconName,
  children,
  iconSize,
  iconColor,
  style,
}) => (
  <View style={style}>
    <Icon
      name={iconName}
      width={iconSize}
      height={iconSize}
      color={iconColor}
    />
    {children}
  </View>
)
