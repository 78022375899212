import {
  deleteOpportunityTaskFile,
  downloadOpportunityTaskDocument,
  previewOpportunityTaskDocument,
} from '@app-hero/shared/src/api'

export const previewTaskDocument = async (objectKey) =>
  previewOpportunityTaskDocument(objectKey)

export const downloadTaskDocument = async (objectKey) =>
  downloadOpportunityTaskDocument(objectKey)

export const deleteTaskDocument = async (payload) =>
  deleteOpportunityTaskFile(payload)
