import { apiCall } from './apicall'
import { UPDATE_NOTIFICATION_STATUS } from './mutation'
import { GET_NOTIFICATIONS_QUERY } from './query'

export const getNotificationByEmail = async (payload) => {
  const { data } = await apiCall('graphql', GET_NOTIFICATIONS_QUERY, {
    ...payload,
  })
  return data.getInAppNotificationByEmail
}

export const updateNotificationStatus = async (notificationItem) => {
  const {
    createdAt,
    email,
    messageDetails: { messageId },
  } = notificationItem
  const { data } = await apiCall('graphql', UPDATE_NOTIFICATION_STATUS, {
    input: {
      email,
      readStatus: true,
      messageId,
      createdAt,
    },
  })
  return data
}
