import { CheckBox, Loader, Text } from '@libs/components'

import { View, StyleSheet, TouchableOpacity } from 'react-native'
import React, { useState } from 'react'
import { Icon } from '@app-hero/native-icons'
import { DateforComments as dateFormat } from '@libs/utils'
import { DocumentActionList } from './documentActionList'
import BottomSheet from '../Drawer'

export const DocumentTaskListMobile = ({
  item,
  iconNames,
  status,
  handleSelectedOpportunity,
  activeCheckboxColor,
  businessUnitFilter,
  isCompletedActionRequiredItems,
  programmeName,
  visible,
  setVisible,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <View
      key={item?.Id}
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <CheckBox
        style={{ alignSelf: 'baseline', padding: 0 }}
        disable
        value={status}
        activeCheckboxColor={activeCheckboxColor}
      />
      <View style={{ gap: 5, justifyContent: 'flex-start', flex: 1 }}>
        <Text variant="display5" color="#545B61">
          {dateFormat(item?.CreatedDate)}
        </Text>
        <Text variant="display4" color="#131E1D" style={{ paddingRight: 20 }}>
          {item.IsAgentClosed ? (
            <Text variant="display5" style={{ fontWeight: 700 }}>
              Completed by the Agent -{' '}
            </Text>
          ) : null}
          {item.IsAgentCancelled ? (
            <Text variant="display5" style={{ fontWeight: 700 }}>
              Cancelled by the Agent -{' '}
            </Text>
          ) : null}
          {item?.IsDirectSalesClosed ? (
            <Text variant="display5" style={{ fontWeight: 700 }}>
              Closed by Advisor -{' '}
            </Text>
          ) : null}
          {item?.comment}
        </Text>
      </View>

      <View style={{ alignSelf: 'baseline' }}>
        {isCompletedActionRequiredItems ? (
          <DocumentActionList
            iconNames={iconNames}
            taskDetails={item}
            businessUnitFilter={businessUnitFilter}
            programmeName={programmeName}
          />
        ) : (
          <BottomSheet
            triggerComponent={
              <Icon name="DotsVertical" width={20} height={20} />
            }
            drawerHeight={100}
            onClose={() => {}}
            isVisible={visible}
            setIsVisible={setVisible}
          >
            <View>
              <View style={styles.topdivider} />
              <TouchableOpacity
                onPress={async () => {
                  setIsLoading(true)
                  await handleSelectedOpportunity(item)
                  setVisible(false)
                  setIsLoading(false)
                }}
                style={{ paddingVertical: 12 }}
              >
                {isLoading ? (
                  <Loader
                    size={20}
                    style={{ alignSelf: 'baseline', marginLeft: 40 }}
                  />
                ) : (
                  <Text variant="display3" color="#545B61">
                    Upload File
                  </Text>
                )}
              </TouchableOpacity>
            </View>
          </BottomSheet>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  uploadButtonStyleFilled: (colors, isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 21 : 20,
    paddingVertical: 10,
    backgroundColor: colors.primary,
  }),
  uploadButtonLabelStyle: (colors) => ({
    color: colors.white,
    fontWeight: '700',
    textTransform: 'uppercase',
    fontSize: 12,
  }),
  topdivider: {
    borderWidth: 2,
    borderColor: 'rgba(212, 212, 216, 1)',
    borderStyle: 'solid',
    marginHorizontal: 80,
    borderRadius: 3.5,
    marginBottom: 15,
  },
})
