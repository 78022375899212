import { View } from 'react-native'
import React from 'react'
import Text from '../Text/Text'

const Badge = (props) => {
  const { theme = 'blue', children, key, style, textVariant } = props

  const getTextStyle = () => {
    if (theme === 'grey') {
      return { backgroundColor: '#ECF2FC', color: '#424242' }
    }
    if (theme === 'blue') {
      return { backgroundColor: '#B6CFF3', color: '#0A3E76' }
    }
    if (theme === 'red') {
      return { backgroundColor: '#FFD7D7', color: '#D72C2C' }
    }
    if (theme === 'green') {
      return { backgroundColor: '#DFFAC4', color: '#065F46' }
    }
    if (theme === 'DarkGreen') {
      return { backgroundColor: '#88C528', color: '#FFFFFF' }
    }
    if (theme === 'yellow') {
      return { backgroundColor: '#FFF4E0', color: '#D4691B' }
    }
  }

  if (typeof children === 'string') {
    return (
      <Text
        style={[
          getTextStyle(),
          {
            alignSelf: 'baseline',
            paddingVertical: 3,
            paddingHorizontal: 10,
            borderRadius: 20,
            marginLeft: 8,
          },
          style,
        ]}
        key={key}
        variant={textVariant || 'display4'}
      >
        {children}
      </Text>
    )
  }
  return (
    <View
      style={[
        getTextStyle(),
        {
          alignSelf: 'center',
          alignItems: 'center',
          paddingVertical: 5,
          paddingHorizontal: 10,
          borderRadius: 20,
        },
        style,
      ]}
      key={key}
    >
      <Text
        variant={textVariant || 'display4'}
        style={[style, { borderWidth: 0 }]}
      >
        {children}
      </Text>
    </View>
  )
}

export default Badge
