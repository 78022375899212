import React from 'react'
import { Image, StyleSheet, View, useWindowDimensions } from 'react-native'
import { AppheroBeta } from '@apphero/assets'
import { AuthLoader } from '@libs/components'
import { useTheme } from '@libs/theme'
import { isMobile } from '@libs/utils/src/screenLayout'
import { useAtom } from 'jotai'
import { ScrollView } from 'react-native-web'
import { loadingMessage } from '../../constants'
import { brandDetailsAtom } from '../../utils/atom'

const MobileView = ({
  dropdownLeft,
  dropdownTop,
  dropdownWidth,
  handleLanguageChange,
  renderModule,
  showVideo,
  toggleDropdown,
  userSelectedLanguage,
  isLoading,
  routeName,
  brandLogoUrl,
  ...props
}) => {
  const { width } = useWindowDimensions()
  const { colors } = useTheme()
  const [brandDetails] = useAtom(brandDetailsAtom)

  const mobile = isMobile(width)

  if (isLoading)
    return (
      <AuthLoader
        label={loadingMessage?.[routeName]?.label}
        subText={loadingMessage?.[routeName]?.subText}
        brandDetails={brandDetails}
        brandLogoUrl={brandLogoUrl}
      />
    )

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.rightContainer(mobile),
          {
            backgroundColor: showVideo ? 'rgba(0,0,0,0.2)' : '',
            justifyContent: mobile ? 'space-between' : 'center',
          },
        ]}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.header}>
          <Image
            source={AppheroBeta}
            style={{
              height: 38,
              width: 146,
            }}
          />
          {brandDetails.brand && brandLogoUrl && (
            <>
              <View
                style={{
                  height: '1.15rem',
                  width: 2,
                  backgroundColor: colors.neutral,
                  marginHorizontal: '0.75rem',
                }}
              />
              <Image
                source={`${brandLogoUrl}/${brandDetails.brand?.replaceAll(
                  ' ',
                  '_',
                )}.png`}
                style={{ height: 60, width: 146 }}
                resizeMode="contain"
                alt={brandDetails.brand}
              />
            </>
          )}
          {/* <DropDown
            items={languageDropDown}
            value={userSelectedLanguage}
            toggleDropdown={toggleDropdown}
            position={{ left: dropdownLeft, top: dropdownTop }}
            dropdownWidth={dropdownWidth}
            labelColor={showVideo ? colors.white : ''}
            onPress={(selectedLanguage) =>
              handleLanguageChange(selectedLanguage)
            }
          /> */}
        </View>
        <ScrollView
          style={{
            backgroundColor: showVideo ? 'rgba(62, 137, 219, 0.3)' : '',
            borderRadius: 20,
          }}
          showsVerticalScrollIndicator
        >
          <View
            style={[
              {
                flex: 1,
                overflow: 'hidden',
                alignSelf: mobile ? 'flex-start' : 'center',
                width: mobile ? '100%' : '70%',
              },
            ]}
          >
            {renderModule(props)}
          </View>
        </ScrollView>
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  rightContainer: () => ({
    flex: 1,
    flexDirection: 'column',
    paddingTop: 20,
  }),
  header: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 20,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default MobileView
