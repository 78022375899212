import React, { useState } from 'react'
import { t } from 'i18next'
import { ScrollView, useWindowDimensions, View, StyleSheet } from 'react-native'
import { NeedSupport as NeedSupportImage } from '@apphero/assets'
import { ShimmerPlaceholder } from '@libs/components'
import { useTheme } from '@libs/theme'
import { findPaddingHorizontal } from '../../utils/findPaddingHorizontal'
import {
  FileUploadModal,
  NeedSupport,
  VoidBox,
  YouTubeVideoPlayer,
} from '../../components'
import { ActionRequired } from '../../components/actionRequired'
import { TitleHeader } from '../../components/headerTitle'
import { sort } from '../../utils/sorting'
import { categorizeActionItems } from '../../utils/categorizeActionItems'

const DesktopView = ({
  opportunities,
  modalVisible,
  setModalVisible,
  handleSelectedOpportunity,
  selectedOpportunity,
  toggleDropdown,
  handleFileUpload,
  dropDownRef,
  dropdownTop,
  dropdownLeft,
  dropdownWidth,
  handleSubmitAllOpportunityTaskFile,
  fetchDocumentTypes,
  isLoading,
  totalActionRequiredCount,
  documentType,
  isSubmitLoading,
}) => {
  const { width } = useWindowDimensions()
  const { colors } = useTheme()
  const [isVideoPlayerVisible, setIsVideoPlayerVisible] = useState(false)

  const findOpportunityFromOpportunities = opportunities?.find(
    (item) => item?.Id === selectedOpportunity?.WhatId,
  )

  return (
    <>
      <ScrollView
        contentContainerStyle={styles.mainContainer(width)}
        style={{
          backgroundColor: colors.backgroundSecondary,
          paddingBottom: 24,
        }}
      >
        <TitleHeader title={t('HEADER.ACTION_REQUIRED')} />
        <View style={styles.contentContainer}>
          {isLoading ? (
            <SkeletonLoaderGrid />
          ) : totalActionRequiredCount > 0 ? (
            <View style={[styles.section]}>
              {sort(opportunities, 'CreatedDate')?.map((opportunity) => {
                if (opportunity) {
                  const restructuredResponse =
                    categorizeActionItems(opportunity)
                  return (
                    <ActionRequired
                      key={opportunity.id} // Assuming `id` is a unique identifier for each opportunity
                      opportunityDetails={restructuredResponse}
                      handleSelectedOpportunity={handleSelectedOpportunity}
                      setModalVisible={setModalVisible}
                      handleSubmitAllOpportunityTaskFile={
                        handleSubmitAllOpportunityTaskFile
                      }
                      isActionRequiredScreen
                      fetchDocumentTypes={fetchDocumentTypes}
                      isSubmitLoading={isSubmitLoading}
                    />
                  )
                }
                return null
              })}
            </View>
          ) : (
            <VoidBox
              heading1={t('EMPTY_CONTAINER.NO_ACTION_REQUIRED_HEADING')}
              heading2={t('EMPTY_CONTAINER.NO_ACTION_REQUIRED')}
            />
          )}

          <NeedSupport
            width={width}
            imageSrc={NeedSupportImage}
            imageAlt="Support Image"
            onShowVideo={() => setIsVideoPlayerVisible(true)}
            canHaveVideo
          >
            Watch our helpful video tutorial to guide you through the
            application process. Learn how to check your application status,
            complete any outstanding tasks, locate your offer letter, and find
            the documents you've uploaded. We've also included contact
            information for your advisor in case you have any questions. We're
            here to support you every step of the way!.
          </NeedSupport>
        </View>

        <FileUploadModal
          visible={modalVisible}
          title={t('APPLICATION_DETAIL.ATTACH_DOCUMENT')}
          toggleDropdown={toggleDropdown}
          handleSave={(documentDetails) =>
            handleFileUpload(documentDetails, findOpportunityFromOpportunities)
          }
          handleClose={() => setModalVisible(false)}
          dropDownPosition={{ left: dropdownLeft + 10, top: dropdownTop + 10 }}
          dropdownWidth={dropdownWidth}
          documentType={documentType}
          dropDownRef={dropDownRef}
          selectedDropDownValue={selectedOpportunity?.documentType}
          taskId={selectedOpportunity?.Id}
        />
      </ScrollView>
      <View style={{ position: 'absolute', top: '66vh', right: 170 }}>
        <YouTubeVideoPlayer
          videoId={process.env.REACT_APP_ACTION_REQUIRED_VIDEO_ID}
          onClose={() => {
            setIsVideoPlayerVisible(false)
          }}
          isVideoPlayerVisible={isVideoPlayerVisible}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  mainContainer: (width) => ({
    paddingHorizontal: findPaddingHorizontal(width),
    paddingVertical: 56,
    gap: 22,
  }),
  contentContainer: {
    gap: 56,
    flexDirection: 'row',
    flex: 1,
  },
  section: {
    flexDirection: 'column',
    gap: 24,
    flex: 1,
  },
  rowContainer: {
    flexDirection: 'row',
    gap: 20,
    width: '100%',
  },
  sidePanel: {
    backgroundColor: 'rgba(221, 223, 238, 1)',
    borderRadius: 10,
  },
  rightContainer: (width) => ({
    borderRadius: 13,
    background: 'transparent',
    borderWidth: 0.2,
    borderColor: 'rgba(0, 0, 0, 0.2)',
    backgroundColor: '#DDE0EE',
    minWidth: width >= 1260 ? 400 : 300,
    alignSelf: 'baseline',
  }),
  divider: {
    borderBottomWidth: 2,
    borderColor: 'rgba(0,0,0,0.2)',
    borderStyle: 'solid',
    opacity: 0.2,
    marginVertical: 20,
  },
})

export default DesktopView

const SkeletonLoaderGrid = () => (
  <View
    style={{
      flexDirection: 'column',
      marginTop: 10,
      flexWrap: 'wrap',
      gap: 20,
      flex: 1,
    }}
  >
    {[1, 2, 3, 4].map(() => (
      <ShimmerPlaceholder
        style={{
          width: '100%',
          height: 262,
          borderRadius: 10,
        }}
      />
    ))}
  </View>
)
