export function categorizeActionItems(data) {
  // Initialize arrays to hold categorized items
  const pendingItems = []
  const completedItems = []

  // Loop through each item and categorize based on Status
  data?.ActionRequiredItems?.Items.forEach((item) => {
    if (item.Status === 'Completed' || item.Status === 'Cancelled') {
      completedItems.push(item)
    } else {
      pendingItems.push(item)
    }
  })

  // Update the original data object
  data.pendingActionRequiredItems = pendingItems
  data.completedActionRequiredItems = completedItems

  // Optionally, remove the old ActionRequiredItems property
  // delete data.ActionRequiredItems;

  return data
}
