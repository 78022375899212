import React, { useState } from 'react'
import {
  TouchableOpacity,
  View,
  Text,
  Image,
  useWindowDimensions,
} from 'react-native'
import { Loader } from '@libs/components'
import { Icon } from '@app-hero/native-icons'
import { isWeb } from '@libs/utils/src/screenLayout'

export const DocumentItem = ({
  index,
  item,
  onPress,
  style,
  documentCount,
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const { width } = useWindowDimensions()
  const isDesktop = isWeb(width)

  return (
    <View
      style={{
        shadowColor: 'rgba(3, 30, 125, 0.05)',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 1,
        shadowRadius: 10,
        elevation: 5,
        borderRadius: 10,
        backgroundColor: '#F3F3F3',
        paddingHorizontal: !isDesktop ? 0 : 32,
        paddingVertical: !isDesktop ? 0 : 25,
        width:
          window.innerWidth <= 1200 || documentCount === 1
            ? '100%'
            : window.innerWidth <= 1370
            ? '47%'
            : '48%',
      }}
      key={index}
    >
      <View
        style={{
          flexDirection: 'row',
          gap: 24,
          paddingHorizontal: 20,
          paddingVertical: !isDesktop ? 20 : 0,
          justifyContent: 'space-between',
        }}
      >
        <Image
          source={item?.placeholderImage}
          style={{ height: 48, width: 40 }}
        />
        <View style={{ flex: 1, justifyContent: 'flex-start', gap: 4 }}>
          <Text style={style} numberOfLines={2}>
            {item?.Name}
          </Text>
          <Text>{item?.Name?.split('.').pop().toUpperCase()}</Text>
        </View>
        {isFetching ? (
          <View>
            <Loader
              size={20}
              style={{
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            />
          </View>
        ) : (
          <TouchableOpacity
            onPress={async () => {
              setIsFetching(true)
              await onPress()
              setIsFetching(false)
            }}
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Icon name="Download" />
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}
