import { useTheme } from '@libs/theme'
import React, { useEffect, useState } from 'react'
import { View, Text, FlatList, Pressable, StyleSheet } from 'react-native'

const VerticalTimeline = ({ data, activeTitle }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const { colors } = useTheme()

  useEffect(() => {
    if (activeTitle === 'Withdrawn') {
      data?.pop()
      data?.push({
        id: 5,
        title: 'WITHDRAWN',
        status: 'Withdrawn',
      })
    } else {
      data?.pop()
      data?.push({
        id: 5,
        title: 'ENROLMENT',
        status: 'Enrolment',
      })
    }
    const index = data.findIndex((item) => item?.status === activeTitle)
    if (index !== -1) {
      setActiveIndex(index)
    }
  }, [activeTitle, data])

  const renderItem = ({ item, index }) => {
    const isActive = index === activeIndex
    const isBeforeActive = index < activeIndex

    return (
      <Pressable
        key={item.id.toString()}
        style={({ pressed }) => [
          styles.itemContainer,
          pressed && styles.pressedItemContainer,
        ]}
        disabled
      >
        <View style={styles.itemWrapper(isActive)}>
          <View style={styles.dotLineContainer}>
            <View
              style={
                isActive || isBeforeActive
                  ? [
                      styles.activeDot(isActive),
                      { backgroundColor: colors.buttonPrimary },
                    ]
                  : [styles.dot, { backgroundColor: 'black' }]
              }
            />
            {item?.id < data.length && (
              <View
                style={{
                  height: 50,
                  width: 1,
                  alignSelf: 'center',
                  borderLeftWidth: 1,
                  borderStyle: isBeforeActive ? 'solid' : 'dashed',
                  borderColor: isBeforeActive ? colors.buttonPrimary : 'gray',
                }}
              />
            )}
          </View>
          <View style={styles.textContainer}>
            <Text
              style={
                isActive || isBeforeActive
                  ? [
                      styles.activeTitle,
                      {
                        color: colors.buttonPrimary,
                        fontWeight: isActive ? '700' : '400',
                      },
                    ]
                  : [styles.title, { color: 'black' }]
              }
            >
              {item.title}
            </Text>
          </View>
        </View>
      </Pressable>
    )
  }

  return (
    <View style={styles.container}>
      <FlatList
        data={data}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.timeline}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    paddingHorizontal: 25,
    paddingVertical: 32,
    borderRadius: 10,
  },
  timeline: {
    alignItems: 'flex-start',
  },
  itemContainer: {
    paddingHorizontal: 36,
    alignItems: 'center',
  },
  pressedItemContainer: {
    opacity: 0.5,
  },
  itemWrapper: (isActive) => ({
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginLeft: isActive ? -1 : 0, // active dot resize
  }),
  dotLineContainer: {
    alignItems: 'center',
    marginRight: 15,
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  activeDot: (isActive) => ({
    width: isActive ? 12 : 10,
    height: isActive ? 12 : 10,
    borderRadius: isActive ? 6 : 10,
  }),
  textContainer: {
    flex: 1,
    marginTop: -3,
  },
  title: {
    color: 'gray',
  },
  activeTitle: {
    color: 'blue',
    fontWeight: 'bold',
  },
})

export default VerticalTimeline
