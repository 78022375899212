import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteBasketItem } from '@app-hero/shared/src/api'
import { userProfile } from '@app-hero/shared/src/utils/atom'
import { useAtom } from 'jotai'

export const useDeleteBasketItem = () => {
  const [userprofile] = useAtom(userProfile)
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (data) => {
      const payload = {
        email: data?.email || '',
        externalId: data?.externalId,
      }
      const response = deleteBasketItem(payload)
      return response
    },
    {
      onSuccess: async () => {
        queryClient.resetQueries([`getBasketItems-${userprofile?.email}`])
      },
    },
  )
  return { mutation }
}
