import {
  View,
  StyleSheet,
  useWindowDimensions,
  TouchableOpacity,
} from 'react-native'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import {
  Button,
  Loader,
  PasswordInput,
  Text,
  TextInput,
} from '@libs/components'
import { Icon } from '@app-hero/native-icons'
import { isMobile, isTablet, isWeb } from '@libs/utils/src/screenLayout'
import { CustomAuth } from '@libs/utils'
// import OTPTextView from 'react-native-otp-textinput'
import OTPTextView from '@twotalltotems/react-native-otp-input'
import { useAtom } from 'jotai'
import { useTheme } from '@libs/theme'
import {
  OTPVerification,
  ResetPasswordAtom,
  errorMessageAtom,
  signUpDetailsAtom,
} from '../../utils/atom'

const ForgotPassword = ({ isLoading, setIsLoading }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const [forgotPasswordCredential, setForgotPasswordCredential] =
    useAtom(ResetPasswordAtom)
  const [errorMessage, setErrorMessage] = useAtom(errorMessageAtom)
  const [showOTPVerification, setshowOTPVerification] = useAtom(OTPVerification)
  const [hasOTPSent, setHasOTPSent] = useState(false)
  const isFocused = useIsFocused()
  const navigation = useNavigation()
  const { width } = useWindowDimensions()
  const mobile = isMobile(width)
  const tablet = isTablet(width)
  const [seconds, setSeconds] = useState(59)
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const route = useRoute()
  const routeName = route?.name
  const isDesktop = isWeb(width)
  const [, setSignupDetails] = useAtom(signUpDetailsAtom)
  const [isInputFieldFocused, setIsInputFieldFocused] = useState(false)

  const atIndex = forgotPasswordCredential?.email?.indexOf('@')
  const repeatCount = atIndex > 0 ? atIndex - 1 : 1
  const username =
    forgotPasswordCredential?.email?.substring(0, 1) +
    '*'.repeat(repeatCount) +
    forgotPasswordCredential?.email?.substring(atIndex)
  // cta enable variable
  const [isCTAEnable, setIsCTAEnable] = useState(false)
  const [isContinueEnable, setIsContinueEnable] = useState(false)

  const [passwordErrorMessage, setPasswordErrorMessage] = useState({
    error: '',
    errorKey: '',
  })

  const handleChange = (field, text) => {
    setErrorMessage('')
    setForgotPasswordCredential((previousData) => ({
      ...previousData,
      [field]: text,
    }))
  }
  const initialValue = {
    country: '',
    countryCode: '',
    firstName: '',
    lastName: '',
    email: '',
    mobile: {
      dialCode: '',
      mobileNumber: '',
    },
    password: '',
    repeatPassword: '',
  }

  useEffect(() => {
    setSignupDetails(initialValue)
    setErrorMessage('')
  }, [])

  // Adjust useEffect to only handle the countdown
  useEffect(() => {
    let interval
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((sec) => sec - 1)
      }, 1000)
    } else {
      clearInterval(interval)
      setButtonDisabled(false) // Enable the button when countdown is done
    }
    return () => clearInterval(interval)
  }, [seconds])

  useEffect(() => {
    if (showOTPVerification === false) {
      setForgotPasswordCredential((prev) => ({
        ...prev,
        newPassword: '',
        confirmPassword: '',
      }))
    }
  }, [showOTPVerification])

  useEffect(() => {
    // Enable button when timer reaches zero
    if (seconds === 0) {
      setButtonDisabled(false)
    }
  }, [seconds])

  const handleResendClick = async () => {
    // Reset the timer
    await handleForgotPassword()
    setSeconds(59)
    setButtonDisabled(true)
    // Implement actual resend logic here
    console.log('OTP resend request sent.')
  }

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const emailPattern = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const isValidEmail = emailPattern.test(forgotPasswordCredential?.email)
      const isValidPassword =
        forgotPasswordCredential.newPassword?.length > 0 &&
        forgotPasswordCredential.confirmPassword?.length > 0
      const code = forgotPasswordCredential?.code?.length === 6

      if (isValidEmail && isValidPassword && code) setIsCTAEnable(true)
      else setIsCTAEnable(false)
      if (isValidEmail) setIsContinueEnable(true)
      else setIsContinueEnable(false)

      if (
        forgotPasswordCredential.newPassword !==
          forgotPasswordCredential.confirmPassword &&
        forgotPasswordCredential.confirmPassword?.length > 0
      ) {
        setPasswordErrorMessage({
          error: t('ERROR_MESSAGE.PASSWORD_NOT_MATCH'),
          errorKey: 'ERROR_MESSAGE.PASSWORD_NOT_MATCH',
        })
        if (code && isValidPassword) {
          setIsCTAEnable(false)
        }
      }
      if (
        forgotPasswordCredential.newPassword ===
        forgotPasswordCredential.confirmPassword
      ) {
        setPasswordErrorMessage({
          error: '',
          errorKey: '',
        })
        if (code && isValidPassword) {
          setIsCTAEnable(true)
        }
      }
    })()
  }, [isFocused, forgotPasswordCredential])

  const handleForgotPassword = async () => {
    // here we are calling the forgot password logic
    const res = await CustomAuth.forgotPasswordAsync(
      forgotPasswordCredential.email.toLowerCase(),
    )
    if (res?.message) {
      if (res?.name === 'UserNotFoundException') {
        return setErrorMessage('User does not exist')
      }
      return setErrorMessage(res?.message)
    }
    if (res?.CodeDeliveryDetails) {
      setshowOTPVerification(true)
      setHasOTPSent(true)
      // Start the timer here after successful OTP request
      setSeconds(59)
      setButtonDisabled(true)
    }
    setshowOTPVerification(true)
    setHasOTPSent(true)
  }

  // const handleCloseModal = ({ setModalVisible = () => {}, type }) => {
  //   setModalVisible(false)
  //   if (type === 'login') {
  //     navigation.navigate('login')
  //   }
  // }
  const handleSubmitPassword = async () => {
    setIsLoading(true)
    const response = await CustomAuth.resetPasswordAsync(
      forgotPasswordCredential.email.toLowerCase(),
      forgotPasswordCredential.code,
      forgotPasswordCredential.newPassword,
    )
    if (response !== 'SUCCESS') {
      setErrorMessage(`${response?.message}`)
    } else {
      navigation.navigate('login')
      setSignupDetails(initialValue)
      setForgotPasswordCredential({
        email: '',
        code: '',
        newPassword: '',
        confirmPassword: '',
      })
    }
    setIsLoading(false)
  }

  const handleGoBack = () => {
    navigation.navigate('login')
    setSignupDetails(initialValue)
    setErrorMessage('')
  }

  return (
    <View style={[styles.loginContainer(mobile, tablet, isDesktop)]}>
      {!showOTPVerification && (
        <View style={{ width: '100%' }}>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              // marginBottom: '2rem',
              justifyContent: 'center',
            }}
          >
            {(mobile || tablet) && (
              <View style={{ flex: 0.1, justifyContent: 'flex-start' }}>
                {routeName !== 'privacy-policy' && routeName !== 'login' && (
                  <TouchableOpacity
                    onPress={() => {
                      setErrorMessage('')
                      if (navigation.canGoBack()) navigation.goBack()
                      else {
                        navigation.navigate('login')
                      }
                    }}
                  >
                    <View style={styles.leftHeader}>
                      <Icon name="ArrowNarrowLeft" color={colors.neutral} />
                    </View>
                  </TouchableOpacity>
                )}
              </View>
            )}
            <View style={{ flex: mobile || tablet ? 0.9 : 1 }}>
              <Text
                color={colors.titleColor}
                variant={isDesktop ? 'heading2' : 'heading3'}
                style={{
                  textAlign: mobile || tablet ? 'start' : 'center',
                  fontWeight: 700,
                  letterSpacing: 0.3,
                }}
              >
                {t('AUTH.RESET_PASSWORD')}
              </Text>

              <View style={{ paddingTop: 12, paddingBottom: 24 }}>
                <Text
                  color="#64748B"
                  style={{
                    textAlign: mobile || tablet ? 'start' : 'center',
                    fontSize: 14,
                  }}
                >
                  {t('AUTH.RESET_PASSWORD_DESC')}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.loginFields}>
            <TextInput
              value={forgotPasswordCredential.email}
              onChangeText={(text) => handleChange('email', text)}
              placeholder={t('TEXT_INPUT.EMAIL')}
              editable={!hasOTPSent}
              disableFieldColor={colors.backgroundOnNeutral}
              style={{
                height: 56,
                borderRadius: isDesktop ? 4 : 8,
                borderColor: isInputFieldFocused ? colors.primary : '#E2E8F0',
              }}
              inputFieldStyle={{
                marginLeft: 12,
                fontSize: 16,
                fontWeight: '600',
              }}
              iconName="Mail2"
              iconSize={24}
              onFocus={() => setIsInputFieldFocused(true)}
              onBlur={() => setIsInputFieldFocused(false)}
            />
          </View>
          {Boolean(errorMessage) && (
            <Text variant="display3" color={colors.onAlert}>
              {errorMessage ===
              'Cannot reset password for the user as there is no registered/verified email or phone_number'
                ? 'User does not exist'
                : errorMessage}
            </Text>
          )}
          {/* forgot enter email sections */}
          <View style={styles.buttonsContainer}>
            <ButtonContainer
              isLoading={isLoading}
              showButton
              handleButton={handleForgotPassword}
              style={{ backgroundColor: colors.draftBlue }}
              disable={
                forgotPasswordCredential.code?.length >= 3 &&
                forgotPasswordCredential.newPassword ===
                  forgotPasswordCredential.confirmPassword &&
                forgotPasswordCredential.newPassword?.length > 5 &&
                forgotPasswordCredential.confirmPassword?.length > 5 &&
                forgotPasswordCredential.email
              }
              isContinueEnable={isContinueEnable}
            />
          </View>
          {!mobile && !tablet && (
            <TouchableOpacity onPress={handleGoBack}>
              <Text
                style={{
                  fontSize: 14,
                  textAlign: 'center',
                  color: colors.draftBlue,
                  fontWeight: 700,
                }}
              >
                {t('AUTH.BACK_TO_SIGNIN')}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      )}
      {showOTPVerification && (
        <View style={{}}>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {(mobile || tablet) && (
              <View style={{ flex: 0.1, justifyContent: 'flex-start' }}>
                {routeName !== 'privacy-policy' && routeName !== 'login' && (
                  <TouchableOpacity
                    onPress={() => {
                      if (showOTPVerification) {
                        setshowOTPVerification(false)
                        setErrorMessage('')
                        return
                      }
                      if (navigation.canGoBack()) navigation.goBack()
                      else {
                        navigation.navigate('login')
                        setSignupDetails(initialValue)
                      }
                    }}
                  >
                    <View style={styles.leftHeader}>
                      <Icon name="ArrowNarrowLeft" color={colors.neutral} />
                    </View>
                  </TouchableOpacity>
                )}
              </View>
            )}
            <View style={{ flex: mobile || tablet ? 0.9 : 1 }}>
              <Text
                color={colors.titleColor}
                variant="heading2"
                style={{
                  textAlign: mobile || tablet ? 'start' : 'center',
                  fontWeight: 700,
                  letterSpacing: 0.3,
                }}
              >
                {t('AUTH.OTP_RESET')}
              </Text>
              <View style={{ paddingTop: 12, paddingBottom: 24 }}>
                <Text
                  color="#64748B"
                  style={{
                    textAlign: mobile || tablet ? 'start' : 'center',
                    fontSize: 14,
                  }}
                >
                  {`${t('AUTH.OTP_RESET_DES_1')} `}
                  <Text color="#64748B" style={{ fontWeight: 700 }}>
                    {username}
                  </Text>
                  {` ${t('AUTH.OTP_RESET_DES_2')}`}
                </Text>
              </View>
            </View>
          </View>
          <OTPTextView
            onCodeChanged={(text) => {
              if (text.length <= 6) {
                setForgotPasswordCredential((prevData) => ({
                  ...prevData,
                  code: text,
                }))
              }
            }}
            inputCount={6}
            tintColor={colors.primary}
            keyboardAppearance="dark"
            placeholderCharacter="-"
            codeInputFieldStyle={styles.OtpFields(mobile, tablet)}
            codeInputHighlightStyle={styles.OtpFields(mobile, tablet)}
          />
          {Boolean(errorMessage) && (
            <Text variant="display3" color={colors.onAlert}>
              {errorMessage}
            </Text>
          )}
          {/* forgot enter otp email section */}

          <View style={[styles.SignUpFields, {}]}>
            <View
              style={[
                {
                  marginTop: mobile || tablet ? 24 : 30,
                  width: '100%',
                },
              ]}
            >
              <PasswordInput
                value={forgotPasswordCredential.newPassword}
                onChangeText={(text) => handleChange('newPassword', text)}
                placeholder={t('TEXT_INPUT.PASSWORD')}
                onPassAllValid={(val) => setPasswordErrorMessage(val)}
                style={{
                  height: 56,
                  marginBottom: 16,
                  borderRadius: 4,
                  borderColor: 'rgba(22, 36, 71, 0.2)',
                }}
                iconName="Lock"
                iconSize={24}
                inputFieldStyle={{ marginLeft: 12 }}
              />
              <PasswordInput
                value={forgotPasswordCredential.confirmPassword}
                onChangeText={(text) => handleChange('confirmPassword', text)}
                placeholder={t('TEXT_INPUT.RE_ENTER_PASSWORD')}
                style={{
                  height: 56,
                  marginBottom: 16,
                  borderRadius: 4,
                  borderColor: 'rgba(22, 36, 71, 0.2)',
                }}
                iconName="Lock"
                iconSize={24}
                inputFieldStyle={{ marginLeft: 12 }}
              />
              {Boolean(passwordErrorMessage.error) ? (
                <Text
                  variant="display3"
                  color={colors.onAlert}
                  style={{ marginBottom: 20 }}
                >
                  {passwordErrorMessage.error}
                </Text>
              ) : null}
              <PasswordInput
                value={forgotPasswordCredential.newPassword}
                onChangeText={(text) => {
                  handleChange('newPassword', text)
                }}
                placeholder={t('TEXT_INPUT.PASSWORD')}
                onPassAllValid={(val) => setPasswordErrorMessage(val)}
                style={{
                  height: 56,
                  marginBottom: 10,
                  borderRadius: 4,
                }}
                iconName="Lock"
                hasValidateOnly
                hasValidate
              />
              <Button
                label={t('BUTTON.SET_PASSWORD')}
                buttonColor={colors.primary}
                labelColors={colors.white}
                onPress={() => {
                  handleSubmitPassword()
                }}
                disable={!isCTAEnable}
                buttonStyle={{ width: '100%', borderRadius: 4, height: 56 }}
                labelStyle={{ fontWeight: 700 }}
              />
            </View>
          </View>
          {/* otp resend button section */}
          <View style={{ padding: 20, alignItems: 'center', paddingBottom: 0 }}>
            <Text
              variant="display4"
              color={colors.primaryPlaceHolder}
              style={{ margin: '0.5rem' }}
            >
              You can request a new code in: 00:
              {String(seconds).padStart(2, '0')}
            </Text>
            <TouchableOpacity
              onPress={() => handleResendClick()}
              disabled={isButtonDisabled}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <View>
                  <Text
                    variant="display4"
                    color={colors.primary}
                    style={{
                      fontWeight: 800,
                      opacity: isButtonDisabled ? 0.5 : 0.8,
                      margin: '0.5rem',
                    }}
                  >
                    Resend Verification Code
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      )}

      <View style={{ flex: 1, justifyContent: 'flex-end', marginBottom: 30 }}>
        <View
          style={{
            alignSelf: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: mobile ? 0 : 0,
          }}
        >
          <Text style={{ fontSize: 14, fontWeight: 400 }}>
            {t('AUTH.DONT_HAVE_ACCOUNT')}
          </Text>
          <TouchableOpacity
            onPress={() => {
              setErrorMessage('')
              navigation.navigate('privacy-policy')
            }}
          >
            <Text
              style={{
                color: colors.draftBlue,
                fontWeight: 700,
                letterSpacing: 0.2,
                fontSize: 14,
              }}
            >
              {' '}
              {t('AUTH.SIGN_UP')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

const ButtonContainer = ({
  disable,
  isLoading,
  showButton,
  handleButton = () => {},
  isContinueEnable,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  if (showButton) {
    return (
      <Button
        label={t('BUTTON.CONTINUE')}
        buttonColor={colors.draftBlue}
        buttonStyle={{ borderRadius: 4, height: 56 }}
        labelStyle={{ color: colors.white, fontWeight: 700, fontSize: 16 }}
        onPress={() => handleButton()}
        disable={!isContinueEnable}
      />
    )
  }
  if (isLoading) {
    return <Loader size={20} />
  }
  return (
    <Button
      label={t('AUTH.RESET_PASSWORD_BUTTON')}
      buttonColor={colors.draftBlue}
      onPress={() => handleButton()}
      disable={!disable}
      buttonStyle={{ borderRadius: 4, height: 56 }}
      labelStyle={{ color: colors.white, fontSize: 16, fontWeight: 700 }}
    />
  )
}

const styles = StyleSheet.create({
  authLinks: {
    alignSelf: 'center',
  },
  buttonsContainer: {
    width: '100%',
    marginVertical: 32,
  },
  loginContainer: (mobile, tablet, isDesktop) => {
    return {
      flexDirection: 'column',
      alignItems: isDesktop && 'center',
      justifyContent: 'space-between',
      paddingTop: !isDesktop ? '6vh' : '10vh',
      paddingLeft: isDesktop
        ? window.innerWidth <= 1150
          ? '0%'
          : '16%'
        : '6%',
      paddingRight: isDesktop
        ? window.innerWidth <= 1150
          ? '0%'
          : '16%'
        : '6%',
      rowGap: mobile || tablet ? 20 : 25,
      minHeight: '92vh',
    }
  },
  loginFields: {
    width: '100%',
  },
  OtpFields: (mobile, tablet) => {
    return {
      borderWidth: 1,

      height: 45,
      borderRadius: 12,
      border: '0.1px solid gray',
      color: 'black',
      fontWeight: 700,
    }
  },
})

export default ForgotPassword
