import {
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Platform,
  Image,
} from 'react-native'
import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { Icon } from '@app-hero/native-icons'
import { Button, DropDown, Loader, Text } from '@libs/components'
import { useTranslation } from 'react-i18next'
import { BrandImageWhite } from '@apphero/assets'
import { useTheme } from '@libs/theme'

const DesktopView = ({
  location,
  intake,
  programmes,
  dropdownTop,
  dropdownLeft,
  dropdownWidth,
  isLoading,
  toggleDropdown,
  handleLocationSelection,
  handleIntakeSelection,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const navigation = useNavigation()

  if (isLoading) {
    return (
      <ScrollView
        contentContainerStyle={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader />
      </ScrollView>
    )
  }
  return (
    <View style={styles({ colors }).container}>
      <LeftContainer content={programmes} />
      <View style={styles({ colors }).rightContainer}>
        <Header title={programmes?.brandFullName} />
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            paddingVertical: 60,
            paddingHorizontal: 40,
            alignItems: 'center',
          }}
        >
          <View>
            <Text
              color={colors.neutral}
              style={{ marginBottom: 30, fontWight: 400, fontSize: 24 }}
            >
              {t('APPLICATION.HEADING')}
            </Text>
            <View
              style={{
                padding: 20,
                maxWidth: 500,
                minWidth: 400,
                backgroundColor: colors.white,
                borderRadius: 10,
                marginBottom: 30,
              }}
            >
              <View
                style={{
                  padding: 20,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  backgroundColor: colors.background,
                  borderRadius: 10,
                }}
              >
                <Text
                  variant="heading5"
                  color={colors.neutral}
                  style={{ maxWidth: 285 }}
                >
                  {programmes?.name || ''}
                </Text>
                {/* <Image
                  source={BrandImageWhite}
                  style={{ height: 71, width: 102 }}
                  resizeMode="contain"
                /> */}
                <View
                  style={{
                    backgroundColor: colors.primary,
                    justifyContent: 'center',
                    padding: 5,
                    borderRadius: 5,
                    shadowColor: 'rgba(3, 30, 125, 0.05)',
                    shadowOffset: { width: 0, height: 2 },
                    elevation: 5,
                    shadowOpacity: 1,
                    shadowRadius: 10,
                    maxWidth: 124,
                  }}
                >
                  <Text variant="display3" color={colors.white}>
                    {programmes?.brand || ''}
                  </Text>
                </View>
              </View>
              <View style={{ marginTop: 20 }}>
                <Text variant="display4" color={colors.onNeutral}>
                  Campus
                  <Text variant="display4" color={colors.onAlert}>
                    *
                  </Text>
                </Text>
                <DropDown
                  items={location}
                  toggleDropdown={toggleDropdown}
                  position={{ top: dropdownTop, left: dropdownLeft }}
                  dropdownWidth={dropdownWidth}
                  style={{ width: '100%', margin: 0, marginTop: 5 }}
                  onPress={(selectedFilter) =>
                    handleLocationSelection(selectedFilter)
                  }
                  label="Select"
                />
              </View>
              <View style={{ marginTop: 20 }}>
                <Text variant="display4" color={colors.onNeutral}>
                  Intake
                  <Text variant="display4" color={colors.onAlert}>
                    *
                  </Text>
                </Text>
                <DropDown
                  items={intake}
                  toggleDropdown={toggleDropdown}
                  position={{ top: dropdownTop, left: dropdownLeft }}
                  style={{ width: '100%', margin: 0, marginTop: 5 }}
                  dropdownWidth={dropdownWidth}
                  onPress={(selectedFilter) =>
                    handleIntakeSelection(selectedFilter)
                  }
                  disable={intake?.length === 0}
                  label="Select"
                />
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                maxWidth: 500,
                justifyContent: 'space-between',
              }}
            >
              <Button
                label={t('BUTTON.START_APPLICATION')}
                buttonColor={colors.primary}
                labelColors={colors.white}
                buttonStyle={{
                  paddingVertical: 10,
                  paddingHorizontal: 30,
                }}
              />
              <Button
                label={t('BUTTON.CANCEL')}
                buttonColor={colors.buttonVariant1}
                labelColors={colors.onNeutral}
                buttonStyle={{
                  paddingVertical: 10,
                  paddingHorizontal: 30,
                }}
                onPress={() => {
                  if (Platform.OS === 'web') {
                    window.history.go(-1)
                  } else {
                    navigation.goBack()
                  }
                }}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

const LeftContainer = ({ content }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  return (
    <View style={styles({ colors }).leftContainer}>
      <Image source={BrandImageWhite} style={{ height: 38, width: 148 }} />
      <Text
        color={colors.white}
        variant="heading4"
        style={{
          marginBottom: 30,
          marginTop: 40,
        }}
      >
        {t('APPLICATION.PROGRAMME_INFORMATION')}
      </Text>
      <Text
        variant="heading5"
        color={colors.white}
        style={{ marginBottom: 30 }}
      >
        {`${t('APPLICATION.SUBJECT')}: `}
        <Text variant="display4" style={{ marginLeft: 5 }} color={colors.white}>
          {content?.subject || '-'}
        </Text>
      </Text>
      <Text
        variant="heading5"
        color={colors.white}
        style={{ marginBottom: 30 }}
      >
        {`${t('APPLICATION.LEVEL')}: `}
        <Text
          variant="display4"
          style={{ marginLeft: 5, maxWidth: 240, marginTop: 2 }}
          color={colors.white}
        >
          {content?.level || '-'}
        </Text>
      </Text>
      <Text variant="heading5" color={colors.white}>
        {`${t('APPLICATION.DESCRIPTION')}: `}
        <Text
          variant="display4"
          style={{ marginLeft: 5, maxWidth: 240, marginTop: 2 }}
          color={colors.white}
        >
          {content?.description || '-'}
        </Text>
      </Text>
      <FilePicker />
    </View>
  )
}

const FilePicker = ({}) => {
  return (
    <View
      style={{ height: 81, borderRadius: 4, borderWidth: 2, padding: 5 }}
    ></View>
  )
}

const Header = ({ title }) => {
  const { colors } = useTheme()
  const navigation = useNavigation()
  return (
    <View style={styles(colors).header}>
      <TouchableOpacity
        onPress={() => {
          if (Platform.OS === 'web') {
            window.history.go(-1)
          } else {
            navigation.goBack()
          }
        }}
      >
        <Icon
          name="ArrowNarrowLeft"
          height={24}
          width={24}
          color={colors.text}
        />
      </TouchableOpacity>
      <View style={styles(colors).headerText}>
        <Icon
          name="ParliamentBuilding"
          height={27}
          width={29}
          color={colors.onNeutral}
        />
        <Text
          variant="display2"
          style={{ paddingLeft: 20 }}
          color={colors.onNeutral}
        >
          {title}
        </Text>
      </View>
    </View>
  )
}

const styles = (props) =>
  StyleSheet.create({
    container: {
      height: '100vh',
      flexDirection: 'row',
    },
    leftContainer: {
      backgroundColor: props.colors?.primary,
      paddingHorizontal: 25,
      paddingVertical: 20,
      maxWidth: 300,
    },
    rightContainer: {
      flex: 1,
      flexDirection: 'column',
      position: 'relative',
    },
    header: {
      flexDirection: 'row',
      paddingVertical: 27,
      paddingHorizontal: 30,
      shadowColor: 'rgba(3, 30, 125, 0.05)',
      shadowOffset: { width: 0, height: 2 },
      elevation: 5,
      shadowOpacity: 1,
      shadowRadius: 10,
    },
    headerText: {
      paddingLeft: 20,
      flexDirection: 'row',
    },
  })

export default DesktopView
