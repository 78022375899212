import { Icon } from '@app-hero/native-icons'
import { useTheme } from '@libs/theme'
import { BlurView } from 'expo-blur'
import React, { useState, useRef, useEffect } from 'react'
import { View, TouchableOpacity, Modal } from 'react-native'

const YouTubeVideoPlayerMobile = ({
  videoId,
  onClose,
  isVideoPlayerVisible,
}) => {
  const [isMinimized, setIsMinimized] = useState(false)
  const handleMinimizeToggle = () => {
    setIsMinimized(!isMinimized)
  }

  return (
    !isMinimized && (
      <Modal visible={isVideoPlayerVisible} transparent animationType="none">
        <BlurView
          intensity={60}
          style={{
            flex: 1,
            backgroundColor: 'rgba(2, 21, 71, 0.37)',
          }}
        >
          <View style={{ flex: 1 }}>
            <DraggableIframe
              videoId={videoId}
              onClose={onClose}
              isMinimized={isMinimized}
              onMinimize={handleMinimizeToggle}
            />
          </View>
        </BlurView>
      </Modal>
    )
  )
}

const DraggableIframe = ({ videoId, onClose }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
  const iframeRef = useRef(null)
  const { colors } = useTheme()

  const handleMouseDown = (e) => {
    setIsDragging(true)
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    })
  }

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y,
      })
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, dragStart])

  return (
    <View
      ref={iframeRef}
      style={{ width: window.innerWidth + 10, height: window.innerHeight }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          backgroundColor: 'black',
          paddingHorizontal: 24,
          paddingVertical: 24,
          height: 60,
        }}
      >
        <TouchableOpacity onPress={onClose}>
          <Icon name="Close" width={32} height={32} color={colors.white} />
        </TouchableOpacity>
      </View>
      <div onMouseDown={handleMouseDown} style={{ cursor: 'move' }}>
        <iframe
          width={window.innerWidth + 10}
          height={window.innerHeight - 60}
          src={`https://www.youtube.com/embed/${videoId}?autoplay=0&controls=1&playsinline=1&draggable=1&fs=0`}
          title="YouTube video player"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; web-share"
          allowFullScreen
          style={{ border: 'none' }}
        />
      </div>
    </View>
  )
}

export default YouTubeVideoPlayerMobile
