import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { HeaderLeft } from '../components'
import { Stacks } from './Stacks'

const Stack = createNativeStackNavigator()

const ScreenOptions = {
  header: () => <HeaderLeft />,

  headerStyle: {
    borderBottomWidth: 1,
    shadowColor: 'transparent',
    height: 80,
  },
}

const createNavigators = (initialRouteName) => (
  <Stack.Navigator
    initialRouteName={initialRouteName}
    screenOptions={ScreenOptions}
  >
    {(() => Stacks(Stack))()}
  </Stack.Navigator>
)

export const HomeStackNavigator = () => createNavigators('login')
