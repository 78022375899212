export const UPDATE_NOTIFICATION_STATUS = `
mutation UpdateNotificationStatus($input: InAppNotificationReadStatusUpdateInput!) {
  updateNotificationStatus(input: $input) {
    messageId
    readStatus
    email
    createdAt
  }
}
`
