import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import {
  getAllPrograms,
  getPricingBookDetail,
  getProgramsDetailsById,
} from '@app-hero/shared/src/api'

export const useProgramsInfiniteData = ({
  enabled,
  queryKey,
  limit,
  filters,
}) => {
  let programData = []
  const {
    data,
    isFetching,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKey],
    initialData: [],
    queryFn: async ({ pageParam = 0 }) => {
      const payload = {
        filterQuery: {
          size: limit,
          from: pageParam * limit,
          filters,
        },
      }
      if (!queryKey.includes('undefined')) {
        programData = await getAllPrograms(payload)
      }

      return programData
    },

    getNextPageParam: (_lastPage, pages) => {
      const paginatedData = []
      pages?.forEach((page) => {
        page?.programList?.map((item) => {
          paginatedData?.push(item)
        })
      })
      if (paginatedData?.length < _lastPage?.total) return pages?.length
    },

    enabled: enabled && !!queryKey,

    retry: false,
    onError: (error) => {
      console.log(error)
    },
  })

  const allData = (data?.pages || [])
    .flatMap((page) => page?.programList || [])
    .filter((item) => item !== undefined)
  return {
    allData,
    isFetching,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  }
}

export const usePriceBookDetail = ({
  isFocused,
  programId,
  brand,
  queryKey,
}) => {
  const fetchPricingBookDetail = async () => {
    const pricingData = await getPricingBookDetail({
      id: programId,
      brand,
    })
    return pricingData?.priceEntries
  }
  const { data, isFetching, refetch } = useQuery({
    queryKey: [queryKey],
    queryFn: () => fetchPricingBookDetail(),
    enabled: isFocused && !!programId && !!brand,
    initialData: [],
  })
  return { data, isFetching, refetch }
}

export const useProgramByID = ({ isFocused, programId, queryKey }) => {
  const fetchProgramById = async () => {
    const programData = await getProgramsDetailsById({ id: programId })
    return programData
  }
  const { data, isFetching, refetch } = useQuery({
    queryKey: [queryKey],
    queryFn: fetchProgramById,
    enabled: isFocused && !!programId,
    initialData: [],
  })
  return { data, isFetching, refetch }
}
