import React, { useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  ScrollView,
  Platform,
  useWindowDimensions,
  Linking,
} from 'react-native'
import { Text, ShimmerPlaceholder } from '@libs/components'
import { Icon } from '@app-hero/native-icons'
import { useTranslation } from 'react-i18next'
import { spacing, useTheme } from '@libs/theme'
import { isTablet, isWeb } from '@libs/utils/src/screenLayout'
import { DocumentLogo } from '@apphero/assets'
import { handleNavigation } from '../../utils/function'
import { FileUploadModal } from '../../components'
import EmptyContainer from '../../components/container/EmptyContainer'
import { DocumentItem } from '../../components/viewApplication/documentItem'
import { sort } from '../../utils/sorting'
import { signedurl } from '../../api'
import { TitleHeader } from '../../components/headerTitle'
import TitleTimeline from '../../components/timelineChart'
import { timeLineData } from '../../constants/dropDownData'
import { findPaddingHorizontal } from '../../utils/findPaddingHorizontal'
import { OutstandingRequiredAction } from '../../components/actionRequired/outstandingRequiredActionCard'
import { CompletedRequiredAction } from '../../components/actionRequired/completedRequiredActionCard'
import { getProgrammeName } from '../../utils/getProgramName'

const DesktopView = ({
  setTabData,
  canShowMenu,
  brandLogoUrl,
  admissionsNotesTabData,
  applicationDocuments,
  applicationLetterDocuments,
  documentType,
  dropdownTop,
  dropdownLeft,
  dropdownWidth,
  dropDownRef,
  handleFileUpload,
  informationList,
  isDocumentFetching,
  moreIconDropDownDetails,
  handleMoreIcon,
  isLoading,
  modalVisible,
  opportunities,
  setModalVisible,
  tabData,
  toggleDropdown,
  scrollViewRef,
  childRef,
  handleScrollComment,
  handleUpdateComment,
  isActiveComment,
  taskDetails,
  handleSubmitAllOpportunityTaskFile,
  selectedOpportunity,
  handleSelectedOpportunity,
  setSelectedOpportunity,
  isSubmitLoading,
  canExpandCompletedAction,
  navigationText,
}) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const web = isWeb(width)
  const tablet = isTablet(width)
  const { colors } = useTheme()

  if (isLoading) {
    return (
      <View
        style={{
          backgroundColor: '#fff',
          flexDirection: 'row',
          paddingHorizontal: findPaddingHorizontal(width),
          minHeight: '100vh',
        }}
      >
        <View
          style={{
            flexDirection: 'column',
            flex: 1,
            marginTop: 56,
          }}
        >
          {web && (
            <TouchableOpacity
              onPress={() => {
                if (Platform.OS === 'web') {
                  window.history.go(-1)
                } else {
                  // navigation.goBack()
                }
              }}
              style={{
                marginBottom: !tablet && spacing.spacing2,
                flexDirection: 'row',
                columnGap: 14,
              }}
            >
              <Icon name="ArrowNarrowLeft" height={20} width={20} />
              <Text
                style={{
                  textTransform: 'uppercase',
                  colors: colors.onNeutral,
                  letterSpacing: '1.5%',
                }}
              >
                {navigationText}
              </Text>
            </TouchableOpacity>
          )}
          <ShimmerPlaceholder
            style={{
              height: 150,
              width: '100%',
              marginTop: spacing.spacing5,
              borderRadius: spacing.spacing5,
            }}
          />

          <View style={{ flexDirection: 'row', width: '100%', gap: 24 }}>
            <ShimmerPlaceholder
              style={{
                height: 600,
                marginTop: spacing.spacing5,
                borderRadius: spacing.spacing5,
                flex: 1,
              }}
            />
            <ShimmerPlaceholder
              style={{
                height: 286,
                width: 400,
                marginTop: spacing.spacing5,
                borderRadius: spacing.spacing5,
              }}
            />
          </View>
        </View>
      </View>
    )
  }

  const programName = opportunities?.ProgrammeName__c
    ? `${opportunities?.ProgrammeName__c} ${
        opportunities?.Delivery_Mode__c
          ? `, ${opportunities?.Delivery_Mode__c}`
          : ''
      }`
    : ''

  return (
    <ScrollView
      style={{
        backgroundColor: colors.backgroundPrimary,
        flex: 1,
        paddingHorizontal: findPaddingHorizontal(width),
      }}
      contentContainerStyle={{
        flexGrow: 1,
        marginVertical: 57,
      }}
      ref={scrollViewRef}
    >
      {web && (
        <TouchableOpacity
          onPress={() => {
            if (Platform.OS === 'web') {
              window.history.go(-1)
            } else {
              // navigation.goBack()
            }
          }}
          style={{
            marginBottom: !tablet && spacing.spacing8,
            flexDirection: 'row',
            columnGap: 14,
          }}
        >
          <Icon name="ArrowNarrowLeft" height={20} width={20} />
          <Text
            style={{
              textTransform: 'uppercase',
              colors: colors.onNeutral,
              letterSpacing: '1.5%',
            }}
          >
            {navigationText}
          </Text>
        </TouchableOpacity>
      )}

      <TitleHeader>
        <View style={{ flexDirection: 'row' }}>
          <Image
            source={`${brandLogoUrl}/${opportunities.BusinessUnitFilter__c?.replaceAll(
              ' ',
              '_',
            )}_LIGHT.png`}
            style={{ width: '14%', height: 50, aspectRatio: 16 / 9 }}
            alt={opportunities?.brand}
            resizeMode="contain"
          />

          <View
            style={{
              borderWidth: 1,
              borderColor: colors.white,
              borderStyle: 'solid',
              opacity: 0.4,
              marginHorizontal: 31,
            }}
          />
          <Text
            variant="heading3"
            color={colors.white}
            style={{
              alignSelf: 'center',
            }}
          >
            {programName || opportunities?.Name?.split('_')[0]}
          </Text>
        </View>
      </TitleHeader>

      <View style={styles.mainContainer(tablet, width)}>
        <LeftContainer
          informationList={informationList}
          brandLogoUrl={brandLogoUrl}
          data={opportunities}
          applicationDocuments={applicationDocuments}
          applicationLetterDocuments={applicationLetterDocuments}
          isDocumentFetching={isDocumentFetching}
          tabData={tabData}
          setModalVisible={setModalVisible}
          moreIconDropDownDetails={moreIconDropDownDetails}
          canShowMenu={canShowMenu}
          handleMoreIcon={handleMoreIcon}
          isLoading={isLoading}
          setTabData={setTabData}
          web={web}
          tablet={tablet}
          childRef={childRef}
          handleUpdateComment={handleUpdateComment}
          handleScrollComment={handleScrollComment}
          admissionsNotesTabData={admissionsNotesTabData}
          isActiveComment={isActiveComment}
          taskDetails={taskDetails}
          selectedOpportunity={selectedOpportunity}
          setSelectedOpportunity={setSelectedOpportunity}
          handleSelectedOpportunity={handleSelectedOpportunity}
          handleSubmitAllOpportunityTaskFile={
            handleSubmitAllOpportunityTaskFile
          }
          isSubmitLoading={isSubmitLoading}
          canExpandCompletedAction={canExpandCompletedAction}
          programName={getProgrammeName(opportunities)}
        />
        <View
          style={{
            gap: 20,
            alignItems: 'flex-start',
          }}
        >
          <View style={styles.rightContainer(width)}>
            <Text
              variant="display1"
              style={{
                paddingBottom: 20,
                color: colors.textPrimary,
                fontSize: 24,
                fontWeight: 700,
              }}
            >
              {t('APPLICATION_DETAIL.ANY_QUESTION')}
            </Text>
            <View style={[styles.divider]} />
            <Text
              variant="display3"
              color={colors.neutral}
              style={[styles.rightSideTextContainer, { width: 350 }]}
            >
              {!opportunities?.isDirectSales
                ? 'Contact your in-country representative '
                : 'Contact your Advisor '}
              <b>
                {!opportunities?.isDirectSales
                  ? opportunities?.Agent_Contact__r?.Name
                    ? opportunities?.Agent_Contact__r?.Name + ', '
                    : ''
                  : opportunities?.OwnerName__c
                  ? opportunities?.OwnerName__c + ', '
                  : ''}
              </b>
            </Text>

            <View
              style={{
                flexDirection: 'row',
                paddingBottom: 5,
                paddingTop: 8,
              }}
            >
              <Icon name="Mail" width={18} height={18} />
              <Text
                variant="display3"
                color={colors.neutral}
                numberOfLines={2}
                style={{
                  marginLeft: 10,
                  fontSize: 15,
                  fontWeight: 700,
                  borderBottomWidth: 1.5,
                  color: colors.textPrimary,
                }}
              >
                {!opportunities?.isDirectSales
                  ? opportunities?.Agent_Contact__r?.Email
                  : opportunities?.OwnerEmail__c}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: 10,
              }}
            >
              <Icon name="Phone" width={18} height={18} />
              <Text
                variant="display3"
                color={colors.neutral}
                style={{
                  marginLeft: 10,
                  fontSize: 15,
                  fontWeight: 700,
                  borderBottomWidth: 1.5,
                  color: colors.textPrimary,
                }}
              >
                {!opportunities?.isDirectSales
                  ? opportunities?.Agent_Contact__r?.Phone
                  : opportunities?.Owner?.Phone}
              </Text>
            </View>

            {opportunities?.isDirectSales &&
              opportunities?.Owner?.Appointment_Booking_Link__c && (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: 15,
                  }}
                >
                  <Icon name="CalenderIcon" width={18} height={18} />
                  <TouchableOpacity
                    onPress={() => {
                      const url =
                        opportunities?.Owner?.Appointment_Booking_Link__c
                      if (url) {
                        Linking.openURL(url).catch((err) =>
                          console.error('Failed to open URL:', err),
                        )
                      }
                    }}
                  >
                    <Text
                      variant="display3"
                      color={colors.neutral}
                      style={{
                        marginLeft: 10,
                        fontSize: 15,
                        fontWeight: 700,
                        borderBottomWidth: 1.5,
                        color: colors.textPrimary,
                      }}
                    >
                      {t('APPLICATION_DETAIL.BOOK_A_MEETING')}
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
            <Text
              variant="display3"
              color={colors.neutral}
              style={[
                styles.rightSideTextContainer,
                { width: 350, marginTop: 20 },
              ]}
            >
              Or speak to our support team by opening the chat window below.
            </Text>
          </View>
        </View>
      </View>

      <FileUploadModal
        visible={modalVisible}
        title={t('APPLICATION_DETAIL.ATTACH_DOCUMENT')}
        toggleDropdown={toggleDropdown}
        handleSave={(documentDetails) => handleFileUpload(documentDetails)}
        handleClose={() => setModalVisible(false)}
        dropDownPosition={{ left: dropdownLeft + 10, top: dropdownTop + 10 }}
        dropdownWidth={dropdownWidth}
        documentType={documentType}
        dropDownRef={dropDownRef}
        selectedDropDownValue={selectedOpportunity?.documentType}
        taskId={selectedOpportunity?.Id}
      />
    </ScrollView>
  )
}

const LeftContainer = ({
  applicationDocuments,
  applicationLetterDocuments,
  data,
  informationList,
  isDocumentFetching,
  setModalVisible,
  setTabData,
  tabData,
  isLoading,
  admissionsNotesTabData,
  childRef,
  handleScrollComment,
  handleUpdateComment,
  isActiveComment,
  taskDetails,
  selectedOpportunity,
  setSelectedOpportunity,
  handleSelectedOpportunity,
  handleSubmitAllOpportunityTaskFile,
  isSubmitLoading,
  canExpandCompletedAction,
  programName,
}) => {
  const { t } = useTranslation()

  return (
    <View
      style={{
        flexDirection: 'row',
        flex: 1,
        marginTop: 14,
      }}
    >
      {isLoading ? (
        <ShimmerPlaceholder
          style={{
            width: '100%',
            height: 400,
            borderRadius: spacing.spacing5,
          }}
        />
      ) : (
        <View
          style={{
            flexDirection: 'column',
            width: '100%',
            rowGap: 20,
          }}
        >
          <Text variant="heading4" style={{ fontSize: 20 }}>
            {t('APPLICATION_DETAIL.STATUS_OF_APPLICATION')}
          </Text>
          <TitleTimeline
            data={timeLineData}
            activeTitle={data?.ApplicationStatus?.status}
          />
          <TabSection
            informationList={informationList}
            applicationDocuments={applicationDocuments}
            applicationLetterDocuments={
              applicationLetterDocuments?.documents || []
            }
            setTabData={setTabData}
            isDocumentFetching={isDocumentFetching}
            tabData={tabData}
            setModalVisible={setModalVisible}
            data={data}
            childRef={childRef}
            handleScrollComment={handleScrollComment}
            admissionsNotesTabData={admissionsNotesTabData}
            handleUpdateComment={handleUpdateComment}
            isActiveComment={isActiveComment}
            taskDetails={taskDetails}
            selectedOpportunity={selectedOpportunity}
            setSelectedOpportunity={setSelectedOpportunity}
            handleSelectedOpportunity={handleSelectedOpportunity}
            handleSubmitAllOpportunityTaskFile={
              handleSubmitAllOpportunityTaskFile
            }
            isSubmitLoading={isSubmitLoading}
            canExpandCompletedAction={canExpandCompletedAction}
            programName={programName}
          />
        </View>
      )}
    </View>
  )
}

const TabSection = ({
  setTabData,
  applicationDocuments,
  applicationLetterDocuments,
  informationList,
  tabData,
  data,
  admissionsNotesTabData,
  taskDetails,
  isDocumentFetching,
  setModalVisible,
  selectedOpportunity,
  setSelectedOpportunity,
  handleSelectedOpportunity,
  handleSubmitAllOpportunityTaskFile,
  isSubmitLoading,
  canExpandCompletedAction,
  programName,
}) => {
  const [activeTab, setActiveTab] = useState(
    tabData.findIndex((item) => item.isActive === true),
  )

  const { colors } = useTheme()
  const { t } = useTranslation()
  const [expandedIndex, setExpandedIndex] = useState(0)

  useEffect(() => {
    setExpandedIndex(0)
  }, [activeTab])

  const messageCounts = [0, 0, 0, 0]

  messageCounts[0] = taskDetails?.pendingActionRequiredItems?.length

  return (
    <View style={{ marginTop: 36 }}>
      <Tab
        activeTab={activeTab}
        tabData={tabData}
        messageCounts={messageCounts}
        conditionsCount={
          !!data?.Admissions_Condition__c &&
          !Object.keys(applicationLetterDocuments)?.length > 0
        }
        labelStyle={{ fontSize: 16 }}
        admissionsNotesTabData={admissionsNotesTabData}
        setActiveTab={(selectedTab) => {
          const tabDataCopy = [...tabData]
          tabDataCopy?.map((tabItem, index) => {
            if (index === selectedTab) {
              tabDataCopy[index] = { ...tabDataCopy[index], isActive: true }
            } else {
              tabDataCopy[index] = { ...tabDataCopy[index], isActive: false }
            }
          })
          setActiveTab(selectedTab)
          setTabData(tabDataCopy)
        }}
      />

      <View
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: 10,
          marginTop: 32,
          paddingHorizontal: 32,
          width: '100%',
        }}
      >
        {activeTab === 1 ? (
          <View>
            {activeTab === 1 ? (
              <View>
                {informationList?.length > 0 ? (
                  informationList?.map((item, index) => (
                    <React.Fragment key={index}>
                      <View
                        style={{
                          flexDirection: 'row',
                          marginVertical: 7,
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          paddingVertical: 16,
                        }}
                      >
                        <View
                          style={{
                            flexWrap: 'wrap',
                          }}
                        >
                          <Text
                            variant="display5"
                            style={{ color: '#545B61', fontSize: 16 }}
                          >
                            {item?.displayName ? `${item?.displayName}:` : ''}
                          </Text>
                        </View>
                        <View style={{ flexWrap: 'wrap' }}>
                          {item?.field === 'MeetLink' ? (
                            <TouchableOpacity
                              onPress={() => {
                                const url = item?.fieldName
                                if (url && url !== '-') {
                                  Linking.openURL(url).catch((err) =>
                                    console.error('Failed to open URL:', err),
                                  )
                                }
                              }}
                            >
                              <Text
                                style={{
                                  color: colors.textTeritiary,
                                  fontSize: 16,
                                  fontWeight: '700',
                                  textDecorationLine: 'underline', // Optional: underline the text
                                }}
                                variant="display5"
                              >
                                {t('APPLICATION_DETAIL.BOOK_A_MEETING')}
                              </Text>
                            </TouchableOpacity>
                          ) : (
                            <Text
                              style={{
                                color: colors.textTeritiary,
                                fontSize: 16,
                                fontWeight: 700,
                                flex: 1,
                              }}
                              variant="display5"
                            >
                              {item?.fieldName !== '-'
                                ? item?.fieldName
                                : 'NIL'}
                            </Text>
                          )}
                        </View>
                      </View>
                      {index !== informationList.length - 1 && (
                        <View style={[styles.divider]} />
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <EmptyContainer hasFixedHight />
                )}
              </View>
            ) : null}
          </View>
        ) : null}

        <View>
          {activeTab === 2 ? (
            <View style={{ paddingVertical: 32 }}>
              {applicationDocuments === undefined ? (
                isDocumentFetching ? (
                  [...Array(4)].map(() => (
                    <>
                      <View style={[styles.divider, { marginTop: 10 }]} />
                      <ShimmerPlaceholder
                        style={{
                          height: 250,
                          width: '100%',
                          marginTop: spacing.spacing5,
                          borderRadius: spacing.spacing5,
                        }}
                      />
                    </>
                  ))
                ) : (
                  <EmptyContainer
                    hasFixedHight
                    noData={'There are no Application Documents'}
                    imageSource={DocumentLogo}
                  />
                )
              ) : (
                Object?.entries(applicationDocuments || {})?.map(
                  ([keys, value], index) => (
                    <View
                      style={{
                        paddingHorizontal: 8,
                        width: '100%',
                      }}
                    >
                      <DocumentAccordion
                        label={keys}
                        data={value}
                        index={index}
                        activeTab={activeTab}
                        style={{
                          color: '#162447',
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                        height={180}
                        sectionCount={
                          Object?.entries(applicationDocuments).length
                        }
                        setExpandedIndex={setExpandedIndex}
                        expandedIndex={expandedIndex}
                      />
                    </View>
                  ),
                )
              )}
            </View>
          ) : null}
        </View>

        <View>
          {activeTab === 3 ? (
            <View style={{ paddingVertical: 32 }}>
              {isDocumentFetching ? (
                [...Array(1)].map((_, i) => (
                  <>
                    <ShimmerPlaceholder
                      style={{
                        height: 250,
                        width: '100%',
                        marginTop: spacing.spacing5,
                        borderRadius: spacing.spacing5,
                      }}
                    />
                  </>
                ))
              ) : Object.keys(applicationLetterDocuments)?.length > 0 ? (
                <View
                  style={{
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  {Object.keys(applicationLetterDocuments)?.length > 0 ? (
                    Object?.entries(applicationLetterDocuments || {})?.map(
                      ([keys, value], index) => (
                        <DocumentAccordion
                          label={keys}
                          data={value}
                          index={index}
                          style={{
                            color: '#162447',
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                          height={209}
                          sectionCount={
                            Object.keys(applicationLetterDocuments)?.length
                          }
                          setExpandedIndex={setExpandedIndex}
                          expandedIndex={expandedIndex}
                        />
                      ),
                    )
                  ) : (
                    <>
                      <Text
                        variant="display2"
                        color={colors.neutral}
                        style={{ marginBottom: spacing.spacing5 }}
                      >
                        {t('APPLICATION_DETAIL.OFFER_LETTER')}
                      </Text>
                      <EmptyContainer hasFixedHight />
                    </>
                  )}
                </View>
              ) : (
                <EmptyContainer hasFixedHight />
              )}
            </View>
          ) : null}
        </View>
        {activeTab === 0 ? (
          <View style={{ paddingVertical: 32 }}>
            {activeTab === 0 ? (
              <View style={{ gap: 24 }}>
                <OutstandingRequiredAction
                  data={taskDetails}
                  setModalVisible={setModalVisible}
                  setSelectedOpportunity={setSelectedOpportunity}
                  handleSelectedOpportunity={handleSelectedOpportunity}
                  selectedOpportunity={selectedOpportunity}
                  handleSubmitAllOpportunityTaskFile={
                    handleSubmitAllOpportunityTaskFile
                  }
                  businessUnitFilter={data?.BusinessUnitFilter__c}
                  isSubmitLoading={isSubmitLoading}
                  programName={programName}
                />
                <CompletedRequiredAction
                  data={taskDetails}
                  canExpandCompletedAction={canExpandCompletedAction}
                  programName={programName}
                />
              </View>
            ) : null}
          </View>
        ) : null}
      </View>
    </View>
  )
}

export const Tab = ({
  tabData,
  activeTab,
  setActiveTab,
  disabledFieldIndex,
  messageCounts,
  labelStyle,
  style,
}) => {
  const { colors } = useTheme()

  return (
    <View
      style={{
        paddingBottom: 15,
        borderBottomColor: 'rgba(0, 0, 0, 0.2)',
        borderBottomWidth: 1,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          gap:
            window.innerWidth <= 1162 ||
            (window.innerWidth >= 1240 && window.innerWidth <= 1251)
              ? 32
              : 40,
          flexWrap: 'wrap',
        }}
      >
        {tabData?.map((item, index) => (
          <TouchableOpacity
            style={[
              activeTab === index ? styles.activeTab : styles.unActiveTab,
              {
                paddingBottom: 10,
                alignItems: 'center',
              },
              style,
            ]}
            disabled={disabledFieldIndex === index}
            onPress={() => index !== disabledFieldIndex && setActiveTab(index)}
            key={index}
          >
            <Text
              variant="display3"
              color={
                activeTab === index ? colors.primary : 'rgba(0, 0, 0, 0.4)'
              }
              style={[
                {
                  fontWeight: activeTab === index ? 700 : 400,
                  borderColor:
                    activeTab === index ? colors.primary : 'transparent',
                },
                labelStyle,
              ]}
            >
              {item.displayText}
              {activeTab === index ? (
                <View
                  style={{
                    position: 'absolute',
                    bottom: -15,
                    left: 0,
                    borderRadius: 5,
                    height: 3,
                    width: '100%',
                    backgroundColor: colors.borderBlue,
                  }}
                />
              ) : null}
            </Text>
            {item.displayText === 'Offers' && (
              <View
                style={{
                  backgroundColor: 'red',
                  marginLeft: 6,
                  width: 15,
                  height: 15,
                  borderRadius: 15 / 2,
                  alignItems: 'center',
                }}
              >
                <Text
                  variant="display6"
                  style={{ color: 'white', fontSize: 12 }}
                >
                  {' • '}
                </Text>
              </View>
            )}
            {messageCounts[index] > 0 && (
              <Text
                variant={activeTab === index ? 'heading5' : 'display3'}
                style={{
                  color:
                    activeTab === index ? colors.primary : 'rgba(0, 0, 0, 0.4)',
                  fontSize: activeTab === index ? 16 : 16,
                  paddingLeft: 3,
                }}
              >
                ({messageCounts[index]})
              </Text>
            )}
          </TouchableOpacity>
        ))}
      </View>
    </View>
  )
}

const DocumentAccordion = ({
  label,
  data,
  index,
  activeTab,
  style,
  height,
  sectionCount,
  expandedIndex,
  setExpandedIndex,
}) => {
  const { colors } = useTheme()

  const { t } = useTranslation()

  const toggleAccordion = () => {
    if (expandedIndex === index) {
      setExpandedIndex(null)
    } else {
      setExpandedIndex(index)
    }
  }

  return (
    <View style={{ width: '100%' }}>
      {activeTab === 2 ? (
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            backgroundColor: colors.accent,
            borderRadius: 8,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          onPress={toggleAccordion}
        >
          <Text
            variant="display3"
            color={colors.textPrimary}
            style={{ marginRight: 10, fontWeight: '500' }}
          >
            {label}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              columnGap: 20,
              alignItems: 'center',
            }}
          >
            <Text style={{ color: colors.textPrimary }}>
              {data.length !== 1
                ? `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENTS')})`
                : `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENT')})`}
            </Text>

            <Icon
              name={expandedIndex === index ? 'ArrowUp' : 'ArrowDown'}
              color="black"
              width={24}
              height={24}
            />
          </View>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            backgroundColor: colors.accent,
            borderRadius: 8,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          onPress={toggleAccordion}
        >
          <Text
            color={colors.textPrimary}
            style={{ marginRight: 10, fontWeight: 500 }}
            variant="display3"
          >
            {label.replaceAll('-', ' ')}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              columnGap: 20,
              alignItems: 'center',
            }}
          >
            <Text style={{ color: colors.textPrimary }}>
              {data.length !== 1
                ? `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENTS')})`
                : `(${data.length} ${t('APPLICATION_DETAIL.DOCUMENT')})`}
            </Text>
            <Icon
              name={expandedIndex === index ? 'ArrowUp' : 'ArrowDown'}
              color="black"
              width={24}
              height={24}
            />
          </View>
        </TouchableOpacity>
      )}
      {expandedIndex === index ? (
        <View
          style={{
            paddingTop: 24,
            gap: 28,
            width: '100%',
            flexDirection: window.innerWidth <= 1200 ? 'column' : 'row',
            flexWrap: 'wrap',
          }}
        >
          {expandedIndex === index &&
            sort(data, 'CreatedDate')?.map((item) => {
              const documentData = item?.CreatedDate?.split('T')[0]
              const finalDocumentDate = documentData
                ?.split('-')
                .reverse()
                .join('/')
              return (
                <DocumentItem
                  index={index}
                  item={item}
                  finalDocumentDate={finalDocumentDate}
                  onPress={async () => {
                    const resp = await signedurl(item)
                    if (resp.FilePath) {
                      await handleNavigation({
                        url: resp.FilePath,
                        fileName: item?.Name,
                        translation: t,
                      })
                    }
                  }}
                  style={style}
                  height={height}
                  documentCount={data?.length}
                />
              )
            })}
        </View>
      ) : null}
      {sectionCount - 1 !== index && <View style={styles.dividerWithPadding} />}
    </View>
  )
}

const styles = StyleSheet.create({
  mainContainer: () => ({
    paddingVertical: 42,
    flexDirection: 'row',
    width: '100%',
    columnGap: 33,
  }),

  activeTab: {
    flexDirection: 'row',
  },
  unActiveTab: {
    flexDirection: 'row',
  },
  row: {
    flexDirection: 'row',
    marginTop: 4,
  },
  dividerWithPadding: {
    borderWidth: 0.2,
    borderColor: '#000000',
    borderStyle: 'solid',
    opacity: 0.2,
    marginVertical: 24,
  },
  divider: {
    borderWidth: 0.2,
    borderColor: '#000000',
    borderStyle: 'solid',
    opacity: 0.2,
  },
  attachDocument: {
    flexDirection: 'row',
    padding: 25,
    alignItems: 'center',
    columnGap: 3,
    borderRadius: 16,
  },
  rightContainer: (width) => ({
    padding: 32,
    borderRadius: 8,
    backgroundColor: '#DDE0EE',
    minWidth: width >= 1260 ? 400 : 300,
  }),
  rightSideTextContainer: {
    paddingBottom: 10,
    marginTop: 20,
    fontSize: 15,
    lineHeight: 21,
  },
  notes: {
    marginHorizontal: 20,
    marginBottom: 10,
    marginTop: 30,
    backgroundColor: '#FFF4E0',
    borderColor: '#D4691B',
    borderRadius: 5,
    borderWidth: 0.2,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  activeTabConatiner: {
    paddingHorizontal: 20,
    marginHorizontal: 20,
    borderRadius: 16,
    marginTop: 5,
    justifyContent: 'space-between',
  },
})

export default DesktopView
