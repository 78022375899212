export const uploadFileToS3 = async ({ url, file }) => {
  const formData = new FormData()
  formData.append('file', file)

  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: formData,
      header: {
        'Content-Type': 'image/png',
      },
    }).then((res) => res.json())
    return response
  } catch (err) {
    console.log(err)
  }
}

export const handleFilePicker = async ({
  event,
  fileTypes,
  setFile = () => {},
  handleFileSelect,
}) => {
  const uploadedFile =
    event.dataTransfer?.files?.[0] || event.target?.files?.[0]

  if (uploadedFile) {
    const isValidFile = uploadedFile
      ? isFileValid({ uploadedFile, fileTypes })
      : false
    const base64Docs = await getBase64(uploadedFile)
    const fileJson = {
      name: uploadedFile?.name,
      size: uploadedFile?.size,
      type: uploadedFile?.type.split('/')[1],
      contentType: uploadedFile?.type,
    }
    setFile(fileJson)
    const fileURI = URL.createObjectURL(uploadedFile)
    if (isValidFile?.isValid) {
      handleFileSelect({
        file: base64Docs?.split(',')[1],
        fileName: uploadedFile.name,
        selectedFile: fileURI,
        fileJson,
      })
    } else {
      handleFileSelect({
        fileJson,
        error: isValidFile.message,
      })
    }
  }
}

const getBase64 = async (f) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(f)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const isFileValid = ({ uploadedFile, fileTypes }) => {
  if (uploadedFile?.size > 5242880) {
    return {
      isValid: false,
      message: 'Maximum file size: 5MB.',
    }
  }
  if (
    !fileTypes?.includes(uploadedFile?.name?.split('.').pop().toUpperCase())
  ) {
    return {
      isValid: false,
      message:
        'Accepted formats: JPEG, JPG, PDF, PNG. Please upload a file in one of these formats.',
    }
  }
  return {
    isValid: true,
    message: '',
  }
}
